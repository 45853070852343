import { Component, OnInit } from '@angular/core';
import {
    EsbmodelService,
    ProductionOrder,
    ToolReplace,
} from '../../swagger-client';
import { Subscription } from 'rxjs';
import {ActiveEntityMediatorService} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-tool-history',
    templateUrl: './tool-history.component.html',
})
export class ToolHistoryComponent implements OnInit {
    public currentOrder: ProductionOrder;
    public toolReplaces: ToolReplace[];

    private subs: Subscription[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit(): void {
        this.subs.push(
            this._activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                if (!currentPO) {
                    this.currentOrder = null;
                    return;
                }

                this.currentOrder = currentPO;

                this.esbModelService
                    .esbmToolReplaceGet(this.currentOrder.id)
                    .subscribe((data: ToolReplace[]) => {
                        this.toolReplaces = data;
                    });
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }
}
