import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSample } from '../../swagger-client';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import LocationEnum = MeasurementSample.LocationEnum;

export function locationLabel(location: LocationEnum | string): string {
    switch (location) {
        case LocationEnum.MACHINE:
            return 'Bij machine';
        case LocationEnum.MEASUREMENTROOM:
            return 'Meetkamer';
    }
}

@Pipe({
    name: 'measurementSampleLocation',
})
export class MeasurementSampleLocationPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    public transform(location: LocationEnum | string): SafeHtml {
        switch (location) {
            case LocationEnum.MACHINE:
                return this.sanitized.bypassSecurityTrustHtml(
                    '<strong i18n class="text-primary">' + locationLabel(location) + '</strong>',
                );
            case LocationEnum.MEASUREMENTROOM:
                return this.sanitized.bypassSecurityTrustHtml(
                    '<span i18n>' + locationLabel(location) + '</span>',
                );
        }
    }
}
