import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    WorkLog,
} from '../../swagger-client';
import { ActiveMachineService } from '../../services/active-machine/active-machine.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import {ActiveEmployeeService} from "../../services/active-employee/active-employee.service";

@Component({
    selector: 'betech-worklog',
    templateUrl: './worklog-list.component.html',
})
export class WorklogListComponent implements OnInit, OnDestroy {
    public worklogList: WorkLog[];
    public employeeList: Employee[];
    public orderList: ProductionOrder[];

    public activeMachine: ProcessingStation;
    private subs: Subscription[] = [];
    public activeEmployee: Employee;

    public worklogByCalculationNumber;

    constructor(
        private _esbmodelService: EsbmodelService,
        private _activeMachineService: ActiveMachineService,
        private _activeEmployeeService: ActiveEmployeeService,
    ) {
        this.worklogList = [];
        this.employeeList = [];
        this.orderList = [];
    }

    public ngOnInit(): void {
        this.subs.push(
            this._activeMachineService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                    this.getOrderList();
                },
            ),
            this._activeEmployeeService.activeEmployee$.subscribe(
                (activeEmployee: Employee) => {
                    this.activeEmployee = activeEmployee;
                },
            ),
        );

        this.worklogByCalculationNumber = environment.config.worklogByCalculationNumber;

        // Fill employee list
        this._esbmodelService.esbmEmployeeGet().subscribe(data => {
            this.employeeList = data.data;
        });
    }

    private getOrderList(): void {
        if (!this.activeMachine) {
            return;
        }

        this._esbmodelService
            .esbmProductionOrderGet(
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                this.activeMachine.name,
            )
            .subscribe(data => {
                this.orderList = data.data;
            });

        // Fill worklog list
        this._esbmodelService.esbmWorklogGet(this.activeMachine.id)
            .subscribe(data => {
            this.worklogList = data;
            this.sortWorklogList();
        });
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * Sort worklogList by 1. no end-date, 2. start-date DESC
     */
    public sortWorklogList(): void {
        this.worklogList = this.worklogList.sort((a, b) => {
            return (
                (!!a.end_datetime ? 1 : 0) - (!!b.end_datetime ? 1 : 0) ||
                new Date(b.start_datetime).getTime() - new Date(a.start_datetime).getTime()
            );
        });
    }
}
