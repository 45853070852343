<betech-select-dropdown
  class="select-dropdown--inline"
  placeholder="Alle machines"
  i18n-placeholder
  [items]="(processingStations$ | async)?.data"
  label="trumpf_id"
  search="trumpf_id"
  [model]="selectedItem"
  (modelChange)="selectedItem = $event"
>
</betech-select-dropdown>
