import {
    enableProdMode,
    MissingTranslationStrategy,
    TRANSLATIONS,
    TRANSLATIONS_FORMAT,
} from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from './environments/environment';
import { BetechModule } from './app/betech.module';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: environment.sentryDSN,
    environment: environment.environment,
    enabled: environment.sentryEnabled,
});

if (environment.production) {
    enableProdMode();
}

declare const require;

const translations = require(`raw-loader!./i18n/messages.nl.xlf`);

platformBrowserDynamic()
    .bootstrapModule(BetechModule, {
        missingTranslation: MissingTranslationStrategy.Ignore,
        providers: [
            { provide: TRANSLATIONS, useValue: translations },
            { provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
        ],
    })
    .catch(err => console.error(err));
