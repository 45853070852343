import { Pipe, PipeTransform } from '@angular/core';
import { ProductionOrder } from '../../swagger-client';
import StatusEnum = ProductionOrder.StatusEnum;

// TODO translation
export function statusLabel(status: StatusEnum): string {
    switch (status) {
        case StatusEnum.STARTED:
            return 'Actief';
        case StatusEnum.NOTSTARTED:
            return 'Start';
        case StatusEnum.ENDED:
            return 'Afgerond';
        case StatusEnum.PAUSED:
            return 'Gepauzeerd';
        case StatusEnum.PREPARED:
            return 'Instellen';
        case StatusEnum.RESUMED:
            return 'Hervat';
        case null:
        case undefined:
            return '';
        default:
            throw new Error('Status not found ' + status);
    }
}
@Pipe({
    name: 'orderStatus',
})
export class OrderStatusPipe implements PipeTransform {
    public transform(status: StatusEnum): string {
        return statusLabel(status);
    }
}
