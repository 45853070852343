<!-- Tool Position -->
<div class="betech-table__cell">
  <span *ngIf="!editMode">{{ toolXref.tool_position.name || '-' }}</span>
  <div
    *ngIf="editMode"
    class="input-group table-input-width time-input-tablet d-inline-flex"
    data-target-input="nearest"
  >
    <input
      [(ngModel)]="toolXref.tool_position.name"
      placeholder="Name"
      i18n-placeholder
      type="text"
      class="form-control"
    />
  </div>
</div>

<!-- Standard -->
<div class="betech-table__cell">
  <betech-checkbox
    (click)="toggleDefault()"
    [checked]="!!toolXref.is_default"
    *ngIf="toolXref.tool"
    [disabled]="!editMode"
  ></betech-checkbox>
</div>

<!-- Tool -->
<div class="betech-table__cell" style="width: 30rem">
  <ng-container *ngIf="!editMode">
    <span class="text-truncate"
      >{{ toolXref.tool?.articlenumber_pdc || '-' }} - {{ toolXref.tool?.name || '-' }}</span
    >
  </ng-container>

  <betech-select-dropdown
    *ngIf="editMode"
    placeholder="Selecteer een tool"
    [label]="labelByArticleNumberPdcName"
    [menuStyle]="{ margin: '-1rem', width: '37.5rem' }"
    [template]="selectItem"
    [required]="false"
    [items]="toolList"
    [model]="toolXref.tool"
    [customSearch]="true"
    (searchInputChange)="filterToolList($event)"
    (modelChange)="toolChange($event)"
  >
  </betech-select-dropdown>

  <ng-template #selectItem let-item="item">
    <div class="d-flex justify-content-center align-items-center">
      <div style="width: 30rem">{{ item.articlenumber_pdc }} - {{ item.name }}</div>
      <div style="width: 7.5rem" class="border-left border-primary">
        <button
          class="btn btn-sm"
          [ngClass]="{
            'btn-outline-dark': !item?.tool_sheet,
            'btn-outline-secondary': item?.tool_sheet
          }"
          (click)="showToolSheet($event, item)"
        >
          <i class="fas fa-file-pdf fa-fw"></i>
        </button>
      </div>
    </div>
  </ng-template>
</div>

<!-- Tool Sheet -->
<div
  class="betech-table__cell"
  style="width: 7.5rem"
  (click)="showToolSheet($event, toolXref.tool)"
>
  <button
    *ngIf="toolXref.tool"
    [disabled]="!hasToolSheet()"
    class="btn btn-sm"
    [ngClass]="{ 'btn-outline-secondary': hasToolSheet(), 'btn-outline-dark': !hasToolSheet() }"
  >
    <i class="fas fa-file-pdf fa-fw"></i>
  </button>
</div>

<!-- Quantity -->
<div class="betech-table__cell" [ngClass]="{ 'text-danger font-weight-bold': percentage >= 100 }">
  <span *ngIf="!editMode"
    >{{ (producedSinceLastReplace | number) || '-' }} /
    {{ (toolXref.expected_quantity | number) || '-' }}</span
  >
  <div *ngIf="editMode" class="input-group table-input-width d-inline-flex">
    <input [(ngModel)]="toolXref.expected_quantity" type="number" class="form-control" />
  </div>
</div>

<!-- Current State -->
<div class="betech-table__cell">
  <div class="progress">
    <div
      class="progress-bar"
      [ngClass]="
        percentage === 0
          ? 'bg-primary'
          : percentage === 100
          ? 'bg-danger text-white'
          : 'bg-secondary'
      "
      [ngStyle]="{ width: percentage + '%' }"
    >
      {{ percentage }}%
    </div>
  </div>
</div>

<!-- Forecast -->
<div class="betech-table__cell" [ngClass]="{ 'text-danger font-weight-bold': percentage >= 100 }">
  <span>{{ forecastInSeconds | forecast }}</span>
</div>

<!-- Last Replace -->
<div class="betech-table__cell">
  <span>{{ lastReplace?.timestamp | date: 'dd-MM-yyyy HH:mm' || '-' }}</span>
</div>

<!-- Change Button -->
<div class="betech-table__cell">
  <button
    *ngIf="
      toolXref.tool &&
      !editMode &&
      (!toolXref.tool_position.tool_position_group || groupLeaderXref.id === toolXref.id)
    "
    class="btn btn-sm btn-outline-secondary text-uppercase"
    (click)="replace()"
  >
    <span i18n>Wissel</span>
  </button>
</div>

<!-- Cube -->
<div class="betech-table__cell" (click)="editMode ? saveToolXref() : editToolXref()">
  <button class="btn btn-sm btn-outline-secondary">
    <i class="fas fa-fw" [ngClass]="editMode ? 'fa-save' : 'fa-pencil-alt'"></i>
  </button>
</div>
