<div class="container-fluid">
    <div class="row mx-1 mt-4 py-2 bg-white shadow-sm rounded overflow-hidden" style="z-index: 200">
        <!--Details-->
        <div class="col-2 m-1 px-2">
            <div class="container">
                <div class="row">
                    <div class="col-3 bg-primary rounded items-center text-white p-0" style="aspect-ratio: 1 / 1;">
                        <div class="d-flex align-content-around flex-wrap h-100">
                            <div class="w-100 text-center font-medium leading-6">
                                <span class="align-middle">
                                    {{activeProductionOrderPerformance.employee_initials}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col px-2">
                            <div class="d-flex align-content-around flex-wrap h-100 ">
                                <div class="w-100">
                                    <span class="h-50 align-middle">
                                        <strong> {{ activeProductionOrderPerformance.employee_name }} </strong>
                                    </span>
                                </div>
                                <div class="w-100">
                                    <span class="h-50 align-middle">
                                        <strong> Geen actieve orders </strong>
                                    </span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Production number-->
        <div class="col m-1 px-2 text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">

            </div>
        </div>
        <!--Fabrication Part Name-->
        <div class="col-2 m-1 px-2 text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">

            </div>
        </div>
        <!--Totaal-->
        <div class="col-3 m-1 px-2 text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">

            </div>
        </div>
        <!--Status-->
        <div class="col-3 m-1 px-2">
            <div class="d-flex align-content-around flex-wrap h-100">

            </div>
        </div>

        <!--button-->
        <div class="col p-0 my-n4">
            <a class="">
                <div class="d-flex align-content-around align-items-center justify-content-center h-100" style="background-color: rgb(229 231 235)">
                    <i class="fa fa-times"></i>
                </div>
            </a>
        </div>
    </div>
</div>
