import { Injectable } from '@angular/core';
import { Employee, EsbmodelService } from 'app/swagger-client';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    public employees: Employee[] = [];

    constructor(private esbModelService: EsbmodelService) {}

    public initialize(): void {
        this.esbModelService
            .esbmEmployeeGet()
            .subscribe((result: { data: Employee[] }) => (this.employees = result.data));
    }
}
