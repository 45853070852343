import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'betech-header',
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit {
    public title: string;

    constructor(
        private activatedroute: ActivatedRoute,
        private _router: Router
    ) {}

    public logoLocation: string;

    public ngOnInit() {
        this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.setTitle();
            }
        });

        this.setTitle();
        this.logoLocation = environment.logoMultipleColors;
    }

    private setTitle(): void
    {
        let firstChild = this.activatedroute.snapshot;
        while (firstChild.firstChild) {
            firstChild = firstChild.firstChild;
        }
        this.title = firstChild.data.title;
    }
}
