import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementDefinition } from '../../swagger-client';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DefinitionTypeEnum = MeasurementDefinition.DefinitionTypeEnum;

export function measurementDefinitionTypeLabel(
    definitionType: DefinitionTypeEnum | string,
): string {
    switch (definitionType) {
        case DefinitionTypeEnum.SETUP:
            return '1e product';
        case DefinitionTypeEnum.RUNNING1:
            return 'taak 1';
        case DefinitionTypeEnum.RUNNING2:
            return 'taak 2';
        case DefinitionTypeEnum.ADHOC:
            return 'operator';
    }
}

@Pipe({
    name: 'measurementDefinitionType',
})
export class MeasurementDefinitionTypePipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}

    public transform(measurementDefinitionType: DefinitionTypeEnum | string): SafeHtml {
        switch (measurementDefinitionType) {
            case DefinitionTypeEnum.ADHOC:
                return this.sanitized.bypassSecurityTrustHtml(
                    '<strong i18n class="text-primary">' +
                        measurementDefinitionTypeLabel(measurementDefinitionType) +
                        '</strong>',
                );
            default:
                return this.sanitized.bypassSecurityTrustHtml(
                    '<span i18n>' +
                        measurementDefinitionTypeLabel(measurementDefinitionType) +
                        '</span>',
                );
        }
    }
}
