<ng-container *ngIf="checklistService.checklist">
  <betech-card
    clickUrl="/checklist"
    class="checklist-card"
    [ngClass]="{
      'checklist-card--critical': !checklistService.done && checklistService.remainingTime < 600000,
      'checklist-card--attention':
        !checklistService.done &&
        checklistService.remainingTime > 600000 &&
        checklistService.remainingTime < 1800000
    }"
  >
    <i card-icon class="fal fa-3x fa-clipboard-list-check fa-fw"></i>
    <span card-title i18n>Checklist</span>
    <span card-subtitle>{{ checklistService.checklist.name }}</span>
    <button
      *ngIf="!checklistService.done"
      card-button
      class="btn btn-secondary btn-block rounded text-uppercase"
      (click)="openModal($event)"
    >
      <span i18n>Afronden</span>
    </button>
    <button
      *ngIf="checklistService.done"
      card-button
      class="btn btn-light btn-block rounded text-uppercase"
      (click)="openModal($event)"
    >
      <span i18n>Bekijken</span>
    </button>
    <ng-container card-info>
      <ng-container *ngIf="checklistService.lastUpdatedItem">
        <p class="mb-2">
          {{ checklistService.lastUpdatedItem.employee_number | employee }}
          heeft de status van taak <i>{{ checklistService.lastUpdatedItem.name }}</i> gewijzigd naar
          {{ status(checklistService.lastUpdatedItem.status) }}
        </p>
        <p class="text-muted">
          {{ checklistService.lastUpdatedItem.modified_at | fromNow }}
        </p>
      </ng-container>
    </ng-container>
    <betech-card-notification type="success" card-notifications>
      <div class="font-weight-bold font-size-lg">
        {{ checklistService.itemsDone.length }} /
        {{ checklistService.items.length }}
      </div>
      <div class="text"><span i18n>Afgerond</span></div>
    </betech-card-notification>
    <ng-container *ngIf="!checklistService.done" card-footer>
      Time remaining: {{ remaining }}
    </ng-container>
  </betech-card>
</ng-container>
<ng-container *ngIf="!checklistService.checklist">
  <betech-card clickUrl="/checklist">
    <i card-icon class="fal fa-3x fa-clipboard-list-check fa-fw"></i>
    <span card-title i18n>Checklist</span>
    <betech-card-notification type="success" card-notifications>
      <div class="font-weight-bold font-size-lg">Geen checklist actief</div>
    </betech-card-notification>
  </betech-card>
</ng-container>
