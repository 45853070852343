import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    SensorData,
} from '../../swagger-client';
import { SensorDataService } from '../../services/sensor-data/sensor-data.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProducedAmountDataService } from '../../services/produced-amount-data-service/produced-amount-data-service';
import { ActiveEntityMediatorService } from '../../services/active-entity-mediator/active-entity-mediator.service';
import {ConfirmationService} from "../../services/confirmation/confirmation.service";
import {DialogConfig} from "../../shared/confirmation-dialog/dialog.config";

@Component({
    selector: 'betech-header',
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
    public isAuthenticated: boolean;
    public activeMachine: ProcessingStation;
    public currentOrder: ProductionOrder;
    public lastSensorData: SensorData;
    public sensorHasError: boolean;
    public processed_parts: number;
    public logoLocation: string;
    public machineNumberForHeader: string;
    public employeeForHeader: string;

    public showMachineCounter = false;

    private pauseSensor: boolean = false;

    private subs: Subscription[] = [];

    public companyName: string;

    public machineHasError = false;
    public htmlStatusClass = '';
    public htmlStatusClassSyncIcon = '';

    constructor(
        protected authenticationService: AuthenticationService,
        protected activeEntityMediatorService: ActiveEntityMediatorService,
        protected sensorDataService: SensorDataService,
        protected esbModelService: EsbmodelService,
        protected producedAmountDataService: ProducedAmountDataService,
        private _confirmationService: ConfirmationService,
    ) {}

    public ngOnInit(): void {
        this.companyName = environment.config.companyName;
        this.logoLocation = environment.logoMultipleColors;
        this.isAuthenticated = this.authenticationService.isAuthenticated;
        this.sensorHasError = false;
        this.showMachineCounter = environment.config.showMachineCounter;
        this.load();
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    protected load(): void {
        this.subs.push(
            this.activeEntityMediatorService.activeProductionOrder$.subscribe(
                currentProductionOrder => {
                    this.currentOrder = currentProductionOrder;
                },
            ),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                    if (this.activeMachine == null) {
                        this.machineNumberForHeader = '-';
                    } else {
                        this.machineNumberForHeader =
                            this.activeMachine.trumpf_id?.toString() || this.activeMachine.name;
                    }
                },
            ),
            this.activeEntityMediatorService.activeEmployee$.subscribe((employee: Employee) => {
                if (!employee) {
                    this.employeeForHeader = '-';
                } else {
                    this.employeeForHeader = employee.full_name;
                }
            }),
            this.sensorDataService.observe().subscribe((lastSensorData: SensorData) => {
                if (!this.pauseSensor) {
                    this.sensorHasError = this.sensorDataService.hasError();
                    this.updateWithSensorData(lastSensorData);
                }
            }),
        );
    }

    private updateWithSensorData(sensorData: SensorData): void {
        if (sensorData) {
            this.lastSensorData = sensorData;
            this.processed_parts = sensorData.processed_parts;

            if (sensorData.status !== null) {
                switch (sensorData.status){
                    case 'error':
                        this.machineHasError = true;
                        this.htmlStatusClassSyncIcon = 'error-unknown-status';
                        break;
                    case 'updated':
                        this.machineHasError = false;
                        break;
                    case 'updated_manually':
                        this.machineHasError = false;
                        break;
                }
            }

            if (sensorData.machine_status !== null && !this.machineHasError) {
                switch (sensorData.machine_status) {
                    case 'active':
                        this.htmlStatusClassSyncIcon = 'active-status';
                        break;
                    case 'idle':
                        this.htmlStatusClassSyncIcon = '';
                        break;
                    case 'failure':
                        this.htmlStatusClass = 'border-danger critical-status';
                        this.htmlStatusClassSyncIcon = '';
                        break;
                }
            }
        }
    }

    public statusErrorOrFailure(): boolean {
        return this.lastSensorData.status === 'error' || this.lastSensorData.machine_status === 'failure';
    }

    public showIconStack(position: string): string {
        switch (position){
            case 'syncIcon':
                return 'fa-stack-1x';
            case 'inputGroup':
                return 'fa-stack fa-lg';
            default:
                return '';
        }
    }

    public showStatusPopup(): void
    {
        let messageText: string = '';
        if (this.lastSensorData.status === 'error'){
            messageText = 'Geen verbinding met machine';
        } else if (this.lastSensorData.machine_status === 'failure'){
            messageText = 'Machine verkeert in kritieke toestand';
        }

        let popupMessage:DialogConfig = {
            title: 'Machine fout',
            message: {
                text: messageText
            },
            icon: {
                show: true,
                name: 'fa-exclamation',
            },
            actions: {
                confirm: {
                    label: 'Oke'
                },
                cancel: {
                    show: false
                },
            },
        };

        this._confirmationService.open(popupMessage);
    }

    public toggleFullscreen() {
        if (this.isFullscreen()) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
            screen.orientation.lock('landscape');
        }
    }

    public isFullscreen(): boolean {
        return !!document.fullscreenElement;
    }

    public updateSensorData(): void {
        if (!this.activeMachine) {
            return;
        }

        this.esbModelService
            .esbmProcessingStationIdSensorDataPut(this.activeMachine.id, {
                processed_parts: this.processed_parts,
                status: 'updated_manually',
            })
            .subscribe(data => {
                this.updateWithSensorData(data);
                this.pauseSensor = false;
                this.producedAmountDataService.forceUpdate();
                this.activeEntityMediatorService.reloadProductionOrder();
            });
    }

    public pauseSensorData(pause: boolean): void {
        this.pauseSensor = pause;
    }
}
