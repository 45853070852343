import { Injectable } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
} from '../../swagger-client';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActiveProductionOrdersService {
    private currentProductionOrder: ReplaySubject<ProductionOrder> = new ReplaySubject(1);
    private currentProductionOrderFetched: ProductionOrder;

    constructor(private _esbmodelService: EsbmodelService) {}

    get activeProductionOrder$(): Observable<ProcessingStation> {
        return this.currentProductionOrder.asObservable();
    }

    public fetch(machine: ProcessingStation, employee: Employee): void {
        let machineName = machine?.name;
        let employeeNumber = employee?.employee_number;

        if (machineName == null && employeeNumber == null) {
            return;
        }

        this._esbmodelService
            .esbmProductionOrderCurrentGet(machineName, employeeNumber)
            .subscribe((currentProductionOrder: ProductionOrder) => {
                this.checkProductionOrder(currentProductionOrder);
            });
    }

    /**
     * Determine if the last fetched currentProductionOrders is different from the given one
     */
    protected isChanged(currentProductionOrder: ProductionOrder) {
        // no order before, no order now: nothing has changed
        if (!this.currentProductionOrderFetched && !currentProductionOrder) {
            return false;
        }

        // TODO for now next order is not used and thus ignored. If ever it were to be used, this should be changed
        return (
            (!this.currentProductionOrderFetched && currentProductionOrder) ||
            (this.currentProductionOrderFetched && !currentProductionOrder) ||
            this.currentProductionOrderFetched?.id !== currentProductionOrder.id
        );
    }

    private checkProductionOrder(productionOrder: ProductionOrder): void {
        if (!this.isChanged(productionOrder)) {
            return;
        }

        this.currentProductionOrderFetched = productionOrder;
        this.currentProductionOrder.next(this.currentProductionOrderFetched);
    }

    public clear(): void {
        this.currentProductionOrderFetched = null;
        this.currentProductionOrder.next(null);
    }
}
