import { Injectable } from '@angular/core';
import { Employee, EsbmodelService } from '../../swagger-client';
import { Observable, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ActiveEmployeeService {
    constructor(private _esbmodelService: EsbmodelService) {}

    private _employee: ReplaySubject<Employee> = new ReplaySubject(1);

    private _employeeAtLogin: Employee;
    private _employeeOverruled: Employee;
    private _latestSendedEmployee: Employee;

    get activeEmployee$(): Observable<Employee> {
        return this._employee.asObservable();
    }

    private updateEmployee(): void {
        let nextEmployee = this._employeeAtLogin ?? this._employeeOverruled;

        if (this._latestSendedEmployee?.id !== nextEmployee?.id) {
            this._employee.next(nextEmployee);
            this._latestSendedEmployee = nextEmployee;
        }
    }

    private set activeEmployeeAtLogin(employee: Employee) {
        if (this._employeeAtLogin != null && employee != null) {
            console.error('Cannot rewrite employeeAtLogin with another employee');
            return;
        }

        this._employeeAtLogin = employee;
        this.updateEmployee();
    }

    public set activeEmployee(employee: Employee) {
        this._employeeOverruled = employee;
        this.updateEmployee();
    }

    public clear(): void {
        this._employeeOverruled = null;
        this._employeeAtLogin = null;
        this.updateEmployee();
    }

    public initialize(employeeId: string): Observable<Employee> {
        if (!this.validEmployeeId(employeeId)) {
            this.activeEmployeeAtLogin = null;
            return of({ id: '' });
        }

        return this._esbmodelService.esbmEmployeeIdGet(employeeId).pipe(
            tap((employee: Employee) => {
                this.activeEmployeeAtLogin = employee;
            }),
        );
    }

    public validEmployeeId(employeeId: string): boolean {
        return employeeId != null && employeeId != '';
    }
}
