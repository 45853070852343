<betech-card>
    <i card-icon class="fal fa-3x fa-user fa-fw"></i>
    <span card-title i18n>Geen werknemer gekoppeld</span>
    <span card-subtitle i18n></span>
    <button card-button
            class="btn btn-secondary btn-block rounded text-uppercase"
            (click)="openModal($event)"
    >
        <span i18n>Werknemer selecteren</span>
    </button>
    <ng-container card-info>
        <div>
            <span>Op dit moment is nog geen werknemer gekoppeld.</span>
            <br />
            <span>Koppel een werknemer om verder te gaan.</span>
        </div>
    </ng-container>
    <ng-container card-footer>
    </ng-container>
</betech-card>

