<div class="vh-100 position-relative">
  <div class="modal-header p-3 text-white bg-primary set-shadow position-relative">
    <span class="left-2 top-2" (click)="rotate()">
      <i class="fa-2x fas fa-redo px-2 py-1 rounded border border-white"></i>
    </span>
    <div class="modal-title pt-2 pl-2 w-100">
      <h4 class="text-center">
        <i class="fal fa-file-contract"></i>&nbsp; <span i18n>Order:</span>&nbsp;
        <span class="text-h4 font-weight-bold">{{ selectedOrder?.production_number }}</span>
      </h4>
    </div>
    <span class="right-2 top-2" (click)="close()">
      <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
    </span>
  </div>
  <div class="modal-body position-relative" style="height: calc(99% - 3.75rem - 5.5rem)">
    <pinch-zoom style="height: 100%">
      <img
        #image
        class="border-left vh-90 border-right bg-white"
        [src]="selectedImageUrl"
        alt="{{ selectedOrder?.fabrication_part_name }}"
      />
    </pinch-zoom>
  </div>
  <div class="modal-footer justify-content-center text-primary bg-white">
    <span class="left-2 top-2" (click)="previousImage()">
      <i class="fa-2x fas fa-arrow-left px-2 py-1 rounded border border-white"></i>
    </span>
    <div>
      <i class="fas fa-drafting-compass"></i>
      <span i18n>Tekening: {{ index + 1 }} van de {{ numberOfImages }}</span>
      <span class="text-h4 font-weight-bold"> {{ selectedOrder?.reference }}</span>
    </div>
    <span class="left-2 top-2" (click)="nextImage()">
      <i class="fa-2x fas fa-arrow-right px-2 py-1 rounded border border-white"></i>
    </span>
  </div>
</div>
