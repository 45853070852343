<!-- main measurement card -->
<betech-card clickUrl="/measurement-sample" *ngIf="!shouldMeasure">
  <i card-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
  <span card-title i18n>Meetgegevens</span>
  <span card-subtitle i18n>Order {{ currentProductionOrder?.production_number || '-' }}</span>
    <button card-button *ngIf="currentProductionOrder" class="btn btn-secondary btn-block rounded text-uppercase" href="#" (click)="takeSample($event, sampleModalTemplate)">
    <span i18n>Sample nemen</span>
  </button>
  <ng-container card-info>
    <div class="d-flex justify-content-between">
      <div><span i18n>Meting over</span>:</div>
      <div class="text-primary text-right">{{ countdown | i18nPlural: { other: '# st.' } }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Laatste meting</span>:</div>
      <div class="text-primary text-right" [ngSwitch]="true">
        <span *ngSwitchCase="lastMeasurementSample?.isInProgress()" i18n>In behandeling</span>
        <div *ngSwitchCase="lastMeasurementSample && !lastMeasurementSample.isInProgress()" i18n>
          <div>
            {{ lastMeasurementSample.getTotalMeasured() }} /
            {{ lastMeasurementSample.getTotal() | i18nPlural: { other: '# total' } }}
          </div>
          <div>
            {{ lastMeasurementSample.getIncorrect() | i18nPlural: { other: '# incorrect' } }}
          </div>
          <div>{{ lastMeasurementSample.location | i18nPlural: { other: '# location' } }}</div>
        </div>
        <span *ngSwitchDefault>-</span>
      </div>
    </div>
  </ng-container>
  <ng-container card-notifications [ngSwitch]="true">
    <betech-card-notification type="primary" *ngSwitchCase="!currentProductionOrder">
      <span i18n>Geen order actief</span>
    </betech-card-notification>
    <betech-card-notification
      type="primary"
      *ngSwitchCase="currentProductionOrder && !lastMeasurementSample"
    >
      <span i18n>Geen meetresultaat beschikbaar</span>
    </betech-card-notification>
    <betech-card-notification type="warning" *ngSwitchCase="lastMeasurementSample?.isInProgress()">
      <span i18n>METING IN BEHANDELING</span>
    </betech-card-notification>
    <betech-card-notification
      type="danger"
      *ngSwitchCase="lastMeasurementSample?.measured_at && lastMeasurementSample.getIncorrect() > 0"
    >
      <span class="text-h2">
        <span class="text-danger">{{ lastMeasurementSample.getIncorrect() }}</span> /
        {{ lastMeasurementSample.getTotalMeasured() }} </span
      ><br />
      <span class="text-danger" i18n>INCORRECT</span>
    </betech-card-notification>
    <betech-card-notification
      type="success"
      *ngSwitchCase="
        lastMeasurementSample?.measured_at && lastMeasurementSample.getIncorrect() === 0
      "
    >
      <span class="text-h2">
        <span class="text-success">0</span> /
        {{ lastMeasurementSample.measurement_data?.measurement_items.length }} </span
      ><br />
      <span class="text-success" i18n>INCORRECT</span>
    </betech-card-notification>
  </ng-container>
  <ng-container card-footer>
    <span class="mr-1" i18n>Laatste meting op:</span>
    <ng-container [ngSwitch]="true">
      <span class="text-primary" *ngSwitchCase="lastMeasurementSample?.isInProgress()" i18n
        >In behandeling</span
      >
      <span class="text-primary" *ngSwitchCase="!!lastMeasurementSample?.measured_at" i18n>
        {{ lastMeasurementSample.measured_at | date: 'dd-MM-yyyy' }} om
        {{ lastMeasurementSample.measured_at | date: 'HH:mm' }}
      </span>
      <span *ngSwitchDefault>-</span>
    </ng-container>
  </ng-container>
</betech-card>

<!-- different full-width view with urgent measure notice -->
<betech-card
  clickUrl="/measurement-sample"
  *ngIf="shouldMeasure"
  [fullWidth]="true"
  bgClass="bg-secondary"
>
  <i card-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
  <span card-title i18n class="text-white">Meetgegevens</span>
  <span card-subtitle i18n class="text-white"
    >Order {{ currentProductionOrder?.production_number }}</span
  >
  <button
    card-button
    class="btn btn-light btn-block rounded text-uppercase"
    (click)="takeSample($event, sampleModalTemplate)"
  >
    <span i18n>Sample nemen</span>
  </button>
  <ng-container card-info *ngIf="!recentToolReplace">
    <span class="mr-1 text-white" i18n
      >De teller staat op {{ lastSensorData?.processed_parts }}.</span
    >
    <strong class="mr-1 text-primary" i18n>Er moet nu worden gemeten!</strong>
    <span class="text-white" i18n
      >Neem daarom nu een sample en breng deze naar de meetkamer. Dankjewel!</span
    >
  </ng-container>
  <ng-container card-info *ngIf="recentToolReplace">
    <span class="mr-1 text-white" i18n>Er heeft een tool wissel plaatsgevonden.</span>
    <strong class="mr-1 text-white" i18n>U dient nu te meten.</strong>
  </ng-container>
</betech-card>

<!-- take sample modal -->
<ng-template #sampleModalTemplate>
  <betech-modal (closeEvent)="modalRef.hide()">
    <i modal-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
    <span i18n modal-title>Sample nemen</span>
    <ng-container modal-body>
      <form>
        <div id="" class="form-group">
          <label>Locatie meting</label>
          <betech-select-dropdown
            [required]="true"
            [items]="locationChoices"
            label="label"
            search="label"
            (modelChange)="chooseLocation($event.location)"
          ></betech-select-dropdown>
        </div>

        <!-- machine measurement -->
        <ng-container *ngIf="modalLocation === LocationEnum.MACHINE">
          <div class="form-group">
            <label>Meting door</label>
            <betech-select-dropdown
              [required]="true"
              placeholder="Kies een medewerker"
              i18n-placeholder
              [items]="employeeList"
              label="full_name"
              search="full_name"
              [model]="selectedEmployee"
              (modelChange)="selectedEmployee = $event"
            >
            </betech-select-dropdown>
          </div>
          <div class="clearfix">
            <button
              *ngIf="selectedEmployee"
              i18n
              class="btn btn-secondary float-right"
              (click)="createMachineMeasurement()"
            >
              NU METEN
            </button>
          </div>
        </ng-container>

        <!-- measurementroom measurement -->
        <ng-container *ngIf="modalLocation === LocationEnum.MEASUREMENTROOM">
          <div class="form-group">
            <label>Selecteer het sample bakje</label>
            <betech-select-dropdown
              [required]="true"
              placeholder="Kies een bakje"
              i18n-placeholder
              [items]="containerList"
              label="name"
              search="name"
              [model]="selectedContainer"
              (modelChange)="selectedContainer = $event"
            >
            </betech-select-dropdown>
          </div>
          <div class="clearfix">
            <button
              *ngIf="selectedContainer"
              i18n
              class="btn btn-secondary float-right"
              (click)="createMeasurementroomMeasurement()"
            >
              NU METEN
            </button>
          </div>
        </ng-container>
      </form>
    </ng-container>
  </betech-modal>
</ng-template>
