/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { InlineResponse200 } from '../model/inlineResponse200';
import { InlineResponse2001 } from '../model/inlineResponse2001';
import { InlineResponse2002 } from '../model/inlineResponse2002';
import { InlineResponse2003 } from '../model/inlineResponse2003';
import { InputTableEnable } from '../model/inputTableEnable';
import { PdcSyncBack } from '../model/pdcSyncBack';
import { PdcTable } from '../model/pdcTable';
import { TrumpfXML } from '../model/trumpfXML';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ConnectorsService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get pdc batches
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcBatchGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse200>;
    public connectorsPdcBatchGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse200>>;
    public connectorsPdcBatchGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse200>>;
    public connectorsPdcBatchGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse200>(`${this.basePath}/connectors/pdc/batch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finish batch data action
     * 
     * @param X_Batch_Id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcDataFinishPost(X_Batch_Id: any, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public connectorsPdcDataFinishPost(X_Batch_Id: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public connectorsPdcDataFinishPost(X_Batch_Id: any, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public connectorsPdcDataFinishPost(X_Batch_Id: any, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Batch_Id === null || X_Batch_Id === undefined) {
            throw new Error('Required parameter X_Batch_Id was null or undefined when calling connectorsPdcDataFinishPost.');
        }

        let headers = this.defaultHeaders;
        if (X_Batch_Id !== undefined && X_Batch_Id !== null) {
            headers = headers.set('X-Batch-Id', String(X_Batch_Id));
        }

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/connectors/pdc/data/finish`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * POST batch data action
     * 
     * @param X_Batch_Id 
     * @param X_Batch_Total 
     * @param X_Batch_Page 
     * @param X_Batch_Size 
     * @param X_Batch_Table 
     * @param X_Sync_Type 
     * @param json 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcDataPost(X_Batch_Id: any, X_Batch_Total: any, X_Batch_Page: any, X_Batch_Size: any, X_Batch_Table: any, X_Sync_Type: any, json: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public connectorsPdcDataPost(X_Batch_Id: any, X_Batch_Total: any, X_Batch_Page: any, X_Batch_Size: any, X_Batch_Table: any, X_Sync_Type: any, json: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public connectorsPdcDataPost(X_Batch_Id: any, X_Batch_Total: any, X_Batch_Page: any, X_Batch_Size: any, X_Batch_Table: any, X_Sync_Type: any, json: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public connectorsPdcDataPost(X_Batch_Id: any, X_Batch_Total: any, X_Batch_Page: any, X_Batch_Size: any, X_Batch_Table: any, X_Sync_Type: any, json: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (X_Batch_Id === null || X_Batch_Id === undefined) {
            throw new Error('Required parameter X_Batch_Id was null or undefined when calling connectorsPdcDataPost.');
        }

        if (X_Batch_Total === null || X_Batch_Total === undefined) {
            throw new Error('Required parameter X_Batch_Total was null or undefined when calling connectorsPdcDataPost.');
        }

        if (X_Batch_Page === null || X_Batch_Page === undefined) {
            throw new Error('Required parameter X_Batch_Page was null or undefined when calling connectorsPdcDataPost.');
        }

        if (X_Batch_Size === null || X_Batch_Size === undefined) {
            throw new Error('Required parameter X_Batch_Size was null or undefined when calling connectorsPdcDataPost.');
        }

        if (X_Batch_Table === null || X_Batch_Table === undefined) {
            throw new Error('Required parameter X_Batch_Table was null or undefined when calling connectorsPdcDataPost.');
        }

        if (X_Sync_Type === null || X_Sync_Type === undefined) {
            throw new Error('Required parameter X_Sync_Type was null or undefined when calling connectorsPdcDataPost.');
        }

        if (json === null || json === undefined) {
            throw new Error('Required parameter json was null or undefined when calling connectorsPdcDataPost.');
        }

        let headers = this.defaultHeaders;
        if (X_Batch_Id !== undefined && X_Batch_Id !== null) {
            headers = headers.set('X-Batch-Id', String(X_Batch_Id));
        }
        if (X_Batch_Total !== undefined && X_Batch_Total !== null) {
            headers = headers.set('X-Batch-Total', String(X_Batch_Total));
        }
        if (X_Batch_Page !== undefined && X_Batch_Page !== null) {
            headers = headers.set('X-Batch-Page', String(X_Batch_Page));
        }
        if (X_Batch_Size !== undefined && X_Batch_Size !== null) {
            headers = headers.set('X-Batch-Size', String(X_Batch_Size));
        }
        if (X_Batch_Table !== undefined && X_Batch_Table !== null) {
            headers = headers.set('X-Batch-Table', String(X_Batch_Table));
        }
        if (X_Sync_Type !== undefined && X_Sync_Type !== null) {
            headers = headers.set('X-Sync-Type', String(X_Sync_Type));
        }

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/connectors/pdc/data`,
            json,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get pdc syncbacks
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcSyncbackGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public connectorsPdcSyncbackGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public connectorsPdcSyncbackGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public connectorsPdcSyncbackGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2002>(`${this.basePath}/connectors/pdc/syncback`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Production Order Syncbacks
     * 
     * @param id Production Order Report Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcSyncbackProductionOrderReportIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PdcSyncBack>>;
    public connectorsPdcSyncbackProductionOrderReportIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PdcSyncBack>>>;
    public connectorsPdcSyncbackProductionOrderReportIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PdcSyncBack>>>;
    public connectorsPdcSyncbackProductionOrderReportIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling connectorsPdcSyncbackProductionOrderReportIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PdcSyncBack>>(`${this.basePath}/connectors/pdc/syncback/production_order_report/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get PDC tables you can sync
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcTableGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2003>;
    public connectorsPdcTableGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2003>>;
    public connectorsPdcTableGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2003>>;
    public connectorsPdcTableGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2003>(`${this.basePath}/connectors/pdc/table`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a table
     * 
     * @param id The ID of the table to map
     * @param inputTableEnable Update a table
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcTableIdPut(id: string, inputTableEnable?: InputTableEnable, observe?: 'body', reportProgress?: boolean): Observable<PdcTable>;
    public connectorsPdcTableIdPut(id: string, inputTableEnable?: InputTableEnable, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PdcTable>>;
    public connectorsPdcTableIdPut(id: string, inputTableEnable?: InputTableEnable, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PdcTable>>;
    public connectorsPdcTableIdPut(id: string, inputTableEnable?: InputTableEnable, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling connectorsPdcTableIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<PdcTable>(`${this.basePath}/connectors/pdc/table/${encodeURIComponent(String(id))}`,
            inputTableEnable,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get PDC syncbacks
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsPdcWritesGet(observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2002>;
    public connectorsPdcWritesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2002>>;
    public connectorsPdcWritesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2002>>;
    public connectorsPdcWritesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2002>(`${this.basePath}/connectors/pdc/writes`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get trumpf xml messages
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsTrumpfXmlGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2001>;
    public connectorsTrumpfXmlGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2001>>;
    public connectorsTrumpfXmlGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2001>>;
    public connectorsTrumpfXmlGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2001>(`${this.basePath}/connectors/trumpf/xml`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get trumpf xml message
     * 
     * @param id The ID of the message to get
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public connectorsTrumpfXmlIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<TrumpfXML>;
    public connectorsTrumpfXmlIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TrumpfXML>>;
    public connectorsTrumpfXmlIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TrumpfXML>>;
    public connectorsTrumpfXmlIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling connectorsTrumpfXmlIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<TrumpfXML>(`${this.basePath}/connectors/trumpf/xml/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
