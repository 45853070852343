<div class="order-list">

    <betech-filter-container>
        <betech-filter-order-number (filterChangeDelayed)="changeSearchValue($event)" (filterChange)="filters.search = $event"></betech-filter-order-number>
        <betech-filter-order-status [data]="filters.reportedStatus"
                                    (filterChange)="changeStatus($event)"></betech-filter-order-status>
        <betech-filter-processing-station [data]="filters.processingStation"
                                          (filterChange)="changeProcessingStation($event)"></betech-filter-processing-station>
        <button class="btn btn-primary" (click)="search()">Search</button>
    </betech-filter-container>
<!--    <div class="p-2"-->
<!--         style="text-align: center;"-->
<!--         *ngIf="this.currentPO && this.currentPO.repeatedOrder">-->
<!--        <i class="fal fa-history"-->
<!--           style="padding-right: 5px;"-->
<!--        ></i>-->
<!--        <span>Dit product is in het verleden al eens op deze machine geproduceerd</span>-->
<!--    </div>-->

    <ngx-datatable
        class="material fullscreen"
        [columnMode]="ColumnMode.flex"
        [headerHeight]="50"
        rowHeight="auto"
        [scrollbarV]="false"
        [rows]="orderList"
        [rowClass]='getRowClass'
        (sort)='onSort($event)'>

        <ngx-datatable-column name="Plandatum" prop="start_date" [flexGrow]="3">
            <ng-template let-value="value" ngx-datatable-cell-template>
                <ng-container *ngIf="value !== statusEnum.STARTED; else nextContractIcon;">
                   <span>{{ (value | date:'dd-MM-yyyy') || '-' }} </span>
               </ng-container>
               <ng-template #nextContractIcon>
                   <span *ngIf="value === statusEnum.STARTED" class="icon-position text-primary">
                       <i class="fa-2x fal fa-file-contract"></i>
                   </span>
               </ng-template>
           </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column name="Productienummer" prop="production_number" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template  let-value="value" let-row="row">
                <span [ngClass]="{'text-secondary font-weight-bold': row.reported_status === statusEnum}"> {{ value || '-' }} / {{ row.order_line_number }} </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="!activeMachine?.is_cnc" name="Bewerking" prop="processing_station.name" [flexGrow]="2">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.reported_processing_station !== null">{{ row.reported_processing_station?.name || '-' }} </span>
                <span *ngIf="row.reported_processing_station === null">{{ row.processing_station?.name || '-' }} </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="companyName !== 'DMI'" name="Machine" prop="processing_station.trumpf_id" [flexGrow]="1">
            <ng-template let-row="row" ngx-datatable-cell-template>
                <span *ngIf="row.reported_processing_station !== null">{{ row.reported_processing_station?.trumpf_id || '-' }} </span>
                <span *ngIf="row.reported_processing_station === null">{{ row.processing_station?.trumpf_id || '-' }} </span>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Bedrijf" prop="company" [flexGrow]="2"></ngx-datatable-column>
        <ngx-datatable-column name="Product" prop="fabrication_part_name" [flexGrow]="3"></ngx-datatable-column>
        <ngx-datatable-column name="Aantal" prop="quantity" [flexGrow]="2"></ngx-datatable-column>
        <ngx-datatable-column name="Leverdatum" prop="due_date" [flexGrow]="3">
            <ng-template ngx-datatable-cell-template let-value="value"> {{value | date:'dd-MM-yyyy'}}</ng-template>
        </ngx-datatable-column>
<!--        <ngx-datatable-column name="CNC Programma" prop="cnc_program" [flexGrow]="1">-->
        <ngx-datatable-column *ngIf="companyName !== 'DMI'" name="Combi" prop="combi_id" [flexGrow]="1">

        </ngx-datatable-column>
        <ngx-datatable-column *ngIf="companyName !== 'DMI'" name="Materiaal?" prop="material_available" [flexGrow]="1">
            <ng-template ngx-datatable-cell-template let-value="value"  >
                <i *ngIf="value" class="fa fa-check text-success"></i>
                <i *ngIf="!value" class="fa fa-times text-danger"></i>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="Status" prop="status" [flexGrow]="3" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-value="value" let-row='row'>
                <div class="order-list__status" style="padding-right: 20px;">
                    <span *ngIf="!row.reported_status" [ngClass]="['order-list__status--'+row.status|lowercase]">{{ row.status | orderStatus }}</span>
                    <span *ngIf="row.reported_status" [ngClass]="['order-list__status--'+row.reported_status|lowercase]">{{ row.reported_status | orderStatus }}</span>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" prop="action_1" [flexGrow]="1" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-value="value"  let-row='row'>
                <div (click)="showDrawing(row)" style="padding-right: 20px;">
                    <button [disabled]="!row.drawing_image_url" class="btn btn-sm" [ngClass]="{'btn-outline-secondary': row.drawing_image_url, 'btn-outline-dark': !row.drawing_image_url}">
                        <i class="fas fa-drafting-compass"></i>
                    </button>
                </div>
            </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="" prop="action_2" [flexGrow]="1" [sortable]="false">
            <ng-template ngx-datatable-cell-template let-value="value"  let-row='row'>
                <div class="dropdown-btn-group btn-group" dropdown style="padding-right: 20px;">
                    <i class="hand fas fa-ellipsis-v" dropdownToggle aria-controls="dropdown-actions-current"></i>
                    <ul id="dropdown-actions-current" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                        role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" *ngIf="currentPO?.id === row.id">
                            <a class="dropdown-item hand" (click)="cancelOrder(row)">
                                <i class="fas fa-cancel"></i>
                                &nbsp;
                                <span i18n>Annuleer</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="currentPO?.id === row.id">
                            <a class="dropdown-item hand" (click)="stopOrder(row)">
                                <i class="fas fa-stop"></i>
                                &nbsp;
                                <span i18n>Stop</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="currentPO?.id === row.id">
                            <a class="dropdown-item hand" (click)="pauseOrder(row)">
                                <i class="fas fa-pause"></i>
                                &nbsp;
                                <span i18n>Pauzeer</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enableStartProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="startOrPrepareOrder(row, statusEnum.STARTED)">
                                <i class="fas fa-play"></i>
                                &nbsp;
                                <span i18n>Start</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enableQueueProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="queueOrder(row)">
                                <i class="fas fa-plus"></i>
                                &nbsp;
                                <span i18n>Wachtrij</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enableUnqueueProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="unqueueOrder(row)">
                                <i class="fas fa-minus"></i>
                                &nbsp;
                                <span i18n>Wachtrij</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enablePrepareProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="startOrPrepareOrder(row, statusEnum.PREPARED)">
                                <i class="fas fa-play"></i>
                                &nbsp;
                                <span i18n>Voorbereiden</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enableCooperateProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="startCooperateModalProductionOrder(row)">
                                <i class="fas fa-user-plus"></i>
                                &nbsp;
                                <span i18n>Aanmelden</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="enableStopCooperateProductionOrder(row)">
                            <a class="dropdown-item hand" (click)="removeWorkerFromOrder(row)">
                                <i class="fas fa-user-minus"></i>
                                &nbsp;
                                <span i18n>Afmelden</span>
                            </a>
                        </li>
                        <li role="menuitem" *ngIf="startProductionOrderDisabled(row)">
                            <a class="dropdown-item text-warning">
                                <i class="fas fa-exclamation-triangle"></i>
                                &nbsp;
                                <span i18n>Stop, pauzeer of annuleer de huidige order eerst!</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-template>
        </ngx-datatable-column>
    </ngx-datatable>


    <!-- Due date -->


    <!-- Order number -->


    <!-- Materiaal aanwezig? -->
    <ng-template #materialTmpl let-value="value">
        <i *ngIf="value" class="fa fa-check text-success"></i>
        <i *ngIf="!value" class="fa fa-times text-danger"></i>
    </ng-template>

    <div class="p-2" *ngIf="total > (offset * limit)">
        <button class="btn btn-secondary w-100" (click)="next()">Load more</button>
    </div>
</div>
