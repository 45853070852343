import { Component, HostListener, OnInit } from '@angular/core';
import { BrowserSupportService } from '../../services/browser-support/browser-support.service';
import { Location } from '@angular/common';
import { BetechComponent } from '../../betech.component';
import { Router } from '@angular/router';

@Component({
    selector: 'betech-not-supported',
    templateUrl: './not-supported.component.html',
})
export class NotSupportedComponent implements OnInit {
    constructor(
        private _browserSupportService: BrowserSupportService,
        private _location: Location,
        private _router: Router,
    ) {}

    @HostListener('window:resize', ['$event'])
    public onResize() {
        if (this._browserSupportService.verifyBrowser()) {
            // If previous url was this page or undefined, navigate to the main module
            if (
                BetechComponent.PREVIOUS_URL !== undefined &&
                BetechComponent.PREVIOUS_URL !== '/auth/not-supported'
            ) {
                this._location.back();
            } else {
                this._router.navigateByUrl('');
            }
        }
    }

    public ngOnInit(): void {
        this.onResize();
    }
}
