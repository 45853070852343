import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActiveEntityMediatorService } from '../services/active-entity-mediator/active-entity-mediator.service';

@Injectable({
    providedIn: 'root',
})
export class MainResolver implements Resolve<any> {
    constructor(
        private router: Router,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.activeEntityMediatorService.initialize().pipe(
            catchError((error: any) => {
                this.router.navigate(['/auth/login'], { queryParams: { error: error } });
                return of(null);
            }),
        );
    }
}
