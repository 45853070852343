import { Component, ElementRef, ViewChild } from '@angular/core';
import { ProductionOrder } from '../../swagger-client';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'betech-media-modal',
    templateUrl: './media-modal.component.html',
})
export class MediaModalComponent {
    @ViewChild('image', { static: true }) private image: ElementRef;

    public selectedOrder: ProductionOrder;
    public selectedImageUrl: string;
    private bsModalRef: BsModalRef;
    private rotationAmount = 0;

    /**
     * show selected image url
     */
    public showMedia(item: ProductionOrder, imageUrl: string): void {
        this.selectedOrder = item;
        this.selectedImageUrl = imageUrl;
    }

    /**
     * set bsModalRef
     */
    public setModalRef(modalRef: BsModalRef): void {
        this.bsModalRef = modalRef;
    }

    /**
     * Close the popup
     */
    public close(): void {
        this.bsModalRef.hide();
        this.bsModalRef = null;
    }

    public rotate(): void {
        this.rotationAmount += 90;
        this.image.nativeElement.style.transform = `rotate(${this.rotationAmount}deg)`;
    }
}
