import { Component } from '@angular/core';
import { Validators } from '@angular/forms';
import { SupportTextareaComponent } from '../textarea/support-textarea.component';

@Component({
    templateUrl: './support-emailfield.component.html',
    selector: 'betech-support-emailfield',
})
export class SupportEmailfieldComponent extends SupportTextareaComponent {
    protected getValidators(): any {
        return [Validators.required];
    }
}
