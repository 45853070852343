import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../swagger-client';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {environment} from '../../../environments/environment';
import {
    ActiveEntityMediatorService,
    DashboardLoginType
} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    // The sign in form
    public _form: FormGroup;

    // Login failed
    public _loginFailure: boolean = false;

    // No machine found
    public _machineFailure: boolean = false;

    // Multiple machines found
    public _multipleMachinesError: boolean = false;

    private _admin;
    public set admin(asAdmin: boolean) {
        this._admin = asAdmin;

        this.machineRequiredForLogin(!asAdmin && this._constantMachine);
    }

    public get admin(): boolean {
        return this._admin;
    }

    public setShowMachine(event): void {
        this.constantMachine = this.machineRadioButton == 1;
    }

    public machineRadioButton: number;

    private _constantMachine: boolean;
    public set constantMachine(isConstant: boolean) {
        this._constantMachine = isConstant;

        this.machineRequiredForLogin(isConstant);
    }
    public get constantMachine(): boolean {
        return this._constantMachine;
    }

    constructor(
        private _formBuilder: FormBuilder,
        private _authService: AuthenticationService,
        private _router: Router,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private _route: ActivatedRoute,
    ) {}

    public ngOnInit() {
        this._route.queryParams.subscribe((params: Params) => {
            this._machineFailure = this._machineFailure ? this._machineFailure : !!params['error'];
            this.removeQueryParams();
        });

        this._machineFailure = !!this._route.snapshot.queryParamMap.get('error');
        this.removeQueryParams();

        this._form = this._formBuilder.group({
            username: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            machine: new FormControl(''),
        });

        this.constantMachine = environment.config.constantMachine; // also sets machine validator
        this.admin = false; // also sets machine validator
    }

    public _continue(): void {
        this._router.navigateByUrl('/');
    }

    public _submit(): void {
        this._loginFailure = false;
        this._machineFailure = false;
        this._multipleMachinesError = false;
        if (this._form.valid) {
            const values = this._form.getRawValue();

            this._activeEntityMediatorService.clear();
            this._activeEntityMediatorService.setLoginDashboardConstants({
                dashboardLoginType: this.constantMachine
                    ? DashboardLoginType.Machine
                    : DashboardLoginType.Employee,
                machineNumber: this.constantMachine ? values.machine : undefined,
                employeeId: undefined,
            });

            this._authService.login(values.username, values.password).subscribe(
                (user: User) => {
                    // If user is authenticated, we can check for machines

                    if (this.admin) {
                        this._router.navigateByUrl('/admin/dash');
                    } else {
                        this._router.navigateByUrl('/dash');
                    }
                },
                (error: Error) => {
                    if (error.message.match(/not found!$/)) {
                        this._machineFailure = true;
                    } else if (error.message.match(/^Multiple ProcessingStations/)) {
                        this._multipleMachinesError = true;
                    } else {
                        this._loginFailure = true;
                    }

                    this._form.enable();
                },
            );
        }
    }

    private removeQueryParams(): void {
        if (this._machineFailure) {
            this._router.navigate([], {
                relativeTo: this._route,
                queryParams: { error: null },
                queryParamsHandling: 'merge',
            });
        }
    }

    private machineRequiredForLogin(required: boolean): void {
        const machineControl: AbstractControl = this._form.get('machine');
        machineControl.setValidators(required ? [Validators.required] : []);
        machineControl.setValue('');
        machineControl.updateValueAndValidity();
    }
}
