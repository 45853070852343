<div
    data-html2canvas-ignore

    [ngClass]="[
    formOpen ? 'active' : 'inactive',
    formSent && !formSentSuccess && !formSentFailed ? 'form-pending' : '',
    formSent && formSentSuccess ? 'form-success' : '',
    formSent && formSentFailed ? 'form-failure' : ''
  ]"
>

    <div class="support-container">
        <div class="row row--header " style="margin: 1rem">
            <div class="col-sm-1 support-container__image rounded-circle bg-light">
                <img
                    class="image d-block"
                    src="assets/icons/attributes-favicon.png"

                />
            </div>
            <div class="col-sm-10">
                <h4 style="margin-bottom: 0">Support</h4>
                <small class="text-muted"> Wij helpen je graag! </small>
            </div>
            <div class="col-sm justify-content-end betech-modal__close betech-modal__close--support"
                 (click)="closeScreen()">
                <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-sm-8">
                <div class="card card--support text-black shadow-none bg-light rounded border border-white">
                    <div class="row m-1">
                        <div class="col-sm card-body">
                            <h5 class="card-title" style="margin-bottom: 0">
                                Ondervind je een probleem of heb je een voorstel?
                            </h5>
                            <h5 class="card-title">Laat je horen! Samen bereiken we meer.</h5>
                            <p class="card-text">
                                Zou je eerst even willen kijken of de support melding al te vinden is in de
                                onderstaande lijst? Dank!
                            </p>
                        </div>
                        <div class="col-sm">
                            <img
                                class="card-img"
                                src="assets/icons/Support_Header_Attributes.svg"
                                alt="Card image"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-sm p-0">
                    <ul class="list-group">
                        <li class="list-group-item" *ngFor="let ticket of tickets">
                            <betech-support-ticket [ticket]="ticket"></betech-support-ticket>
                        </li>
                    </ul>
                </div>

            </div>


            <div class="col-sm bg-light mr-3 rounded">
                <div class="row m-1 my-1 mt-4">
                    <div class="col-sm-11">
                        <h4 style="margin-bottom: 0">Nieuw probleem of een tip?</h4>
                        <small class="text-muted"> Meld het hieronder </small>
                        <small class="text-muted"> Een screenshot van het huidige scherm wordt meegestuurd. </small>
                    </div>
                </div>


                <div *ngIf="formSentFailed" class="row m-1" style="margin-bottom: 2rem;">
                    <div class="col-sm shadow-none border-0" style="background-color: #d512121a">
                        <div class="card-body text-wrap text-center">
                            <h1 style="color: darkred">☹</h1>
                            <p style="color: darkred">Versturen mislukt</p>
                        </div>
                    </div>

                    <div class="mb-2 col-sm shadow-none border-0" style="background-color: #f5f5f5">
                        <div class="card-body text-wrap text-center">
                            <h5>LET OP!</h5>
                            <small
                            >We hebben je melding niet kunnen versturen. Controleer de internet verbinding en
                                probeer het opnieuw</small
                            >
                        </div>
                    </div>
                    <div class="col-sm send-button text-center">
                        <button class="btn retry btn-success btn-lg" (click)="retry()">OPNIEUW PROBEREN</button>
                    </div>
                </div>

                <div *ngIf="formSentSuccess" class="row m-1" style="margin-bottom: 2rem;">
                    <div class="col-sm shadow-none border-0" style="background-color: #23b4101a">
                        <div class="card-body text-wrap text-center">
                            <h1 style="color: #5fa800">☺</h1>
                            <p style="color: #5fa800">Verstuurd!</p>
                        </div>
                    </div>

                    <div class="mb-2 col-sm shadow-none border-0" style="background-color: #f5f5f5">
                        <div class="card-body text-wrap text-center">
                            <h5>BEDANKT!</h5>
                            <small
                            >Je melding is met succes verstuurd. Wij gaan er zo spoedig mogelijk mee aan de slag.
                                Dank voor de moeite.</small
                            >
                        </div>
                    </div>
                    <div class="col-sm send-button text-center">
                        <button class="btn add btn-success btn-lg" (click)="newTicket()">NIEUWE MELDING</button>
                    </div>
                </div>

                <div class="row m-1 my-1">
                    <div class="col-sm form-content text-center" [hidden]="formSentFailed || formSentSuccess">
                        <form [formGroup]="supportForm" #sForm="ngForm" (ngSubmit)="submit()">
                            <betech-support-textfield
                                type="url"
                                [parentForm]="supportForm"
                            ></betech-support-textfield>
                            <betech-support-emailfield
                                type="email"
                                [parentForm]="supportForm"
                            ></betech-support-emailfield>
                            <betech-support-textarea
                                type="description"
                                label="description"
                                [parentForm]="supportForm"
                            ></betech-support-textarea>
                            <div class="send-button">
                                <button
                                    class="btn send btn-success btn-lg"
                                    type="submit"
                                    [disabled]="!supportForm.valid"
                                >
                                    VERSTUUR
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="row m-2">

        </div>
    </div>
</div>
