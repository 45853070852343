import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { AuthenticationService as SwaggerAuthenticationService, User } from '../../swagger-client';
import { Observable, Subject } from 'rxjs';

/**
 * The user is stored in localstorage after each login and can be relied upon directly
 * from local storage during the remainder of the session, since it will never change,
 * unless we implement some way to change it (ex. profile edit) - for now we don't have that.
 */
@Injectable()
export class UserService {
    /**
     * The local storage key for an user.
     */
    private readonly localStorageKey = 'user';

    /**
     * Constructor
     */
    constructor(
        private authenticationApi: SwaggerAuthenticationService,
        private localStorage: LocalStorageService,
    ) {}

    /**
     * Load the user that was lastly signed in.
     */
    public get(): User {
        return this.localStorage.getObject(this.localStorageKey);
    }

    /**
     * Clear this service.
     */
    public clear(): void {
        this.localStorage.remove(this.localStorageKey);
    }

    /**
     * Fetch the user account and store in local storage
     */
    public fetch(): Observable<User> {
        const subject: Subject<User> = new Subject();

        this.authenticationApi.userGet().subscribe(
            (user: User) => {
                this.localStorage.setObject(this.localStorageKey, user);
                subject.next(user);
            },
            (error: string) => {
                subject.error('No user logged in!');
            },
        );

        return subject;
    }
}
