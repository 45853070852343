<ng-container *ngIf="totals !== undefined && totals !== null">
  <tr [ngClass]="{ 'order-complete': progressPercentage >= 100 }" (click)="toggle = !toggle">
    <td style="width: 2%">
      <div class="order-id">{{ productionOrder.order_line_number }}</div>
    </td>
    <td style="width: 25%">
      <p>
        {{ productionOrder.processing_station.trumpf_id }} -
        {{ productionOrder.processing_station.description }}
      </p>
      <small
        ><i class="fas fa-cog"></i> {{ processingTime }} minuten <i class="fas fa-check"></i>
        {{ 3600 / productionOrder.planned_cycle_time }}/uur</small
      >
    </td>
    <td style="width: 50%">
      <div class="container">
        <span class="invalid-total"
          >{{ invalidPercentage }} % afkeur | {{ totals.scrap + totals.missing }} &nbsp;</span
        >
        <div class="progress" style="height: 1rem">
          <div
            class="progress-bar bg-warning"
            [class.bg-warning]="progressPercentage < 100"
            [class.bg-white]="progressPercentage >= 100"
            role="progressbar"
            [ngStyle]="{ width: invalidOfQuantityPercentage + '%' }"
          ></div>
          <div
            class="progress-bar bg-success"
            role="progressbar"
            [ngStyle]="{ width: progressPercentage - invalidOfQuantityPercentage + '%' }"
          ></div>
        </div>
        <span class="order-quantity"
          >&nbsp;{{ totals.approved }} / {{ productionOrder.quantity }}</span
        >
      </div>
    </td>
    <td>
      <p class="order-progress">
        {{ progressPercentage }}
        % afgerond
        <i
          class="fas fa-lg"
          [class.fa-check]="progressPercentage >= 100"
          [class.fa-sync-alt]="
            productionOrder.reported_status === 'STARTED' && progressPercentage < 100
          "
          [class.fa-hourglass-start]="productionOrder.reported_status !== 'STARTED'"
        ></i>
      </p>

      <small>Nog {{ productionOrder.quantity - totals.approved }} resterend</small>
    </td>
    <td class="info-button" style="width: 5%">
      <i class="fas fa-lg" [class.fa-chevron-right]="!toggle" [class.fa-chevron-down]="toggle"></i>
    </td>
  </tr>
  <div class="info-content" [ngClass]="toggle ? 'active' : 'inactive'" [collapse]="!toggle">
    <table class="table info-table">
      <thead>
        <tr>
          <td>Gestart om/door</td>
          <td>Voorkeur bewerkingsplek</td>
          <td>Werkelijke bewerkingsplek</td>
          <td>Instructies</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {{ (totals.started | date: 'dd-MM-yyyy') || '-' }}
          </td>
          <td>
            {{
              productionOrder.processing_station
                ? productionOrder.processing_station.trumpf_id +
                  ' - ' +
                  productionOrder.processing_station.name
                : '-'
            }}
          </td>
          <td>
            {{
              productionOrder.reported_processing_station
                ? productionOrder.reported_processing_station.trumpf_id +
                  ' - ' +
                  productionOrder.reported_processing_station.name
                : '-'
            }}
          </td>
          <td rowspan="2">
            {{ productionOrder.instruction[0].text }}
          </td>
        </tr>
        <tr>
          <td>
            {{ totals.employee || '-' }}
          </td>
          <td>
            {{
              productionOrder.processing_station
                ? productionOrder.processing_station.description
                : '-'
            }}
          </td>
          <td>
            {{
              productionOrder.reported_processing_station
                ? productionOrder.reported_processing_station.description
                : '-'
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
