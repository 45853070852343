import { Injectable, OnDestroy } from '@angular/core';
import {
    EsbmodelService,
    ProducedAmountDetails,
    ProductionOrder,
    SensorData,
} from '../../swagger-client';
import { interval, Observable, ReplaySubject, Subscription } from 'rxjs';
import { ActiveEntityMediatorService } from '../active-entity-mediator/active-entity-mediator.service';
import { SensorDataService } from '../sensor-data/sensor-data.service';
import { cloneDeep } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class ProducedAmountDataService implements OnDestroy {
    protected lastProducedAmountDetails: ReplaySubject<ProducedAmountDetails> = new ReplaySubject(
        1,
    );

    protected readonly fetchInterval = 1000 * 67; // 67 seconds
    protected lastFetched: ProducedAmountDetails;
    protected lastCorrected: ProducedAmountDetails;
    private _currentPO: ProductionOrder;
    private _lastSensorData: SensorData;
    private initialized: boolean = false;

    private subs: Subscription[] = [];

    constructor(
        private esbmodelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private sensorDataService: SensorDataService,
    ) {}

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    public initialize(): Observable<ProducedAmountDetails> {
        this.subs.push(
            this.activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    this._currentPO = currentPO;
                    this.fetch();
                },
            ),
            this.sensorDataService.observe().subscribe((sensorDataModel: SensorData) => {
                this._lastSensorData = sensorDataModel;
                this.generateCorrectedProductedAmountDetails();
            }),
            interval(this.fetchInterval).subscribe(() => this.fetch()),
        );
        return this.observe();
    }

    public forceUpdate(): void {
        this.fetch();
    }

    public unsubscribe(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
        this.lastFetched = null;
        this.lastProducedAmountDetails.next(null);
        this.initialized = false;
    }

    public observe(): Observable<ProducedAmountDetails> {
        return this.lastProducedAmountDetails.asObservable();
    }

    protected isChanged(producedAmountDetails: ProducedAmountDetails): boolean {
        return JSON.stringify(this.lastCorrected) !== JSON.stringify(producedAmountDetails);
    }

    protected fetch(): void {
        if (null == this._currentPO) {
            return;
        }

        this.esbmodelService
            .esbmProductionOrderReportOrderNumberTotalsGet(this._currentPO.order_number)
            .subscribe((newProducedAmountDetails: ProducedAmountDetails) => {
                this.lastFetched = newProducedAmountDetails;
                this.generateCorrectedProductedAmountDetails();
            });
    }

    private generateCorrectedProductedAmountDetails(): void {
        let correctedProducedAmountDetails = cloneDeep(this.lastFetched);

        if (
            correctedProducedAmountDetails &&
            correctedProducedAmountDetails.machineCounter > 0 &&
            this._lastSensorData
        ) {
            let correction =
                this._lastSensorData.processed_parts -
                correctedProducedAmountDetails.machineCounter;

            if (correction > 0) {
                correctedProducedAmountDetails.processed += correction;
                correctedProducedAmountDetails.approved += correction;
            }
        }

        if (this.isChanged(correctedProducedAmountDetails)) {
            this.lastCorrected = correctedProducedAmountDetails;
            this.lastProducedAmountDetails.next(correctedProducedAmountDetails);
        }
    }
}
