import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    OnDestroy,
    ViewChild,
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import QrScanner from 'qr-scanner';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, take } from 'rxjs/operators';
import { ProductionOrder } from '../../swagger-client';
import { ProductionOrderService } from '../../services/production-order/production-order.service';
import { ProductionOrderFilter } from '../../services/production-order/production-order.filter';

export interface QrCodeModalOptions {
    searchForResult?: boolean;
    jsonField?: string;
}

@Component({
    selector: 'betech-scan-qr-code-modal',
    templateUrl: './scan-qr-code-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanQrCodeModalComponent implements AfterViewInit, OnDestroy {
    searchProductionOrder: boolean = false;

    private bsModalRef: BsModalRef;
    private qrScanner: QrScanner;
    private options: QrCodeModalOptions;

    @ViewChild('qrScanner') qrVideoElement: ElementRef;
    @ViewChild('qrActiveRegion') qrActiveRegion: ElementRef;

    private _scanResult: Subject<string> = new Subject<string>();
    private _scanSubscription: Subscription;

    public showProductionOrder: boolean;
    public foundProductionOrder: ProductionOrder;

    readonly onScanError = error => {
        // overrule onDecodeError to prevent lots of messages
    };

    constructor(
        private _productionOrderService: ProductionOrderService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _ngZone: NgZone,
    ) {}

    public ngAfterViewInit() {
        this.qrScanner = new QrScanner(
            this.qrVideoElement.nativeElement,
            result => {
                this._scanResult.next(this.parseResult(result.data));
            },
            {
                onDecodeError: this.onScanError,
                overlay: this.qrActiveRegion.nativeElement,
                highlightCodeOutline: true,
                highlightScanRegion: true,
            },
        );
        this.qrScanner.start();
    }

    public ngOnDestroy(): void {
        this.qrScanner.stop();

        if (this._scanSubscription) {
            this._scanSubscription.unsubscribe();
        }
    }

    get scanResult$(): Observable<string> {
        return this._scanResult.asObservable();
    }

    public initModalRef(modalRef: any, options: QrCodeModalOptions): void {
        this.bsModalRef = modalRef;
        this.options = options;

        this.showProductionOrder = false;
        if (this.options.searchForResult) {
            this._scanSubscription = this._scanResult
                .asObservable()
                .pipe(distinctUntilChanged())
                .subscribe(data => {
                    this.handleNewScanInput(data);
                });
        }
    }

    public close(): void {
        if (this.bsModalRef) {
            this.bsModalRef.hide();
            this.bsModalRef = null;

            return;
        }
    }

    public removeFoundOrder(): void {
        this.showProductionOrder = false;
        this._scanResult.next(null);
    }

    private handleNewScanInput(data): void {
        if (data === null) {
            return;
        }

        const productionOrderFilter: ProductionOrderFilter = {
            search: data,
        };

        this._productionOrderService
            .fetchProductionOrders(productionOrderFilter)
            .pipe(take(1))
            .subscribe(productionOrders => {
                this.handleProductionOrderSearch(productionOrders.data);
            });
    }

    private handleProductionOrderSearch(productionOrders: ProductionOrder[]): void {
        if (productionOrders.length == 0) {
            return;
        }

        this._ngZone.run(() => {
            this.showProductionOrder = true;
            this.foundProductionOrder = productionOrders[0];
            this._changeDetectorRef.markForCheck();
        });
    }

    private parseResult(data: string): string {
        if (!this.options.jsonField) {
            return data;
        }

        return JSON.parse(data)[this.options.jsonField];
    }
}
