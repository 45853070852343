import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminGuard } from '../guards/admin/admin.guard';
import { MeasurementStatsComponent } from './measurement-stats/measurement-stats.component';
import { ToolStatsComponent } from './tool-stats/tool-stats.component';
import { MaterialStatsComponent } from './material-stats/material-stats.component';
import { PerformanceComponent } from './performance/performance.component';

const adminRoute: Routes = [
    {
        path: 'admin',
        canActivate: [AdminGuard],
        canActivateChild: [AdminGuard],
        component: AdminComponent,
        data: {
            title: 'Administrator overzicht',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'dash',
            },
            {
                path: 'dash',
                component: DashboardComponent,
                data: {
                    title: 'Machine overzicht',
                },
            },
            {
                path: 'measurement-stats',
                component: MeasurementStatsComponent,
                data: {
                    title: 'Machine overzicht metingen',
                },
            },
            {
                path: 'tool-stats',
                component: ToolStatsComponent,
                data: {
                    title: 'Machine overzicht standtijden',
                },
            },
            {
                path: 'material-stats',
                component: MaterialStatsComponent,
                data: {
                    title: 'Machine overzicht materiaal',
                },
            },
            {
                path: 'performance',
                data: {
                    title: 'Actieve order overzicht',
                },
                loadChildren: () =>
                    import('app/admin/performance/performance.module').then(
                        m => m.PerformanceModule,
                    ),
            },
        ],
    },
];

export const AdminRoutingModule = RouterModule.forChild(adminRoute);
