import { Component } from '@angular/core';
import { Employee, EsbmodelService } from '../../../../swagger-client';
import {
    ActiveEntityMediatorService,
    DashboardLoginType
} from "../../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    templateUrl: './no-employee-modal.component.html',
})
export class NoEmployeeModalComponent {
    public modalRef;

    public employeeList: Employee[];
    public activeEmployee: Employee;

    constructor(
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private esbModelService: EsbmodelService,
    ) {
        this.esbModelService.esbmEmployeeGet().subscribe(data => (this.employeeList = data.data));
    }

    public closeScreen(): void {
        this.modalRef.hide();
    }

    public setModalRef(modalRef: any): void {
        this.modalRef = modalRef;
    }

    public submit(): void {
        this._activeEntityMediatorService.setLoginDashboardConstants({
            dashboardLoginType: DashboardLoginType.Employee,
            machineNumber: undefined,
            employeeId: this.activeEmployee.id,
        });

        this._activeEntityMediatorService.initialize().subscribe(() => {
            this.closeScreen();
        });
    }

    public setEmployee(employee: any): void {
        if (typeof employee !== 'object') {
            return;
        }

        this.activeEmployee = employee;
    }
}
