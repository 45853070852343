import { Pipe, PipeTransform } from '@angular/core';
import { StockRequest } from '../../swagger-client';
import StatusEnum = StockRequest.StatusEnum;

@Pipe({
    name: 'stockRequestStatus',
})
export class StockRequestStatusPipe implements PipeTransform {
    public transform(status: StockRequest.StatusEnum | 'EMPTY'): string {
        switch (status) {
            case StatusEnum.ORDERED:
                return '<strong i18n class="text-warning">Te ontvangen</strong>';
            case StatusEnum.RECEIVED:
                return '<strong i18n class="text-success">Ontvangen</strong>';
            case StatusEnum.MEASURED:
                return '<strong i18n class="text-success">Gemeten</strong>';
            case 'EMPTY':
                return '<strong i18n class="text-danger">Materiaal op</strong>';
        }
    }
}
