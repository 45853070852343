<div class="row">
  <div class="input-field col" [formGroup]="childForm">
    <textarea
      class="form-control"
      style="resize: none"
      (focus)="focus($event)"
      (focusout)="focusOut($event)"
      id="{{ type }}"
      name="{{ type }}"
      placeholder="omschrijving"
      formControlName="{{ type }}"
    ></textarea>
  </div>
</div>
