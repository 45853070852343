import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    EsbmodelService, InlineResponse20019,
    InlineResponse20020,
    ProcessingStation,
    ProducedAmountDetails,
    ProductionOrder, SensorData,
    ToolXref, ToolXrefResponse,
} from '../../../swagger-client';
import { ProducedAmountDataService } from '../../../services/produced-amount-data-service/produced-amount-data-service';
import { Subscription } from 'rxjs';
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';
import {SensorDataService} from "../../../services/sensor-data/sensor-data.service";

@Component({
    selector: 'betech-tool-lifetime-card',
    templateUrl: './tool-lifetime-card.component.html',
})
export class ToolLifetimeCardComponent implements OnInit, OnDestroy {
    public currentProductionOrder: ProductionOrder;

    private times: number[] = [];
    private currentProducedAmountDetails: ProducedAmountDetails;
    private activeMachine: ProcessingStation;
    private subs: Subscription[] = [];
    private rawToolXRefData: ToolXrefResponse;
    private sensorData: SensorData;

    constructor(
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private _esbModelService: EsbmodelService,
        private producedAmountDataService: ProducedAmountDataService,
        private sensorDataService: SensorDataService,
    ) {}

    public ngOnInit(): void {
        this.subs.push(
            this.activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    this.currentProductionOrder = currentPO;
                    this.updateToolXRefData();
                },
            ),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                    this.updateToolXRefData();
                },
            ),
            this.sensorDataService
                .observe()
                .subscribe((sensorData: SensorData) => {
                    if (sensorData) {
                        this.sensorData = sensorData;
                        this.updateToolPrognose();
                    }
                }),
            this.producedAmountDataService
                .observe()
                .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                    if (producedAmountDetails) {
                        this.currentProducedAmountDetails = producedAmountDetails;
                        this.updateToolPrognose();
                    }
                }),
        );
    }

    public ngOnDestroy() {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public get lessThan4Hours(): number {
        return this.times.filter(a => a < 60 * 60 * 4).length;
    }

    public get greaterThan4Hours(): number {
        return this.times.filter(a => a >= 60 * 60 * 4).length;
    }

    public get lowestTime(): number {
        return this.times[0];
    }

    public get total(): number {
        return this.times.length;
    }

    public get loaded(): boolean {
        return this.times.length > 0;
    }

    public get urgentReplace(): number {
        return this.times.filter(a => a < 60 * 60).length; // for now: less than 1 hour
    }

    private updateToolXRefData() {
        if (!this.activeMachine) {
            return;
        }

        if (!this.currentProductionOrder) {
            return;
        }

        this._esbModelService
            .esbmToolXrefGet(this.activeMachine.id, this.currentProductionOrder.id)
            .subscribe((data: ToolXrefResponse) => {
                this.rawToolXRefData = data;
                this.updateToolPrognose();
            });
    }

    private updateToolPrognose(): void {
        if (!this.rawToolXRefData || !this.currentProducedAmountDetails) {
            return;
        }

        this.times = [];

        // deepcopy
        let data = this.rawToolXRefData.data.map(x => Object.assign({}, x));

        data.forEach((toolXref: ToolXref) => {
            if (!toolXref.tool) {
                return;
            }

            let startCounterOfCurrentTool: number = toolXref.last_replace
                ? toolXref.last_replace.machine_counter
                : this.currentProductionOrder.start_machine_counter;
            let producedSinceLastReplace = this.sensorData.processed_parts - startCounterOfCurrentTool;

            let expectedQuantity;
            if (toolXref.tool_position.tool_position_group) {
                expectedQuantity = this.getExpectedQuantity(toolXref);
            } else {
                expectedQuantity = toolXref.expected_quantity;
            }

            if (
                toolXref.tool_position.tool_position_group &&
                toolXref.id !== this.groupLeaderXref.id
            ) {
                return;
            }

            const totalToGo = expectedQuantity - producedSinceLastReplace;
            const timeToGo = Math.max(
                totalToGo * this.currentProductionOrder.planned_cycle_time,
                0,
            );
            this.times.push(timeToGo);
        });
        this.times.sort((a, b) => (a < b ? -1 : 1));
    }

    private get groupLeaderXref(): ToolXref {
        return this.rawToolXRefData.data[this.rawToolXRefData.data.length - 1];
    }

    private getExpectedQuantity(toolXref: ToolXref): number {
        let expectedQuantity = 0;

        // get the total processed for myself and previous group members
        for (let i = 0; i < this.rawToolXRefData.data.length; i++) {
            if (this.rawToolXRefData.data[i].id === toolXref.id) {
                break;
            }
            expectedQuantity += this.rawToolXRefData.data[i].expected_quantity;
        }

        return expectedQuantity;
    }
}
