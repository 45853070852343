import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Employee, EsbmodelService, ProductionOrder, WorkLog } from '../../../swagger-client';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ActiveEntityMediatorService } from 'app/services/active-entity-mediator/active-entity-mediator.service';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'betech-worklog-list-item',
    templateUrl: './worklog-list-item.component.html',
})
export class WorklogListItemComponent implements OnInit, OnDestroy {
    @Input() public worklog: WorkLog;
    @Input() public employeeList: Employee[];
    @Input() public orderList: ProductionOrder[];
    @Output() public worklogChange = new EventEmitter<WorkLog>();

    @HostBinding('class.betech-table__row--editing') public editMode: boolean = false;
    @HostBinding('class.betech-table__row--highlight') public get active(): boolean {
        return !this.worklog.end_datetime;
    }
    public isSaving: boolean;
    private interval$;

    public now: BehaviorSubject<Date> = new BehaviorSubject(new Date());

    constructor(
        private _esbmodelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit(): void {
        // keep track of a running 'now' (the time) for calculation purposes
        setTimeout(() => {
            this.now.next(new Date());
            this.interval$ = setInterval(() => this.now.next(new Date()), 60000); // 1 minute
        }, (60 - new Date().getSeconds()) * 1000);
    }

    public ngOnDestroy(): void {
        clearInterval(this.interval$);
    }

    public search(productionOrder: ProductionOrder, searchInput: string) {
        const pattern = new RegExp(searchInput, 'i');

        if (environment.config.worklogByCalculationNumber) {
            return (
                productionOrder.calculation_number +
                ' - ' +
                productionOrder.order_line_number
            ).match(pattern);
        } else {
            return productionOrder.production_number.match(pattern);
        }
    }

    public poLabelGenerator(productionOrder: ProductionOrder): string {
        if (environment.config.worklogByCalculationNumber) {
            return productionOrder.calculation_number + ' - ' + productionOrder.order_line_number;
        } else {
            return productionOrder.production_number;
        }
    }

    /**
     * PUT changes
     */
    public saveWorklog(): void {
        if (
            this.isSaving ||
            !this.worklog.employee ||
            !this.worklog.start_datetime ||
            !this.worklog.end_datetime
        ) {
            return;
        }
        this.isSaving = true;
        this._esbmodelService
            .esbmWorklogIdPut(this.worklog.id, this.worklog)
            .pipe(tap(() => this._activeEntityMediatorService.reloadEmployee()))
            .subscribe((backendWorklog: WorkLog) => {
                this.worklog = backendWorklog;
                this.editMode = false;
                this.isSaving = false;
                this.worklogChange.emit();
            });
    }

    /**
     * Start editing this worklog
     */
    public startWorklogEdit(): void {
        this.editMode = true;
    }

    /**
     * Ability to end active/highlighted orders
     */
    public stopStopwatch(): void {
        this.worklog.end_datetime = new Date();
        this.saveWorklog();
    }
}
