<div class="update-progress-modal">
  <div class="betech-modal__header">
    <h2>{{ statusHeader }}</h2>
    <div class="betech-modal__close" (click)="closeScreen()">
      <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
    </div>
  </div>
  <div class="row mx-4 mt-3 pb-4">
    <div class="col-6 pr-5">
      <div class="row">
        <div class="col-12">
          <label for="productionNumber">Productienummer</label>
          <input
            type="text"
            name="productionNumber"
            id="productionNumber"
            value="{{ order.production_number }}"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label for="instruction">Order Instructies</label>
          <textarea
            name="instruction"
            id="instruction"
            cols="10"
            rows="10"
            value="{{ instructionText }}"
            style="height: 160px"
            disabled
          ></textarea>
        </div>
      </div>
    </div>
    <div class="col-6 pl-5">
      <div *ngIf="this.order.reported_status === reportedStatusEnum.STARTED && !pendingSent" class="row form-group">
        <div class="col-6">
          <label for="approved">Goedgekeurd</label>
          <input
            type="number"
            name="approved"
            id="approved"
            [(ngModel)]="startPrepareOrderModel.approved"
            (input)="determineValidForm()"
            required
          />
        </div>
        <div class="col-6">
          <label for="rejected">Afgekeurd</label>
          <input
            type="number"
            name="rejected"
            id="rejected"
            [(ngModel)]="startPrepareOrderModel.rejected"
            (input)="determineValidForm()"
            required
          />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-12">
          <label>Opgegeven door:</label>
          <betech-select-dropdown
            [required]="true"
            [items]="employeeList"
            label="full_name"
            search="full_name"
            [model]="activeEmployee"
            (modelChange)="setEmployee($event)"
          ></betech-select-dropdown
          >
        </div>
      </div>
      <div class="row submit-buttons buttons-at-bottom pr-4">
        <div class="col-6">
          <button i18n class="btn btn-outline-secondary float-left" (click)="closeScreen()">
            Annuleren
          </button>
        </div>
        <div class="col-6">
          <button
            i18n
            class="btn btn-secondary float-right"
            [disabled]="pendingSent || !validForm"
            (click)="submitForm()"
            type="submit"
          >
            <i *ngIf="pendingSent" class="fas fa-sync fa-spin"></i>
            {{ statusHeader }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
