<betech-modal [showCloseButton]="config.dismissible" (click)="close()">
    <i *ngIf="config.icon.show" modal-icon [ngClass]="config.icon.name" class="fal fa-3x fa-fw"></i>
    <span i18n modal-title>{{ config.title }}</span>
    <ng-container modal-body>
        <div class="container">
            <div class="row">
                <div class="col py-4"
                     [ngClass]="{'alert alert-warning': config.message.alert }">
                     {{ config.message.text }}
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <button [ngClass]="config.actions.cancel.class" *ngIf="config.actions.cancel.show" i18n class="btn float-left" (click)="close()">{{config.actions.cancel.label}}</button>
                    <button [ngClass]="config.actions.confirm.class"  *ngIf="config.actions.confirm.show" i18n class="btn float-right" (click)="confirm()"> {{config.actions.confirm.label}} </button>
                </div>
            </div>
        </div>
    </ng-container>
</betech-modal>
