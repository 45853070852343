import { Injectable, OnDestroy } from '@angular/core';
import { interval, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { EsbmodelService, ProcessingStation, SensorData } from '../../swagger-client';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActiveEntityMediatorService } from '../active-entity-mediator/active-entity-mediator.service';

@Injectable({
    providedIn: 'root',
})
export class SensorDataService implements OnDestroy {
    protected lastSensorData: ReplaySubject<SensorData> = new ReplaySubject(1);
    protected readonly fetchInterval = 1000 * 67; // 67 seconds
    protected lastFetched: SensorData;
    private initialized: boolean = false;
    private sensorDataError = false;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    public activeMachine: ProcessingStation;

    /**
     * Constructor
     */
    constructor(
        private esbmodelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    /**
     * Start interval reloading and return directly
     */
    public initialize(): Observable<SensorData> {
        this.activeEntityMediatorService.activeMachine$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((activeMachine: ProcessingStation) => {
                this.activeMachine = activeMachine;
                this.fetch();
            });

        if (this.initialized) {
            return;
        }

        this.fetch();
        if (environment.config.showMachineCounter) {
            interval(this.fetchInterval)
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(() => this.fetch());
        }
        this.initialized = true;
        return this.observe();
    }

    public ngOnDestroy(): void {
        this.unsubscribe();
    }

    /**
     * Stop interval
     */
    public unsubscribe(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        this.initialized = false;
    }

    /**
     * Load newest sensor data (from memory or server)
     * If you want to observe each change, use `observe()` instead.
     */
    public load(): Observable<SensorData> {
        return this.lastFetched ? of(this.lastFetched) : this.observe().pipe(take(1));
    }

    /**
     * Continuously keep observing the sensor data changes
     */
    public observe(): Observable<SensorData> {
        return this.lastSensorData.asObservable();
    }

    /**
     * Determine if the last fetched currentProductionOrders is different from the given one
     */
    protected isChanged(sensorDataModel: SensorData): boolean {
        return JSON.stringify(this.lastFetched) !== JSON.stringify(sensorDataModel);
    }

    /**
     * Find latest order report
     */
    public fetch(): void {
        if (this.activeMachine == null) {
            return;
        }

        this.esbmodelService
            .esbmProcessingStationIdSensorDataGet(this.activeMachine.id)
            .pipe(take(1))
            .subscribe((sensorData: SensorData) => {
                if (this.isChanged(sensorData)) {
                    this.lastFetched = sensorData;
                    this.sensorDataError = sensorData.status === 'error';
                    this.lastSensorData.next(sensorData);
                } else {
                    this.lastSensorData.next(this.lastFetched);
                }
            });
    }

    public hasError(): boolean {
        return this.sensorDataError;
    }
}
