import { Injectable } from '@angular/core';
import { EsbmodelService, ProcessingStation } from '../../swagger-client';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * The active ProcessingStation (Machine) is stored in the local storage for the
 * duration of the session. Since this is chosen at login, there is no reason for
 * it to ever change as an observable (via polling or some such).
 *
 * Only
 */
@Injectable({
    providedIn: 'root',
})
export class ActiveMachineService {
    constructor(private esbmodelService: EsbmodelService) {}

    private _activeMachine: Subject<ProcessingStation> = new ReplaySubject(1);

    private _processingStationAtLogin: ProcessingStation;
    private _processingStationOverruled: ProcessingStation;
    private _latestSendedProcessingStation: ProcessingStation;

    get activeMachine$(): Observable<ProcessingStation> {
        return this._activeMachine.asObservable();
    }

    private updateMachine(): void {
        let nextProcessingStation: ProcessingStation =
            this._processingStationAtLogin ?? this._processingStationOverruled;

        if (this._latestSendedProcessingStation?.id !== nextProcessingStation?.id) {
            this._activeMachine.next(nextProcessingStation);
            this._latestSendedProcessingStation = nextProcessingStation;
        }
    }

    private set activeMachineAtLogin(processingStation: ProcessingStation) {
        if (this._processingStationAtLogin != null && processingStation != null) {
            console.error('Cannot rewrite processingStationAtLogin with another processingStation');
            return;
        }

        this._processingStationAtLogin = processingStation;
        this.updateMachine();
    }

    public set activeMachine(processingStation: ProcessingStation) {
        this._processingStationOverruled = processingStation;
        this.updateMachine();
    }

    public clear(): void {
        this._processingStationAtLogin = null;
        this._processingStationOverruled = null;
        this.updateMachine();
    }

    public initialize(machineNumber: string): Observable<ProcessingStation> {
        if (!this.validMachineNumber(machineNumber)) {
            this.activeMachineAtLogin = null;
            return of({});
        }

        return this.esbmodelService.esbmProcessingStationGet(machineNumber).pipe(
            map(
                data => {
                    if (data.data.length === 0) {
                        throw 'ProcessingStation with number= ' + machineNumber + ' not found!';
                    }

                    if (data.data.length > 1) {
                        throw 'Multiple ProcessingStations with number= ' + machineNumber;
                    }

                    const activeMachine = data.data[0];
                    if (activeMachine.active_cnc_program === undefined) {
                        activeMachine.active_cnc_program = '';
                    }
                    if (activeMachine.operational_status === undefined) {
                        activeMachine.operational_status = '';
                    }

                    this.activeMachineAtLogin = activeMachine;
                    return activeMachine;
                },
                (error: string) => {
                    throw (
                        'Failed to fetch ProcessingStation with number=' +
                        machineNumber +
                        '. Error=' +
                        error
                    );
                },
            ),
        );
    }

    public validMachineNumber(machNr: string): boolean {
        return machNr != null && machNr != '';
    }
}
