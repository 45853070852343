import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'betech-card',
    templateUrl: './card.component.html',
})
export class CardComponent {
    @Input() public fullWidth: boolean = false;
    @Input() public clickUrl: string;
    @Input() public bgClass: string;

    constructor(private router: Router) {}

    public click(): void {
        if (this.clickUrl) {
            this.router.navigateByUrl(this.clickUrl);
        }
    }
}
