<div class="order-performance">
  <div class="back-button">
    <a routerLink="/dash"><i class="fas fa-arrow-left"></i> Terug </a>
  </div>
  <hr />
  <table *ngIf="!isEmpty" class="betech-table">
    <thead>
      <tr class="betech-table__row">
        <th class="betech-table__heading"><span i18n>Datum</span></th>
        <th class="betech-table__heading"><span i18n>Cyclustijd (sec.)</span></th>
        <th class="betech-table__heading"><span i18n>Aantal/uur</span></th>
        <!--            <th class="betech-table__heading"><span i18n>Feedrate (%)</span></th>-->
        <!--            <th class="betech-table__heading"><span i18n>Aantal x feedrate/uur</span></th>-->
        <th class="betech-table__heading"><span i18n>Uren per dag</span></th>
        <th class="betech-table__heading"><span i18n>Aantal/dag</span></th>
        <th class="betech-table__heading"><span i18n>Gecalculeerd aantal/dag</span></th>
        <th class="betech-table__heading"><span i18n>Dagen per week</span></th>
        <th class="betech-table__heading"><span i18n>Aantal/week</span></th>
        <th class="betech-table__heading"><span i18n>Gecalculeerd aantal/week</span></th>
          <th class="betech-table__heading"><span i18n>Type</span></th>
        <!--            <th class="betech-table__heading"><span i18n>OEE (%)</span></th>-->
        <th class="betech-table__heading"><span i18n>Toelichting</span></th>
      </tr>
    </thead>
    <ng-container *ngFor="let performance of performanceTargets">
      <tbody class="betech-table__row-group" betech-performance-item [currentProductionOrder]="currentProductionOrder" [performanceTarget]="performance">
      </tbody>
    </ng-container>
  </table>
  <ng-container *ngIf="isEmpty">
    <div><h1 class="text-center">Geen overzicht beschikbaar</h1></div>
  </ng-container>
</div>
