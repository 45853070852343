import { PerformanceComponent } from './performance.component';
import { NgModule } from '@angular/core';
import { PerformanceAdminRouting } from './performance.routing';
import { NavigationPerformanceComponent } from './navigation/navigation-performance.component';
import { ArticlePerformanceComponent } from './article/article-performance.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { MachinesPerformanceComponent } from './machines/machines-performance.component';
import { CommonModule } from '@angular/common';
import { MachinesEntryPerformanceComponent } from './machines/machines-entry/machines-entry-performance.component';
import { EmployeesPerformanceComponent } from './employees/employees-performance.component';
import { EmployeesEntryPerformanceComponent } from './employees/employee-entry/employees-entry-performance.component';
import { EmployeesNotActiveComponent } from './employees/not-active/employees-not-active.component';

@NgModule({
    imports: [CommonModule, SharedModule, PerformanceAdminRouting],
    declarations: [
        NavigationPerformanceComponent,
        PerformanceComponent,
        ArticlePerformanceComponent,
        MachinesPerformanceComponent,
        MachinesEntryPerformanceComponent,
        EmployeesPerformanceComponent,
        EmployeesEntryPerformanceComponent,
        EmployeesNotActiveComponent,
    ],
    exports: [RouterModule],
})
export class PerformanceModule {}
