<div
  class="instruction-bar"
  *ngIf="showInstruction"
  (click)="toggle()"
  [ngClass]="[instructionOpen ? 'active' : 'inactive']"
  [ngStyle]="{ bottom: height }"
>
  <div class="expand-button">
    <i class="fa fa-chevron-up" [ngClass]="[instructionOpen ? 'active' : 'inactive']"></i>
  </div>
  <div class="instruction" id="instruction-container">
    <div class="instruction-icon">
      <i class="fa fa-info"></i>
    </div>
    <div class="instruction-text">
      <span>
        {{ instruction }}
      </span>
    </div>
  </div>
</div>
