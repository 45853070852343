import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {EsbmodelService, ProducedAmountDetails, ProductionOrder,} from '../../../swagger-client';
import {environment} from '../../../../environments/environment';
import {
    ActiveProductionOrdersService
} from '../../../services/active-production-orders/active-production-orders.service';
import {Subscription} from 'rxjs';
import {TokenService} from '../../../services/token/token.service';
import {ProducedAmountDataService} from '../../../services/produced-amount-data-service/produced-amount-data-service';
import {MediaSlideshowModalComponent} from '../../../shared/media-slideshow-modal/media-slideshow-modal.component';
import {ActiveEntityMediatorService} from "../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-order-card',
    templateUrl: './order-card.component.html',
})
export class OrderCardComponent implements OnInit, OnDestroy {
    public currentProductionOrder: ProductionOrder = null;
    private bsModalRef: BsModalRef;
    public imageUrl: string;
    public correctProduced: number;
    private subs: Subscription[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private modalService: BsModalService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private tokenService: TokenService,
        private producedAmountDataService: ProducedAmountDataService,
    ) {
    }

    public ngOnInit(): void {
        this.subs.push(
            this.producedAmountDataService
                .observe()
                .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                    if (producedAmountDetails) {
                        this.correctProduced = producedAmountDetails.approved;
                    }
                }),
            this.activeEntityMediatorService
                .activeProductionOrder$
                .subscribe((currentPO: ProductionOrder) => {
                        this.currentProductionOrder = currentPO;
                        if (this.currentOrderImageNotNull()) {
                            this.imageUrl = `${environment.apiUrl}/api${this.currentProductionOrder.drawing_image_url}`;
                        }
                    }
                )
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * Show media modal
     */
    public showMediaModal($event: Event): void {
        $event.preventDefault();
        $event.stopPropagation();

        const order: ProductionOrder = this.currentProductionOrder;
        if (!order.drawing_image_url) {
            return;
        }

        this.bsModalRef = this.modalService.show(MediaSlideshowModalComponent, {
            class: 'mw-100 m-0 h-auto',
        });
        this.bsModalRef.content.setModalRef(this.bsModalRef);

        this.bsModalRef.content.showMediaSlideshow(
            order,
            this.currentProductionOrder.drawing_image_urls,
        );
    }

    /**
     * Check whether the image url of a current order is not null
     */
    public currentOrderImageNotNull(): boolean {
        return !!(
            this.currentProductionOrder &&
            this.currentProductionOrder.drawing_image_urls
        );
    }
}
