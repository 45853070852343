<div class="tool-history">
  <div class="order-list betech-table">
    <div class="betech-table__row">
      <div class="betech-table__heading"><span i18n>Wissel moment</span></div>
      <div class="betech-table__heading"><span i18n>Operator</span></div>
      <div class="betech-table__heading"><span i18n>Tool positie</span></div>
      <div class="betech-table__heading"><span i18n>Tool</span></div>
      <div class="betech-table__heading"><span i18n>Aantal geproduceerd</span></div>
    </div>
    <ng-container *ngFor="let toolReplace of toolReplaces">
      <div class="betech-table__row">
        <div class="betech-table__cell">
          <span>{{ (toolReplace.timestamp | date: 'dd-MM-yyyy') || '-' }}</span>
        </div>

        <div class="betech-table__cell">
          <span>{{ toolReplace.employee?.full_name || '-' }}</span>
        </div>

        <div class="betech-table__cell">
          <span>{{ toolReplace.tool_xref?.tool_position?.name || '-' }}</span>
        </div>

        <div class="betech-table__cell">
          <span>{{ toolReplace.tool_xref?.tool?.name || '-' }}</span>
        </div>

        <div class="betech-table__cell">
          <span>{{ toolReplace.total_processed || '-' }}</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>
