<div class="row">
  <div class="input-field col" [formGroup]="childForm">
    <input
      class="form-control"
      (focus)="focus($event)"
      (focusout)="focusOut($event)"
      id="{{ type }}"
      name="{{ type }}"
      placeholder="{{ type }}"
      formControlName="{{ type }}"
    />
  </div>
</div>
