<div class="tool-takeover-modal">
    <div class="betech-modal__header">
        <h2>Tools Overnemen</h2>
        <div class="betech-modal__close" (click)="closeScreen()">
            <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
        </div>
    </div>
    <div class="row mx-4 mt-4 pb-4">
        <div class="col">
            <div class="row">
                <div class="col tool-takeover-modal-info py-3 px-3 my-2">
                    Part
                    <input
                        type="search"
                        [(ngModel)]="search"
                        typeaheadOptionField="name"
                        [typeahead]="dataSource"
                        [typeaheadAsync]="true"
                        placeholder="Zoek..."
                        typeaheadWaitMs="500"
                        (typeaheadOnSelect)="getSimilar($event)"
                        class="search-input"
                    />
                    voor de actieve order is al eerder op een andere machine gerealiseerd. Wil je de tool
                    instellingen zoals deze destijds zijn gebruikt overnemen? Maak dan hieronder je keuze en
                    klik vervolgens op 'overnemen'. Wil je deze tool instellingen niet overnemen? Klik dan op
                    'annuleren' zodat je zelf de tools kunt instellen.
                </div>
            </div>
            <ng-container *ngFor="let tool of previousTools">
                <betech-tool-takeover-item
                    (toolTakeoverChange)="setTools($event)"
                    [choosenTools]="choosenTools"
                    [ownTooldata]="tool"
                >
                </betech-tool-takeover-item>
            </ng-container>
            <div *ngIf="previousTools !== undefined && previousTools.length === 0">Geen resultaten gevonden.</div>
        </div>
    </div>
    <div class="row mx-0 py-4 footer">
        <div class="col">
            <button class="btn btn-outline-primary mx-2" i18n (click)="closeScreen()">
                ANNULEREN
                <i class="fas fa-times ml-1"></i>
            </button>
        </div>
        <div class="col-8 d-flex justify-content-center pt-2" *ngIf="choosenTools">
      <span i18n>
        <i class="fal fa-toolbox mr-1"></i>
        Geselecteerd:
      </span>
            <span class="font-weight-bold text-primary mx-2">
        {{ machineNumber }} | {{ productionNumber }}
      </span>
        </div>
        <div class="col-8 d-flex justify-content-center pt-2" *ngIf="!choosenTools">
      <span i18n>
        <i class="fal fa-toolbox mr-1"></i>
        Maak een keuze
      </span>
        </div>
        <div class="col">
            <button
                class="btn btn-primary float-right mx-2"
                i18n
                (click)="submitTools()"
                [disabled]="!choosenTools"
            >
                KIEZEN
                <i class="fas fa-angle-right ml-1"></i>
            </button>
        </div>
    </div>
</div>
