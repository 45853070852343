import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    ProductionOrderReport,
} from '../../../swagger-client';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StartPrepareOrderModel } from '../model/start-prepare-order-model';
import { OrderListComponent } from '../order-list.component';
import ReportedStatusEnum = ProductionOrder.ReportedStatusEnum;
import { Observable, of, Subscription } from 'rxjs';
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';
import { ModalTypes } from './start-prepare-order-modal-types.enum';
import { ProductionOrderService } from '../../../services/production-order/production-order.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'betech-start-prepare-order-modal',
    templateUrl: './start-prepare-order-modal.component.html',
})
export class StartPrepareOrderModalComponent implements OnInit, OnDestroy {
    public readonly DEFAULTINSTRUCTION: string = 'Geen instructie';

    public modalType: ModalTypes = null;
    public order: ProductionOrder;
    public status: ReportedStatusEnum;
    public statusHeader: string;
    public reportedStatusEnum = ReportedStatusEnum;

    public pendingSent: boolean = false;
    public validForm: boolean;
    public instructionText: string;

    public employeeList;

    public startPrepareOrderModel = new StartPrepareOrderModel();

    public parentOrder: OrderListComponent;

    public activeEmployee: Employee;
    public activeMachine: ProcessingStation;

    private subs: Subscription[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private _productionOrderService: ProductionOrderService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        public modalRef: BsModalRef,
    ) {}

    public ngOnInit() {
        this.pendingSent = false;

        if (this.modalType == null) {
            this.modalType = this.determineModalType();
        }
        this.statusHeader = this.setStatusHeader();

        if (this.order.instruction.length > 0) {
            this.instructionText = this.order.instruction[0].text;
        } else {
            this.instructionText = this.DEFAULTINSTRUCTION;
        }

        this.subs.push(
            this.esbModelService
                .esbmEmployeeGet()
                .subscribe(data => (this.employeeList = data.data)),
            this._activeEntityMediatorService.activeEmployee$.subscribe(employee => {
                if (employee !== null) {
                    this.startPrepareOrderModel.employee = employee;
                    this.activeEmployee = employee;
                }
            }),
            this._activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public closeScreen(): void {
        this.modalRef.hide();
    }

    public submitForm(): void {
        this.pendingSent = true;

        let observable: Observable<any>;

        switch (this.modalType) {
            case ModalTypes.Start:
                observable = this._productionOrderService.sendStartOrder(
                    this.order,
                    this.startPrepareOrderModel.employee,
                );
                break;
            case ModalTypes.Prepare:
                observable = this._productionOrderService.sendPrepareOrder(
                    this.order,
                    this.startPrepareOrderModel.employee,
                );
                break;
            case ModalTypes.Cooperation:
                observable = this._productionOrderService.addWorkerToOrder(
                    this.order,
                    this.startPrepareOrderModel.employee,
                );
                break;
            default:
                observable = of({});
        }

        observable.pipe(take(1)).subscribe(() => this.closeScreen());
    }

    public determineValidForm(): void {
        this.validForm = this.startPrepareOrderModel.employee != null;
    }

    public setEmployee(employee: any): void {
        if (typeof employee !== 'object') {
            return;
        }

        this.startPrepareOrderModel.employee = employee;
        this.determineValidForm();
    }

    public setStatusHeader(): string {
        switch (this.modalType) {
            case ModalTypes.Start:
                return 'Start productie';
            case ModalTypes.Prepare:
                return 'Start instellen';
            case ModalTypes.Cooperation:
                return 'Start samenwerken';
            default:
                return '';
        }
    }

    public determineModalType(): ModalTypes {
        switch (this.status) {
            case ReportedStatusEnum.STARTED:
                return ModalTypes.Start;
            case ReportedStatusEnum.PREPARED:
                return ModalTypes.Prepare;
            default:
                return ModalTypes.Start;
        }
    }

    public createProductionOrderReport(
        formInput: StartPrepareOrderModel,
        order: ProductionOrder,
    ): ProductionOrderReport {
        const date = new Date();

        return {
            scrap_parts: formInput.rejected,
            missing_parts: 0,
            approved_parts: formInput.approved,
            report_id: order.processing_station.name + '_' + Date.now().toString(),
            processing_time: 0,
            setup_time: 0,
            start_datetime: date,
            timestamp: date,
            employee_number: formInput.employee.employee_number,
            processing_station_number: this.activeMachine.name,
            order_number: order.order_number,
        };
    }

    private start;
}
