import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    MeasurementDefinition,
    MeasurementSample,
    MeasurementSampleContainer,
    ProducedAmountDetails,
    ProductionOrder,
    ToolReplace,
} from '../../../swagger-client';
import { MeasurementSampleModel } from '../../measurement-sample/model/measurement-sample.model';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { locationLabel } from '../../../pipes/measurement-sample-location/measurement-sample-location.pipe';
import { interval, Subscription } from 'rxjs';
import { AlertService } from '../../../services/alert/alert.service';
import StatusEnum = MeasurementSample.StatusEnum;
import LocationEnum = MeasurementSample.LocationEnum;
import DefinitionTypeEnum = MeasurementDefinition.DefinitionTypeEnum;
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';
import { ProducedAmountDataService } from '../../../services/produced-amount-data-service/produced-amount-data-service';

@Component({
    selector: 'betech-measurement-sample-card',
    templateUrl: './measurement-sample-card.component.html',
})
export class MeasurementSampleCardComponent implements OnInit, OnDestroy {
    public intervalValue: number = 1000 * 180;
    public lastMeasurementSample: MeasurementSampleModel;
    public currentProductionOrder: ProductionOrder;
    public StatusEnum = StatusEnum;
    public modalRef: BsModalRef;
    public locationChoices: Array<Object> = [];

    public LocationEnum = LocationEnum;
    public modalLocation: string;
    public employeeList: Array<Employee>;
    public containerList: Array<MeasurementSampleContainer>;
    public selectedEmployee: Employee;
    public selectedContainer: MeasurementSampleContainer;
    public lastToolReplace: ToolReplace;

    private subs: Subscription[] = [];

    private processedParts: number;

    constructor(
        private esbModelService: EsbmodelService,
        private producedAmountDataService: ProducedAmountDataService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private modalService: BsModalService,
        private router: Router,
        private alertService: AlertService,
    ) {
        Object.values(LocationEnum).forEach((location: string) =>
            this.locationChoices.push({ location: location, label: locationLabel(location) }),
        );
    }

    public ngOnInit() {
        this.subs.push(
            this._activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    if (!currentPO) {
                        return;
                    }

                    this.currentProductionOrder = currentPO;
                    this.updateToolAndMeasurementSample();
                },
            ),
            this.producedAmountDataService
                .observe()
                .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                    if (producedAmountDetails) {
                        this.processedParts = producedAmountDetails.processed;
                    }
                }),
            interval(this.intervalValue).subscribe(() => this.updateToolAndMeasurementSample()),
        );

        this.setSelectedEmployeeFromActive();
    }

    private updateToolAndMeasurementSample(): void {
        if (this.currentProductionOrder == undefined) {
            return;
        }

        this.esbModelService
            .esbmToolReplaceLastGet(this.currentProductionOrder.id)
            .subscribe(toolReplace => (this.lastToolReplace = toolReplace));

        this.esbModelService
            .esbmMeasurementSampleCurrentGet(this.currentProductionOrder.id)
            .subscribe((lastMeasurementSample: MeasurementSample) => {
                this.lastMeasurementSample = lastMeasurementSample
                    ? new MeasurementSampleModel(lastMeasurementSample)
                    : null;
            });
    }

    protected setSelectedEmployeeFromActive() {
        this.subs.push(
            this._activeEntityMediatorService.activeEmployee$.subscribe(
                (employee: Employee) => (this.selectedEmployee = employee),
            ),
        );
    }

    public ngOnDestroy() {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public get countdown(): string | number {
        if (!this.currentProductionOrder) {
            return '-';
        }

        if (!this.currentProductionOrder.measurement_count) {
            return '?';
        }

        if (this.lastMeasurementSample === undefined) {
            return '?';
        }

        if (this.lastToolReplace === undefined) {
            return '?';
        }

        if (this.processedParts === undefined) {
            return '?';
        }

        const lastCount: number =
            (this.lastMeasurementSample && this.lastMeasurementSample.sampled_count) || 0;
        return this.currentProductionOrder.measurement_count - (this.processedParts - lastCount);
    }

    public get shouldMeasure(): boolean {
        if (typeof this.countdown !== 'number') {
            return;
        }

        return (
            this.countdown <= 0 ||
            // when a tool-replace has been done more recently than the last measurement, we should measure now
            this.recentToolReplace
        );
    }

    public get recentToolReplace(): boolean {
        return (
            this.lastToolReplace &&
            (!this.lastMeasurementSample ||
                this.lastToolReplace.timestamp > this.lastMeasurementSample.measured_at)
        );
    }

    public chooseLocation(location: string): void {
        switch (location) {
            case LocationEnum.MACHINE:
                if (!this.employeeList) {
                    this.subs.push(
                        this.esbModelService
                            .esbmEmployeeGet()
                            .subscribe(data => (this.employeeList = data.data)),
                    );
                }
                this.modalLocation = location;
                break;
            case LocationEnum.MEASUREMENTROOM:
                if (!this.containerList) {
                    this.subs.push(
                        this.esbModelService
                            .esbmMeasurementSampleContainerGet()
                            .subscribe(data => (this.containerList = data)),
                    );
                }
                this.modalLocation = location;
                break;
        }
    }

    public takeSample($event: Event, modalTemplate: TemplateRef<any>): void {
        // prevent click from bubbling and taking you to measurement page
        $event.preventDefault();
        $event.stopPropagation();

        // reset modal data
        this.setSelectedEmployeeFromActive();
        this.selectedContainer = null;
        this.modalLocation = null;

        // open modal
        this.modalRef = this.modalService.show(modalTemplate);
    }

    protected createMeasurementSample(definition: MeasurementDefinition): MeasurementSample {
        return {
            production_order: this.currentProductionOrder,
            measurement_definition: definition,
            employee: this.selectedEmployee,
            measurement_sample_container: this.selectedContainer,
            sampled_at: new Date(),
            sampled_count: this.processedParts,
            measured_at: null,
            location:
                this.modalLocation === LocationEnum.MEASUREMENTROOM
                    ? LocationEnum.MEASUREMENTROOM
                    : LocationEnum.MACHINE,
            status: StatusEnum.INPROGRESS,
        };
    }

    public createMachineMeasurement(): void {
        this.modalRef.hide();
        this.router.navigateByUrl(
            '/measurement-sample/machine/' + this.selectedEmployee.id + '/create',
        );
    }

    public createMeasurementroomMeasurement(): void {
        this.subs.push(
            this.esbModelService
                .esbmMeasurementDefinitionGet(
                    null,
                    this.currentProductionOrder.id,
                    DefinitionTypeEnum.RUNNING1,
                )
                .subscribe(definitions => {
                    if (definitions.length > 0) {
                        const sample: MeasurementSample = this.createMeasurementSample(
                            definitions[0],
                        );

                        this.esbModelService.esbmMeasurementSamplePost(sample).subscribe(() => {
                            this.modalRef.hide();
                            this.router.navigateByUrl('/measurement-sample');
                        });
                    } else {
                        this.alertService.warning(
                            'Het product dat hoort bij deze order heeft geen meetdefinities voor de meetkamer en kan dus niet gemeten worden.',
                        );
                    }
                }),
        );
    }
}
