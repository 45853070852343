import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from './auth.routing';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { AuthComponent } from './auth.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    imports: [CommonModule, FormsModule, ReactiveFormsModule, AuthRoutingModule, SharedModule],
    declarations: [AuthComponent, LoginComponent, ForgotPasswordComponent, HeaderComponent],
    exports: [RouterModule],
})
export class AuthModule {}
