import { Injectable } from '@angular/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { OauthAccessToken } from '../../swagger-client';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TokenService {
    /**
     * Constructor
     */
    constructor(private _localStorage: LocalStorageService) {
        this._token = this._localStorage.getObject(this._localStorageKey) || null;
    }

    /**
     * The local storage key for an token.
     */
    private _localStorageKey = 'token';

    /**
     * The currently used token.
     */
    public _token: OauthAccessToken = null;

    /**
     * Retrieve the token
     */
    public getToken(): OauthAccessToken | null {
        return this._token;
    }

    /**
     * Clear this service.
     */
    public clear(): void {
        this._token = null;
        this._localStorage.remove(this._localStorageKey);
    }

    /**
     * Update the current token.
     */
    public setToken(token: OauthAccessToken): void {
        token.time_requested = Date.now();
        this._token = token;
        this._localStorage.setObject(this._localStorageKey, this._token);
    }
}
