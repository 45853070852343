import {AfterViewInit, Component, HostBinding, Input, OnInit} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SupportService } from '../../services/support/support.service';
import { SupportInterface } from '../../services/support/support.interface';
import { environment } from '../../../environments/environment';
import { TicketInterface } from '../../services/support/ticket.interface';
import * as moment from 'moment';
import html2canvas from 'html2canvas';

@Component({
    selector: 'betech-support',
    templateUrl: './support-modal.component.html',
})
export class SupportModalComponent implements OnInit, AfterViewInit {
    public modalRef;

    private readonly ticketPlaceholder = {
        fields: {
            summary: 'Geen tickets gevonden!',
        },
    };

    @HostBinding('class.support-modal')

    @Input()
    public setSupport: boolean = true;

    public supportForm: FormGroup;

    public formOpen: boolean = false;

    public formSent: boolean = false;

    public formSentSuccess: boolean = false;

    public formSentFailed: boolean = false;

    public defaultEmail: string;

    public url: string;

    @Input() public tickets: TicketInterface[] = [];

    constructor(private supportService: SupportService, private router: Router) {}


    public setModalRef(modalRef: any): void {
        this.modalRef = modalRef;
    }

    public ngOnInit(): void {
        this.supportForm = new FormGroup({});
        this.defaultEmail = environment.config.supportDefaultEmail;
        this.formOpen = true;
        this.url = this.router.url;
        this.fetchTickets();
    }

    public ngAfterViewInit(): void {
        this.supportForm.get('email').get('email').patchValue(this.defaultEmail);
        this.supportForm.get('url').get('url').patchValue(this.url);
    }

    public closeScreen(): void {
        this.modalRef.hide();
    }

    public submit(): void {
        this.formSent = true;

        setTimeout(() => {
            html2canvas(document.querySelector('.main'), {
                allowTaint: true,
                foreignObjectRendering: false,
                useCORS: true,
            })
                .then((canvas: any) => {
                    const imageData = canvas.toDataURL();

                    const data: SupportInterface = {
                        email:
                            environment.config.supportDefaultEmail ||
                            this.supportForm.get('email').get('email').value,
                        description: this.supportForm.get('description').get('description').value,
                        url: this.url,
                        screenshot: imageData,
                    };

                    this.supportService.submit(data).subscribe(
                        res => {
                            this.formSentSuccess = true;
                            console.log(res);
                        },
                        err => {
                            this.formSentFailed = true;
                            console.log(err);
                        },
                    );
                })
                .catch(error => {
                    console.error('oops, something went wrong!', error);
                    this.formSentFailed = true;
                });
        }, 1000);
    }

    public retry(): void {
        this.formSentFailed = false;
        this.submit();
    }

    public newTicket(): void {
        this.formSent = false;
        this.formSentSuccess = false;
        this.supportForm.get('email').get('email').patchValue(this.defaultEmail);
        this.supportForm.get('url').get('url').patchValue(this.url);
    }

    public fetchTickets(): void {
        this.tickets = [];
        this.supportService.getTickets().subscribe(
            res => {
                this.checkTicketResponse(res);
            },
            err => {
                console.log(err);
                this.setPlaceholder();
            },
        );
    }

    public checkTicketResponse(res): void {
        if (res.issues.length !== 0) {
            this.setTickets(res);
        } else {
            this.setPlaceholder();
        }
    }

    public setTickets(tickets): void {
        for (let i = 0; i < tickets.issues.length; i++) {
            this.tickets.push(this.ticketInterfaceFactory(tickets.issues[i]));
        }
    }

    public setPlaceholder(): void {
        this.tickets.push(this.ticketInterfaceFactory(this.ticketPlaceholder));
    }

    public ticketInterfaceFactory(issue): TicketInterface {
        return {
            code: issue?.key,
            summary: issue?.fields?.summary,
            status: issue?.fields?.status?.name,
            created: issue?.fields?.created
                ? moment(issue?.fields?.created).format('DD/MM/YYYY')
                : '',
            reporter: issue?.fields?.reporter?.displayName,
        };
    }
}
