import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Router } from '@angular/router';
import { SensorDataService } from '../services/sensor-data/sensor-data.service';
import { ActiveMachineService } from '../services/active-machine/active-machine.service';
import { ProducedAmountDataService } from '../services/produced-amount-data-service/produced-amount-data-service';
import { EmployeeService } from '../services/employee/employee.service';
import { RejectionCodeService } from '../services/rejection-code/rejection-code.service';
import { ChecklistService } from '../services/checklist/checklist.service';
import { ActiveEntityMediatorService } from '../services/active-entity-mediator/active-entity-mediator.service';
import { ProductionOrderService } from '../services/production-order/production-order.service';

@Component({
    selector: 'betech-main',
    templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, OnDestroy {
    constructor(
        private authService: AuthenticationService,
        private sensorDataService: SensorDataService,
        private activeMachineService: ActiveMachineService,
        private router: Router,
        private producedAmountDataService: ProducedAmountDataService,
        private employeeService: EmployeeService,
        private rejectionCodeService: RejectionCodeService,
        private checklistService: ChecklistService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private _productionOrderService: ProductionOrderService,
    ) {
        // when not logged in, don't even show this component
        if (!this.authService.isAuthenticated) {
            this.router.navigateByUrl('/auth/login');
        }
    }

    public ngOnInit(): void {
        this._activeEntityMediatorService.initializeSubscriptions();
        this.sensorDataService.initialize();
        this.producedAmountDataService.initialize();
        this.employeeService.initialize();
        this.rejectionCodeService.initialize();
        this.checklistService.initialize();
        this._productionOrderService.initialize();
    }

    public ngOnDestroy(): void {
        this.sensorDataService.unsubscribe();
        this.producedAmountDataService.unsubscribe();
    }

    @HostListener('document:visibilitychange', ['$event'])
    visibilitychange($event) {
        if (document.visibilityState === 'visible') {
            this._activeEntityMediatorService.updateProductionOrder();
        }
    }
}
