<betech-card *ngIf="isLoaded" [fullWidth]="true" routerLink="/order-performance">
  <i card-icon class="fal fa-3x fa-chart-line fa-fw"></i>
  <span card-title i18n>Performance</span>
  <span card-subtitle>
    <span i18n>Order</span>
  </span>
  <a
    card-button
    class="btn btn-secondary btn-block rounded text-uppercase"
    (click)="openModal($event)"
  >
    <span i18n>Updaten</span>
  </a>

  <ng-container card-info>
    <div class="d-flex justify-content-between">
      <div><span i18n>Cyclustijd</span>:</div>
      <div class="text-primary text-truncate text-right">
        {{ performanceTarget?.cycle_time || '-' }} sec
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Aantal</span>:</div>
      <div class="text-primary text-truncate text-right">{{ amount || '-' }} /hr</div>
    </div>
    <div class="d-flex justify-content-between" *ngIf="performanceTarget?.employee !== null">
      <div><span i18n>Theoretisch aantal per dag</span>:</div>
      <div class="text-primary text-truncate text-right">{{ amountDay || '-' }}</div>
    </div>
      <div class="d-flex justify-content-between" *ngIf="performanceTarget?.employee === null">
          <div><span i18n>Gemeten aantal per dag</span>:</div>
          <div class="text-primary text-truncate text-right">{{ trueAmountDay || '-' }}</div>
      </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Gecalculeerd aantal per dag</span>:</div>
      <div class="text-primary text-truncate text-right">{{ amountDay || '-' }}</div>
    </div>
  </ng-container>
  <ng-container card-footer>
    <span i18n>Last updated </span>
    <span class="text-primary">{{
      (performanceTarget?.timestamp | date: 'shortDate') || '-'
    }}</span>
    at:
    <span class="text-primary">{{
      (performanceTarget?.timestamp | date: 'shortTime') || '-'
    }}</span>
  </ng-container>
</betech-card>
