import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFilterComponent } from '../../filter-container/abstract.filter.component';
import { EsbmodelService, ProcessingStation } from '../../../swagger-client';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'betech-filter-processing-station',
    templateUrl: './processing-station.filter.component.html',
    providers: [
        { provide: AbstractFilterComponent, useExisting: ProcessingStationFilterComponent },
    ],
})
export class ProcessingStationFilterComponent extends AbstractFilterComponent {
    public processingStations$: Observable<any>;
    public departmentStations: Array<string> = ['1XX', '2XX', '3XX', '4XX', '5XX'];

    constructor(
        private esbmodelService: EsbmodelService,
    ) {
        super();
        this.processingStations$ = this.esbmodelService
            .esbmProcessingStationGet(null, environment.config.filterCnc ? true: null, 999)
            // ignore empty trumpfId and sort
            .pipe(tap((response) => {
                let selected = [];
                response.data.forEach((machine) => {
                    if (machine.trumpf_id) selected.push(machine);
                });

                this.departmentStations.forEach((department: string) => {
                    let ps = {
                        trumpf_id: department
                    } as ProcessingStation;
                    selected.push(ps);
                })

                selected.sort((ps1: ProcessingStation, ps2: ProcessingStation) => {
                    if(ps1.trumpf_id < ps2.trumpf_id) return -1;
                    if(ps1.trumpf_id > ps2.trumpf_id) return 1;
                    return 0;
                });

                response.data = selected;
            }));
    }

    @Input() public set data(item: ProcessingStation) {
        this._selectedItem = item;
    }

    private _selectedItem: ProcessingStation;
    public get selectedItem() {
        return this._selectedItem;
    }
    public set selectedItem(item: ProcessingStation) {
        this._selectedItem = item;
        this.filterChange.emit(item);
    }

    @Output() public filterChange = new EventEmitter();

    public clear() {
        this.selectedItem = null;
    }
}
