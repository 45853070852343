<div class="error-card container" *ngIf="!alertService.isOnline">
  <div class="container">
    <div class="row my-3">
      <div class="col-3">
        <i class="text-secondary fas fa-3x text-light-grey fa-wifi-slash"></i>
      </div>
      <div class="col-9">
        <span>We hebben momenteel geen verbinding met het internet. Even geduld a.u.b.</span><br />
      </div>
    </div>
  </div>
</div>
