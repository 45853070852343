import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    WorkLog,
} from '../../../swagger-client';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { tap } from 'rxjs/operators';
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-worklog-list-new',
    templateUrl: './worklog-list-new.component.html',
})
export class WorklogListNewComponent implements OnInit, OnDestroy {
    @Input() public employeeList: Employee[];
    @Input() public orderList: ProductionOrder[];
    @Input() public worklogList: WorkLog[];
    @Input() public activeMachine: ProcessingStation;
    @Input() public activeEmployee: Employee;

    public now: BehaviorSubject<Date> = new BehaviorSubject(new Date());

    public isSaving: boolean = false;
    private interval$;

    public worklogByCalculationNumber;

    constructor(
        private esbmodelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit() {
        this.worklogByCalculationNumber = environment.config.worklogByCalculationNumber;

        // keep track of a running 'now' (the time) for calculation purposes
        setTimeout(() => {
            this.now.next(new Date());
            this.interval$ = setInterval(() => this.now.next(new Date()), 60000); // 1 minute
        }, (60 - new Date().getSeconds()) * 1000);
    }

    public ngOnDestroy(): void {
        clearInterval(this.interval$);
    }

    public poLabelGenerator(productionOrder: ProductionOrder): string {
        if (environment.config.worklogByCalculationNumber) {
            return productionOrder.calculation_number + ' - ' + productionOrder.order_line_number;
        } else {
            return productionOrder.production_number;
        }
    }

    public search(productionOrder: ProductionOrder, searchInput: string) {
        const pattern = new RegExp(searchInput, 'i');

        if (environment.config.worklogByCalculationNumber) {
            return (
                productionOrder.calculation_number +
                ' - ' +
                productionOrder.order_line_number
            ).match(pattern);
        } else {
            return productionOrder.production_number.match(pattern);
        }
    }

    /**
     * Set the current date as start_datetime in the new worklog
     */
    public startStopWatch(): void {
        if (!this.activeEmployee) {
            // TODO error message?
            return;
        }

        if (this.isSaving) {
            return;
        }

        // TODO terrible naming in the backend and no need to send numbers if you ask me
        const worklog: WorkLog = {
            processing_station: { id: this.activeMachine.id },
            employee: this.activeEmployee,
            start_datetime: new Date(),
        };

        // actually save
        this.isSaving = true;
        this.esbmodelService
            .esbmWorklogPost(worklog)
            .pipe(tap(() => this._activeEntityMediatorService.reloadEmployee()))
            .subscribe((backendWorklog: WorkLog) => {
                this.worklogList.unshift(backendWorklog);
                this.isSaving = false;

                // reset form
                this.activeEmployee = null;
            });
    }
}
