import { RouterModule, Routes } from '@angular/router';
import { PerformanceComponent } from './performance.component';
import { MachinesPerformanceComponent } from './machines/machines-performance.component';
import { ArticlePerformanceComponent } from './article/article-performance.component';
import {EmployeesPerformanceComponent} from "./employees/employees-performance.component";

const adminRoute: Routes = [
    {
        path: '',
        component: PerformanceComponent,
        data: {
            title: 'Actieve orders',
        },
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'machines',
            },
            {
                path: 'machines',
                component: MachinesPerformanceComponent,
                data: {
                    title: 'Actieve orders machine overzicht',
                },
            },
            {
                path: 'employees',
                component: EmployeesPerformanceComponent,
                data: {
                    title: 'Actieve orders werknemers overzicht',
                },
            },
            {
                path: 'articles',
                component: ArticlePerformanceComponent,
                data: {
                    title: 'Artikel geschiedenis overzicht',
                },
            },
        ],
    },
];

export const PerformanceAdminRouting = RouterModule.forChild(adminRoute);
