<!-- Sidebar -->
<div *ngIf="isOpen" class="sidebar__backdrop"></div>
<div class="sidebar bg-primary" [ngClass]="{ 'sidebar--open': isOpen }">
  <div class="sidebar__inner" (clickOutside)="close()">
    <!-- Toggle -->
    <div class="sidebar__toggle">
      <div class="sidebar__icon-toggle" (click)="toggle()">
        <i class="" [ngClass]="isOpen ? 'fal fa-times' : 'fa fa-bars'"></i>
      </div>
      <div class="my-auto">
        <img class="sidebar__logo" [src]="logoLocation" />
      </div>
    </div>
    <!-- Dashboard -->
    <div class="sidebar__item">
      <a
        [routerLink]="'/admin/dash'"
        [routerLinkActive]="'sidebar__link--active'"
        class="sidebar__link"
        (click)="close()"
      >
        <i class="sidebar__icon fal fa-th fa-fw"></i>
        <span class="sidebar__item-title pt-0625 pl-4" [ngClass]="{ 'd-none': !isOpen }" i18n
          >Dashboard</span
        >
      </a>
    </div>
    <!-- Measurement-->
    <div class="sidebar__item">
      <a
        [routerLink]="'/admin/measurement-stats'"
        [routerLinkActive]="'sidebar__link--active'"
        class="sidebar__link"
        (click)="close()"
      >
        <i class="sidebar__icon fal fa-ruler-combined fa-fw"></i>
        <span class="sidebar__item-title pt-0625 pl-4" [ngClass]="{ 'd-none': !isOpen }" i18n
          >Meetgegevens</span
        >
      </a>
    </div>
    <!-- Tools-->
    <div class="sidebar__item">
      <a
        [routerLink]="'/admin/tool-stats'"
        [routerLinkActive]="'sidebar__link--active'"
        class="sidebar__link"
        (click)="close()"
      >
        <i class="sidebar__icon fal fa-toolbox fa-fw"></i>
        <span class="sidebar__item-title pt-0625 pl-4" [ngClass]="{ 'd-none': !isOpen }" i18n
          >Standtijden</span
        >
      </a>
    </div>
      <div class="sidebar__item">
          <a
              [routerLink]="'/admin/performance'"
              [routerLinkActive]="'sidebar__link--active'"
              class="sidebar__link"
              (click)="close()"
          >
              <i class="sidebar__icon fal fa-chart-line fa-fw"></i>
              <span class="sidebar__item-title pt-0625 pl-4" [ngClass]="{ 'd-none': !isOpen }" i18n
              >Performance</span
              >
          </a>
      </div>
    <!-- Material Stock Requests -->
    <div class="sidebar__item">
      <a
        [routerLink]="'/admin/material-stats'"
        [routerLinkActive]="'sidebar__link--active'"
        class="sidebar__link"
        (click)="close()"
      >
        <i class="sidebar__icon fal fa-ball-pile fa-fw"></i>
        <span class="sidebar__item-title pt-0625 pl-4" [ngClass]="{ 'd-none': !isOpen }" i18n
          >Materiaal</span
        >
      </a>
    </div>

    <!-- Logout -->
      <div class="btn-group dropup sidebar__item sidebar__item--bottom" dropdown [dropup]="true">
          <div class="sidebar__link">
              <i class="sidebar__icon fal fa-ellipsis-v fa-fw" dropdownToggle
                 aria-controls="dropdown-actions-current"></i>
          </div>
          <ul id="dropdown-actions-current" *dropdownMenu class="dropdown-menu"
              role="menu" aria-labelledby="button-basic"
          >
              <li role="menuitem">
                  <a class="dropdown-item hand"
                     data-html2canvas-ignore="true"
                     (click)="modalSupport()"
                  >
                      <img src="assets/icons/attributes-favicon.png" class="mr-1" style="width: 22%" />
                      <span i18n>Support</span>
                  </a>
              </li>
              <li role="menuitem">
                  <a class="dropdown-item hand"
                     (click)="logout()"
                     [routerLink]="'/auth/login'"
                     title="Log uit"
                     i18n-title
                  >
                      <i class="fas fa-sign-out ml-1 mr-2"></i>
                      <span i18n>Uitloggen</span>
                  </a>
              </li>
          </ul>
      </div>
  </div>
</div>
