import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { SupportService } from '../../services/support/support.service';

@Component({
    template: '',
})
export class SupportAbstractFormComponent implements OnInit {
    @Input()
    public type: string;

    @Input()
    public label: string;

    @Input()
    public parentForm: FormGroup;

    public childForm: FormGroup;

    @Input()
    public readonly: boolean = false;

    @Input()
    public required: boolean = false;

    @Input()
    public placeholder: string = '';

    public active: boolean = false;

    protected parentFormUpdates: Observable<any>;

    constructor(private supportService: SupportService) {}

    public ngOnInit(): void {
        this.childForm = new FormGroup({});
        this.parentFormUpdates = this.supportService.getFormSubscriber();

        this.parentFormUpdates.subscribe(value => {
            this.onUpdates(value);
        });

        this.childForm.addControl(
            this.type,
            new FormControl(this.getFormState(), this.getValidators()),
        );
        this.parentForm.addControl(this.type, this.childForm);
    }

    public focus(event: any): void {
        this.active = true;
    }

    public focusOut(event: any): void {
        if (event.currentTarget.value.length === 0) {
            this.active = false;
        }
    }

    protected getFormState(): any {
        return '';
    }

    protected getValidators(): any {
        return [];
    }

    protected onUpdates(update: any): any {
        if (update['reset']) {
            this.childForm.reset();
            this.ngOnInit();
        }
    }
}
