<div class="container-fluid">
    <div class="row mx-1 mt-3 py-2 bg-white shadow-sm rounded overflow-hidden" style="z-index: 200">
        <!--Details-->
        <div class="col-3 m-1 px-2">
            <div class="container">
                <div class="row">
                    <div class="col-2 bg-primary rounded items-center text-white p-0" style="aspect-ratio: 1 / 1;">
                        <div class="d-flex align-content-around flex-wrap h-100">
                            <div class="w-100 text-center font-medium leading-6">
                                <span class="align-middle">
                                    {{performance.processing_station?.trumpf_id || performance.processing_station?.name}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col px-2">
                            <div class="d-flex align-content-around flex-wrap h-100 ">
                                <div class="w-100">
                                    <span class="h-50 align-middle">
                                        <strong> {{ performance.active_production_order?.production_number }} </strong>
                                    </span>
                                </div>
                                <div class="w-100">
                                    <span class="h-50 align-middle">
                                        <strong> {{ performance.active_production_order.fabrication_part_articlenumber }} </strong>
                                        {{ performance.active_production_order?.fabrication_part_name }}
                                    </span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Gisteren-->
        <div class="col m-1 px-2 border-left border-light text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100">
                    <span class="h-50 align-middle">
                        <span class="text-warning">{{ yesterdayRejected }}</span>&nbsp;afkeur
                    </span>
                </div>
                <div class="w-100">
                    <span class="h-50 align-middle">
                        <span class="text-success">{{ yesterdayApproved }}</span>&nbsp;gereed
                    </span>
                </div>
            </div>
        </div>
        <!--Vandaag-->
        <div class="col m-1 px-2 border-left border-light text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100">
                    <span class="h-50 align-middle">
                        <span class="text-warning">{{ todayRejected }}</span>&nbsp;afkeur
                    </span>
                </div>
                <div class="w-100">
                    <span class="h-50 align-middle">
                        <span class="text-success">{{ todayApproved }}</span>&nbsp;gereed
                    </span>
                </div>
            </div>
        </div>
        <!--Totaal-->
        <div class="col-3 m-1 px-2 border-left border-l-gray-200 text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100">
                    <div class="d-flex flex-row align-content-around">
                        <betech-progress-bar [value]="declinedPercentage" color="warning" class="m-auto w-25"></betech-progress-bar>
                        <div class="h-full w-75 items-center text-right">
                            <span class="text-warning">{{ performance.reported_scrap_parts }}</span>
                            /&nbsp;{{ performance.reported_processed_parts }}&nbsp;&nbsp;afkeur
                            &nbsp;&nbsp;{{ declinedPercentage }}%
                        </div>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-flex flex-row align-content-around">
                        <betech-progress-bar [value]="readyPercentage" color="success" class="m-auto w-25"></betech-progress-bar>
                        <div class="h-full w-75 items-center text-right">
                            <span class="text-success">{{ performance.reported_approved_parts }}</span>
                            /&nbsp;{{ performance.required_parts }}&nbsp; gereed
                            &nbsp;&nbsp;{{ readyPercentage }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Status-->
        <div class="col-3 m-1 px-2 border-left border-light">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100 text-right">
                    <div class="d-flex align-content-around justify-content-end flex-wrap text-right h-100">
                        <div><i class="fa fa-sync mx-2"></i><span> {{remaining || 0 }} resterend</span></div>&nbsp;
                            <span *ngIf="performance.active_production_order.reported_status === 'STARTED'" class="text-success">Actief <i class="fa fa-sync"></i></span>
                            <span *ngIf="performance.active_production_order.reported_status === 'PAUSED'" class="text-warning">Pauze <i class="fa fa-hourglass-start"></i></span>
                            <span *ngIf="performance.active_production_order.reported_status === 'PREPARED'" class="text-success">Voorbereiden <i class="fa fa-ballot-check"></i></span>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-flex align-content-around justify-content-end flex-wrap text-right h-100">
                        <div *ngIf="performanceTarget?.employee !== null && amountDay"><i class="fa fa-check mx-2"></i><span>Theoretisch /dag: {{ amountDay || '-' }}</span></div>
                        <div *ngIf="trueAmountDay"><i class="fa fa-check mx-2"></i><span>Productie /dag: {{ trueAmountDay || '-' }}</span></div>
                        <div *ngIf="performanceTarget?.employee === null && amountDay"><i class="fa fa-check mx-2"></i><span>Gecalculeerd /dag: {{ amountDay || '-' }}</span></div>
                        <div><i class="fa fa-calendar mx-2"></i><span>Geupdate: {{
                            (performanceTarget?.timestamp | date: 'shortDate') || '-'
                        }}</span></div>
                    </div>
                </div>
            </div>
        </div>
        <!--button-->
            <div class="col p-0 my-n4">
                <a class="cursor-pointer" (click)="loadInfo()">
                    <div class="d-flex align-content-around align-items-center justify-content-center h-100" style="background-color: rgb(229 231 235)">
                        <i *ngIf="!showInfo" class="fa fa-chevron-right"></i>
                        <i *ngIf="showInfo" class="fa fa-chevron-down"></i>
                    </div>
                </a>
            </div>
    </div>
</div>

<div class="container-fluid mt-n2 overflow-hidden"
     [ngStyle]="{'max-height': showInfo ? '500px' : '0px' }"
     style="transition: max-height 0.5s ease-in-out;"
>
    <div class="row bg-white shadow-sm rounded mx-1 py-2" style="z-index: -1">
        <div class="col my-4 m-2 px-2">
            <div class="flex flex-row text-gray-400">
                Gestart om/door
            </div>
            <div class="flex flex-row m-3">
                {{ performance.active_production_order?.start_date | date: 'dd-MM-yyyy'  }}
            </div>
            <div class="flex flex-row m-3">
                {{ performance.active_production_order?.started_by }}
            </div>
        </div>
        <div class="col my-4 m-2 px-2">
            <div class="flex flex-row text-gray-400">
                Product
            </div>
            <div class="flex flex-row m-3">
                {{ performance.active_production_order?.fabrication_part_name }}
            </div>
            <button *ngIf="performance.active_production_order.drawing_image_urls"
                    class="btn btn-sm btn-outline-secondary"
                    (click)="showMediaModal($event)"
            >
                Tekening
                <i class="fas fa-drafting-compass"></i>
            </button>
        </div>
        <div class="col my-4 m-2 px-2">
            <div class="flex flex-row text-gray-400">
                Klant
            </div>
            <div class="flex flex-row m-3">
                {{ performance.active_production_order?.company }}
            </div>
        </div>
        <div class="col-3 my-4 m-2 px-2">
            <div class="flex flex-row text-gray-400">
                Instructies
            </div>
            <div class="flex flex-row m-3">
                <ng-container *ngIf="performance?.active_production_order?.instruction?.length; else noInstruction">
                    <ng-container *ngFor="let instruction of performance.active_production_order.instruction; let i = index;">
                        <p>{{ instruction }}</p>
                    </ng-container>
                </ng-container>
                <ng-template #noInstruction>
                    Geen instructie
                </ng-template>
            </div>
        </div>
    </div>
</div>


<ng-template #loadingOrderInfo>
    <div class="flex flex-col px-2">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>


<ng-template #loadingProductionOrder>
    <div class="basis-9/12 m-1 px-2 border-left border-light text-gray-400">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>

<ng-template #noProductionOrderDetails>
    <div  class="w-full m-4 px-2 text-gray-400">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>
