<ng-container dropdown #dropdown="bs-dropdown" (isOpenChange)="focusSearch($event)">
  <div dropdownToggle class="select-dropdown__toggle">{{ getLabel() }}</div>
  <ul *dropdownMenu class="dropdown-menu select-dropdown__menu" [ngStyle]="menuStyle">
    <li *ngIf="search || customSearch">
      <input
        #dropdownSearch
        class="select-dropdown__search"
        placeholder="Start met zoeken.."
        i18n-placeholder
        [(ngModel)]="searchInput"
        (keyup)="searchItems()"
        (keyup.enter)="click(searchedItems); dropdown.hide()"
        (click)="$event.preventDefault(); $event.stopPropagation()"
      />
    </li>
    <li *ngIf="!required && model">
      <a class="select-dropdown__item" (click)="click(null)">{{ placeholder || '..selecteer' }}</a>
    </li>
    <ng-container *ngFor="let item of searchedItems || items">
      <li>
        <a class="select-dropdown__item" (click)="click(item)">
          <ng-container *ngIf="!!template">
            <ng-container *ngTemplateOutlet="template; context: { item: item }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!template">{{ getLabel(item) }}</ng-container>
        </a>
      </li>
    </ng-container>
  </ul>
</ng-container>
