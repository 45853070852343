import { Component, OnDestroy, OnInit } from '@angular/core';
import { EsbmodelService, ProcessingStationStats } from '../../swagger-client';
import { ProductionOrderStatsModel } from '../model/production-order-stats.model';
import PriorityEnum = ProductionOrderStatsModel.PriorityEnum;
import { InMemoryFilter } from '../filter/filter';

@Component({
    selector: 'betech-measurement-stats',
    templateUrl: './material-stats.component.html',
})
export class MaterialStatsComponent implements OnInit, OnDestroy {
    private readonly interval = 1000 * 60; // every minute
    private interval$;

    public stats: Array<ProductionOrderStatsModel>;
    public filter: InMemoryFilter = new InMemoryFilter();

    constructor(private esbModelService: EsbmodelService) {}

    public ngOnInit() {
        this.load();
        this.interval$ = setInterval(() => this.load(), this.interval);
    }

    public ngOnDestroy() {
        clearInterval(this.interval$);
    }

    protected load(): void {
        this.esbModelService
            .esbmProductionOrderStatsGet()
            .subscribe((data: Array<ProcessingStationStats>) => {
                this.stats = [];
                data.forEach(stat => this.stats.push(new ProductionOrderStatsModel(stat)));

                // sort by next action time
                this.stats = this.stats.sort(
                    (a: ProductionOrderStatsModel, b: ProductionOrderStatsModel) => {
                        return a.getStockTimeUntilNextAction() - b.getStockTimeUntilNextAction();
                    },
                );
                this.filter.init(this.stats);
            });
    }

    public isHighlight(stat: ProductionOrderStatsModel) {
        return stat.getUrgency('material_action') > PriorityEnum.PRIORITY_NORMAL;
    }
}
