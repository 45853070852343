<betech-card *ngIf="isLoaded" clickUrl="/progress">
  <i card-icon class="fal fa-3x fa-hourglass-half fa-fw"></i>
  <span card-title i18n>Order voortgang</span>
  <span card-subtitle><span i18n>Status:</span> {{ status || '' }}</span>
  <a
    card-button
    class="btn btn-secondary btn-block rounded text-uppercase"
    href="#"
    (click)="openUpdateScreen($event)"
  >
    <span i18n>Bijwerken</span>
  </a>
  <ng-container card-info>
    <div class="d-flex justify-content-between">
      <div><span i18n>Te produceren</span>:</div>
      <div class="text-primary text-right">{{ quantity }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Geproduceerd</span>:</div>
      <div class="text-primary text-right">{{ produced }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Goedgekeurd</span>:</div>
      <div class="text-primary text-right">{{ correct }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Afkeur</span>:</div>
      <div class="text-primary text-right">{{ incorrect }}</div>
    </div>
    <div class="d-flex justify-content-between">
      <div><span i18n>Leverdatum</span>:</div>
      <div class="text-primary text-right">{{ dueDate | date: 'dd-MM-y' || '-' }}</div>
    </div>
  </ng-container>
  <betech-card-notification type="success" card-notifications>
    <div class="font-weight-bold font-size-lg">{{ percentage + '%' || '-' }}</div>
    <div class="text"><span i18n>Afgerond</span></div>
  </betech-card-notification>
  <ng-container card-footer>
    <betech-progress-bar [value]="percentage" class="progress-dashboard"></betech-progress-bar>
  </ng-container>
</betech-card>
