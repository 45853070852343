import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { MainRoutingModule } from './main.routing';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderCardComponent } from './dashboard/order-card/order-card.component';
import { WorklogCardComponent } from './dashboard/worklog-card/worklog-card.component';
import { NotificationsCardComponent } from './dashboard/notifications-card/notifications-card.component';
import { ToolLifetimeCardComponent } from './dashboard/tool-lifetime-card/tool-lifetime-card.component';
import { SharedModule } from '../shared/shared.module';
import { OrderListComponent } from './order-list/order-list.component';
import { WorklogListComponent } from './worklog-list/worklog-list.component';
import { WorklogListItemComponent } from './worklog-list/worklog-list-item/worklog-list-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToolListComponent } from './tool-list/tool-list.component';
import { ToolListItemComponent } from './tool-list/tool-list-item/tool-list-item.component';
import { ForecastPipe } from '../pipes/forecast/forecast.pipe';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { SidebarComponent } from './sidebar/sidebar.component';
import { WorklogListNewComponent } from './worklog-list/worklog-list-new/worklog-list-new.component';
import { HeaderComponent } from './header/header.component';
import { SupportModule } from './support/support.module';
import { ProgressCardComponent } from './dashboard/progress-card/progress-card.component';
import { UpdateProgressModalComponent } from './dashboard/progress-card/update-progress-modal/update-progress-modal.component';
import { InstructionsBarComponent } from './dashboard/instructions-bar/instructions-bar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { StartPrepareOrderModalComponent } from './order-list/start-prepare-order-modal/start-prepare-order-modal.component';
import { ToolTakeoverModalComponent } from './tool-list/tool-takeover-modal/tool-takeover-modal.component';
import { ToolTakeoverItemComponent } from './tool-list/tool-takeover-modal/tool-takeover-item/tool-takeover-item.component';
import { ProgressComponent } from './progress/progress/progress.component';
import { FromNowPipe } from 'app/pipes/from-now/from-now.pipe';
import { ProgressReportModalComponent } from './progress/progress-report-modal/progress-report-modal.component';
import { EmployeePipe } from '../pipes/employee/employee.pipe';
import { RejectCodePipe } from '../pipes/reject-code/reject-code.pipe';
import { ChecklistItemComponent } from './checklist/checklist-item/checklist-item.component';
import { ChecklistComponent } from './checklist/checklist/checklist.component';
import { ChecklistCardComponent } from './dashboard/checklist-card/checklist-card.component';
import { CalculationProgressComponent } from './calculation-progress/calculation-progress.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { ProductionOrderComponent } from './calculation-progress/production-order/production-order.component';
import { OrderPerformanceComponent } from './order-performance/order-performance.component';
import { PerformanceItemComponent } from './order-performance/performance-item/performance-item.component';
import { RemarkModalComponent } from './order-performance/remark-modal/remark-modal.component';
import { PerformanceCardComponent } from './dashboard/performance-card/performance-card.component';
import { PerformanceModalComponent } from './dashboard/performance-card/performance-modal/performance-modal.component';
import { ToolHistoryComponent } from './tool-history/tool-history.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MeasurementSampleCardComponent } from './dashboard/measurement-sample-card/measurement-sample-card.component';
import { UpdateNotificationComponent } from './update-notification/update-notification.component';
import { FailureCodePipe } from '../pipes/failure-code/failure-code.pipe';
import { CauseCodePipe } from '../pipes/cause-code/cause-code.pipe';
import { StockRequestCardComponent } from './dashboard/stock-request-card/stock-request-card.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoOrderActiveCardComponent } from './dashboard/no-order-active-card/no-order-active-card.component';
import { NoEmployeeCardComponent } from './dashboard/no-employee-card/no-employee-card.component';
import {NoEmployeeModalComponent} from "./dashboard/no-employee-card/no-employee-modal/no-employee-modal.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
        MainRoutingModule,
        BsDropdownModule,
        TimepickerModule.forRoot(),
        FormsModule,
        ProgressbarModule,
        NgClickOutsideModule,
        SupportModule,
        ReactiveFormsModule,
        CollapseModule,
        TypeaheadModule,
        NgxDatatableModule,
    ],
    declarations: [
        MainComponent,
        DashboardComponent,
        OrderCardComponent,
        InstructionsBarComponent,
        WorklogCardComponent,
        NotificationsCardComponent,
        ProgressCardComponent,
        UpdateProgressModalComponent,
        ToolLifetimeCardComponent,
        MeasurementSampleCardComponent,
        OrderListComponent,
        WorklogListComponent,
        WorklogListItemComponent,
        WorklogListNewComponent,
        ToolListComponent,
        ToolListItemComponent,
        SidebarComponent,
        HeaderComponent,
        StartPrepareOrderModalComponent,
        ToolTakeoverModalComponent,
        ToolTakeoverItemComponent,
        ProgressComponent,
        ProgressReportModalComponent,
        FromNowPipe,
        EmployeePipe,
        RejectCodePipe,
        FailureCodePipe,
        CauseCodePipe,
        ChecklistItemComponent,
        ChecklistComponent,
        ChecklistCardComponent,
        CalculationProgressComponent,
        ProductionOrderComponent,
        PerformanceCardComponent,
        PerformanceModalComponent,
        OrderPerformanceComponent,
        StockRequestCardComponent,
        PerformanceItemComponent,
        RemarkModalComponent,
        ToolHistoryComponent,
        UpdateNotificationComponent,
        NoOrderActiveCardComponent,
        NoEmployeeCardComponent,
        NoEmployeeModalComponent,
    ],
    exports: [RouterModule, ForecastPipe],
    entryComponents: [
        UpdateProgressModalComponent,
        ProgressReportModalComponent,
        StartPrepareOrderModalComponent,
        ToolTakeoverModalComponent,
    ],
})
export class MainModule {}
