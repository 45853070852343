import { Component } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { NoEmployeeModalComponent } from './no-employee-modal/no-employee-modal.component';

@Component({
    selector: 'betech-no-employee-card',
    templateUrl: './no-employee-card.component.html',
})
export class NoEmployeeCardComponent {
    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    public openModal($event): void {
        $event.preventDefault();
        $event.stopPropagation();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-no-employee';

        this.modalRef = this.modalService.show(NoEmployeeModalComponent, modalOptions);
        this.modalRef.content.setModalRef(this.modalRef);
    }
}
