import {Component, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {
    Certificate,
    Employee,
    EsbmodelService, ProcessingStation,
    ProducedAmountDetails,
    ProductionOrder,
    StockAllocation,
    StockRequest,
} from '../../../swagger-client';
import {StockRequestModel} from '../../stock-request/model/stock-request.model';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject, Subscription} from 'rxjs';
import {ProducedAmountDataService} from '../../../services/produced-amount-data-service/produced-amount-data-service';
import * as moment from 'moment';
import StatusEnum = StockRequest.StatusEnum;
import {environment} from '../../../../environments/environment';
import ReportedStatusEnum = ProductionOrder.ReportedStatusEnum;
import {debounceTime, take} from 'rxjs/operators';
import {ActiveEntityMediatorService} from '../../../services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-stock-request-card',
    templateUrl: './stock-request-card.component.html',
})
export class StockRequestCardComponent implements OnInit, OnDestroy {
    public lastStockRequest: StockRequestModel;
    public selectedStockRequest: StockRequestModel; // if the current production order has more request, this is the selected stock request

    public currentProductionOrder: ProductionOrder;
    public StatusEnum = StatusEnum;
    public modalRef: BsModalRef;
    public producedAmountDetails: ProducedAmountDetails;
    public loadingAllocation: boolean = false;
    public loadingRequests: boolean = false;

    public certificateList: Array<Certificate> = [];
    public location: string;

    public newStockRequest: StockRequest;
    private activeMachine: ProcessingStation;

    private subs: Subscription[] = [];

    public employeeList: Employee[];
    public orderList: ProductionOrder[];
    public recoveryOrderList: ProductionOrder[];

    private searchDebouncher: Subject<string> = new Subject<string>();

    public stockAllocations: StockAllocation[] = [];
    public allStockRequests: StockRequestModel[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private modalService: BsModalService,
        private producedAmountDataService: ProducedAmountDataService,
    ) {
    }

    public ngOnInit() {
        this.subs.push(
            this.producedAmountDataService
                .observe()
                .subscribe(
                    (producedAmountDetails: ProducedAmountDetails) =>
                        (this.producedAmountDetails = producedAmountDetails),
                ),
        );

        this.subs.push(
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (processingStation: ProcessingStation) => {
                    if (!processingStation) {
                        return;
                    }

                    this.activeMachine = processingStation;
                },
            ),
            this.activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    if (!currentPO) {
                        return;
                    }

                    this.currentProductionOrder = currentPO;

                    this.loadLastStockRequest(currentPO.id);
                },
            ),
            this.searchDebouncher
                .pipe(debounceTime(500))
                .subscribe(value => this.filterOrdersDebounced(value)),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    protected loadLastStockRequest(productionOrderId: string): Promise<void> {
        return new Promise<void>(resolve => {
            this.esbModelService
                .esbmStockRequestCurrentGet(productionOrderId)
                .subscribe((lastStockRequest: StockRequest) => {
                    if (lastStockRequest) {
                        this.lastStockRequest = new StockRequestModel(lastStockRequest);
                    } else {
                        this.lastStockRequest = null;
                    }

                    resolve();
                });
        });
    }

    public stockRequestLabel(stockAllocation: StockAllocation): string {
        const material = stockAllocation.material_article
            ? stockAllocation.material_article.material_summary
            : 'unknown';

        return material + '/' + stockAllocation.quantity + 'm';
    }

    public measure($event: Event, modalTemplate: TemplateRef<any>): void {
        // prevent click from bubbling and taking you to measurement page
        $event.preventDefault();
        $event.stopPropagation();

        // set form data
        this.newStockRequest = this.createStockRequest('MEASURED');
        this.newStockRequest.stock_level = null; // let user decide

        this.esbModelService.esbmEmployeeGet().subscribe(data => (this.employeeList = data.data));

        this.activeEntityMediatorService
            .activeEmployee$
            .pipe(take(1))
            .subscribe(employee => (this.newStockRequest.employee = employee));

        // open modal
        this.modalRef = this.modalService.show(modalTemplate);
    }

    public order($event: Event, modalTemplate: TemplateRef<any>): void {
        // prevent click from bubbling and taking you to measurement page
        $event.preventDefault();
        $event.stopPropagation();

        // set form data
        this.newStockRequest = this.createStockRequest('ORDERED');

        // default delivery at tomorrow 7:30 am
        this.newStockRequest.delivery_at = new Date();
        this.newStockRequest.delivery_at.setDate(this.newStockRequest.delivery_at.getDate() + 1);
        this.newStockRequest.delivery_at.setHours(7);
        this.newStockRequest.delivery_at.setMinutes(30);

        this.esbModelService.esbmEmployeeGet().subscribe(data => (this.employeeList = data.data));
        this.esbModelService
            .esbmProductionOrderGet(
                20,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                ReportedStatusEnum.NOTSTARTED,
                environment.config.filterCnc ? true : null,
            )
            .subscribe(data => this.setOrderList(data.data));


        this.activeEntityMediatorService
            .activeEmployee$
            .pipe(take(1))
            .subscribe(employee => (this.newStockRequest.employee = employee));


        // open modal
        this.modalRef = this.modalService.show(modalTemplate);
    }

    public received($event: Event, modalTemplate: TemplateRef<any>): void {
        // prevent click from bubbling and taking you to measurement page
        $event.preventDefault();
        $event.stopPropagation();

        this.loadingAllocation = true;
        this.allStockRequests = [];

        this.loadStockRequests();

        // open modal
        this.modalRef = this.modalService.show(modalTemplate);
    }

    // this only works for the active order !!!!
    protected loadStockRequests(): void {
        this.loadingRequests = true;

        this.esbModelService
            .esbmStockRequestGet(this.currentProductionOrder.id)
            .subscribe((data: StockRequest[]) => {
                if (data) {
                    for (const stockRequest of data) {
                        if (stockRequest.status === this.StatusEnum.ORDERED) {
                            this.allStockRequests.push(new StockRequestModel(stockRequest));
                        }
                    }

                    this.selectedStockRequest = this.allStockRequests[0];

                    this.esbModelService
                        .esbmStockRequestIdAllocatedGet(this.lastStockRequest.id)
                        .subscribe((stockAllocations: Array<StockAllocation>) => {
                            this.stockAllocations = stockAllocations;
                            if (this.stockAllocations.length > 0) {
                                this.selectedStockRequest.stock_allocation =
                                    this.stockAllocations[0];
                            }

                            if (
                                null == this.selectedStockRequest ||
                                null == this.selectedStockRequest.stock_allocation
                            ) {
                                this.loadingAllocation = false;
                                return;
                            }

                            // these are reported value
                            // this.lastStockRequest.charge_number = this.lastStockRequest.stock_allocation.article_stock_location?.certificate?.charge_number;
                            // this.lastStockRequest.quantity = this.lastStockRequest.stock_allocation.quantity;

                            this.loadingAllocation = false;
                        });
                } else {
                    this.allStockRequests = null;
                }

                this.loadingRequests = false;
            });
    }

    protected createStockRequest(status: StatusEnum): StockRequest {
        const processedParts: number = this.producedAmountDetails
            ? this.producedAmountDetails.processed
            : 0;
        return {
            production_order: this.currentProductionOrder,
            processing_station: this.activeMachine,
            processed_parts:
                status === 'ORDERED'
                    ? this.lastStockRequest
                        ? this.lastStockRequest.processed_parts || 0
                        : 0
                    : processedParts,
            sequence_number: this.lastStockRequest.sequence_number + 1,
            quantity: null,
            stock_level: this.lastStockRequest.stock_level,
            delivery_at: null,
            location: null,
            charge_number: null,
            status: status,
        };
    }

    public post(): void {
        // a measurement is only for the current production order
        if (this.newStockRequest.status === 'MEASURED') {
            this.newStockRequest.quantity =
                this.newStockRequest.stock_level -
                this.lastStockRequest.calculateMachine(this.producedAmountDetails);
            this.newStockRequest.stock_level =
                this.lastStockRequest.stock_level + this.newStockRequest.quantity;
        }

        // a new request can be for any order
        this.esbModelService
            .esbmStockRequestPost(this.newStockRequest)
            .subscribe((backendRequest: StockRequest) => {
                // switch back to current order
                this.loadLastStockRequest(this.currentProductionOrder.id);

                if (this.modalRef) {
                    this.modalRef.hide();
                }
            });
    }

    public put(status: StatusEnum): void {
        this.selectedStockRequest.status = status;
        this.selectedStockRequest.quantity = this.selectedStockRequest.stock_allocation.quantity;
        this.selectedStockRequest.charge_number =
            this.selectedStockRequest.stock_allocation.article_stock_location?.certificate?.charge_number;
        this.selectedStockRequest.stock_level += this.selectedStockRequest.quantity;
        this.selectedStockRequest.processed_parts = this.producedAmountDetails.processed;

        this.esbModelService
            .esbmStockRequestIdPut(this.selectedStockRequest.id, this.selectedStockRequest)
            .subscribe((backendRequest: StockRequest) => {
                // switch back to current order
                this.loadLastStockRequest(this.currentProductionOrder.id);

                this.modalRef.hide();
            });
    }

    public getCertificateLabel(item: StockRequest): string {
        if (item && item.stock_allocation) {
            if (item.stock_allocation.article_stock_location) {
                const certificate = item.stock_allocation.article_stock_location.certificate;
                if (certificate) {
                    return certificate.charge_number + ' / ' + certificate.pdc_id;
                }
                return 'geen certicaat';
            }
            return 'geen article stock location';
        }
        return 'onbekend';
    }

    public setSelectedStockRequest(stockRequest): void {
        if (typeof stockRequest !== 'object') {
            return;
        }

        this.selectedStockRequest = stockRequest;
    }

    public getStockRequestLabel(item: StockRequest): string {
        if (item.material_article === null) {
            return 'onbekend materiaal';
        }
        return item.material_article.name + ' / ' + moment(item._created_at).format('DD-MM-YYYY');
    }

    public orderSelected(productionOrder: ProductionOrder): void {
        this.loadLastStockRequest(productionOrder.id).then(() => {
            this.newStockRequest.production_order = productionOrder;
            this.newStockRequest.sequence_number = this.lastStockRequest.sequence_number + 1;
        });
    }

    public filterOrders(search: string): void {
        // dispatch an internal event so we can debounce it.
        this.searchDebouncher.next(search);
    }

    private filterOrdersDebounced(search: string): void {
        if (search === null || search.length < 3) {
            this.orderList = this.recoveryOrderList;

            return;
        }

        this.esbModelService
            .esbmProductionOrderGet(
                20,
                null,
                null,
                search,
                null,
                null,
                null,
                null,
                null,
                null,
                ReportedStatusEnum.NOTSTARTED,
                environment.config.filterCnc ? true : null,
                null,
            )
            .subscribe(data => {
                this.orderList = data.data;
            });
    }

    private setOrderList(orderList): void {
        this.orderList = orderList;
        this.recoveryOrderList = orderList;
    }
}
