import { Injectable, OnDestroy } from '@angular/core';
import { ActiveMachineService } from '../active-machine/active-machine.service';
import { ActiveEmployeeService } from '../active-employee/active-employee.service';
import { ActiveProductionOrdersService } from '../active-production-orders/active-production-orders.service';
import { forkJoin, interval, Observable, of, Subject } from 'rxjs';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
} from '../../swagger-client';
import { LocalStorageService } from '../local-storage/local-storage.service';
import ReportedStatusEnum = ProductionOrder.ReportedStatusEnum;
import { takeUntil } from 'rxjs/operators';

export enum DashboardLoginType {
    Employee = 'EMPLOYEE',
    Machine = 'MACHINE',
}

export class DashboardConstants {
    public dashboardLoginType: DashboardLoginType;
    public machineNumber: string;
    public employeeId: string;
}

@Injectable({
    providedIn: 'root',
})
export class ActiveEntityMediatorService implements OnDestroy {
    private readonly loginTypeLocalStorageId = 'dashboardLoginType';
    private readonly employeeIdLocalStorageId = 'constantEmployeeId';
    private readonly machineNumberLocalStorageId = 'constantMachineId';

    private readonly productionOrderFetchInterval = 1000 * 55;

    private _dashboardConstants: DashboardConstants;

    private _machine: ProcessingStation;
    private _employee: Employee;
    private _productionOrder: ProductionOrder;

    private unsubscribeSubject: Subject<any> = new Subject<any>();

    constructor(
        private _esbmodelService: EsbmodelService,
        private _localStorage: LocalStorageService,
        private _activeMachineService: ActiveMachineService,
        private _activeEmployeeService: ActiveEmployeeService,
        private _activeProductionOrderService: ActiveProductionOrdersService,
    ) {}

    public isInitialized(): boolean {
        let constants = this.dashboardConstants;

        if (
            constants.dashboardLoginType === DashboardLoginType.Machine &&
            constants.machineNumber != null
        ) {
            return true;
        }

        if (constants.dashboardLoginType === DashboardLoginType.Employee) {
            return true;
        }

        return false;
    }

    public reloadProductionOrder(): void {
        this.updateProductionOrder();
    }

    public reloadEmployee(): void {
        this.updateEmployee();
    }

    public initializeSubscriptions(): void {
        this._activeMachineService.activeMachine$
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((activeMachine: ProcessingStation) => {
                this._machine = activeMachine;
                this.updateEmployee();
                this.updateProductionOrder();
            });

        this._activeEmployeeService.activeEmployee$
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((activeEmployee: Employee) => {
                this._employee = activeEmployee;
                this.updateProductionOrder();
            });

        this._activeProductionOrderService.activeProductionOrder$
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((activeProductionOrder: ProductionOrder) => {
                this._productionOrder = activeProductionOrder;
                this.updateMachine();
            });

        interval(this.productionOrderFetchInterval)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(() => {
                this.updateProductionOrder();
            });
    }

    public initialize(): Observable<any> {
        return forkJoin({
            ActiveMachine: this._activeMachineService.initialize(
                this.dashboardConstants.machineNumber,
            ),
            ActiveEmployee: this._activeEmployeeService.initialize(
                this.dashboardConstants.employeeId,
            ),
        });
    }

    public setLoginDashboardConstants(constants: DashboardConstants): void {
        this._dashboardConstants = constants;

        this._localStorage.set(this.loginTypeLocalStorageId, constants.dashboardLoginType);

        if (!!constants.employeeId) {
            this._localStorage.set(this.employeeIdLocalStorageId, constants.employeeId);
        }

        if (!!constants.machineNumber) {
            this._localStorage.set(this.machineNumberLocalStorageId, constants.machineNumber);
        }
    }

    get dashboardConstants(): DashboardConstants {
        if (this._dashboardConstants == null) {
            this._dashboardConstants = this.generateDashboardConstants();
        }

        return this._dashboardConstants;
    }

    get activeProductionOrder$(): Observable<ProcessingStation> {
        return this._activeProductionOrderService.activeProductionOrder$;
    }

    get activeEmployee$(): Observable<Employee> {
        return this._activeEmployeeService.activeEmployee$;
    }

    set activeEmployee(employee: Employee) {
        this._activeEmployeeService.activeEmployee = employee;
    }

    get activeMachine$(): Observable<ProcessingStation> {
        return this._activeMachineService.activeMachine$;
    }

    set activeMachine(processingStation: ProcessingStation) {
        this._activeMachineService.activeMachine = processingStation;
    }

    public ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    public clear(): void {
        this._localStorage.remove(this.loginTypeLocalStorageId);
        this._localStorage.remove(this.employeeIdLocalStorageId);
        this._localStorage.remove(this.machineNumberLocalStorageId);

        this._activeEmployeeService.clear();
        this._activeMachineService.clear();
        this._activeProductionOrderService.clear();
    }

    private generateDashboardConstants(): DashboardConstants {
        let constants = new DashboardConstants();

        constants.machineNumber = this._localStorage.get(this.machineNumberLocalStorageId);
        constants.employeeId = this._localStorage.get(this.employeeIdLocalStorageId);

        let loginType = this._localStorage.get(this.loginTypeLocalStorageId);

        if (loginType == DashboardLoginType.Machine) {
            constants.dashboardLoginType = DashboardLoginType.Machine;
        } else if (loginType == DashboardLoginType.Employee) {
            constants.dashboardLoginType = DashboardLoginType.Employee;
        } else {
            this.clear();
            constants.dashboardLoginType = null;
        }

        return constants;
    }

    public updateProductionOrder(): void {
        let constants = this.dashboardConstants;

        if (constants.dashboardLoginType === DashboardLoginType.Machine) {
            this._activeProductionOrderService.fetch(this._machine, undefined);
        } else {
            this._activeProductionOrderService.fetch(undefined, this._employee);
        }
    }

    private updateMachine(): void {
        if (
            this._productionOrder?.reported_status === ReportedStatusEnum.STARTED ||
            this._productionOrder?.reported_status === ReportedStatusEnum.PREPARED ||
            this._productionOrder?.reported_status === ReportedStatusEnum.RESUMED
        ) {
            this._activeMachineService.activeMachine =
                this._productionOrder.reported_processing_station ??
                this._productionOrder.processing_station;
        } else {
            this._activeMachineService.activeMachine = null;
        }
    }

    private updateEmployee(): void {
        if (this._machine == null) {
            return;
        }

        this._esbmodelService
            .esbmEmployeeProcessingStationActiveGet(this._machine.id)
            .subscribe((employee: Employee) => {
                this._activeEmployeeService.activeEmployee = employee;
            });
    }
}
