import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProducedAmountDetails, ProductionOrder } from '../../../swagger-client';
import { Subscription } from 'rxjs';
import { ProducedAmountDataService } from '../../../services/produced-amount-data-service/produced-amount-data-service';

@Component({
    selector: 'betech-production-order',
    templateUrl: './production-order.component.html',
})
export class ProductionOrderComponent implements OnInit, OnDestroy {
    @Input() public productionOrder: ProductionOrder;
    public totals: ProducedAmountDetails;
    public toggle: boolean = false;

    public invalidPercentage: number = 0;
    public invalidOfQuantityPercentage: number = 0;
    public progressPercentage: number = 0;
    public processingTime: number = 0;

    private subs: Subscription[] = [];

    constructor(private _producedAmountDataService: ProducedAmountDataService) {}

    public ngOnInit(): void {
        this.subs.push(
            this._producedAmountDataService
                .observe()
                .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                    this.onUpdate(producedAmountDetails);
                }),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public onUpdate(producedAmountDetails: ProducedAmountDetails): void {
        if (producedAmountDetails == null) {
            this.totals = {
                scrap: 0,
                missing: 0,
                processed: 0,
                approved: 0,
            };
        } else {
            this.totals = producedAmountDetails;
        }

        this.updateTotals();
    }

    private updateTotals(): void {
        this.invalidPercentage = this.getInvalidPercentage();
        this.invalidOfQuantityPercentage = this.getInvalidOfQuantityPercentage();
        this.progressPercentage = this.getProgressPercentage();
        this.processingTime = this.getProcessingTime();
    }

    private getInvalidPercentage(): number {
        if (
            this.totals === undefined ||
            (this.totals.processed === 0 && this.totals.scrap === 0 && this.totals.missing === 0)
        ) {
            return 0;
        }

        const percentage =
            ((this.totals.scrap + this.totals.missing) / this.totals.processed) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    private getInvalidOfQuantityPercentage(): number {
        if (
            this.totals === undefined ||
            (this.productionOrder.quantity === 0 && this.totals.scrap === 0)
        ) {
            return 0;
        }

        const percentage =
            ((this.totals.missing + this.totals.scrap) / this.productionOrder.quantity) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    private getProgressPercentage(): number {
        if (
            this.totals === undefined ||
            (this.totals.approved === 0 && this.productionOrder.quantity === 0)
        ) {
            return 0;
        }

        const percentage = (this.totals.approved / this.productionOrder.quantity) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    private getProcessingTime(): number {
        const time = (this.productionOrder.quantity * this.productionOrder.planned_cycle_time) / 60;
        return parseFloat(time.toFixed(0));
    }
}
