import {Component, OnDestroy, OnInit} from '@angular/core';
import {EsbmodelService, ProductionOrder} from '../../swagger-client';
import {Subscription} from "rxjs";
import {ActiveEntityMediatorService} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-calculation-progress',
    templateUrl: './calculation-progress.component.html',
})
export class CalculationProgressComponent implements OnInit, OnDestroy {
    public productionOrders: ProductionOrder[] = [];
    public employeeNumbers: string[] = [];
    public toggle = [];
    public calculationId: string = null;
    public totals;

    private subs: Subscription[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {
    }

    public ngOnInit(): void {
        this.subs.push(
            this.activeEntityMediatorService
                .activeProductionOrder$
                .subscribe((order: ProductionOrder) => {
                    if (order !== null) {
                        this.calculationId = order.calculation_id;
                        this.fetchCalculation();
                    } else {
                        this.calculationId = null;
                        this.productionOrders = [];
                    }
                })
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public fetchCalculation(): void {
        this.esbModelService.esbmCalculationIdGet(this.calculationId).subscribe(calculation => {
            this.productionOrders = calculation.production_orders;
            this.getTotalsLastOrder();
        });
    }

    public getTotalsLastOrder(): void {
        this.esbModelService
            .esbmProductionOrderReportOrderNumberTotalsGet(
                this.productionOrders[this.productionOrders.length - 1].order_number,
            )
            .subscribe(response => {
                if (response == null) {
                    this.totals = {
                        scrap: 0,
                        missing: 0,
                        processed: 0,
                        approved: 0,
                    };
                } else {
                    this.totals = response;
                }
            });
    }

    public progressPercentage() {
        const percentage =
            (this.totals.approved /
                this.productionOrders[this.productionOrders.length - 1].quantity) *
            100;
        return parseFloat(percentage.toFixed(2));
    }
}
