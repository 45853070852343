<betech-select-dropdown
  class="select-dropdown--inline"
  placeholder="Alle statussen"
  i18n-placeholder
  [items]="choices"
  [model]="selectedItem"
  label="label"
  search="label"
  (modelChange)="selectedItem = $event"
>
</betech-select-dropdown>
