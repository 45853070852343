import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '../../swagger-client';
import { TokenService } from '../../services/token/token.service';

@Pipe({
    name: 'accessTokenPipe',
})
export class AccessTokenPipe implements PipeTransform {
    constructor(
        private _authenticationService: AuthenticationService,
        private _tokenService: TokenService,
    ) {}

    public transform(url?: string): string | null {
        return (
            url +
            (url.indexOf('?') === -1 ? '?' : '&') +
            'access_token=' +
            this._tokenService.getToken().access_token
        );
    }
}
