import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService {
    /**
     * The local storage instance
     */
    private localStorage: Storage;

    /**
     * Constructor of the local storage class.
     */
    constructor() {
        if (!window.localStorage) {
            throw new Error('Current browser does not support Local Storage');
        }
        this.localStorage = window.localStorage;
    }

    /**
     * Set an item in the local storage.
     */
    public set(key: string, value: string): void {
        this.localStorage.setItem(key, value);
    }

    /**
     * Get an item from the local storage.
     */
    public get(key: string): string | null {
        return this.localStorage.getItem(key) || null;
    }

    /**
     * Get an item from the local storage.
     */
    public has(key: string): boolean {
        return this.get(key) !== null;
    }

    /**
     * Set an object in the local storage.
     */
    public setObject(key: string, value: any): void {
        this.set(key, JSON.stringify(value));
    }

    /**
     * Get an object from the local storage.
     */
    public getObject(key: string): any {
        return JSON.parse(this.get(key) || null);
    }

    /**
     * Remove an item from the local storage.
     */
    public remove(key: string): any {
        this.localStorage.removeItem(key);
    }

    /**
     * Clear the local storage.
     */
    public clear(): void {
        this.localStorage.clear();
    }
}
