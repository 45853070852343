import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AdminRoutingModule } from './admin.routing';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { NgClickOutsideModule } from 'ng-click-outside2';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { MeasurementStatsComponent } from './measurement-stats/measurement-stats.component';
import { ToolStatsComponent } from './tool-stats/tool-stats.component';
import { MaterialStatsComponent } from './material-stats/material-stats.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PerformanceModule } from './performance/performance.module';
import {BsDropdownModule} from "ngx-bootstrap/dropdown";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AdminRoutingModule,
        BsDropdownModule,
        FormsModule,
        NgClickOutsideModule,
        PopoverModule.forRoot(),
        PerformanceModule,
    ],
    providers: [],
    declarations: [
        AdminComponent,
        DashboardComponent,
        SidebarComponent,
        HeaderComponent,
        MeasurementStatsComponent,
        ToolStatsComponent,
        MaterialStatsComponent,
    ],
    exports: [RouterModule],
})
export class AdminModule {}
