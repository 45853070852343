import { Component, ContentChildren, QueryList } from '@angular/core';
import { AbstractFilterComponent } from './abstract.filter.component';

@Component({
    selector: 'betech-filter-container',
    templateUrl: './filter-container.component.html',
})
export class FilterContainerComponent {
    @ContentChildren(AbstractFilterComponent) public filters: QueryList<AbstractFilterComponent>;

    public clear(): void {
        this.filters.forEach((filter: AbstractFilterComponent) => filter.clear());
    }
}
