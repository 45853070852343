import { Component, Input } from '@angular/core';

@Component({
    selector: 'betech-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
    @Input()
    public value: number = 0;

    @Input()
    public color: string = 'secondary';

    @Input()
    public completedColor: string = 'success';
}
