import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Employee, ReportedChecklistItem } from 'app/swagger-client';
import { ChecklistItem, ChecklistService } from 'app/services/checklist/checklist.service';
import { EmployeeService } from 'app/services/employee/employee.service';
import { ActiveEntityMediatorService } from 'app/services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-checklist-item',
    templateUrl: './checklist-item.component.html',
})
export class ChecklistItemComponent implements OnInit, OnDestroy {
    @Input()
    public item: ChecklistItem;

    @Input()
    public checklistOverrideActiveEmployee: Subject<Employee> = new Subject<Employee>();

    public loading = false;
    private subs: Subscription[] = [];

    constructor(
        private employeeService: EmployeeService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private checklistService: ChecklistService,
    ) {}

    public ngOnInit(): void {
        if (this.item.employee_number) {
            this.item.employee = this.employeeService.employees.find(
                (e: Employee) => e.employee_number === this.item.employee_number,
            );
        } else {
            this.subs.push(
                this._activeEntityMediatorService.activeEmployee$.subscribe(
                    (employee: Employee) => {
                        this.setEmployee(employee, false);
                    },
                ),
            );
        }

        this.subs.push(
            this.checklistOverrideActiveEmployee.asObservable().subscribe((employee: Employee) => {
                this.setEmployee(employee, false);
            }),
        );
    }

    public ngOnDestroy() {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public get done(): boolean {
        return this.item.status === ReportedChecklistItem.StatusEnum.DONE;
    }

    public get todo(): boolean {
        return this.item.status === ReportedChecklistItem.StatusEnum.TODO;
    }

    public get skipped(): boolean {
        return this.item.status === ReportedChecklistItem.StatusEnum.SKIPPED;
    }

    public get employees(): Employee[] {
        return this.employeeService.employees;
    }

    public setEmployee(employee: Employee | undefined | null, publishChange: boolean = true): void {
        if (employee.employee_number === this.item.employee_number) {
            return;
        }

        if (publishChange) {
            this.checklistOverrideActiveEmployee.next(employee);
        }

        this.item.employee_number = employee.employee_number;
        this.item.employee = employee;
    }

    public checkItem(): void {
        this._saveItem(ReportedChecklistItem.StatusEnum.DONE);
    }

    public skipItem(): void {
        this._saveItem(ReportedChecklistItem.StatusEnum.SKIPPED);
    }

    public uncheckItem(): void {
        this._saveItem(ReportedChecklistItem.StatusEnum.TODO);
    }

    public toggle(): void {
        if (this.item.employee_number == null) {
            alert('Selecteer eerst een werknemer');
            return;
        }

        if (this.item.status === ReportedChecklistItem.StatusEnum.DONE) {
            this.uncheckItem();
        } else {
            this.checkItem();
        }
    }

    public skip(): void {
        this.item.status = ReportedChecklistItem.StatusEnum.SKIPPED;
    }

    private _saveItem(status: ReportedChecklistItem.StatusEnum, updateLastActivity = true) {
        this.loading = true;
        this.checklistService.saveItem(this.item, status, updateLastActivity).subscribe(() => {
            this.loading = false;
            this.item.status = status;
        });
    }
}
