import { Pipe, PipeTransform } from '@angular/core';
import { EmployeeService } from '../../services/employee/employee.service';
import { Employee } from '../../swagger-client';

@Pipe({
    name: 'employee',
})
export class EmployeePipe implements PipeTransform {
    constructor(private employeeService: EmployeeService) {}

    public transform(number: any, fieldName?: any): any {
        const employee = this.employeeService.employees.find(
            (e: Employee) => e.employee_number === number,
        );
        return employee ? employee[fieldName ? fieldName : 'full_name'] : number;
    }
}
