<div class="error-card container">
  <div class="container">
    <div class="row my-3">
      <div class="col-3">
        <i class="text-secondary fas fa-3x text-light-grey fa-laptop-code"></i>
      </div>
      <div class="col-9">
        <span>Sorry, dit device of deze browser wordt niet ondersteund.</span><br />
      </div>
    </div>
  </div>
</div>
