<tr class="betech-table__row">
  <td *ngIf="performanceTarget.type === 'ACTUAL'" class="betech-table__cell">
    {{ performanceTarget.start_workday | date: 'dd-MM-yyyy' }}
  </td>
  <td *ngIf="performanceTarget.type !== 'ACTUAL'" class="betech-table__cell">
    {{ performanceTarget.timestamp | date: 'dd-MM-yyyy' }}
  </td>

  <td class="betech-table__cell">
    {{ performanceTarget.cycle_time | number: '1.1-1' }}
  </td>

  <td class="betech-table__cell">
    {{ 3600 / performanceTarget.cycle_time | number: '1.1-1' }}
  </td>

  <!--    <td class="betech-table__cell">-->
  <!--        {{performanceTarget.feedrate}}-->
  <!--    </td>-->

  <!--    <td class="betech-table__cell">-->

  <!--    </td>-->

  <td class="betech-table__cell">
    {{ performanceTarget.availability.hours_per_day }}
  </td>

  <td class="betech-table__cell">
    {{ trueAmountDay }}
  </td>

  <td class="betech-table__cell">
    {{ amountDay }}
  </td>

  <td class="betech-table__cell">
    {{ performanceTarget.availability.days_per_week }}
  </td>

  <td class="betech-table__cell">
    {{ trueAmountWeek }}
  </td>

  <td class="betech-table__cell">
    {{ amountWeek }}
  </td>

    <td class="betech-table__cell">
        {{ performanceTarget.type === 'THEORETICAL' ? 'theoretisch' : 'gemeten' }}
    </td>

  <!--    <td class="betech-table__cell">-->
  <!--        {{performanceTarget.oee}}-->
  <!--    </td>-->

  <!--    <td class="betech-table__cell">-->
  <!--        {{trueAmountDay}}-->
  <!--    </td>-->

  <td class="betech-table__cell">
    <button *ngIf="performanceTarget.remark" type="button" class="btn btn-outline-dark" (click)="openModal()">
      Toelichting &nbsp; <i class="fas fa-info"></i>
    </button>
  </td>
</tr>
