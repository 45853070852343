import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFilterComponent } from '../../filter-container/abstract.filter.component';
import { ProductionOrderStatsModel } from '../../../admin/model/production-order-stats.model';

@Component({
    selector: 'betech-filter-department',
    templateUrl: './department.filter.component.html',
    providers: [{ provide: AbstractFilterComponent, useExisting: DepartmentFilterComponent }],
})
export class DepartmentFilterComponent extends AbstractFilterComponent {
    public choices: Array<string> = ['1XX', '2XX', '3XX', '4XX', '5XX'];

    private _selectedItem: any;
    public get selectedItem() {
        return this._selectedItem;
    }
    public set selectedItem(item: string | null) {
        this._selectedItem = item;

        this.filterChange.emit(
            item
                ? (value: ProductionOrderStatsModel) =>
                      value &&
                      value.processing_station_stats &&
                      value.processing_station_stats.trumpf_id &&
                      value.processing_station_stats.trumpf_id.substr(0, 1) === item.substr(0, 1)
                : null,
        );
    }

    @Output()
    public filterChange = new EventEmitter<(item: ProductionOrderStatsModel) => boolean | null>();

    public clear() {
        this.selectedItem = null;
        this.filterChange.emit(null);
    }
}
