import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OrderListComponent } from './order-list/order-list.component';
import { WorklogListComponent } from './worklog-list/worklog-list.component';
import { ToolListComponent } from './tool-list/tool-list.component';
import { ProgressComponent } from './progress/progress/progress.component';
import { MainGuard } from '../guards/main/main.guard';
import { ChecklistComponent } from './checklist/checklist/checklist.component';
import { CalculationProgressComponent } from './calculation-progress/calculation-progress.component';
import { OrderPerformanceComponent } from './order-performance/order-performance.component';
import { ToolHistoryComponent } from './tool-history/tool-history.component';
import { MainResolver } from './main.resolvers';

const mainRoute: Routes = [
    {
        path: '',
        canActivate: [MainGuard],
        canActivateChild: [MainGuard],
        component: MainComponent,
        resolve: {
            initialData: MainResolver,
        },
        children: [
            {
                path: 'dash',
                component: DashboardComponent,
            },
            {
                path: 'order',
                component: OrderListComponent,
            },
            {
                path: 'worklog',
                component: WorklogListComponent,
            },
            {
                path: 'tool',
                component: ToolListComponent,
            },
            {
                path: 'measurement-sample',
                loadChildren: () =>
                    import('app/main/measurement-sample/measurement-sample.module').then(
                        m => m.MeasurementSampleModule,
                    ),
            },
            {
                path: 'stock-request',
                loadChildren: () =>
                    import('app/main/stock-request/stock-request.module').then(
                        m => m.StockRequestModule,
                    ),
            },
            {
                path: 'progress',
                component: ProgressComponent,
            },
            {
                path: 'checklist',
                component: ChecklistComponent,
            },
            {
                path: 'calculation-progress',
                component: CalculationProgressComponent,
            },
            {
                path: 'order-performance',
                component: OrderPerformanceComponent,
            },
            {
                path: 'tool-history',
                component: ToolHistoryComponent,
            },
        ],
    },
];

export const MainRoutingModule = RouterModule.forChild(mainRoute);
