import { Component } from '@angular/core';

class navigationItem {
    name: string;
    location: string;
}

@Component({
    selector: 'betech-navigation-performance',
    templateUrl: './navigation-performance.component.html',
})
export class NavigationPerformanceComponent {
    public navigationItems: navigationItem[] = [
        {
            name: 'Performance',
            location: './machines',
        },
        {
            name: 'Werknemers',
            location: './employees',
        },
    ];
}
