import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseLoadingBarComponent } from 'app/loading-bar/loading-bar.component';

@NgModule({
    declarations: [FuseLoadingBarComponent],
    imports: [CommonModule],
    exports: [FuseLoadingBarComponent],
})
export class BetechLoadingBarModule {}
