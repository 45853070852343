import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductionOrder } from '../../../swagger-client';
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-instructions-bar',
    templateUrl: './instructions-bar.component.html',
})
export class InstructionsBarComponent implements OnInit, OnDestroy {
    public instructionOpen: boolean = false;
    public height: string = '0px';

    public instruction: string;
    public showInstruction: boolean = false;

    private subs: Subscription[] = [];

    constructor(private activeEntityMediatorService: ActiveEntityMediatorService) {}

    public ngOnInit(): void {
        this.subs.push(
            this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                this.determineMessage(currentPO);
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public determineMessage(currentPO: ProductionOrder): void {
        if (
            null != currentPO &&
            null != currentPO.instruction &&
            currentPO.instruction.length > 0
        ) {
            this.showInstruction = true;
            this.instruction = currentPO.instruction[0].text;
            this.instruction = this.instruction.replace(/(?:\r\n|\r|\n)/g, ' - ');
            if (this.instruction == '') {
                this.instruction = 'Geen instructie beschikbaar';
            }
        } else {
            this.showInstruction = false;
        }
    }

    public toggle(): void {
        this.instructionOpen = !this.instructionOpen;

        if (this.instructionOpen) {
            const elemHeight = document.getElementById('instruction-container').offsetHeight;
            const adjustmentHeight = elemHeight - 42;

            this.height = adjustmentHeight + 'px';
        } else {
            this.height = '0px';
        }
    }
}
