import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProducedAmountDetails,
    ProductionOrder,
    ProductionOrderReport,
} from '../../../../swagger-client';
import { Subscription } from 'rxjs';
import { statusLabel } from '../../../../pipes/order-status/order-status.pipe';
import { UpdateProgressModel } from '../model/update-progress.model';
import { ProgressCardComponent } from '../progress-card.component';
import { ProducedAmountDataService } from '../../../../services/produced-amount-data-service/produced-amount-data-service';
import { environment } from '../../../../../environments/environment';
import {ActiveEntityMediatorService} from "../../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-update-progress-modal',
    templateUrl: './update-progress-modal.component.html',
})
export class UpdateProgressModalComponent implements OnInit, OnDestroy {
    public readonly UPDATESTATUS: string = 'Tussenmelding';
    public readonly ENDSTATUS: string = 'Eindmelding';

    public isLoaded: boolean = false;
    public dueDate: Date;

    public percentageSucceed: number = 0;
    public percentageRejected: number = 0;
    public status: string;

    public quantity: number = 0;
    public produced: number = 0;
    public correct: number = 0;
    public incorrect: number = 0;

    public rejectChoices;
    public failureChoices;
    public causeChoices;
    public updateTypes = [this.UPDATESTATUS, this.ENDSTATUS];
    public employeeList;

    public activeMachine: ProcessingStation;
    public currentProductionOrder: ProductionOrder;
    public currentOrder: ProductionOrder;

    public updateProgressModel = new UpdateProgressModel(
        null, null, null, null, null, null, null, null, null
    );

    public modalRef;
    public parentCard: ProgressCardComponent;

    private subs: Subscription[] = [];

    public requiresTypeUpdate: boolean;
    public submitButtonText: string;
    public endReport: boolean;
    public validForm: boolean;

    public pendingSent: boolean;

    public activeEmployee: Employee;

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private producedAmountDataService: ProducedAmountDataService,
    ) {}

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public ngOnInit() {
        this.isLoaded = false;
        this.pendingSent = false;

        this.updateProgressModel.autoApproved = environment.config.autoApproveProduction;
        this.requiresTypeUpdate = environment.config.productionOrderProgressRequiresTypeUpdate;

        this.esbModelService.esbmEmployeeGet().subscribe(data => (this.employeeList = data.data));

        this.esbModelService
            .esbmRejectioncodesGet(true)
            .subscribe(data => {
                this.rejectChoices = data.rejectCodes;
                this.failureChoices = data.failureCodes;
                this.causeChoices = data.causeCodes;
            });

        this.subs.push(
            this.activeEntityMediatorService.activeEmployee$.subscribe(employee => {
                if (employee !== null) {
                    this.updateProgressModel.employee = employee.id;
                    this.activeEmployee = employee;
                }
            }),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );

        this.submitButtonText = 'Updaten';
        this.endReport = this.requiresTypeUpdate;

        this.subs.push(
            this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                this.currentProductionOrder = currentPO;
                this.loadDetails();
            })
        );

        this.subs.push(
            this.producedAmountDataService
            .observe()
            .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                if (producedAmountDetails) {
                    this.incorrect = producedAmountDetails.scrap;
                    this.correct = producedAmountDetails.approved;
                    this.produced = producedAmountDetails.processed;

                    this.calculateFinishedPercentage();
                    this.calculateRejectedPercentage();
                }
            })
        );
    }

    private loadDetails() {
        this.currentOrder = this.currentProductionOrder;

        this.status = statusLabel(this.currentOrder.reported_status);
        this.dueDate = new Date(this.currentOrder.due_date);
        this.quantity = this.currentOrder.quantity;

        this.isLoaded = true;
    }

    private calculateFinishedPercentage() {
        this.percentageSucceed = this.quantity > 0 ? Math.round((this.correct / this.quantity) * 100) : 100;
    }

    private calculateRejectedPercentage() {
        this.percentageRejected = this.produced > 0 ? Math.round((this.incorrect / this.produced) * 100) : 0;
    }

    public updateCorrectValue(): void {
        if (this.updateProgressModel.autoApproved) {
            this.updateProgressModel.approved = this.correct - this.updateProgressModel.rejected;
        }

        if (this.correct + this.updateProgressModel.approved >= this.currentProductionOrder.quantity && !this.activeMachine.is_cnc) {
            this.updateProgressModel.status = this.ENDSTATUS
        } else {
            this.updateProgressModel.status = this.UPDATESTATUS
        }

        this.setTypeUpdate(this.updateProgressModel.status);

        this.determineValidForm();
    }

    public sendOrderUpdates(): void {
        this.pendingSent = true;

        const date = new Date();
        const approved = this.updateProgressModel.autoApproved
            ? -this.updateProgressModel.rejected
            : this.updateProgressModel.approved;

        const productionOrderReport: ProductionOrderReport = {
            scrap_parts: this.updateProgressModel.rejected,
            missing_parts: 0,
            approved_parts: approved,
            description: this.updateProgressModel.description,
            reject_code: this.updateProgressModel.reject_category,
            failure_code: this.updateProgressModel.failure_category,
            cause_code: this.updateProgressModel.cause_category,
            order_number: this.currentOrder.order_number,
            report_id:
                this.currentOrder.reported_processing_station.name + '_' + Date.now().toString(),
            processing_time: 0,
            setup_time: 0,
            start_datetime: date,
            timestamp: date,
            employee_number: this.updateProgressModel.employee,
            processing_station_number: this.currentOrder.reported_processing_station.name,
        };

        this.esbModelService
            .esbmProductionOrderReportPost(productionOrderReport)
            .subscribe(data => {
                if (this.endReport && this.requiresTypeUpdate) {
                    this.stopOrder(this.currentOrder);
                } else {
                    this.closeScreenAfterSubmit();
                }
            });
    }

    public stopOrder(order: ProductionOrder) {
        if (!this.activeMachine) {
            console.error("this should not happen. It should have an active machine");
            return;
        }


        // TODO: RENE MAKE A BIT NICER
        order.reported_status = ProductionOrder.ReportedStatusEnum.ENDED;
        order.reported_processing_station = this.activeMachine;
        this.esbModelService.esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(order.id, this.activeMachine.id).subscribe(data => {
            this.activeEntityMediatorService.reloadProductionOrder();
            this.closeScreenAfterSubmit();
        });
    }

    public closeScreenAfterSubmit(): void {
        setTimeout(
            function () {
                this.closeScreen();
                this.parentCard.update();
            }.bind(this),
            1000,
        );
    }

    public determineValidForm(): void {
        this.validForm =
            this.updateProgressModel.rejected != null || this.updateProgressModel.approved != null;

        this.validForm =
            this.updateProgressModel.employee != null &&
            this.validForm;

        if (this.updateProgressModel.rejected !== null && this.updateProgressModel.rejected >= 0) {
            this.validForm = this.validForm && this.updateProgressModel.reject_category != null;
        }
    }

    public closeScreen(): void {
        this.modalRef.hide();
    }

    public setModalRef(modalRef: any, parentCard: ProgressCardComponent): void {
        this.modalRef = modalRef;
        this.parentCard = parentCard;
    }

    public setEmployee(employee: any): void {
        if (typeof employee !== 'object') {
            return;
        }

        this.updateProgressModel.employee = employee.employee_number;
        this.determineValidForm();
    }

    public setRejectCategory(reject: any): void {
        if (typeof reject !== 'object') {
            return;
        }

        this.updateProgressModel.reject_category = reject.code;
        this.determineValidForm();
    }

    public setFailureCategory(failure: any): void {
        if (typeof failure !== 'object') {
            return;
        }

        this.updateProgressModel.failure_category = failure.code;
        this.determineValidForm();
    }

    public setCauseCategory(cause: any): void {
        if (typeof cause !== 'object') {
            return;
        }

        this.updateProgressModel.cause_category = cause.code;
        this.determineValidForm();
    }

    public setTypeUpdate(type: any): void {
        this.updateProgressModel.status = type;

        if (typeof type !== 'string') {
            return;
        }

        if (type === this.UPDATESTATUS) {
            this.submitButtonText = 'Updaten';
            this.endReport = false;
        }

        if (type === this.ENDSTATUS) {
            this.submitButtonText = 'Bewerking gereed melden';
            this.endReport = true;
        }
    }
}
