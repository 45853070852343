<div class="order-progress-stats shadow-sm mb-2">
  <div>
    <h1 class="text-primary mb-4">{{ scrapPercentage }}% afkeur</h1>
    <betech-progress-bar [value]="scrapPercentage" class="mb-4"></betech-progress-bar>
    <div class="d-flex align-items-center">
      <h4 class="text-primary text-center p-2 mr-2 w-50">Reeds geproduceerd</h4>
      <h4 class="text-primary text-center p-2 w-50">Reeds afgekeurd</h4>
    </div>
    <div class="d-flex align-items-center">
      <h3 class="bg-primary text-center text-white p-2 mr-4 rounded w-50">{{ processed }}</h3>
      <h3 class="bg-primary text-center text-white p-2 rounded w-50">{{ scrap }}</h3>
    </div>
  </div>
  <div>
    <h1 class="text-primary mb-4">{{ progressPercentage }}% order gereed</h1>
    <betech-progress-bar [value]="progressPercentage" class="mb-4"></betech-progress-bar>
    <div class="d-flex align-items-center">
      <h4 class="text-primary text-center p-2 mr-2 w-50">Totaal goedgekeurd</h4>
      <h4 class="text-primary text-center p-2 w-50">Te produceren</h4>
    </div>
    <div class="d-flex align-items-center">
      <h3 class="bg-primary text-center text-white p-2 mr-4 rounded w-50">{{ approved }}</h3>
      <h3 class="bg-primary text-center text-white p-2 rounded w-50">{{ quantity }}</h3>
    </div>
  </div>
</div>

<ng-container *ngIf="reports && reports.length">
  <div class="px-3" style="width: 100%; overflow-x: auto">
    <table class="table order-progress-table">
      <thead>
        <tr>
          <td width="125">Goedkeurd</td>
          <td width="125">Afkeur</td>
          <td width="300">Datum</td>
          <td>Categorie afkeur</td>
          <td width="200">Toelichting</td>
          <td>Operator</td>
          <td width="200">Datum</td>
          <td width="200">Tijd</td>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of reports" (click)="openReport(report)">
          <td>
            <span class="text-success">{{
              report.approved_parts ? report.approved_parts : 0
            }}</span>
          </td>
          <td>
            <span class="text-danger">{{ report.scrap_parts }}</span>
          </td>
          <td>{{ report.timestamp | fromNow }}</td>
          <td>{{ report.returncode | rejectionCode }}</td>
          <td>
            <button *ngIf="report.description" class="btn btn-md btn-outline-primary">
              Toelichting
              <i class="fas fa-info ml-2"></i>
            </button>
            <span *ngIf="!report.description">n.v.t.</span>
          </td>
          <td>{{ report.employee_number | employee }}</td>
          <td>{{ report.timestamp | date: 'mediumDate' }}</td>
          <td>{{ report.timestamp | date: 'shortTime' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
<ng-container *ngIf="reports && !reports.length">
  <div class="text-center p-4">Geen resultaten gevonden.</div>
</ng-container>
<ng-container *ngIf="!reports">
  <div class="text-center p-4">Resultaten laden...</div>
</ng-container>
