import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler as BaseErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class ErrorHandler implements BaseErrorHandler {
    public handleError(error: any): void {
        this.captureToSentry(error);

        throw error;
    }

    private captureToSentry(error: any): void {
        if (!error) {
            return;
        }

        if (this.isTrueError(error)) {
            Sentry.captureException(error);

            return;
        }

        if (error instanceof HttpErrorResponse) {
            Sentry.captureMessage(JSON.stringify(error));
        } else if (error.message) {
            Sentry.captureMessage(error.message);
        } else {
            Sentry.captureMessage(JSON.stringify(error));
        }
    }

    private isTrueError(error: any): boolean {
        return (
            this.isError(error) ||
            this.isErrorEvent(error) ||
            this.isDOMError(error) ||
            this.isDOMException(error)
        );
    }

    // Sentry methods

    private isError(wat: any): boolean {
        switch (Object.prototype.toString.call(wat)) {
            case '[object Error]':
                return true;
            case '[object Exception]':
                return true;
            case '[object DOMException]':
                return true;
            default:
                return wat instanceof Error;
        }
    }

    private isErrorEvent(wat: any): boolean {
        return Object.prototype.toString.call(wat) === '[object ErrorEvent]';
    }

    private isDOMError(wat: any): boolean {
        return Object.prototype.toString.call(wat) === '[object DOMError]';
    }

    private isDOMException(wat: any): boolean {
        return Object.prototype.toString.call(wat) === '[object DOMException]';
    }
}
