import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
    name: 'subtractDates',
})
export class SubtractDatesPipe implements PipeTransform {
    public transform(start: any, end: any): any {
        if (start == null || end == null) {
            return null;
        }
        return this.subtractDatesToTime(moment(start), moment(end));
    }

    /**
     * Return the total time between start and end
     */
    private subtractDatesToTime(start: Moment, end: Moment): string {
        const diff = moment.utc(end.diff(start));
        if (diff.valueOf() < 0) {
            return '00:00';
        }
        return diff.format('HH:mm');
    }
}
