import { Injectable } from '@angular/core';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';
import { Subject, Observable } from 'rxjs';
import {
    User,
    AuthenticationService as SwaggerAuthenticationService,
    OauthAccessToken,
} from '../../swagger-client';
import { environment } from '../../../environments/environment';
import { take } from 'rxjs/operators';
import { AlertService } from '../alert/alert.service';
import { ProducedAmountDataService } from '../produced-amount-data-service/produced-amount-data-service';
import { ActiveEntityMediatorService } from '../active-entity-mediator/active-entity-mediator.service';

@Injectable()
export class AuthenticationService {
    constructor(
        private authenticationApi: SwaggerAuthenticationService,
        private tokenService: TokenService,
        private userService: UserService,
        private alertService: AlertService,
        private producedAmountDataService: ProducedAmountDataService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public get isAuthenticated(): boolean {
        return !!this.user;
    }
    public get user(): User {
        return this.userService.get();
    }

    /**
     * Sign the user in.
     */
    public login(username: string, password: string): Observable<User> {
        // Create token stuff
        const data = {
            client_id: environment.clientId,
            client_secret: environment.clientSecret,
            grant_type: 'password',
            username: username,
            password: password,
        };

        // Setup subject
        const subject = new Subject<User>();

        // Make the call§
        this.authenticationApi
            .authTokenPost(data)
            .pipe(take(1))
            .subscribe(
                (token: OauthAccessToken) => {
                    // Set the token
                    this.tokenService.setToken(token);

                    // Now fetch the user
                    this.userService
                        .fetch()
                        .pipe(take(1))
                        .subscribe(
                            (user: User) => {
                                subject.next(user);
                            },
                            error =>
                                subject.error(
                                    new Error('Could not authenticate - user data not found.'),
                                ),
                        );
                },
                error =>
                    subject.error(new Error('Could not authenticate - invalid username/password')),
            );

        // Return the observable
        return subject.asObservable();
    }

    /**
     * Sign the user out and clear all local storage
     */
    public logout(): void {
        this._activeEntityMediatorService.clear();
        this.producedAmountDataService.unsubscribe();
        this.userService.clear();
        this.tokenService.clear();
    }
}
