<div id="modal-tool-replace">
  <div class="modal-header p-3 text-white bg-primary set-shadow">
    <div class="modal-title pt-2 pl-2">
      <h4>
        <span i18n>Wissel</span>
        <span class="pr-3"> {{ selectedToolXref?.tool_position.name }}</span>
      </h4>
    </div>
    <span class="float-right mx-2 mt--2" (click)="close()"
      ><i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i
    ></span>
  </div>
  <div class="modal-body">
    <p i18n>
      Weet u zeker dat het gereedschap op toolpositie
      {{ selectedToolXref?.tool_position.name }} wilt verwisselen?
    </p>

    <div class="form-group">
      <betech-select-dropdown
        [required]="true"
        placeholder="Kies een medewerker"
        [items]="employeeList"
        label="full_name"
        search="full_name"
        [model]="selectedEmployee"
        (modelChange)="selectedEmployee = $event"
      ></betech-select-dropdown>

      <button
        class="
          float-right
          mt-3
          btn
          w-7
          rounded
          border-secondary
          bg-secondary
          text-white text-uppercase
          line-height-1
          pull-right
        "
        i18n
        (click)="doChange()"
      >
        Wissel
      </button>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
