/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MeasurementDefinitionItem } from './measurementDefinitionItem';
import { ProductionOrder } from './productionOrder';


export interface MeasurementDefinition { 
    readonly id?: string;
    readonly _created_at?: Date;
    readonly _updated_at?: Date;
    definition_type?: MeasurementDefinition.DefinitionTypeEnum;
    production_order?: ProductionOrder;
    definition_items?: Array<MeasurementDefinitionItem>;
}
export namespace MeasurementDefinition {
    export type DefinitionTypeEnum = 'MEASUREMENT_SETUP' | 'MEASUREMENT_RUNNING1' | 'MEASUREMENT_RUNNING2' | 'MEASUREMENT_ADHOC';
    export const DefinitionTypeEnum = {
        SETUP: 'MEASUREMENT_SETUP' as DefinitionTypeEnum,
        RUNNING1: 'MEASUREMENT_RUNNING1' as DefinitionTypeEnum,
        RUNNING2: 'MEASUREMENT_RUNNING2' as DefinitionTypeEnum,
        ADHOC: 'MEASUREMENT_ADHOC' as DefinitionTypeEnum
    };
}
