export class UpdateProgressModel {
    constructor(
        public autoApproved?: boolean,
        public approved?: number,
        public rejected?: number,
        public reject_category?: string,
        public failure_category?: string,
        public cause_category?: string,
        public description?: string,
        public employee?: string,
        public status?: string,
    ) {}
}
