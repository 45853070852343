<ng-container *ngIf="currentProductionOrder">
  <betech-card clickUrl="/tool-history" *ngIf="currentProductionOrder">
    <i card-icon class="fal fa-3x fa-toolbox fa-fw"></i>
    <span card-title i18n>Standtijden</span>

    <span card-subtitle>
      <span i18n>Order</span> {{ currentProductionOrder.production_number || '-' }}
    </span>

    <a card-button class="btn btn-secondary btn-block rounded text-uppercase" routerLink="/tool">
      <span i18n>Bewerken</span>
    </a>

    <ng-container card-info>
      <div class="d-flex justify-content-between">
        <div><span i18n>Aantal tools</span>:</div>
        <div class="text-primary text-truncate text-right">{{ total || '-' }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>&lt; 4 uur resterend</span>:</div>
        <div class="text-primary text-truncate text-right">{{ lessThan4Hours || '-' }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>&gt; 4 uur resterend</span>:</div>
        <div class="text-primary text-truncate text-right">{{ greaterThan4Hours || '-' }}</div>
      </div>
    </ng-container>

    <ng-container card-notifications>
      <betech-card-notification type="danger" *ngIf="urgentReplace">
        <span class="font-weight-bold"
          >{{ urgentReplace | i18nPlural: { '=1': '# tool', 'other': '# tools' } }}
        </span>
        <span i18n>spoedig vervangen</span>
      </betech-card-notification>
    </ng-container>

    <ng-container card-footer>
      <span i18n>Eerste tool moet worden vervangen over</span>&nbsp;<span class="text-primary">{{
        lowestTime | forecast
      }}</span>
    </ng-container>
  </betech-card>
</ng-container>
