import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'betech-modal',
    templateUrl: './modal.component.html',
})
export class ModalComponent {
    @Input() public showCloseButton: boolean = true;
    @Output() public closeEvent = new EventEmitter<boolean>();

    public doClose(): void {
        this.closeEvent.emit(true);
    }
}
