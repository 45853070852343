<betech-filter-container>
  <betech-filter-department (filterChange)="filter.filter('1', $event)"></betech-filter-department>
</betech-filter-container>

<table class="masterial-list betech-table">
  <thead>
    <tr class="betech-table__row">
      <th class="betech-table__heading"><span i18n>Actie over</span></th>
      <th class="betech-table__heading"><span i18n>Machine</span></th>
      <th class="betech-table__heading"><span i18n>Productie Nummer</span></th>
      <th class="betech-table__heading"><span i18n>Materiaal</span></th>
      <th class="betech-table__heading"><span i18n>Machine voorraad</span></th>
      <th class="betech-table__heading"><span i18n>Overschot</span></th>
      <th class="betech-table__heading"><span i18n>Chargenummer</span></th>
      <th class="betech-table__heading"><span i18n>Locatie</span></th>
      <th class="betech-table__heading"><span i18n>Status</span></th>
    </tr>
  </thead>
  <ng-container *ngFor="let stat of filter.current">
    <tr class="betech-table__row" [ngClass]="{ 'betech-table__row--highlight': isHighlight(stat) }">
      <td class="betech-table__cell">
        <div class="d-flex d-flex justify-content-around align-items-center">
          <div>
            <i
              class="fas fa-fw"
              [ngClass]="[
                'text-' + stat.getUrgencyClass('material_action'),
                stat.getUrgencyIcon('material_action')
              ]"
            ></i>
          </div>

          <div class="text-left">
            <span i18n>Actie vereist over</span><br />
            <strong class="text-danger" *ngIf="stat.getUrgencyClass('material_action') === 'danger'"
              >NU!&nbsp;</strong
            >
            <span [ngClass]="['text-' + stat.getUrgencyClass('material_action')]">{{
              stat.getStockTimeUntilNextAction() | forecast
            }}</span>
          </div>
        </div>
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.trumpf_id || '(no trumpf_id)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.production_number || '(Geen productie nummer)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.stock_request_material || '-' }}
      </td>
      <td
        class="betech-table__cell"
        [ngClass]="['text-' + stat.getUrgencyClass('material_action')]"
      >
        <strong>{{ stat.calculateMachine() || 0 | number: '1.0-1' }} m.</strong>
        <i
          *ngIf="stat.calculateMachine() !== stat.stock_request_machine"
          class="ml-1 fas fa-info-circle text-primary"
          popover="Dit is een geschatte waarde gebaseerd op het verwachte materiaal verbruik per gemaakt onderdeel"
          i18n-popover
          style="cursor: pointer"
        ></i>
      </td>
      <td
        class="betech-table__cell"
        [ngClass]="['text-' + stat.getUrgencyClass('material_surplus')]"
      >
        <strong
          >{{
            stat.stock_request_surplus ? (stat.stock_request_surplus | number: '1.0-1') : '?'
          }}
          m.</strong
        >
      </td>
      <td class="betech-table__cell">
        {{ stat.stock_request_charge_number || '-' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.stock_request_location || '-' }}
      </td>
      <td
        class="betech-table__cell"
        [innerHTML]="stat.stock_request_status | stockRequestStatus"
      ></td>
    </tr>
  </ng-container>
</table>
