import { Injectable } from '@angular/core';

@Injectable()
export class MeasurementService {
    private measurementId: Array<string> = [];

    public setMeasurementId(id: string): void {
        this.measurementId.push(id);
    }

    public getMeasurementId(): Array<string> {
        return this.measurementId;
    }

    public hasMeasurementId(id: string): boolean {
        if (this.measurementId.some(item => item === id)) {
            return true;
        }
    }

    public deleteMeasurementId(id: string) {
        this.measurementId.forEach((item, index) => {
            if (item === id) {
                this.measurementId.splice(index, 1);
            }
        });
    }
}
