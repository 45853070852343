/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Employee } from './employee';
import { MeasurementData } from './measurementData';
import { MeasurementDefinition } from './measurementDefinition';
import { MeasurementSampleContainer } from './measurementSampleContainer';
import { ProductionOrder } from './productionOrder';


export interface MeasurementSample { 
    readonly id?: string;
    readonly _created_at?: Date;
    readonly _updated_at?: Date;
    production_order?: ProductionOrder;
    measurement_sample_container?: MeasurementSampleContainer;
    measurement_definition?: MeasurementDefinition;
    employee?: Employee;
    sampled_at?: Date;
    sampled_count?: number;
    measured_at?: Date;
    location?: MeasurementSample.LocationEnum;
    status?: MeasurementSample.StatusEnum;
    sequence_number?: number;
    measurement_data?: MeasurementData;
}
export namespace MeasurementSample {
    export type LocationEnum = 'MACHINE' | 'MEASUREMENT_ROOM';
    export const LocationEnum = {
        MACHINE: 'MACHINE' as LocationEnum,
        MEASUREMENTROOM: 'MEASUREMENT_ROOM' as LocationEnum
    };
    export type StatusEnum = 'IN_PROGRESS' | 'PROCESSED_FAILURE' | 'PROCESSED_SUCCESS' | 'FAILURE_SOLVED';
    export const StatusEnum = {
        INPROGRESS: 'IN_PROGRESS' as StatusEnum,
        PROCESSEDFAILURE: 'PROCESSED_FAILURE' as StatusEnum,
        PROCESSEDSUCCESS: 'PROCESSED_SUCCESS' as StatusEnum,
        FAILURESOLVED: 'FAILURE_SOLVED' as StatusEnum
    };
}
