import { Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {
    Employee,
    EsbmodelService, ProcessingStation,
    ReportedChecklist,
    ReportedChecklistItem,
} from 'app/swagger-client';
import { Observable, Subject, Subscription} from 'rxjs';
import { take, takeUntil, tap} from 'rxjs/operators';
import { ActiveEntityMediatorService } from '../active-entity-mediator/active-entity-mediator.service';

export interface ChecklistItem extends ReportedChecklistItem {
    modified_at?: Date;
    employee?: Employee;
}

@Injectable({
    providedIn: 'root',
})
export class ChecklistService implements OnDestroy {
    public remaining: string;
    public remainingTime: number;
    public lastUpdatedItem: ChecklistItem;

    private _checklist: ReportedChecklist;
    private _ticker;

    public activeMachine: ProcessingStation;
    private subs: Subscription[] = [];
    private _newActiveMachineSubject: Subject<any> = new Subject<any>();

    private nextChecklistTimeout;

    constructor(
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private esbModelService: EsbmodelService,
    ) {}

    public initialize(): void {
        this.subs.push(
            this._activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this._newActiveMachineSubject.next(null);
                    this.activeMachine = activeMachine;
                    this._getActiveChecklists();

                },
            ),
        );
    }

    private _getActiveChecklists(): void {
        if (!this.activeMachine)
        {
            this._resetTimedFunctions();
            return;
        }

        this.esbModelService
            .esbmProcessingStationIdDailyChecklistGet(this.activeMachine.id)
            .pipe(takeUntil(this._newActiveMachineSubject))
            .subscribe((result: ReportedChecklist[]) => {
                this._handleChecklistResult(result);
            });
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
        this._newActiveMachineSubject.next(null);
        this._newActiveMachineSubject.complete();
    }

    private _handleChecklistResult(reportedChecklist: ReportedChecklist[]): void {
        this._resetTimedFunctions();

        if (0 === reportedChecklist.length) {
            return;
        }

        const nextCheck = this.determineNextChecklistPeriod(reportedChecklist);
        this._setTimeoutForNextChecklist(nextCheck);

        this._checklist = this.getActiveChecklistFromList(reportedChecklist);

        if (null == this._checklist) {
            return;
        }

        this._ticker = setInterval(() => {
            this._calculateRemaining();
        }, 1000);
    }

    public getActiveChecklistFromList(
        reportedChecklist: ReportedChecklist[],
    ): ReportedChecklist | null {
        return reportedChecklist.find((list: ReportedChecklist) => {
            return (
                moment(list.valid_from).isBefore(moment()) &&
                moment(list.valid_to).isAfter(moment())
            );
        });
    }

    public get checklist(): ReportedChecklist | null {
        return this._checklist;
    }

    public get items(): ChecklistItem[] {
        return this.checklist ? this.checklist.reported_checklist_items : [];
    }

    public get itemsTodo(): ChecklistItem[] {
        return this.items.filter(
            (i: ChecklistItem) => i.status === ReportedChecklistItem.StatusEnum.TODO,
        );
    }

    public get itemsDone(): ChecklistItem[] {
        return this.items
            .filter(
                (i: ChecklistItem) =>
                    i.status === ReportedChecklistItem.StatusEnum.DONE ||
                    i.status === ReportedChecklistItem.StatusEnum.SKIPPED,
            )
            .sort((a, b) => (a.modified_at < b.modified_at ? 1 : -1));
    }

    public get done(): boolean {
        return this.itemsTodo.length === 0;
    }

    public get deadline(): any {
        return moment(this.checklist.valid_to);
    }

    public get progress(): number {
        const percentage = ((this.items.length - this.itemsTodo.length) / this.items.length) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    public saveItem(
        item: ReportedChecklistItem,
        status: ReportedChecklistItem.StatusEnum,
        updateLastActivity = true,
    ): Observable<ReportedChecklist> {
        return this.esbModelService
            .esbmReportedChecklistItemIdStatusPost(item.id, status, item.employee_number)
            .pipe(
                tap((result: ReportedChecklist) => {
                    this._handleResult(result);
                    if (updateLastActivity) {
                        this.lastUpdatedItem = { ...item, status };
                        this.lastUpdatedItem.modified_at = new Date();
                    }
                }),
            );
    }

    private _handleResult(result: ReportedChecklist): void {
        this._activeEntityMediatorService.activeEmployee$
            .pipe(take(1))
            .subscribe((e: Employee) => {
            result.reported_checklist_items = result.reported_checklist_items.map(
                (i: ReportedChecklistItem) => {
                    return i;
                },
            );

            if (this._checklist) {
                return;
            }

            this._checklist = result;
        });
    }

    private _calculateRemaining(): void {
        const diff = moment.duration(this.deadline.diff(moment()));
        const duration = moment.duration(diff, 'seconds');

        this.remainingTime = duration.as('milliseconds');
        this.remaining = moment.utc(this.remainingTime).format('HH:mm:ss');

        if (duration.asSeconds() <= 0) {
            clearInterval(this._ticker);
            this.remaining = '';
        }
    }

    private determineNextChecklistPeriod(reportedChecklist: ReportedChecklist[]): number | null {
        const closedNextTime = this._determineNextClosedTime(reportedChecklist);

        // at lease check every hour

        if (null == closedNextTime) {
            return 3600000;
        }

        return Math.min(3600000, closedNextTime.diff(moment(), 'milliseconds'));
    }

    private _determineNextClosedTime(reportedChecklist: ReportedChecklist[]): moment.Moment | null {
        const arrayOfTimes = [];

        reportedChecklist.forEach(checklist => {
            arrayOfTimes.push(moment(checklist.valid_to));
            arrayOfTimes.push(moment(checklist.valid_from));
        });

        const sortedTimes = arrayOfTimes.sort((a, b) => a.valueOf() - b.valueOf());

        for (let i = 0; i < sortedTimes.length; i++) {
            if (sortedTimes[i].isBefore(moment())) {
                continue;
            }

            return sortedTimes[i];
        }

        return null;
    }

    private _setTimeoutForNextChecklist(nextCheck: number | null): void {
        if (null == nextCheck) {
            return;
        }

        this.nextChecklistTimeout = setTimeout(() => {
            this._getActiveChecklists();
        }, nextCheck);
    }

    private _resetTimedFunctions(): void {
        clearInterval(this._ticker);
        clearTimeout(this.nextChecklistTimeout);
    }
}
