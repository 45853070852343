import { Component, Input, OnInit } from '@angular/core';
import { TicketInterface } from '../../../services/support/ticket.interface';

@Component({
    selector: 'betech-support-ticket',
    templateUrl: './support-ticket.component.html',
    styleUrls: ['../stylesheet/support-ticket.component.scss'],
})
export class SupportTicketComponent {
    @Input() public ticket: TicketInterface;

    constructor() {}
}
