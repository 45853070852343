/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ScsnService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get scsn collection
     * 
     * @param database The database
     * @param collection The collection
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scsnDatabaseCollectionGet(database: string, collection: string, observe?: 'body', reportProgress?: boolean): Observable<Array<any>>;
    public scsnDatabaseCollectionGet(database: string, collection: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<any>>>;
    public scsnDatabaseCollectionGet(database: string, collection: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<any>>>;
    public scsnDatabaseCollectionGet(database: string, collection: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (database === null || database === undefined) {
            throw new Error('Required parameter database was null or undefined when calling scsnDatabaseCollectionGet.');
        }

        if (collection === null || collection === undefined) {
            throw new Error('Required parameter collection was null or undefined when calling scsnDatabaseCollectionGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<any>>(`${this.basePath}/scsn/${encodeURIComponent(String(database))}/${encodeURIComponent(String(collection))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
