export class InMemoryFilter {
    private original: Array<any>;
    private filtered: Array<any>;
    private filters: Object = {};

    public init(original: Array<any>) {
        this.filtered = this.original = original;
    }

    public filter(filterKey: string, filterFn: (item) => boolean | null) {
        this.filters[filterKey] = filterFn;
        this.filtered = this.original;
        Object.keys(this.filters).forEach(
            (key: string) =>
                (this.filtered = this.filters[key]
                    ? this.filtered.filter(this.filters[key])
                    : this.filtered),
        );
    }

    public get current() {
        return this.filtered;
    }
}
