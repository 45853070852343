import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ScanQrCodeModalComponent } from '../../../shared/scan-qr-code-modal/scan-qr-code-modal.component';
import QrScanner from 'qr-scanner';

@Component({
    selector: 'betech-no-order-active-card',
    templateUrl: './no-order-active-card.component.html',
})
export class NoOrderActiveCardComponent implements OnInit {
    public modalRef: BsModalRef;
    public hasCamera: boolean = false;

    constructor(private modalService: BsModalService) {}

    public ngOnInit(): void {
        // Disable scanning QR code on the dashboard for now until we have more information on the given QR code.
        this.hasCamera = false;
        // QrScanner.hasCamera().then(result => {
        //     this.hasCamera = result;
        // });
    }

    public openQrScanScreen($event) {
        $event.preventDefault();
        $event.stopPropagation();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-update-progress';

        this.modalRef = this.modalService.show(ScanQrCodeModalComponent, modalOptions);
        this.modalRef.content.initModalRef(this.modalRef, {
            searchForResult: true,
        });
    }
}
