/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ArticleHistoryResponseDomain } from '../model/articleHistoryResponseDomain';
import { ArticleStockMutation } from '../model/articleStockMutation';
import { Attachment } from '../model/attachment';
import { BaseMaterial } from '../model/baseMaterial';
import { Calculation } from '../model/calculation';
import { CalculationReport } from '../model/calculationReport';
import { Certificate } from '../model/certificate';
import { Company } from '../model/company';
import { Employee } from '../model/employee';
import { FabricationPart } from '../model/fabricationPart';
import { InlineResponse20010 } from '../model/inlineResponse20010';
import { InlineResponse20011 } from '../model/inlineResponse20011';
import { InlineResponse20012 } from '../model/inlineResponse20012';
import { InlineResponse20013 } from '../model/inlineResponse20013';
import { InlineResponse20014 } from '../model/inlineResponse20014';
import { InlineResponse20015 } from '../model/inlineResponse20015';
import { InlineResponse20016 } from '../model/inlineResponse20016';
import { InlineResponse20017 } from '../model/inlineResponse20017';
import { InlineResponse20018 } from '../model/inlineResponse20018';
import { InlineResponse20019 } from '../model/inlineResponse20019';
import { InlineResponse20020 } from '../model/inlineResponse20020';
import { InlineResponse20021 } from '../model/inlineResponse20021';
import { InlineResponse20022 } from '../model/inlineResponse20022';
import { InlineResponse20023 } from '../model/inlineResponse20023';
import { InlineResponse2004 } from '../model/inlineResponse2004';
import { InlineResponse2005 } from '../model/inlineResponse2005';
import { InlineResponse2006 } from '../model/inlineResponse2006';
import { InlineResponse2007 } from '../model/inlineResponse2007';
import { InlineResponse2008 } from '../model/inlineResponse2008';
import { InlineResponse2009 } from '../model/inlineResponse2009';
import { Material } from '../model/material';
import { MaterialArticle } from '../model/materialArticle';
import { Measurement } from '../model/measurement';
import { MeasurementData } from '../model/measurementData';
import { MeasurementDataSet } from '../model/measurementDataSet';
import { MeasurementDefinition } from '../model/measurementDefinition';
import { MeasurementSample } from '../model/measurementSample';
import { MeasurementSampleContainer } from '../model/measurementSampleContainer';
import { Message } from '../model/message';
import { Nesting } from '../model/nesting';
import { PerformanceTarget } from '../model/performanceTarget';
import { Person } from '../model/person';
import { ProcessingStation } from '../model/processingStation';
import { ProcessingStationTotals } from '../model/processingStationTotals';
import { ProducedAmountDetails } from '../model/producedAmountDetails';
import { ProductionOrder } from '../model/productionOrder';
import { ProductionOrderActionData } from '../model/productionOrderActionData';
import { ProductionOrderPerformanceTotals } from '../model/productionOrderPerformanceTotals';
import { ProductionOrderReport } from '../model/productionOrderReport';
import { ProductionOrderResponse } from '../model/productionOrderResponse';
import { ProductionOrderStats } from '../model/productionOrderStats';
import { Project } from '../model/project';
import { ReportedChecklist } from '../model/reportedChecklist';
import { SensorData } from '../model/sensorData';
import { StartMachineCounter } from '../model/startMachineCounter';
import { StockAllocation } from '../model/stockAllocation';
import { StockLocation } from '../model/stockLocation';
import { StockRequest } from '../model/stockRequest';
import { Tool } from '../model/tool';
import { ToolPosition } from '../model/toolPosition';
import { ToolPositionGroup } from '../model/toolPositionGroup';
import { ToolReplace } from '../model/toolReplace';
import { ToolXref } from '../model/toolXref';
import { ToolXrefResponse } from '../model/toolXrefResponse';
import { WorkLog } from '../model/workLog';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class EsbmodelService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get article history
     * 
     * @param article_number 
     * @param trumpf_id 
     * @param limit 
     * @param offset 
     * @param group_by 
     * @param start_date 
     * @param end_date 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmArticleHistoryGet(article_number: string, trumpf_id?: string, limit?: number, offset?: number, group_by?: string, start_date?: string, end_date?: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleHistoryResponseDomain>;
    public esbmArticleHistoryGet(article_number: string, trumpf_id?: string, limit?: number, offset?: number, group_by?: string, start_date?: string, end_date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleHistoryResponseDomain>>;
    public esbmArticleHistoryGet(article_number: string, trumpf_id?: string, limit?: number, offset?: number, group_by?: string, start_date?: string, end_date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleHistoryResponseDomain>>;
    public esbmArticleHistoryGet(article_number: string, trumpf_id?: string, limit?: number, offset?: number, group_by?: string, start_date?: string, end_date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (article_number === null || article_number === undefined) {
            throw new Error('Required parameter article_number was null or undefined when calling esbmArticleHistoryGet.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (article_number !== undefined && article_number !== null) {
            queryParameters = queryParameters.set('article_number', <any>article_number);
        }
        if (trumpf_id !== undefined && trumpf_id !== null) {
            queryParameters = queryParameters.set('trumpf_id', <any>trumpf_id);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (group_by !== undefined && group_by !== null) {
            queryParameters = queryParameters.set('group_by', <any>group_by);
        }
        if (start_date !== undefined && start_date !== null) {
            queryParameters = queryParameters.set('start_date', <any>start_date);
        }
        if (end_date !== undefined && end_date !== null) {
            queryParameters = queryParameters.set('end_date', <any>end_date);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleHistoryResponseDomain>(`${this.basePath}/esbm/article-history`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get article stock mutations
     * Get a paginated list of article stock mutations
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmArticleStockMutationGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20023>;
    public esbmArticleStockMutationGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20023>>;
    public esbmArticleStockMutationGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20023>>;
    public esbmArticleStockMutationGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20023>(`${this.basePath}/esbm/article_stock_mutation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get article stock mutation
     * 
     * @param id The ID of the article stock mutation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmArticleStockMutationIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ArticleStockMutation>;
    public esbmArticleStockMutationIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ArticleStockMutation>>;
    public esbmArticleStockMutationIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ArticleStockMutation>>;
    public esbmArticleStockMutationIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmArticleStockMutationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ArticleStockMutation>(`${this.basePath}/esbm/article_stock_mutation/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post attchment with base64encoded data
     * 
     * @param externalId Unique external Id for the attachment
     * @param inputAttachment The attachment
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmAttachmentExternalIdPost(externalId: string, inputAttachment?: Attachment, observe?: 'body', reportProgress?: boolean): Observable<Attachment>;
    public esbmAttachmentExternalIdPost(externalId: string, inputAttachment?: Attachment, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Attachment>>;
    public esbmAttachmentExternalIdPost(externalId: string, inputAttachment?: Attachment, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Attachment>>;
    public esbmAttachmentExternalIdPost(externalId: string, inputAttachment?: Attachment, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (externalId === null || externalId === undefined) {
            throw new Error('Required parameter externalId was null or undefined when calling esbmAttachmentExternalIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Attachment>(`${this.basePath}/esbm/attachment/${encodeURIComponent(String(externalId))}`,
            inputAttachment,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get base material
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmBaseMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20015>;
    public esbmBaseMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20015>>;
    public esbmBaseMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20015>>;
    public esbmBaseMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20015>(`${this.basePath}/esbm/base_material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get base material
     * 
     * @param id The ID of the base material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmBaseMaterialIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<BaseMaterial>;
    public esbmBaseMaterialIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseMaterial>>;
    public esbmBaseMaterialIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseMaterial>>;
    public esbmBaseMaterialIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmBaseMaterialIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<BaseMaterial>(`${this.basePath}/esbm/base_material/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post material article
     * 
     * @param inputMaterialArticle The base material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmBaseMaterialPost(inputMaterialArticle?: BaseMaterial, observe?: 'body', reportProgress?: boolean): Observable<BaseMaterial>;
    public esbmBaseMaterialPost(inputMaterialArticle?: BaseMaterial, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BaseMaterial>>;
    public esbmBaseMaterialPost(inputMaterialArticle?: BaseMaterial, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BaseMaterial>>;
    public esbmBaseMaterialPost(inputMaterialArticle?: BaseMaterial, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<BaseMaterial>(`${this.basePath}/esbm/base_material`,
            inputMaterialArticle,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Calculation By Production Number
     * 
     * @param ordernumber Order Number
     * @param productionnumber Production Number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationDataTraceGet(ordernumber?: string, productionnumber?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmCalculationDataTraceGet(ordernumber?: string, productionnumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmCalculationDataTraceGet(ordernumber?: string, productionnumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmCalculationDataTraceGet(ordernumber?: string, productionnumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }
        if (productionnumber !== undefined && productionnumber !== null) {
            queryParameters = queryParameters.set('productionnumber', <any>productionnumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/esbm/calculation/data/trace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get calculations
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param ordernumber 
     * @param companynumber 
     * @param projectnumber 
     * @param productionnumber 
     * @param customernumber 
     * @param articlenumber 
     * @param updated_from 
     * @param updated_to 
     * @param sequence_greater_than 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, companynumber?: string, projectnumber?: string, productionnumber?: string, customernumber?: string, articlenumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, status?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20018>;
    public esbmCalculationGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, companynumber?: string, projectnumber?: string, productionnumber?: string, customernumber?: string, articlenumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, status?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20018>>;
    public esbmCalculationGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, companynumber?: string, projectnumber?: string, productionnumber?: string, customernumber?: string, articlenumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, status?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20018>>;
    public esbmCalculationGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, companynumber?: string, projectnumber?: string, productionnumber?: string, customernumber?: string, articlenumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, status?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }
        if (companynumber !== undefined && companynumber !== null) {
            queryParameters = queryParameters.set('companynumber', <any>companynumber);
        }
        if (projectnumber !== undefined && projectnumber !== null) {
            queryParameters = queryParameters.set('projectnumber', <any>projectnumber);
        }
        if (productionnumber !== undefined && productionnumber !== null) {
            queryParameters = queryParameters.set('productionnumber', <any>productionnumber);
        }
        if (customernumber !== undefined && customernumber !== null) {
            queryParameters = queryParameters.set('customernumber', <any>customernumber);
        }
        if (articlenumber !== undefined && articlenumber !== null) {
            queryParameters = queryParameters.set('articlenumber', <any>articlenumber);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }
        if (sequence_greater_than !== undefined && sequence_greater_than !== null) {
            queryParameters = queryParameters.set('sequence_greater_than', <any>sequence_greater_than);
        }
        if (status) {
            queryParameters = queryParameters.set('status', status.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20018>(`${this.basePath}/esbm/calculation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get order
     * 
     * @param id The ID of the calculation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Calculation>;
    public esbmCalculationIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Calculation>>;
    public esbmCalculationIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Calculation>>;
    public esbmCalculationIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCalculationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Calculation>(`${this.basePath}/esbm/calculation/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post calculation
     * 
     * @param calculation The calculation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationPost(calculation?: Calculation, observe?: 'body', reportProgress?: boolean): Observable<Calculation>;
    public esbmCalculationPost(calculation?: Calculation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Calculation>>;
    public esbmCalculationPost(calculation?: Calculation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Calculation>>;
    public esbmCalculationPost(calculation?: Calculation, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Calculation>(`${this.basePath}/esbm/calculation`,
            calculation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get calculation reports
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param ordernumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationReportGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, ordernumber?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20010>;
    public esbmCalculationReportGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, ordernumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20010>>;
    public esbmCalculationReportGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, ordernumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20010>>;
    public esbmCalculationReportGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, ordernumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20010>(`${this.basePath}/esbm/calculation_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get calculation report
     * 
     * @param id The ID of the calculation report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationReportIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Nesting>;
    public esbmCalculationReportIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nesting>>;
    public esbmCalculationReportIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nesting>>;
    public esbmCalculationReportIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCalculationReportIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Nesting>(`${this.basePath}/esbm/calculation_report/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post calculation report
     * 
     * @param inputCalculationReport The calculation report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCalculationReportPost(inputCalculationReport?: CalculationReport, observe?: 'body', reportProgress?: boolean): Observable<CalculationReport>;
    public esbmCalculationReportPost(inputCalculationReport?: CalculationReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CalculationReport>>;
    public esbmCalculationReportPost(inputCalculationReport?: CalculationReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CalculationReport>>;
    public esbmCalculationReportPost(inputCalculationReport?: CalculationReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CalculationReport>(`${this.basePath}/esbm/calculation_report`,
            inputCalculationReport,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Certificate
     * 
     * @param productionOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCertificateGet(productionOrderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Certificate>>;
    public esbmCertificateGet(productionOrderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Certificate>>>;
    public esbmCertificateGet(productionOrderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Certificate>>>;
    public esbmCertificateGet(productionOrderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Certificate>>(`${this.basePath}/esbm/certificate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Certificate
     * 
     * @param id The ID of the Certificate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCertificateIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmCertificateIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmCertificateIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmCertificateIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCertificateIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/certificate/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get certificate entity
     * 
     * @param id The ID of the Certificate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCertificateIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public esbmCertificateIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public esbmCertificateIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public esbmCertificateIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCertificateIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Certificate>(`${this.basePath}/esbm/certificate/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Certificate
     * 
     * @param id The ID of the Certificate
     * @param inputcertificate The Certificate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCertificateIdPut(id: string, inputcertificate?: Certificate, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public esbmCertificateIdPut(id: string, inputcertificate?: Certificate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public esbmCertificateIdPut(id: string, inputcertificate?: Certificate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public esbmCertificateIdPut(id: string, inputcertificate?: Certificate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCertificateIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Certificate>(`${this.basePath}/esbm/certificate/${encodeURIComponent(String(id))}`,
            inputcertificate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Certificate
     * 
     * @param inputcertificate The Certificate
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCertificatePost(inputcertificate?: Certificate, observe?: 'body', reportProgress?: boolean): Observable<Certificate>;
    public esbmCertificatePost(inputcertificate?: Certificate, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Certificate>>;
    public esbmCertificatePost(inputcertificate?: Certificate, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Certificate>>;
    public esbmCertificatePost(inputcertificate?: Certificate, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Certificate>(`${this.basePath}/esbm/certificate`,
            inputcertificate,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get companies
     * Get a paginated list of companies
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCompanyGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20020>;
    public esbmCompanyGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20020>>;
    public esbmCompanyGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20020>>;
    public esbmCompanyGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20020>(`${this.basePath}/esbm/company`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get company
     * 
     * @param id The ID of the company
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCompanyIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public esbmCompanyIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public esbmCompanyIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public esbmCompanyIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmCompanyIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Company>(`${this.basePath}/esbm/company/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post company
     * 
     * @param inputProductionOrder The company
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmCompanyPost(inputProductionOrder?: Company, observe?: 'body', reportProgress?: boolean): Observable<Company>;
    public esbmCompanyPost(inputProductionOrder?: Company, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Company>>;
    public esbmCompanyPost(inputProductionOrder?: Company, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Company>>;
    public esbmCompanyPost(inputProductionOrder?: Company, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Company>(`${this.basePath}/esbm/company`,
            inputProductionOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get employees
     * Get a list of employees
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmEmployeeGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20022>;
    public esbmEmployeeGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20022>>;
    public esbmEmployeeGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20022>>;
    public esbmEmployeeGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20022>(`${this.basePath}/esbm/employee`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get employee
     * 
     * @param id The ID of the employee
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmEmployeeIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Employee>;
    public esbmEmployeeIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Employee>>;
    public esbmEmployeeIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Employee>>;
    public esbmEmployeeIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmEmployeeIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Employee>(`${this.basePath}/esbm/employee/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order stats
     * 
     * @param employeeNumber 
     * @param trumpfId 
     * @param limit 
     * @param offset 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmEmployeePerformanceGet(employeeNumber?: string, trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderPerformanceTotals>;
    public esbmEmployeePerformanceGet(employeeNumber?: string, trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderPerformanceTotals>>;
    public esbmEmployeePerformanceGet(employeeNumber?: string, trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderPerformanceTotals>>;
    public esbmEmployeePerformanceGet(employeeNumber?: string, trumpfId?: string, limit?: number, offset?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (employeeNumber !== undefined && employeeNumber !== null) {
            queryParameters = queryParameters.set('employeeNumber', <any>employeeNumber);
        }
        if (trumpfId !== undefined && trumpfId !== null) {
            queryParameters = queryParameters.set('trumpfId', <any>trumpfId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrderPerformanceTotals>(`${this.basePath}/esbm/employee-performance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get active employee (looks at worklog)
     * 
     * @param processingStation The ID of the active processingStation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmEmployeeProcessingStationActiveGet(processingStation: string, observe?: 'body', reportProgress?: boolean): Observable<Employee>;
    public esbmEmployeeProcessingStationActiveGet(processingStation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Employee>>;
    public esbmEmployeeProcessingStationActiveGet(processingStation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Employee>>;
    public esbmEmployeeProcessingStationActiveGet(processingStation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processingStation === null || processingStation === undefined) {
            throw new Error('Required parameter processingStation was null or undefined when calling esbmEmployeeProcessingStationActiveGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Employee>(`${this.basePath}/esbm/employee/${encodeURIComponent(String(processingStation))}/active`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get fabrication parts
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmFabricationPartGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20016>;
    public esbmFabricationPartGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20016>>;
    public esbmFabricationPartGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20016>>;
    public esbmFabricationPartGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20016>(`${this.basePath}/esbm/fabrication_part`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Fabrication part
     * 
     * @param id The ID of the fabrication part
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmFabricationPartIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<FabricationPart>;
    public esbmFabricationPartIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FabricationPart>>;
    public esbmFabricationPartIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FabricationPart>>;
    public esbmFabricationPartIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmFabricationPartIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FabricationPart>(`${this.basePath}/esbm/fabrication_part/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post fabrication part
     * 
     * @param inputFabricationPart The fabrication part
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmFabricationPartPost(inputFabricationPart?: FabricationPart, observe?: 'body', reportProgress?: boolean): Observable<FabricationPart>;
    public esbmFabricationPartPost(inputFabricationPart?: FabricationPart, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FabricationPart>>;
    public esbmFabricationPartPost(inputFabricationPart?: FabricationPart, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FabricationPart>>;
    public esbmFabricationPartPost(inputFabricationPart?: FabricationPart, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<FabricationPart>(`${this.basePath}/esbm/fabrication_part`,
            inputFabricationPart,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get image
     * 
     * @param fileName Name of the image
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmImageFileNameGet(fileName: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public esbmImageFileNameGet(fileName: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public esbmImageFileNameGet(fileName: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public esbmImageFileNameGet(fileName: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling esbmImageFileNameGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(`${this.basePath}/esbm/image/${encodeURIComponent(String(fileName))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get material articles
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialArticleGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20013>;
    public esbmMaterialArticleGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20013>>;
    public esbmMaterialArticleGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20013>>;
    public esbmMaterialArticleGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20013>(`${this.basePath}/esbm/material_article`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get certificates for material article
     * 
     * @param id The ID of the material article
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialArticleIdCertificateGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Certificate>>;
    public esbmMaterialArticleIdCertificateGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Certificate>>>;
    public esbmMaterialArticleIdCertificateGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Certificate>>>;
    public esbmMaterialArticleIdCertificateGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMaterialArticleIdCertificateGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<Certificate>>(`${this.basePath}/esbm/material_article/${encodeURIComponent(String(id))}/certificate`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Material article
     * 
     * @param id The ID of the material article
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialArticleIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<MaterialArticle>;
    public esbmMaterialArticleIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MaterialArticle>>;
    public esbmMaterialArticleIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MaterialArticle>>;
    public esbmMaterialArticleIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMaterialArticleIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MaterialArticle>(`${this.basePath}/esbm/material_article/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post material article
     * 
     * @param inputMaterialArticle The material_article
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialArticlePost(inputMaterialArticle?: MaterialArticle, observe?: 'body', reportProgress?: boolean): Observable<MaterialArticle>;
    public esbmMaterialArticlePost(inputMaterialArticle?: MaterialArticle, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MaterialArticle>>;
    public esbmMaterialArticlePost(inputMaterialArticle?: MaterialArticle, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MaterialArticle>>;
    public esbmMaterialArticlePost(inputMaterialArticle?: MaterialArticle, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MaterialArticle>(`${this.basePath}/esbm/material_article`,
            inputMaterialArticle,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get  material
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20014>;
    public esbmMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20014>>;
    public esbmMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20014>>;
    public esbmMaterialGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20014>(`${this.basePath}/esbm/material`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Material
     * 
     * @param id The ID of the material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Material>;
    public esbmMaterialIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Material>>;
    public esbmMaterialIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Material>>;
    public esbmMaterialIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMaterialIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Material>(`${this.basePath}/esbm/material/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post material article
     * 
     * @param inputMaterial The  material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMaterialPost(inputMaterial?: Material, observe?: 'body', reportProgress?: boolean): Observable<Material>;
    public esbmMaterialPost(inputMaterial?: Material, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Material>>;
    public esbmMaterialPost(inputMaterial?: Material, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Material>>;
    public esbmMaterialPost(inputMaterial?: Material, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Material>(`${this.basePath}/esbm/material`,
            inputMaterial,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get dataset
     * 
     * @param productionOrderId The production order id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataDatasetGet(productionOrderId: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementDataSet>;
    public esbmMeasurementDataDatasetGet(productionOrderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementDataSet>>;
    public esbmMeasurementDataDatasetGet(productionOrderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementDataSet>>;
    public esbmMeasurementDataDatasetGet(productionOrderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionOrderId === null || productionOrderId === undefined) {
            throw new Error('Required parameter productionOrderId was null or undefined when calling esbmMeasurementDataDatasetGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementDataSet>(`${this.basePath}/esbm/measurement-data/dataset`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Measurement
     * 
     * @param productionOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataGet(productionOrderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MeasurementData>>;
    public esbmMeasurementDataGet(productionOrderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeasurementData>>>;
    public esbmMeasurementDataGet(productionOrderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeasurementData>>>;
    public esbmMeasurementDataGet(productionOrderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeasurementData>>(`${this.basePath}/esbm/measurement-data`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Measurement
     * 
     * @param id The ID of the Measurement data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmMeasurementDataIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmMeasurementDataIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmMeasurementDataIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDataIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/measurement-data/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the Measurement data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementData>;
    public esbmMeasurementDataIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementData>>;
    public esbmMeasurementDataIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementData>>;
    public esbmMeasurementDataIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDataIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementData>(`${this.basePath}/esbm/measurement-data/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Measurement
     * 
     * @param id The ID of the Measurement data
     * @param inputMeasurement The Measurement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataIdPut(id: string, inputMeasurement?: Measurement, observe?: 'body', reportProgress?: boolean): Observable<Measurement>;
    public esbmMeasurementDataIdPut(id: string, inputMeasurement?: Measurement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Measurement>>;
    public esbmMeasurementDataIdPut(id: string, inputMeasurement?: Measurement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Measurement>>;
    public esbmMeasurementDataIdPut(id: string, inputMeasurement?: Measurement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDataIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Measurement>(`${this.basePath}/esbm/measurement-data/${encodeURIComponent(String(id))}`,
            inputMeasurement,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Measurement Sample
     * 
     * @param inputMeasurement The Measurement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDataPost(inputMeasurement?: Measurement, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSample>;
    public esbmMeasurementDataPost(inputMeasurement?: Measurement, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSample>>;
    public esbmMeasurementDataPost(inputMeasurement?: Measurement, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSample>>;
    public esbmMeasurementDataPost(inputMeasurement?: Measurement, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeasurementSample>(`${this.basePath}/esbm/measurement-data`,
            inputMeasurement,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get MeasurementDefinitions
     * 
     * @param fabricationPartId 
     * @param productionOrderId Use instead of fabricationPartId, which will simply look up the fabricationPartId of the given production order and use that.
     * @param definitionType Type of measurement to filter the defintions for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDefinitionGet(fabricationPartId?: string, productionOrderId?: string, definitionType?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MeasurementDefinition>>;
    public esbmMeasurementDefinitionGet(fabricationPartId?: string, productionOrderId?: string, definitionType?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeasurementDefinition>>>;
    public esbmMeasurementDefinitionGet(fabricationPartId?: string, productionOrderId?: string, definitionType?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeasurementDefinition>>>;
    public esbmMeasurementDefinitionGet(fabricationPartId?: string, productionOrderId?: string, definitionType?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fabricationPartId !== undefined && fabricationPartId !== null) {
            queryParameters = queryParameters.set('fabricationPartId', <any>fabricationPartId);
        }
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }
        if (definitionType !== undefined && definitionType !== null) {
            queryParameters = queryParameters.set('definitionType', <any>definitionType);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeasurementDefinition>>(`${this.basePath}/esbm/measurement-definition`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete MeasurementDefinition
     * 
     * @param id The ID of the measurement definition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDefinitionIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmMeasurementDefinitionIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmMeasurementDefinitionIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmMeasurementDefinitionIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDefinitionIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/measurement-definition/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the measurement definition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDefinitionIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementDefinition>;
    public esbmMeasurementDefinitionIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementDefinition>>;
    public esbmMeasurementDefinitionIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementDefinition>>;
    public esbmMeasurementDefinitionIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDefinitionIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementDefinition>(`${this.basePath}/esbm/measurement-definition/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put MeasurementDefinition
     * 
     * @param id The ID of the measurement definition
     * @param inputMeasurementDefinition The MeasurementDefinition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDefinitionIdPut(id: string, inputMeasurementDefinition?: MeasurementDefinition, observe?: 'body', reportProgress?: boolean): Observable<MeasurementDefinition>;
    public esbmMeasurementDefinitionIdPut(id: string, inputMeasurementDefinition?: MeasurementDefinition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementDefinition>>;
    public esbmMeasurementDefinitionIdPut(id: string, inputMeasurementDefinition?: MeasurementDefinition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementDefinition>>;
    public esbmMeasurementDefinitionIdPut(id: string, inputMeasurementDefinition?: MeasurementDefinition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementDefinitionIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MeasurementDefinition>(`${this.basePath}/esbm/measurement-definition/${encodeURIComponent(String(id))}`,
            inputMeasurementDefinition,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post MeasurementDefinition
     * 
     * @param inputMeasurementDefinition The MeasurementDefinition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementDefinitionPost(inputMeasurementDefinition?: MeasurementDefinition, observe?: 'body', reportProgress?: boolean): Observable<MeasurementDefinition>;
    public esbmMeasurementDefinitionPost(inputMeasurementDefinition?: MeasurementDefinition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementDefinition>>;
    public esbmMeasurementDefinitionPost(inputMeasurementDefinition?: MeasurementDefinition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementDefinition>>;
    public esbmMeasurementDefinitionPost(inputMeasurementDefinition?: MeasurementDefinition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeasurementDefinition>(`${this.basePath}/esbm/measurement-definition`,
            inputMeasurementDefinition,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Measurement Sample Container
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleContainerGet(observe?: 'body', reportProgress?: boolean): Observable<Array<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeasurementSampleContainer>>>;
    public esbmMeasurementSampleContainerGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeasurementSampleContainer>>>;
    public esbmMeasurementSampleContainerGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeasurementSampleContainer>>(`${this.basePath}/esbm/measurement-sample-container`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Measurement Sample Container
     * 
     * @param id The ID of the Measurement Sample Container
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleContainerIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmMeasurementSampleContainerIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmMeasurementSampleContainerIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmMeasurementSampleContainerIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleContainerIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/measurement-sample-container/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the Measurement Sample Container
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleContainerIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSampleContainer>;
    public esbmMeasurementSampleContainerIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleContainerIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementSampleContainer>(`${this.basePath}/esbm/measurement-sample-container/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Measurement Sample Container
     * 
     * @param id The ID of the Measurement Sample Container
     * @param inputMeasurementSampleContainer The Measurement Sample Container
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleContainerIdPut(id: string, inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSampleContainer>;
    public esbmMeasurementSampleContainerIdPut(id: string, inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerIdPut(id: string, inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerIdPut(id: string, inputMeasurementSampleContainer?: MeasurementSampleContainer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleContainerIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MeasurementSampleContainer>(`${this.basePath}/esbm/measurement-sample-container/${encodeURIComponent(String(id))}`,
            inputMeasurementSampleContainer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Measurement Sample Container
     * 
     * @param inputMeasurementSampleContainer The Measurement Sample Container
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleContainerPost(inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSampleContainer>;
    public esbmMeasurementSampleContainerPost(inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerPost(inputMeasurementSampleContainer?: MeasurementSampleContainer, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSampleContainer>>;
    public esbmMeasurementSampleContainerPost(inputMeasurementSampleContainer?: MeasurementSampleContainer, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeasurementSampleContainer>(`${this.basePath}/esbm/measurement-sample-container`,
            inputMeasurementSampleContainer,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get latest entity
     * 
     * @param productionOrderId The ID of the Current Production Order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleCurrentGet(productionOrderId: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSample>;
    public esbmMeasurementSampleCurrentGet(productionOrderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSample>>;
    public esbmMeasurementSampleCurrentGet(productionOrderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSample>>;
    public esbmMeasurementSampleCurrentGet(productionOrderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionOrderId === null || productionOrderId === undefined) {
            throw new Error('Required parameter productionOrderId was null or undefined when calling esbmMeasurementSampleCurrentGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementSample>(`${this.basePath}/esbm/measurement-sample/current`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Measurement Sample
     * 
     * @param productionOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleGet(productionOrderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<MeasurementSample>>;
    public esbmMeasurementSampleGet(productionOrderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MeasurementSample>>>;
    public esbmMeasurementSampleGet(productionOrderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MeasurementSample>>>;
    public esbmMeasurementSampleGet(productionOrderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<MeasurementSample>>(`${this.basePath}/esbm/measurement-sample`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Measurement Sample
     * 
     * @param id The ID of the Measurement Sample
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmMeasurementSampleIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmMeasurementSampleIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmMeasurementSampleIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/measurement-sample/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the Measurement Sample
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSample>;
    public esbmMeasurementSampleIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSample>>;
    public esbmMeasurementSampleIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSample>>;
    public esbmMeasurementSampleIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MeasurementSample>(`${this.basePath}/esbm/measurement-sample/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Measurement Sample
     * 
     * @param id The ID of the Measurement Sample
     * @param inputMeasurementSample The Measurement Sample
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSampleIdPut(id: string, inputMeasurementSample?: MeasurementSample, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSample>;
    public esbmMeasurementSampleIdPut(id: string, inputMeasurementSample?: MeasurementSample, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSample>>;
    public esbmMeasurementSampleIdPut(id: string, inputMeasurementSample?: MeasurementSample, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSample>>;
    public esbmMeasurementSampleIdPut(id: string, inputMeasurementSample?: MeasurementSample, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMeasurementSampleIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<MeasurementSample>(`${this.basePath}/esbm/measurement-sample/${encodeURIComponent(String(id))}`,
            inputMeasurementSample,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Measurement Sample
     * 
     * @param inputMeasurementSample The Measurement Sample
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMeasurementSamplePost(inputMeasurementSample?: MeasurementSample, observe?: 'body', reportProgress?: boolean): Observable<MeasurementSample>;
    public esbmMeasurementSamplePost(inputMeasurementSample?: MeasurementSample, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MeasurementSample>>;
    public esbmMeasurementSamplePost(inputMeasurementSample?: MeasurementSample, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MeasurementSample>>;
    public esbmMeasurementSamplePost(inputMeasurementSample?: MeasurementSample, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MeasurementSample>(`${this.basePath}/esbm/measurement-sample`,
            inputMeasurementSample,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get message
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMessageGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20012>;
    public esbmMessageGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20012>>;
    public esbmMessageGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20012>>;
    public esbmMessageGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20012>(`${this.basePath}/esbm/message`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get nesting
     * 
     * @param id The ID of the message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmMessageIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Message>;
    public esbmMessageIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Message>>;
    public esbmMessageIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Message>>;
    public esbmMessageIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmMessageIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Message>(`${this.basePath}/esbm/message/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get nestings
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param sequence_greater_than 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmNestingGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2004>;
    public esbmNestingGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2004>>;
    public esbmNestingGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2004>>;
    public esbmNestingGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }
        if (sequence_greater_than !== undefined && sequence_greater_than !== null) {
            queryParameters = queryParameters.set('sequence_greater_than', <any>sequence_greater_than);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2004>(`${this.basePath}/esbm/nesting`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get nesting
     * 
     * @param id The ID of the nesting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmNestingIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Nesting>;
    public esbmNestingIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nesting>>;
    public esbmNestingIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nesting>>;
    public esbmNestingIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmNestingIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Nesting>(`${this.basePath}/esbm/nesting/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post nesting
     * 
     * @param inputNesting The nesting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmNestingPost(inputNesting?: Nesting, observe?: 'body', reportProgress?: boolean): Observable<Nesting>;
    public esbmNestingPost(inputNesting?: Nesting, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Nesting>>;
    public esbmNestingPost(inputNesting?: Nesting, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Nesting>>;
    public esbmNestingPost(inputNesting?: Nesting, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Nesting>(`${this.basePath}/esbm/nesting`,
            inputNesting,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all performance targets for specific order
     * 
     * @param ordernumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmPerformanceTargetGet(ordernumber: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PerformanceTarget>>;
    public esbmPerformanceTargetGet(ordernumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PerformanceTarget>>>;
    public esbmPerformanceTargetGet(ordernumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PerformanceTarget>>>;
    public esbmPerformanceTargetGet(ordernumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ordernumber === null || ordernumber === undefined) {
            throw new Error('Required parameter ordernumber was null or undefined when calling esbmPerformanceTargetGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<PerformanceTarget>>(`${this.basePath}/esbm/performance_target`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post performance target
     * 
     * @param ordernumber 
     * @param performanceTarget A performance target for this production order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmPerformanceTargetPost(ordernumber: string, performanceTarget?: PerformanceTarget, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmPerformanceTargetPost(ordernumber: string, performanceTarget?: PerformanceTarget, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmPerformanceTargetPost(ordernumber: string, performanceTarget?: PerformanceTarget, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmPerformanceTargetPost(ordernumber: string, performanceTarget?: PerformanceTarget, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ordernumber === null || ordernumber === undefined) {
            throw new Error('Required parameter ordernumber was null or undefined when calling esbmPerformanceTargetPost.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/esbm/performance_target`,
            performanceTarget,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get companies
     * Get a paginated list of companies
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param updated_from 
     * @param updated_to 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmPersonGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20021>;
    public esbmPersonGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20021>>;
    public esbmPersonGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20021>>;
    public esbmPersonGet(limit?: number, offset?: number, order?: string, q?: string, updated_from?: Date, updated_to?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20021>(`${this.basePath}/esbm/person`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get company
     * 
     * @param id The ID of the company
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmPersonIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Person>;
    public esbmPersonIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Person>>;
    public esbmPersonIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Person>>;
    public esbmPersonIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmPersonIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Person>(`${this.basePath}/esbm/person/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get processing station
     * 
     * @param trumpfId 
     * @param cnc 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationGet(trumpfId?: string, cnc?: boolean, limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20011>;
    public esbmProcessingStationGet(trumpfId?: string, cnc?: boolean, limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20011>>;
    public esbmProcessingStationGet(trumpfId?: string, cnc?: boolean, limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20011>>;
    public esbmProcessingStationGet(trumpfId?: string, cnc?: boolean, limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trumpfId !== undefined && trumpfId !== null) {
            queryParameters = queryParameters.set('trumpfId', <any>trumpfId);
        }
        if (cnc !== undefined && cnc !== null) {
            queryParameters = queryParameters.set('cnc', <any>cnc);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20011>(`${this.basePath}/esbm/processing_station`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get the checklist for a particular machine for the current day
     * 
     * @param id The ID of the processing station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationIdDailyChecklistGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ReportedChecklist>>;
    public esbmProcessingStationIdDailyChecklistGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReportedChecklist>>>;
    public esbmProcessingStationIdDailyChecklistGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReportedChecklist>>>;
    public esbmProcessingStationIdDailyChecklistGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProcessingStationIdDailyChecklistGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ReportedChecklist>>(`${this.basePath}/esbm/processing_station/${encodeURIComponent(String(id))}/daily-checklist`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get processing station
     * 
     * @param id The ID of the processing station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ProcessingStation>;
    public esbmProcessingStationIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProcessingStation>>;
    public esbmProcessingStationIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProcessingStation>>;
    public esbmProcessingStationIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProcessingStationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProcessingStation>(`${this.basePath}/esbm/processing_station/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update &amp; get processing station sensor data
     * 
     * @param id The ID of the processing station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationIdSensorDataGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<SensorData>;
    public esbmProcessingStationIdSensorDataGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorData>>;
    public esbmProcessingStationIdSensorDataGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorData>>;
    public esbmProcessingStationIdSensorDataGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProcessingStationIdSensorDataGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<SensorData>(`${this.basePath}/esbm/processing_station/${encodeURIComponent(String(id))}/sensor-data`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put SensorData
     * 
     * @param id The ID of the processing station
     * @param inputSensorData The SensorData
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationIdSensorDataPut(id: string, inputSensorData?: SensorData, observe?: 'body', reportProgress?: boolean): Observable<SensorData>;
    public esbmProcessingStationIdSensorDataPut(id: string, inputSensorData?: SensorData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SensorData>>;
    public esbmProcessingStationIdSensorDataPut(id: string, inputSensorData?: SensorData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SensorData>>;
    public esbmProcessingStationIdSensorDataPut(id: string, inputSensorData?: SensorData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProcessingStationIdSensorDataPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<SensorData>(`${this.basePath}/esbm/processing_station/${encodeURIComponent(String(id))}/sensor-data`,
            inputSensorData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order stats
     * 
     * @param trumpfId 
     * @param limit 
     * @param offset 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationPerformanceGet(trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderPerformanceTotals>;
    public esbmProcessingStationPerformanceGet(trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderPerformanceTotals>>;
    public esbmProcessingStationPerformanceGet(trumpfId?: string, limit?: number, offset?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderPerformanceTotals>>;
    public esbmProcessingStationPerformanceGet(trumpfId?: string, limit?: number, offset?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (trumpfId !== undefined && trumpfId !== null) {
            queryParameters = queryParameters.set('trumpfId', <any>trumpfId);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrderPerformanceTotals>(`${this.basePath}/esbm/processing-station-performance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put ProcessingStation
     * 
     * @param inputProcessingStation The toolXref
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProcessingStationPut(inputProcessingStation?: ToolXref, observe?: 'body', reportProgress?: boolean): Observable<ToolXref>;
    public esbmProcessingStationPut(inputProcessingStation?: ToolXref, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolXref>>;
    public esbmProcessingStationPut(inputProcessingStation?: ToolXref, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolXref>>;
    public esbmProcessingStationPut(inputProcessingStation?: ToolXref, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ToolXref>(`${this.basePath}/esbm/processing_station`,
            inputProcessingStation,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get current production orders
     * 
     * @param processing_station Processing station name
     * @param employee_number employee number
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderCurrentGet(processing_station?: string, employee_number?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderCurrentGet(processing_station?: string, employee_number?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderCurrentGet(processing_station?: string, employee_number?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderCurrentGet(processing_station?: string, employee_number?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (processing_station !== undefined && processing_station !== null) {
            queryParameters = queryParameters.set('processing_station', <any>processing_station);
        }
        if (employee_number !== undefined && employee_number !== null) {
            queryParameters = queryParameters.set('employee_number', <any>employee_number);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrder>(`${this.basePath}/esbm/production_order/current`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production orders
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param ordernumber 
     * @param fabricationpartnumber 
     * @param updated_from 
     * @param updated_to 
     * @param processing_station 
     * @param status 
     * @param reportedStatus 
     * @param cnc 
     * @param production_number 
     * @param company_name 
     * @param fabrication_part_name 
     * @param quantity 
     * @param delivery_date 
     * @param material 
     * @param calculation_statuses 
     * @param sort_by Valid values are order_number, production_number, processing_station, company_name, fabrication_part_name, quantity, delivery_date, status, material
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, fabricationpartnumber?: string, updated_from?: Date, updated_to?: Date, processing_station?: string, status?: string, reportedStatus?: string, cnc?: boolean, production_number?: string, company_name?: string, fabrication_part_name?: string, quantity?: any, delivery_date?: string, material?: boolean, calculation_statuses?: string, sort_by?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderResponse>;
    public esbmProductionOrderGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, fabricationpartnumber?: string, updated_from?: Date, updated_to?: Date, processing_station?: string, status?: string, reportedStatus?: string, cnc?: boolean, production_number?: string, company_name?: string, fabrication_part_name?: string, quantity?: any, delivery_date?: string, material?: boolean, calculation_statuses?: string, sort_by?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderResponse>>;
    public esbmProductionOrderGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, fabricationpartnumber?: string, updated_from?: Date, updated_to?: Date, processing_station?: string, status?: string, reportedStatus?: string, cnc?: boolean, production_number?: string, company_name?: string, fabrication_part_name?: string, quantity?: any, delivery_date?: string, material?: boolean, calculation_statuses?: string, sort_by?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderResponse>>;
    public esbmProductionOrderGet(limit?: number, offset?: number, order?: string, q?: string, ordernumber?: string, fabricationpartnumber?: string, updated_from?: Date, updated_to?: Date, processing_station?: string, status?: string, reportedStatus?: string, cnc?: boolean, production_number?: string, company_name?: string, fabrication_part_name?: string, quantity?: any, delivery_date?: string, material?: boolean, calculation_statuses?: string, sort_by?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }
        if (fabricationpartnumber !== undefined && fabricationpartnumber !== null) {
            queryParameters = queryParameters.set('fabricationpartnumber', <any>fabricationpartnumber);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }
        if (processing_station !== undefined && processing_station !== null) {
            queryParameters = queryParameters.set('processing_station', <any>processing_station);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (reportedStatus !== undefined && reportedStatus !== null) {
            queryParameters = queryParameters.set('reportedStatus', <any>reportedStatus);
        }
        if (cnc !== undefined && cnc !== null) {
            queryParameters = queryParameters.set('cnc', <any>cnc);
        }
        if (production_number !== undefined && production_number !== null) {
            queryParameters = queryParameters.set('production_number', <any>production_number);
        }
        if (company_name !== undefined && company_name !== null) {
            queryParameters = queryParameters.set('company_name', <any>company_name);
        }
        if (fabrication_part_name !== undefined && fabrication_part_name !== null) {
            queryParameters = queryParameters.set('fabrication_part_name', <any>fabrication_part_name);
        }
        if (quantity !== undefined && quantity !== null) {
            queryParameters = queryParameters.set('quantity', <any>quantity);
        }
        if (delivery_date !== undefined && delivery_date !== null) {
            queryParameters = queryParameters.set('delivery_date', <any>delivery_date);
        }
        if (material !== undefined && material !== null) {
            queryParameters = queryParameters.set('material', <any>material);
        }
        if (calculation_statuses !== undefined && calculation_statuses !== null) {
            queryParameters = queryParameters.set('calculation_statuses', <any>calculation_statuses);
        }
        if (sort_by !== undefined && sort_by !== null) {
            queryParameters = queryParameters.set('sort_by', <any>sort_by);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrderResponse>(`${this.basePath}/esbm/production_order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Production order
     * 
     * @param id The ID of the production order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Pauze Production order
     * 
     * @param id The ID of the production order
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdPausePost(id: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdPausePost(id: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdPausePost(id: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdPausePost(id: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdPausePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/pause`,
            productionOrderActionData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets employee to the Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param employee_number The employeeNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost.');
        }

        if (employee_number === null || employee_number === undefined) {
            throw new Error('Required parameter employee_number was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/add_employee/${encodeURIComponent(String(employee_number))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/prepare`,
            productionOrderActionData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/queue`,
            productionOrderActionData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes employee to the Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param employee_number The employeeNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost(id: string, processingStationId: string, employee_number: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost.');
        }

        if (employee_number === null || employee_number === undefined) {
            throw new Error('Required parameter employee_number was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/remove_employee/${encodeURIComponent(String(employee_number))}`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param employee_number The employeeNumber
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdStartPost(id: string, processingStationId: string, employee_number?: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStartPost(id: string, processingStationId: string, employee_number?: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStartPost(id: string, processingStationId: string, employee_number?: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStartPost(id: string, processingStationId: string, employee_number?: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdStartPost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdStartPost.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (employee_number !== undefined && employee_number !== null) {
            queryParameters = queryParameters.set('employee_number', <any>employee_number);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/start`,
            productionOrderActionData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Stop Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdStopPost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdStopPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/stop`,
            productionOrderActionData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Start Production order
     * 
     * @param id The ID of the production order
     * @param processingStationId The ID of the processing station
     * @param productionOrderActionData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost(id: string, processingStationId: string, productionOrderActionData?: ProductionOrderActionData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost.');
        }

        if (processingStationId === null || processingStationId === undefined) {
            throw new Error('Required parameter processingStationId was null or undefined when calling esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/processing_station/${encodeURIComponent(String(processingStationId))}/unqueue`,
            productionOrderActionData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Order
     * 
     * @param id The ID of the production order
     * @param inputProductionOrder The ProductionOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdPut(id: string, inputProductionOrder?: ProductionOrder, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdPut(id: string, inputProductionOrder?: ProductionOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdPut(id: string, inputProductionOrder?: ProductionOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdPut(id: string, inputProductionOrder?: ProductionOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}`,
            inputProductionOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset Production order
     * 
     * @param id The ID of the production order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdResetPost(id: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdResetPost(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdResetPost(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdResetPost(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdResetPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/reset`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update start_machine_counter Production order
     * 
     * @param id The ID of the production order
     * @param start_machine_counter The start machine counter for this production order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderIdStartMachineCounterPost(id: string, start_machine_counter?: StartMachineCounter, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderIdStartMachineCounterPost(id: string, start_machine_counter?: StartMachineCounter, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderIdStartMachineCounterPost(id: string, start_machine_counter?: StartMachineCounter, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderIdStartMachineCounterPost(id: string, start_machine_counter?: StartMachineCounter, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderIdStartMachineCounterPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order/${encodeURIComponent(String(id))}/start_machine_counter`,
            start_machine_counter,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post production order
     * 
     * @param inputProductionOrder The production order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderPost(inputProductionOrder?: ProductionOrder, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrder>;
    public esbmProductionOrderPost(inputProductionOrder?: ProductionOrder, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrder>>;
    public esbmProductionOrderPost(inputProductionOrder?: ProductionOrder, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrder>>;
    public esbmProductionOrderPost(inputProductionOrder?: ProductionOrder, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrder>(`${this.basePath}/esbm/production_order`,
            inputProductionOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get total produced
     * 
     * @param tool_xref The id of the tool xref
     * @param from_date The date from which to start counting
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderProcessedPartsGet(tool_xref: string, from_date?: string, observe?: 'body', reportProgress?: boolean): Observable<ProcessingStationTotals>;
    public esbmProductionOrderProcessedPartsGet(tool_xref: string, from_date?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProcessingStationTotals>>;
    public esbmProductionOrderProcessedPartsGet(tool_xref: string, from_date?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProcessingStationTotals>>;
    public esbmProductionOrderProcessedPartsGet(tool_xref: string, from_date?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (tool_xref === null || tool_xref === undefined) {
            throw new Error('Required parameter tool_xref was null or undefined when calling esbmProductionOrderProcessedPartsGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tool_xref !== undefined && tool_xref !== null) {
            queryParameters = queryParameters.set('tool_xref', <any>tool_xref);
        }
        if (from_date !== undefined && from_date !== null) {
            queryParameters = queryParameters.set('from_date', <any>from_date);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProcessingStationTotals>(`${this.basePath}/esbm/production_order/processed_parts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order reports
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param processing_station 
     * @param ordernumber 
     * @param updated_from 
     * @param updated_to 
     * @param sequence_greater_than 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderReportGet(limit?: number, offset?: number, order?: string, q?: string, processing_station?: Array<string>, ordernumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2009>;
    public esbmProductionOrderReportGet(limit?: number, offset?: number, order?: string, q?: string, processing_station?: Array<string>, ordernumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2009>>;
    public esbmProductionOrderReportGet(limit?: number, offset?: number, order?: string, q?: string, processing_station?: Array<string>, ordernumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2009>>;
    public esbmProductionOrderReportGet(limit?: number, offset?: number, order?: string, q?: string, processing_station?: Array<string>, ordernumber?: string, updated_from?: Date, updated_to?: Date, sequence_greater_than?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (processing_station) {
            queryParameters = queryParameters.set('processing_station', processing_station.join(COLLECTION_FORMATS['csv']));
        }
        if (ordernumber !== undefined && ordernumber !== null) {
            queryParameters = queryParameters.set('ordernumber', <any>ordernumber);
        }
        if (updated_from !== undefined && updated_from !== null) {
            queryParameters = queryParameters.set('updated_from', <any>updated_from.toISOString());
        }
        if (updated_to !== undefined && updated_to !== null) {
            queryParameters = queryParameters.set('updated_to', <any>updated_to.toISOString());
        }
        if (sequence_greater_than !== undefined && sequence_greater_than !== null) {
            queryParameters = queryParameters.set('sequence_greater_than', <any>sequence_greater_than);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2009>(`${this.basePath}/esbm/production_order_report`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order report
     * 
     * @param id The ID of the production_order_report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderReportIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderReport>;
    public esbmProductionOrderReportIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderReport>>;
    public esbmProductionOrderReportIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderReport>>;
    public esbmProductionOrderReportIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProductionOrderReportIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrderReport>(`${this.basePath}/esbm/production_order_report/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order report
     * 
     * @param orderNumber The ID of the production_order_report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderReportOrderNumberTotalsGet(orderNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ProducedAmountDetails>;
    public esbmProductionOrderReportOrderNumberTotalsGet(orderNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProducedAmountDetails>>;
    public esbmProductionOrderReportOrderNumberTotalsGet(orderNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProducedAmountDetails>>;
    public esbmProductionOrderReportOrderNumberTotalsGet(orderNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (orderNumber === null || orderNumber === undefined) {
            throw new Error('Required parameter orderNumber was null or undefined when calling esbmProductionOrderReportOrderNumberTotalsGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProducedAmountDetails>(`${this.basePath}/esbm/production_order_report/${encodeURIComponent(String(orderNumber))}/totals`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post production order report
     * 
     * @param inputProductionOrderReport The production order report
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderReportPost(inputProductionOrderReport?: ProductionOrderReport, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderReport>;
    public esbmProductionOrderReportPost(inputProductionOrderReport?: ProductionOrderReport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderReport>>;
    public esbmProductionOrderReportPost(inputProductionOrderReport?: ProductionOrderReport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderReport>>;
    public esbmProductionOrderReportPost(inputProductionOrderReport?: ProductionOrderReport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ProductionOrderReport>(`${this.basePath}/esbm/production_order_report`,
            inputProductionOrderReport,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get production order stats
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderStatsGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ProductionOrderStats>>;
    public esbmProductionOrderStatsGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProductionOrderStats>>>;
    public esbmProductionOrderStatsGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProductionOrderStats>>>;
    public esbmProductionOrderStatsGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ProductionOrderStats>>(`${this.basePath}/esbm/production-order-stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get projects
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param projectnumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProjectGet(limit?: number, offset?: number, order?: string, q?: string, projectnumber?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20017>;
    public esbmProjectGet(limit?: number, offset?: number, order?: string, q?: string, projectnumber?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20017>>;
    public esbmProjectGet(limit?: number, offset?: number, order?: string, q?: string, projectnumber?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20017>>;
    public esbmProjectGet(limit?: number, offset?: number, order?: string, q?: string, projectnumber?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (projectnumber !== undefined && projectnumber !== null) {
            queryParameters = queryParameters.set('projectnumber', <any>projectnumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20017>(`${this.basePath}/esbm/project`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get project
     * 
     * @param id The ID of the project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProjectIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Project>;
    public esbmProjectIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Project>>;
    public esbmProjectIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Project>>;
    public esbmProjectIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmProjectIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Project>(`${this.basePath}/esbm/project/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all rejectioncodes
     * 
     * @param production 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmRejectioncodesGet(production?: boolean, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2005>;
    public esbmRejectioncodesGet(production?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2005>>;
    public esbmRejectioncodesGet(production?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2005>>;
    public esbmRejectioncodesGet(production?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (production !== undefined && production !== null) {
            queryParameters = queryParameters.set('production', <any>production);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2005>(`${this.basePath}/esbm/rejectioncodes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get nesting
     * 
     * @param id The ID of the reported checklist item
     * @param status 
     * @param employeeNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmReportedChecklistItemIdStatusPost(id: string, status: string, employeeNumber: string, observe?: 'body', reportProgress?: boolean): Observable<ReportedChecklist>;
    public esbmReportedChecklistItemIdStatusPost(id: string, status: string, employeeNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReportedChecklist>>;
    public esbmReportedChecklistItemIdStatusPost(id: string, status: string, employeeNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReportedChecklist>>;
    public esbmReportedChecklistItemIdStatusPost(id: string, status: string, employeeNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmReportedChecklistItemIdStatusPost.');
        }

        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling esbmReportedChecklistItemIdStatusPost.');
        }

        if (employeeNumber === null || employeeNumber === undefined) {
            throw new Error('Required parameter employeeNumber was null or undefined when calling esbmReportedChecklistItemIdStatusPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (employeeNumber !== undefined && employeeNumber !== null) {
            queryParameters = queryParameters.set('employeeNumber', <any>employeeNumber);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ReportedChecklist>(`${this.basePath}/esbm/reported-checklist-item/${encodeURIComponent(String(id))}/status`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Stock Locations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockLocationGet(observe?: 'body', reportProgress?: boolean): Observable<Array<StockLocation>>;
    public esbmStockLocationGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StockLocation>>>;
    public esbmStockLocationGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StockLocation>>>;
    public esbmStockLocationGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StockLocation>>(`${this.basePath}/esbm/stock-location`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the Stock Location
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockLocationIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<StockLocation>;
    public esbmStockLocationIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockLocation>>;
    public esbmStockLocationIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockLocation>>;
    public esbmStockLocationIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmStockLocationIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StockLocation>(`${this.basePath}/esbm/stock-location/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get latest entity
     * 
     * @param productionOrderId The ID of the Current Production Order
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestCurrentGet(productionOrderId: string, observe?: 'body', reportProgress?: boolean): Observable<StockRequest>;
    public esbmStockRequestCurrentGet(productionOrderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockRequest>>;
    public esbmStockRequestCurrentGet(productionOrderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockRequest>>;
    public esbmStockRequestCurrentGet(productionOrderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionOrderId === null || productionOrderId === undefined) {
            throw new Error('Required parameter productionOrderId was null or undefined when calling esbmStockRequestCurrentGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StockRequest>(`${this.basePath}/esbm/stock-request/current`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Stock Request
     * 
     * @param productionOrderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestGet(productionOrderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StockRequest>>;
    public esbmStockRequestGet(productionOrderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StockRequest>>>;
    public esbmStockRequestGet(productionOrderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StockRequest>>>;
    public esbmStockRequestGet(productionOrderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrderId !== undefined && productionOrderId !== null) {
            queryParameters = queryParameters.set('productionOrderId', <any>productionOrderId);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StockRequest>>(`${this.basePath}/esbm/stock-request`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get allocated for stock request
     * 
     * @param id The ID of the Stock Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestIdAllocatedGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Array<StockAllocation>>;
    public esbmStockRequestIdAllocatedGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StockAllocation>>>;
    public esbmStockRequestIdAllocatedGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StockAllocation>>>;
    public esbmStockRequestIdAllocatedGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmStockRequestIdAllocatedGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<StockAllocation>>(`${this.basePath}/esbm/stock-request/${encodeURIComponent(String(id))}/allocated`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete Stock Request
     * 
     * @param id The ID of the Stock Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestIdDelete(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmStockRequestIdDelete(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmStockRequestIdDelete(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmStockRequestIdDelete(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmStockRequestIdDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/esbm/stock-request/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get entity
     * 
     * @param id The ID of the Stock Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<StockRequest>;
    public esbmStockRequestIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockRequest>>;
    public esbmStockRequestIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockRequest>>;
    public esbmStockRequestIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmStockRequestIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<StockRequest>(`${this.basePath}/esbm/stock-request/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put Stock Request
     * 
     * @param id The ID of the Stock Request
     * @param inputStockRequest The Measurement Sample
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestIdPut(id: string, inputStockRequest?: StockRequest, observe?: 'body', reportProgress?: boolean): Observable<StockRequest>;
    public esbmStockRequestIdPut(id: string, inputStockRequest?: StockRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockRequest>>;
    public esbmStockRequestIdPut(id: string, inputStockRequest?: StockRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockRequest>>;
    public esbmStockRequestIdPut(id: string, inputStockRequest?: StockRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmStockRequestIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<StockRequest>(`${this.basePath}/esbm/stock-request/${encodeURIComponent(String(id))}`,
            inputStockRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post Stock Request
     * 
     * @param inputStockRequest The Stock Request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmStockRequestPost(inputStockRequest?: StockRequest, observe?: 'body', reportProgress?: boolean): Observable<StockRequest>;
    public esbmStockRequestPost(inputStockRequest?: StockRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockRequest>>;
    public esbmStockRequestPost(inputStockRequest?: StockRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockRequest>>;
    public esbmStockRequestPost(inputStockRequest?: StockRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<StockRequest>(`${this.basePath}/esbm/stock-request`,
            inputStockRequest,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tools
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2006>;
    public esbmToolGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2006>>;
    public esbmToolGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2006>>;
    public esbmToolGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2006>(`${this.basePath}/esbm/tool`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool
     * 
     * @param id The ID of the tool
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<Tool>;
    public esbmToolIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tool>>;
    public esbmToolIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tool>>;
    public esbmToolIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Tool>(`${this.basePath}/esbm/tool/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put tool
     * 
     * @param id The ID of the tool
     * @param inputTool The tool
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolIdPut(id: string, inputTool?: Tool, observe?: 'body', reportProgress?: boolean): Observable<Tool>;
    public esbmToolIdPut(id: string, inputTool?: Tool, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tool>>;
    public esbmToolIdPut(id: string, inputTool?: Tool, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tool>>;
    public esbmToolIdPut(id: string, inputTool?: Tool, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<Tool>(`${this.basePath}/esbm/tool/${encodeURIComponent(String(id))}`,
            inputTool,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool positions
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2007>;
    public esbmToolPositionGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2007>>;
    public esbmToolPositionGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2007>>;
    public esbmToolPositionGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2007>(`${this.basePath}/esbm/tool_position`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool position groups
     * 
     * @param limit 
     * @param offset 
     * @param order 
     * @param q 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionGroupGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse2008>;
    public esbmToolPositionGroupGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse2008>>;
    public esbmToolPositionGroupGet(limit?: number, offset?: number, order?: string, q?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse2008>>;
    public esbmToolPositionGroupGet(limit?: number, offset?: number, order?: string, q?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse2008>(`${this.basePath}/esbm/tool_position_group`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool position group
     * 
     * @param id The ID of the tool position group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionGroupIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ToolPositionGroup>;
    public esbmToolPositionGroupIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPositionGroup>>;
    public esbmToolPositionGroupIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPositionGroup>>;
    public esbmToolPositionGroupIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolPositionGroupIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ToolPositionGroup>(`${this.basePath}/esbm/tool_position_group/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put toolPositionGroup
     * 
     * @param id The ID of the tool position group
     * @param inputToolPositionGroup The toolPositionGroup
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionGroupIdPut(id: string, inputToolPositionGroup?: ToolPositionGroup, observe?: 'body', reportProgress?: boolean): Observable<ToolPositionGroup>;
    public esbmToolPositionGroupIdPut(id: string, inputToolPositionGroup?: ToolPositionGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPositionGroup>>;
    public esbmToolPositionGroupIdPut(id: string, inputToolPositionGroup?: ToolPositionGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPositionGroup>>;
    public esbmToolPositionGroupIdPut(id: string, inputToolPositionGroup?: ToolPositionGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolPositionGroupIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ToolPositionGroup>(`${this.basePath}/esbm/tool_position_group/${encodeURIComponent(String(id))}`,
            inputToolPositionGroup,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post toolPositionGroup
     * 
     * @param inputToolPositionGroup The tool position group
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionGroupPost(inputToolPositionGroup?: ToolPositionGroup, observe?: 'body', reportProgress?: boolean): Observable<ToolPositionGroup>;
    public esbmToolPositionGroupPost(inputToolPositionGroup?: ToolPositionGroup, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPositionGroup>>;
    public esbmToolPositionGroupPost(inputToolPositionGroup?: ToolPositionGroup, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPositionGroup>>;
    public esbmToolPositionGroupPost(inputToolPositionGroup?: ToolPositionGroup, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ToolPositionGroup>(`${this.basePath}/esbm/tool_position_group`,
            inputToolPositionGroup,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool position
     * 
     * @param id The ID of the tool position
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ToolPosition>;
    public esbmToolPositionIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPosition>>;
    public esbmToolPositionIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPosition>>;
    public esbmToolPositionIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolPositionIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ToolPosition>(`${this.basePath}/esbm/tool_position/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put toolPosition
     * 
     * @param id The ID of the tool position
     * @param inputToolPosition The toolPosition
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionIdPut(id: string, inputToolPosition?: ToolPosition, observe?: 'body', reportProgress?: boolean): Observable<ToolPosition>;
    public esbmToolPositionIdPut(id: string, inputToolPosition?: ToolPosition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPosition>>;
    public esbmToolPositionIdPut(id: string, inputToolPosition?: ToolPosition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPosition>>;
    public esbmToolPositionIdPut(id: string, inputToolPosition?: ToolPosition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolPositionIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ToolPosition>(`${this.basePath}/esbm/tool_position/${encodeURIComponent(String(id))}`,
            inputToolPosition,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post toolPosition
     * 
     * @param inputToolPosition The tool position
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPositionPost(inputToolPosition?: ToolPosition, observe?: 'body', reportProgress?: boolean): Observable<ToolPosition>;
    public esbmToolPositionPost(inputToolPosition?: ToolPosition, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolPosition>>;
    public esbmToolPositionPost(inputToolPosition?: ToolPosition, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolPosition>>;
    public esbmToolPositionPost(inputToolPosition?: ToolPosition, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ToolPosition>(`${this.basePath}/esbm/tool_position`,
            inputToolPosition,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post tool
     * 
     * @param inputTool The tool
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolPost(inputTool?: Tool, observe?: 'body', reportProgress?: boolean): Observable<Tool>;
    public esbmToolPost(inputTool?: Tool, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Tool>>;
    public esbmToolPost(inputTool?: Tool, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Tool>>;
    public esbmToolPost(inputTool?: Tool, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Tool>(`${this.basePath}/esbm/tool`,
            inputTool,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all tool replace
     * 
     * @param productionOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolReplaceGet(productionOrder: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ToolReplace>>;
    public esbmToolReplaceGet(productionOrder: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ToolReplace>>>;
    public esbmToolReplaceGet(productionOrder: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ToolReplace>>>;
    public esbmToolReplaceGet(productionOrder: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionOrder === null || productionOrder === undefined) {
            throw new Error('Required parameter productionOrder was null or undefined when calling esbmToolReplaceGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrder !== undefined && productionOrder !== null) {
            queryParameters = queryParameters.set('productionOrder', <any>productionOrder);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<ToolReplace>>(`${this.basePath}/esbm/tool_replace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool replace
     * 
     * @param id The ID of the tool replace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolReplaceIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ToolReplace>;
    public esbmToolReplaceIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolReplace>>;
    public esbmToolReplaceIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolReplace>>;
    public esbmToolReplaceIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolReplaceIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ToolReplace>(`${this.basePath}/esbm/tool_replace/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put toolReplace
     * 
     * @param id The ID of the tool replace
     * @param inputToolReplace The toolReplaec
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolReplaceIdPut(id: string, inputToolReplace?: ToolReplace, observe?: 'body', reportProgress?: boolean): Observable<ToolReplace>;
    public esbmToolReplaceIdPut(id: string, inputToolReplace?: ToolReplace, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolReplace>>;
    public esbmToolReplaceIdPut(id: string, inputToolReplace?: ToolReplace, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolReplace>>;
    public esbmToolReplaceIdPut(id: string, inputToolReplace?: ToolReplace, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolReplaceIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ToolReplace>(`${this.basePath}/esbm/tool_replace/${encodeURIComponent(String(id))}`,
            inputToolReplace,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get last tool replace
     * 
     * @param productionOrder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolReplaceLastGet(productionOrder: string, observe?: 'body', reportProgress?: boolean): Observable<ToolReplace>;
    public esbmToolReplaceLastGet(productionOrder: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolReplace>>;
    public esbmToolReplaceLastGet(productionOrder: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolReplace>>;
    public esbmToolReplaceLastGet(productionOrder: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (productionOrder === null || productionOrder === undefined) {
            throw new Error('Required parameter productionOrder was null or undefined when calling esbmToolReplaceLastGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (productionOrder !== undefined && productionOrder !== null) {
            queryParameters = queryParameters.set('productionOrder', <any>productionOrder);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ToolReplace>(`${this.basePath}/esbm/tool_replace/last`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post toolReplace
     * 
     * @param inputToolReplace The tool replace
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolReplacePost(inputToolReplace?: ToolReplace, observe?: 'body', reportProgress?: boolean): Observable<ToolReplace>;
    public esbmToolReplacePost(inputToolReplace?: ToolReplace, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolReplace>>;
    public esbmToolReplacePost(inputToolReplace?: ToolReplace, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolReplace>>;
    public esbmToolReplacePost(inputToolReplace?: ToolReplace, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ToolReplace>(`${this.basePath}/esbm/tool_replace`,
            inputToolReplace,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool xrefs
     * 
     * @param processing_station The id of the processing station
     * @param production_order The production order id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolXrefGet(processing_station: string, production_order: string, observe?: 'body', reportProgress?: boolean): Observable<ToolXrefResponse>;
    public esbmToolXrefGet(processing_station: string, production_order: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolXrefResponse>>;
    public esbmToolXrefGet(processing_station: string, production_order: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolXrefResponse>>;
    public esbmToolXrefGet(processing_station: string, production_order: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processing_station === null || processing_station === undefined) {
            throw new Error('Required parameter processing_station was null or undefined when calling esbmToolXrefGet.');
        }

        if (production_order === null || production_order === undefined) {
            throw new Error('Required parameter production_order was null or undefined when calling esbmToolXrefGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (processing_station !== undefined && processing_station !== null) {
            queryParameters = queryParameters.set('processing_station', <any>processing_station);
        }
        if (production_order !== undefined && production_order !== null) {
            queryParameters = queryParameters.set('production_order', <any>production_order);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ToolXrefResponse>(`${this.basePath}/esbm/tool_xref`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put toolXref
     * 
     * @param id The ID of the tool xref
     * @param inputToolXref The toolXref
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolXrefIdPut(id: string, inputToolXref?: ToolXref, observe?: 'body', reportProgress?: boolean): Observable<ToolXref>;
    public esbmToolXrefIdPut(id: string, inputToolXref?: ToolXref, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolXref>>;
    public esbmToolXrefIdPut(id: string, inputToolXref?: ToolXref, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolXref>>;
    public esbmToolXrefIdPut(id: string, inputToolXref?: ToolXref, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmToolXrefIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ToolXref>(`${this.basePath}/esbm/tool_xref/${encodeURIComponent(String(id))}`,
            inputToolXref,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post toolXref
     * 
     * @param inputToolXref The tool xref
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolXrefPost(inputToolXref?: ToolXref, observe?: 'body', reportProgress?: boolean): Observable<ToolXref>;
    public esbmToolXrefPost(inputToolXref?: ToolXref, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ToolXref>>;
    public esbmToolXrefPost(inputToolXref?: ToolXref, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ToolXref>>;
    public esbmToolXrefPost(inputToolXref?: ToolXref, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ToolXref>(`${this.basePath}/esbm/tool_xref`,
            inputToolXref,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Put toolXref
     * 
     * @param current_production_order The id of the current productionorder
     * @param reference_production_order The id of the productionorder to take the tools from
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolXrefReplaceSimilarPost(current_production_order: string, reference_production_order: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public esbmToolXrefReplaceSimilarPost(current_production_order: string, reference_production_order: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public esbmToolXrefReplaceSimilarPost(current_production_order: string, reference_production_order: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public esbmToolXrefReplaceSimilarPost(current_production_order: string, reference_production_order: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (current_production_order === null || current_production_order === undefined) {
            throw new Error('Required parameter current_production_order was null or undefined when calling esbmToolXrefReplaceSimilarPost.');
        }

        if (reference_production_order === null || reference_production_order === undefined) {
            throw new Error('Required parameter reference_production_order was null or undefined when calling esbmToolXrefReplaceSimilarPost.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (current_production_order !== undefined && current_production_order !== null) {
            queryParameters = queryParameters.set('current_production_order', <any>current_production_order);
        }
        if (reference_production_order !== undefined && reference_production_order !== null) {
            queryParameters = queryParameters.set('reference_production_order', <any>reference_production_order);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<any>(`${this.basePath}/esbm/tool_xref/replace_similar`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get tool xrefs
     * 
     * @param production_order_id The production order id
     * @param fabrication_part_id The fabrication part id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(production_order_id: string, fabrication_part_id: string, observe?: 'body', reportProgress?: boolean): Observable<InlineResponse20019>;
    public esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(production_order_id: string, fabrication_part_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InlineResponse20019>>;
    public esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(production_order_id: string, fabrication_part_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InlineResponse20019>>;
    public esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(production_order_id: string, fabrication_part_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (production_order_id === null || production_order_id === undefined) {
            throw new Error('Required parameter production_order_id was null or undefined when calling esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet.');
        }

        if (fabrication_part_id === null || fabrication_part_id === undefined) {
            throw new Error('Required parameter fabrication_part_id was null or undefined when calling esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<InlineResponse20019>(`${this.basePath}/esbm/tool_xref/similar/${encodeURIComponent(String(production_order_id))}/${encodeURIComponent(String(fabrication_part_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get worklogs
     * 
     * @param processingStation The id of the processing station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmWorklogGet(processingStation: string, observe?: 'body', reportProgress?: boolean): Observable<Array<WorkLog>>;
    public esbmWorklogGet(processingStation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<WorkLog>>>;
    public esbmWorklogGet(processingStation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<WorkLog>>>;
    public esbmWorklogGet(processingStation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processingStation === null || processingStation === undefined) {
            throw new Error('Required parameter processingStation was null or undefined when calling esbmWorklogGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (processingStation !== undefined && processingStation !== null) {
            queryParameters = queryParameters.set('processingStation', <any>processingStation);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<WorkLog>>(`${this.basePath}/esbm/worklog`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get worklog
     * 
     * @param id The ID of the worklog
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmWorklogIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<WorkLog>;
    public esbmWorklogIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkLog>>;
    public esbmWorklogIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkLog>>;
    public esbmWorklogIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmWorklogIdGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<WorkLog>(`${this.basePath}/esbm/worklog/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post worklog
     * 
     * @param id The ID of the worklog
     * @param inputWorklog The worklog
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmWorklogIdPut(id: string, inputWorklog?: WorkLog, observe?: 'body', reportProgress?: boolean): Observable<WorkLog>;
    public esbmWorklogIdPut(id: string, inputWorklog?: WorkLog, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkLog>>;
    public esbmWorklogIdPut(id: string, inputWorklog?: WorkLog, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkLog>>;
    public esbmWorklogIdPut(id: string, inputWorklog?: WorkLog, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling esbmWorklogIdPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<WorkLog>(`${this.basePath}/esbm/worklog/${encodeURIComponent(String(id))}`,
            inputWorklog,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Post worklog
     * 
     * @param inputWorklog The worklog
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmWorklogPost(inputWorklog?: WorkLog, observe?: 'body', reportProgress?: boolean): Observable<WorkLog>;
    public esbmWorklogPost(inputWorklog?: WorkLog, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<WorkLog>>;
    public esbmWorklogPost(inputWorklog?: WorkLog, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<WorkLog>>;
    public esbmWorklogPost(inputWorklog?: WorkLog, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<WorkLog>(`${this.basePath}/esbm/worklog`,
            inputWorklog,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get worklog totals
     * 
     * @param processingStation The id of the processing station
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmWorklogTotalsGet(processingStation: string, observe?: 'body', reportProgress?: boolean): Observable<ProcessingStationTotals>;
    public esbmWorklogTotalsGet(processingStation: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProcessingStationTotals>>;
    public esbmWorklogTotalsGet(processingStation: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProcessingStationTotals>>;
    public esbmWorklogTotalsGet(processingStation: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (processingStation === null || processingStation === undefined) {
            throw new Error('Required parameter processingStation was null or undefined when calling esbmWorklogTotalsGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (processingStation !== undefined && processingStation !== null) {
            queryParameters = queryParameters.set('processingStation', <any>processingStation);
        }

        let headers = this.defaultHeaders;

        // authentication (esb_auth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProcessingStationTotals>(`${this.basePath}/esbm/worklog/totals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
