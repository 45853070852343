<div class="container-fluid main-header sticky-top shadow">
    <div class="row h-100">
        <div class="col-xl-2 my-auto d-lg-none d-xl-block">
            <img class="main-header__logo" [src]="logoLocation"/>
        </div>
        <div class="col-12 col-xl-10 h-100">
            <div class="d-flex flex-row justify-content-end h-100">

                <div class="align-self-center px-4">
                    <span i18n>Pagina:</span><br>
                    <span class="font-weight-bold text-primary">{{ title }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
