import { Component, OnInit } from '@angular/core';
import { VERSION } from 'environments/version';

@Component({
    selector: 'betech-update-notification',
    templateUrl: './update-notification.component.html',
})
export class UpdateNotificationComponent implements OnInit {
    public showNotification = false;
    public appVersion: string;
    public today;

    constructor() {}

    public ngOnInit() {
        // remove after first development build. Just to verify if VERSION is created during codeship build
        console.log(VERSION);
        this.appVersion = VERSION.tag;
        const knownTag = localStorage.getItem('buildTag');
        if (knownTag != null && knownTag != '' && VERSION.buildTag != knownTag) {
            this.showNotification = true;
            this.today = new Date();
        }
        localStorage.setItem('buildTag', VERSION.buildTag);
    }

    public acknowledgeScreen(): void {
        this.showNotification = false;
    }
}
