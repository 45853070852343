import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    ProductionOrderResponse,
} from '../../swagger-client';
import { merge, Subscription } from 'rxjs';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { MediaModalComponent } from '../../shared/media-modal/media-modal.component';
import { environment } from '../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TokenService } from '../../services/token/token.service';
import { StartPrepareOrderModalComponent } from './start-prepare-order-modal/start-prepare-order-modal.component';
import { SensorDataService } from '../../services/sensor-data/sensor-data.service';
import { ActiveEntityMediatorService } from '../../services/active-entity-mediator/active-entity-mediator.service';
import { ProductionOrderService } from '../../services/production-order/production-order.service';
import { ProductionOrderFilter } from '../../services/production-order/production-order.filter';
import { ModalTypes } from './start-prepare-order-modal/start-prepare-order-modal-types.enum';
import { ConfirmationService } from '../../services/confirmation/confirmation.service';
import { DialogReturnType } from '../../shared/confirmation-dialog/dialog-return-type.enum';
import ReportedStatusEnum = ProductionOrder.ReportedStatusEnum;

@Component({
    selector: 'betech-betech-order-list',
    templateUrl: './order-list.component.html',
})
export class OrderListComponent implements OnInit, OnDestroy {
    public orderList: ProductionOrder[] = [];
    public orderListBackup: ProductionOrder[] = [];

    public filters: {
        processingStation: ProcessingStation;
        reportedStatus: ReportedStatusEnum;
        search: string;
    } = {
        processingStation: null,
        reportedStatus: ReportedStatusEnum.NOTSTARTED,
        search: null,
    };
    public loading: boolean;
    ColumnMode = ColumnMode;

    public activeMachine: ProcessingStation;
    public activeEmployee: Employee;

    private subs: Subscription[] = [];
    public currentPO: ProductionOrder;

    public limit: number = 20;
    public offset: number = 1;
    public total: number = null;

    public columnMode = ColumnMode.force;

    // templates for customizable columns in datatable
    public productionNumberTmpl: TemplateRef<any>;
    @ViewChild('materialTmpl', { static: true }) public materialTmpl: TemplateRef<any>;

    public columns = [];

    public statusEnum = ReportedStatusEnum;

    public bsModalRef: BsModalRef;
    public modalRef: BsModalRef;

    public tableFilter: any = {};
    public sorter = { sort_dir: 'ASC', sort_by: 'start_date' };

    public companyName: string;

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private modalService: BsModalService,
        private tokenService: TokenService,
        private sensorDataService: SensorDataService,
        private _productionOrderService: ProductionOrderService,
        private _confirmationService: ConfirmationService,
    ) {}

    public ngOnInit() {
        this.companyName = environment.config.companyName;

        this.columns = [
            { name: 'Week', prop: 'start_date' },
            {
                name: 'Productienummer',
                prop: 'production_number',
                cellTemplate: this.productionNumberTmpl,
            },
            { name: 'Bewerking', prop: 'processing_station.name' },
            { name: 'Machine', prop: 'processing_station.trumpf_id' },
            { name: 'Bedrijf', prop: 'company' },
            {
                name: 'Product naam',
                prop: 'fabrication_part_name',
            },
            { name: 'Aantal', prop: 'quantity' },
            {
                name: 'Leverdatum',
                prop: 'due_date',
            },
            {
                name: 'Combi',
                prop: 'combi_id',
            },
            {
                name: 'Materiaal?',
                prop: 'material_available',
                cellTemplate: this.materialTmpl,
            },
            { name: 'Status', prop: 'reported_status' },
        ];

        this.subs.push(
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                    this.filters.processingStation = this.activeMachine;
                },
            ),
            this.activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    this.currentPO = currentPO;
                },
            ),
            this.activeEntityMediatorService.activeEmployee$.subscribe(
                (employee: Employee) => (this.activeEmployee = employee),
            ),
            this._productionOrderService.productionOrders$.subscribe(
                (response: ProductionOrderResponse) => {
                    this.total = response.total;
                    this.orderProductionOrderList(response.data);
                    this.loading = false;
                },
            ),
            merge(
                this.activeEntityMediatorService.activeEmployee$,
                this.activeEntityMediatorService.activeMachine$,
            ).subscribe(() => {
                this.orderProductionOrderList(this.orderList);
                this.fetch();
            }),
        );
    }

    public fetch(offset: number = null) {
        if (this.loading) {
            return;
        }

        this.loading = true;
        const trumpfIdProcessingStation = this.filters.processingStation
            ? this.filters.processingStation.trumpf_id.replace(/X/g, '\\d')
            : null;

        const productionOrderFilter: ProductionOrderFilter = {
            limit: this.limit,
            processingStation: trumpfIdProcessingStation,
            sortDir: this.sorter.sort_dir,
            sortBy: this.sorter.sort_by,
            productionNumber: this.tableFilter.production_number,
            company: this.tableFilter.company,
            fabricationPartName: this.tableFilter.fabrication_part_name,
            quantity: this.tableFilter.quantity,
            deliveryDate: this.tableFilter.due_date,
            materialAvailable: this.tableFilter.material_available,
            search: this.filters.search,
            reportedStatus: this.filters.reportedStatus,
        };

        this._productionOrderService
            .fetchProductionOrders(productionOrderFilter, offset)
            .subscribe();
    }

    private orderProductionOrderList(orderlist: ProductionOrder[]): void {
        this.orderListBackup = [...orderlist];

        if (this.activeMachine == null) {
            this.orderList = [...orderlist];
            return;
        }

        let tempList = [...orderlist];

        // TODO backend should be responsible for ordering correctly!
        const currentOrder = this.currentPO;
        const nextOrders = this.activeMachine.next_production_orders;

        let i = nextOrders.length - 1;
        while (i >= 0) {
            tempList = this.setProductionOrderAtTopOfList(nextOrders[i], tempList);
            i--;
        }

        if (currentOrder) {
            tempList = this.setProductionOrderAtTopOfList(currentOrder, tempList);
        }

        // Only add to orderlist at the end of the function to prevent multiple rerenders.
        this.orderList = [...tempList];
    }

    private setProductionOrderAtTopOfList(
        productionOrder: ProductionOrder,
        list: Array<ProductionOrder>,
    ): Array<ProductionOrder> {
        const i = list.findIndex(o => o.id === productionOrder.id);

        if (i == -1) {
            list = [productionOrder, ...list];
        } else {
            const tempOrder = list[i];
            list.splice(i, 1);
            list = [tempOrder, ...list];
        }

        return list;
    }

    public next() {
        this.offset++;
        this.fetch(this.offset);
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public changeSearchValue(status) {
        this.filters.search = status;
        this.filters.reportedStatus = null;
        this.filters.processingStation = null;

        this.fetch();
    }

    public changeStatus(status) {
        this.filters.reportedStatus = status;
        this.fetch();
    }

    public changeProcessingStation(station) {
        this.filters.processingStation = station;
        this.fetch();
    }

    public showDrawing(order: ProductionOrder) {
        if (!order.drawing_image_url) {
            return;
        }

        // Show modal
        this.bsModalRef = this.modalService.show(MediaModalComponent, {
            class: 'mw-100 m-0 h-auto',
        });
        this.bsModalRef.content.setModalRef(this.bsModalRef);

        const accessToken: string = this.tokenService.getToken().access_token;
        this.bsModalRef.content.showMedia(
            order,
            `${environment.apiUrl}/api${order.drawing_image_url}?access_token=${accessToken}`,
        );
    }

    public cancelOrder(order: ProductionOrder) {
        this._productionOrderService.resetOrder(order).subscribe();
    }

    public stopOrder(order: ProductionOrder) {
        let warningMessage = 'Als je de order stopt kan deze niet meer opnieuw gestart worden.';

        if (order.active_workers?.length > 1) {
            warningMessage =
                warningMessage + " Ook sluit je direct de order voor je samenwerkende collega's.";
        }

        this._confirmationService
            .open({
                title: 'Stop productie',
                message: {
                    text: warningMessage,
                    alert: true,
                },
                icon: {
                    show: false,
                },
                actions: {
                    confirm: {
                        label: 'Stoppen',
                    },
                },
                dismissible: true,
            })
            .subscribe((result: DialogReturnType) => {
                if (result === DialogReturnType.Confirm) {
                    this._productionOrderService.sendStopOrder(order).subscribe();
                }
            });
    }

    public pauseOrder(order: ProductionOrder) {
        this._productionOrderService.pauseOrder(order).subscribe();
    }

    public removeWorkerFromOrder(order: ProductionOrder) {
        if (!this.activeMachine.multi_order_processing_station || this.activeEmployee == null) {
            // should not happen.
            return;
        }

        this._productionOrderService.removeWorkerFromOrder(order, this.activeEmployee).subscribe();
    }

    public startOrPrepareOrder(order: ProductionOrder, status: ReportedStatusEnum): void {
        if (!environment.config.showOrderPrepareStartModal) {
            this.setOrderStatus(order, status);

            return;
        }

        this.modalRef = this.modalService.show(StartPrepareOrderModalComponent, {
            class: 'modal-update-progress',
            initialState: {
                order: order,
                status: status,
                parentOrder: this,
            },
        });
    }

    public startCooperateModalProductionOrder(order: ProductionOrder): void {
        if (!order.reported_processing_station.multi_order_processing_station) {
            this._confirmationService.open({
                title: 'Fout',
                message: {
                    text: `Deze order is actief op bewerkingsplek ${order.reported_processing_station.name}. Op deze bewerkingsplek kan je helaas niet samenwerken.`,
                },
                icon: {
                    show: false,
                },
                actions: {
                    cancel: {
                        show: false,
                    },
                },
            });

            return;
        }

        this.modalRef = this.modalService.show(StartPrepareOrderModalComponent, {
            class: 'modal-update-progress',
            initialState: {
                order: order,
                modalType: ModalTypes.Cooperation,
                status: ReportedStatusEnum.STARTED,
                parentOrder: this,
            },
        });
    }

    public queueOrder(order: ProductionOrder): void {
        this._productionOrderService.sendQueueOrder(order, this.activeEmployee).subscribe(() =>{
            this.activeMachine.next_production_orders.push(order);
            this.orderProductionOrderList(this.orderListBackup);
        });
    }

    public unqueueOrder(order: ProductionOrder): void {
        this._productionOrderService.sendUnqueueOrder(order, this.activeEmployee).subscribe(() => {
            this.activeMachine.next_production_orders =
                this.activeMachine.next_production_orders.filter(o => o.id !== order.id);
            this.orderProductionOrderList(this.orderListBackup);
        });
    }

    public setOrderStatus(order: ProductionOrder, status: ReportedStatusEnum): void {
        if (this.currentPO && this.currentPO.reported_status === status) {
            return;
        }

        order.reported_status = status;
        this.sendOrderChange(order);
    }

    public sendOrderChange(order: ProductionOrder): void {
        if (order.reported_status === ReportedStatusEnum.STARTED) {
            this._productionOrderService.sendStartOrder(order, this.activeEmployee).subscribe();
        }

        if (order.reported_status === ReportedStatusEnum.PREPARED) {
            this._productionOrderService.sendPrepareOrder(order, this.activeEmployee).subscribe();
        }
    }

    public enableStartProductionOrder(order: ProductionOrder): boolean {
        if (
            order.reported_status === ProductionOrder.StatusEnum.STARTED ||
            order.reported_status === ProductionOrder.StatusEnum.PREPARED
        ) {
            return false;
        }

        if (null == this.currentPO) {
            return !environment.config.enablePrepareProductionOrder;
        }

        return (
            (!environment.config.enablePrepareProductionOrder &&
                this.currentPO.id !== order.id &&
                this.currentPO.reported_status !== ProductionOrder.StatusEnum.STARTED) ||
            this.currentPO.reported_status === ProductionOrder.StatusEnum.PREPARED
        );
    }

    public enableQueueProductionOrder(order: ProductionOrder): boolean {
        if (
            null != this.currentPO &&
            null != this.activeMachine &&
            this.currentPO.id !== order.id &&
            this.activeMachine.next_production_orders.map(o => o.id).indexOf(order.id) === -1
        ) {
            return environment.config.enableQueueProductionOrder;
        }

        return false;
    }

    public enableUnqueueProductionOrder(order: ProductionOrder): boolean {
        if (
            null != this.currentPO &&
            null != this.activeMachine &&
            this.currentPO.id !== order.id &&
            this.activeMachine.next_production_orders.map(o => o.id).indexOf(order.id) > -1
        ) {
            return environment.config.enableQueueProductionOrder;
        }

        return false;
    }

    public enablePrepareProductionOrder(order: ProductionOrder): boolean {
        if (null == this.currentPO) {
            return environment.config.enablePrepareProductionOrder;
        }

        return (
            environment.config.enablePrepareProductionOrder &&
            this.currentPO.id !== order.id &&
            this.currentPO.reported_status !== ProductionOrder.StatusEnum.STARTED &&
            this.currentPO.reported_status !== ProductionOrder.StatusEnum.PREPARED
        );
    }

    public startProductionOrderDisabled(order: ProductionOrder): boolean {
        return (
            this.currentPO?.id !== order.id &&
            (this.currentPO?.reported_status === this.statusEnum.STARTED ||
                this.currentPO?.reported_status === this.statusEnum.PREPARED)
        );
    }

    public enableCooperateProductionOrder(order: ProductionOrder): boolean {
        if (
            null == this.currentPO &&
            null == this.activeMachine &&
            ReportedStatusEnum.STARTED == order.reported_status
        ) {
            return environment.config.enableCooperationProductionOrder;
        }

        return false;
    }

    public enableStopCooperateProductionOrder(order: ProductionOrder): boolean {
        if (
            null != this.currentPO &&
            null != this.activeEmployee &&
            ReportedStatusEnum.STARTED == order.reported_status &&
            this.currentPO.id === order.id &&
            order.processing_station?.multi_order_processing_station &&
            order.active_workers?.length > 1
        ) {
            return environment.config.enableCooperationProductionOrder;
        }

        return false;
    }

    public onSort($event) {
        this.loading = true;
        const sort = $event.sorts[0];
        this.sorter.sort_dir = sort.dir.toUpperCase();
        this.sorter.sort_by = sort.prop;
        this.fetch(null);
    }

    public updateFilter($event, column) {
        const value = $event.target.value;
        if (value.length === 0) {
            this.tableFilter[column] = null;
            this.fetch();
        } else {
            this.tableFilter[column] = value;
            if (value.length > 1) this.fetch();
        }
    }

    public getRowClass = order => {
        if (this.currentPO && this.activeMachine && order.id === this.currentPO.id) {
            if (this.activeMachine.next_production_orders.length === 0) {
                return 'betech-table__row betech-table__row--active betech-table__row--active-end betech-table__row--editing';
            }

            return 'betech-table__row betech-table__row--active betech-table__row--editing';
        }

        if (this.activeMachine) {
            let index = this.activeMachine.next_production_orders.map(o => o.id).indexOf(order.id);
            if (index > -1) {
                if (index === this.activeMachine.next_production_orders.length - 1) {
                    return 'betech-table__row betech-table__row--queued betech-table__row--queued-end betech-table__row--editing';
                }

                return 'betech-table__row betech-table__row--queued betech-table__row--editing';
            }
        }

        if (!order.material_available) {
            return 'betech-table__row--danger betech-table__row';
        }

        return 'betech-table__row';
    };

    public search() {
        this.filters.reportedStatus = null;
        this.filters.processingStation = null;

        this.fetch();
    }
}
