import { Component, OnInit } from '@angular/core';
import { Tool } from '../../../swagger-client';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'betech-tool-sheet',
    templateUrl: './tool-sheet.component.html',
})
export class ToolSheetComponent implements OnInit {
    public selectedTool: Tool;
    public safeUrl: SafeUrl;

    constructor(public bsModalRef: BsModalRef, public sanitizer: DomSanitizer) {}

    public ngOnInit(): void {
        // prevent iframe flicking by storing the safeUrl once
        if (this.selectedTool && !this.safeUrl) {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
                this.selectedTool.tool_sheet,
            );
        }
    }

    /**
     * Close the popup
     */
    public close(): void {
        this.bsModalRef.hide();
        this.bsModalRef = null;
    }
}
