import { Component, OnInit } from '@angular/core';
import { PerformanceCardComponent } from '../performance-card.component';
import {
    Employee,
    EsbmodelService,
    PerformanceAvailability,
    PerformanceTarget,
    ProcessingStation,
    ProductionOrder,
} from '../../../../swagger-client';
import { Subscription } from 'rxjs';
import { ActiveEntityMediatorService } from '../../../../services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-performance-modal',
    templateUrl: './performance-modal.component.html',
})
export class PerformanceModalComponent implements OnInit {
    public modalRef;
    public parentCard: PerformanceCardComponent;

    public employees: Employee[];
    public currentOrder: ProductionOrder;
    public currentProcessingStation: ProcessingStation;

    public newPerformanceTarget = new (class implements PerformanceTarget {
        public availability?: PerformanceAvailability;
        public cycle_time?: number;
        public employee?: Employee;
        public feedrate?: number;
        public oee?: number;
        public order_number?: string;
        public production_order?: ProductionOrder;
        public reported_processing_station?: ProcessingStation;
        public remark?: string;
        public timestamp?: Date;
        public type?: string;
    })();

    public availability = new (class implements PerformanceAvailability {
        public hours_per_day?: number = 15.0;
        public days_per_week?: number = 5.0;
    })();

    public amountPerHour: number;
    public feedRateAmount: number;
    public amountDay: number;
    public amountWeek: number;
    public trueAmountDay: number;
    public activeEmployee: Employee;

    private subs: Subscription[] = [];

    constructor(
        private esbModelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit(): void {
        this.esbModelService.esbmEmployeeGet().subscribe(data => (this.employees = data.data));

        this.subs.push(
            this._activeEntityMediatorService.activeEmployee$.subscribe(employee => {
                this.newPerformanceTarget.employee = employee;
            }),
            this._activeEntityMediatorService.activeProductionOrder$.subscribe(
                (currentPO: ProductionOrder) => {
                    this.currentOrder = currentPO;
                },
            ),
            this._activeEntityMediatorService.activeMachine$.subscribe(
                (currentProcessingStation: ProcessingStation) => {
                    this.currentProcessingStation = currentProcessingStation;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public update(): void {
        if (
            !isNaN(this.newPerformanceTarget.cycle_time) &&
            this.newPerformanceTarget.cycle_time > 0
        ) {
            this.amountPerHour = Math.round(3600 * 100 / this.newPerformanceTarget.cycle_time) / 100;
            this.amountDay = Math.round(this.amountPerHour * this.availability.hours_per_day * 100) / 100;
            this.amountWeek = Math.round(this.amountDay * this.availability.days_per_week * 100) / 100;
        } else {
            this.amountPerHour = 0;
            this.amountDay = 0;
            this.amountWeek = 0;
        }
    }

    public submit(): void {
        this.newPerformanceTarget.timestamp = new Date();
        this.newPerformanceTarget.availability = this.availability;
        this.newPerformanceTarget.order_number = this.currentOrder.order_number;
        this.newPerformanceTarget.reported_processing_station = this.currentProcessingStation;
        this.newPerformanceTarget.type = 'THEORETICAL';

        this.esbModelService
            .esbmPerformanceTargetPost(
                this.newPerformanceTarget.order_number,
                this.newPerformanceTarget,
            )
            .subscribe(data => {
                setTimeout(
                    function () {
                        this.closeScreen();
                    }.bind(this),
                    1000,
                );
            });
    }

    public setEmployee(employee): void {
        if (typeof employee !== 'object') {
            return;
        }

        this.newPerformanceTarget.employee = employee;
    }

    public closeScreen(): void {
        this.parentCard.getCurrentProductionOrder();
        this.modalRef.hide();
    }

    public setModalRef(modalRef: any, parentCard: PerformanceCardComponent): void {
        this.modalRef = modalRef;
        this.parentCard = parentCard;
    }
}
