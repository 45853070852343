import { Component } from '@angular/core';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { Router } from '@angular/router';

@Component({
    selector: 'betech-main',
    templateUrl: './admin.component.html',
})
export class AdminComponent {
    constructor(private authService: AuthenticationService, private router: Router) {
        // when not logged in, don't even show this component
        if (!this.authService.isAuthenticated) {
            this.router.navigateByUrl('/auth/login');
        }

        // // when logged in as an admin, go there directly to avoid all kinds of errors
        // if (this.authService.user && !this.authService.user.is_betech_admin) {
        //     this.router.navigateByUrl('/dash');
        // }
    }
}
