import { Component, Input, OnInit } from '@angular/core';
import {
    Performance,
    PerformanceTarget,
    ProcessingStationPerformanceProductionOrder,
    ProcessingStationPerformanceProductionOrderReport,
} from '../../../../swagger-client';
import { environment } from '../../../../../environments/environment';
import { MediaSlideshowModalComponent } from '../../../../shared/media-slideshow-modal/media-slideshow-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'betech-performance-machines-entry',
    templateUrl: './machines-entry-performance.component.html',
})
export class MachinesEntryPerformanceComponent implements OnInit {
    @Input() performance: Performance;
    public showInfo: boolean;

    public declinedPercentage: number;
    public readyPercentage: number;
    public remaining: number;

    public performanceTarget: PerformanceTarget;
    public amount: number;
    public amountDay: number;
    public trueAmountDay: number;

    public todayApproved: number;
    public todayRejected: number;

    public yesterdayApproved: number;
    public yesterdayRejected: number;

    private bsModalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    isToday = dateString => {
        if (dateString == null) {
            return false;
        }

        const someDate = new Date(dateString);
        const today = new Date();

        return (
            someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
        );
    };

    isYesterday = dateString => {
        if (dateString == null) {
            return false;
        }

        const someDate = new Date(dateString);
        const today = new Date();

        return (
            someDate.getDate() == today.getDate() - 1 &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
        );
    };

    public ngOnInit() {
        this.remaining = Math.max(
            this.performance.required_parts - this.performance.reported_approved_parts,
            0,
        );

        this.readyPercentage = Math.round(
            (this.performance.reported_approved_parts / this.performance.required_parts) * 100,
        );

        if (this.performance.reported_processed_parts == 0) {
            this.declinedPercentage = 0;
        } else {
            this.declinedPercentage = Math.round(
                (this.performance.reported_scrap_parts /
                    this.performance.reported_processed_parts) *
                    100,
            );
        }

        this.todayApproved = 0;
        this.todayRejected = 0;
        this.yesterdayApproved = 0;
        this.yesterdayRejected = 0;

        this.trueAmountDay = this.calculateMachinePerformance();
        this.calculateDaysPerformance();
    }

    public loadInfo(): void {
        this.showInfo = !this.showInfo;
    }

    private calculateMachinePerformance(): number {
        const reportsLatestFirst: Array<ProcessingStationPerformanceProductionOrderReport> = this.performance?.production_order_reports.sort((rp1, rp2) => (new Date(rp1.timestamp) < new Date(rp2.timestamp))?1:-1);
        const newestDate = new Date(reportsLatestFirst[0].timestamp);

        let producedParts = 0;

        reportsLatestFirst.forEach((report: ProcessingStationPerformanceProductionOrderReport) => {
            if (report?.approved_parts > 0) {
                producedParts += report.approved_parts;
            }

            if (report?.returncode === 'STARTED') {
                const startDate = new Date(report.timestamp);
                var diff = Math.abs(newestDate.getTime() - startDate.getTime());
                var diffDays = Math.max(Math.ceil(diff / (1000 * 3600 * 24)), 1);
                return Math.round(producedParts / diffDays);
            }
        });

        // Todo: Leave in until backend is updated;
        const startDate = new Date(reportsLatestFirst[reportsLatestFirst.length-1].timestamp);
        var diff = Math.abs(newestDate.getTime() - startDate.getTime());
        var diffDays = Math.max(Math.ceil(diff / (1000 * 3600 * 24)), 1);
        return Math.round(producedParts / diffDays);

        // When backend is updated;
        return 0;
    }

    private calculateDaysPerformance(): void {
        if (this.performance?.production_order_reports == null) {
            return;
        }

        this.performance?.production_order_reports.forEach(
            (report: ProcessingStationPerformanceProductionOrderReport) => {
                if (this.isToday(report.timestamp)) {
                    this.todayRejected += report.scrap_parts;
                    this.todayApproved += report.approved_parts;
                }

                if (this.isYesterday(report.timestamp)) {
                    this.yesterdayRejected += report.scrap_parts;
                    this.yesterdayApproved += report.approved_parts;
                }
            },
        );
    }

    public showMediaModal($event: Event): void {
        $event.preventDefault();
        $event.stopPropagation();

        const order: ProcessingStationPerformanceProductionOrder =
            this.performance.active_production_order;
        if (!order.drawing_image_urls) {
            return;
        }

        this.bsModalRef = this.modalService.show(MediaSlideshowModalComponent, {
            class: 'mw-100 m-0 h-auto',
        });
        this.bsModalRef.content.setModalRef(this.bsModalRef);

        this.bsModalRef.content.showMediaSlideshow(order, order.drawing_image_urls);
    }
}
