import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccessTokenPipe } from '../pipes/access-token/access-token.pipe';
import { WeekOfYearPipe } from '../pipes/week-of-year/week-of-year.pipe';
import { SubtractDatesPipe } from '../pipes/subtract-dates/subtract-dates.pipe';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { OrderStatusPipe } from '../pipes/order-status/order-status.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ModalComponent } from './modal/modal.component';
import { CardComponent } from './card/card.component';
import { CardNotificationComponent } from './card-notification/card-notification.component';
import { AlertsComponent } from './alerts/alerts.component';
import { ForecastPipe } from '../pipes/forecast/forecast.pipe';
import { MeasurementSampleLocationPipe } from '../pipes/measurement-sample-location/measurement-sample-location.pipe';
import { StockRequestStatusPipe } from '../pipes/stock-request-status/stock-request-status.pipe';
import { FilterContainerComponent } from './filter-container/filter-container.component';
import { LineChartComponent } from 'app/shared/line-chart/line-chart.component';
import { LineChartModule, NgxChartsModule } from '@swimlane/ngx-charts';
import { MeasurementDefinitionTypePipe } from '../pipes/measurement-definition-type/measurement-definition-type.pipe';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MediaSlideshowModalComponent } from './media-slideshow-modal/media-slideshow-modal.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { OrderStatusFilterComponent } from './filter/order-status/order-status.filter.component';
import { ProcessingStationFilterComponent } from './filter/processing-station/processing-station.filter.component';
import { OrderNumberFilterComponent } from './filter/order-number/order-number.filter.component';
import { MeasurementLocationFilterComponent } from '../admin/filter/measurement-location/measurement-location.filter.component';
import { DepartmentFilterComponent } from './filter/department/department.filter.component';
import { ScanQrCodeModalComponent } from './scan-qr-code-modal/scan-qr-code-modal.component';
import { ConfirmationDialogComponent } from "./confirmation-dialog/dialog.component";

@NgModule({
    declarations: [
        ForecastPipe,
        AccessTokenPipe,
        WeekOfYearPipe,
        SubtractDatesPipe,
        OrderStatusPipe,
        MeasurementSampleLocationPipe,
        MeasurementDefinitionTypePipe,
        StockRequestStatusPipe,
        NotificationsComponent,
        ErrorPageComponent,
        SelectDropdownComponent,
        CheckboxComponent,
        ModalComponent,
        CardComponent,
        CardNotificationComponent,
        AlertsComponent,
        FilterContainerComponent,
        LineChartComponent,
        MediaSlideshowModalComponent,
        ScanQrCodeModalComponent,
        ProgressBarComponent,
        OrderStatusFilterComponent,
        ProcessingStationFilterComponent,
        OrderNumberFilterComponent,
        MeasurementLocationFilterComponent,
        DepartmentFilterComponent,
        ConfirmationDialogComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        PinchZoomModule,
        BsDropdownModule,
        AlertModule,
        LineChartModule,
        NgxChartsModule,
    ],
    exports: [
        AccessTokenPipe,
        WeekOfYearPipe,
        SubtractDatesPipe,
        MeasurementSampleLocationPipe,
        StockRequestStatusPipe,
        ForecastPipe,
        ErrorPageComponent,
        OrderStatusPipe,
        NotificationsComponent,
        SelectDropdownComponent,
        CheckboxComponent,
        ModalComponent,
        CardComponent,
        CardNotificationComponent,
        AlertsComponent,
        FilterContainerComponent,
        OrderStatusFilterComponent,
        ProcessingStationFilterComponent,
        OrderNumberFilterComponent,
        MeasurementLocationFilterComponent,
        DepartmentFilterComponent,
        LineChartComponent,
        MeasurementDefinitionTypePipe,
        MediaSlideshowModalComponent,
        ScanQrCodeModalComponent,
        ProgressBarComponent,
        ConfirmationDialogComponent,
    ],
})
export class SharedModule {}
