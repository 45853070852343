import { Component, Input, OnInit } from '@angular/core';
import {PerformanceTarget, ProductionOrder} from '../../../swagger-client';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { RemarkModalComponent } from '../remark-modal/remark-modal.component';

@Component({
    selector: '[betech-performance-item]',
    templateUrl: './performance-item.component.html',
})
export class PerformanceItemComponent implements OnInit {
    @Input() public currentProductionOrder: ProductionOrder;
    @Input() public performanceTarget: PerformanceTarget;
    public amountDay: number;
    public trueAmountDay: number;
    public amountWeek: number;
    public trueAmountWeek: number;
    public modalRef: BsModalRef;

    constructor(private modalService: BsModalService) {}

    public ngOnInit(): void {


        this.amountDay = Math.round(
            (this.performanceTarget.availability.hours_per_day * 3600) /
                this.currentProductionOrder.planned_cycle_time,
        );
        this.amountWeek = Math.round(
            this.amountDay * this.performanceTarget.availability.days_per_week,
        );

        if (this.performanceTarget.type === 'ACTUAL') {
            this.trueAmountDay = this.performanceTarget.processed_parts_per_day;
            this.trueAmountWeek = null;
        } else {
            this.trueAmountDay = Math.round(
                (this.performanceTarget.availability.hours_per_day * 3600) /
                this.performanceTarget.cycle_time,
            );
            this.trueAmountWeek = Math.round(
                this.trueAmountDay * this.performanceTarget.availability.days_per_week,
            );
        }
    }

    public openModal(): void {
        const initialState = { remark: this.performanceTarget.remark };
        this.modalRef = this.modalService.show(
            RemarkModalComponent,
            Object.assign({}, {}, { class: 'modal-sm', initialState }),
        );
        this.modalRef.content.setModalRef(this.modalRef);
    }
}
