import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PerformanceModalComponent } from './performance-modal/performance-modal.component';
import {
    EsbmodelService,
    PerformanceTarget, ProductionOrder,
} from '../../../swagger-client';
import { ActiveProductionOrdersService } from '../../../services/active-production-orders/active-production-orders.service';
import {Subscription} from "rxjs";
import {ActiveEntityMediatorService} from "../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-performance-card',
    templateUrl: './performance-card.component.html',
})
export class PerformanceCardComponent implements OnInit {
    public modalRef: BsModalRef;
    public performanceTarget: PerformanceTarget;
    public amount: number;
    public amountDay: number;
    public trueAmountDay: number;
    public isLoaded: boolean;
    public currentProductionOrder: ProductionOrder;

    private subs: Subscription[] = [];

    constructor(
        private modalService: BsModalService,
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit(): void {
        this.getCurrentProductionOrder();
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public getCurrentProductionOrder(): void {
        this.subs.push(
        this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                if (currentPO !== null) {
                    this.currentProductionOrder = currentPO;
                    this.getPerformanceTargets(currentPO.order_number);
                }
            })
        );
    }

    public getPerformanceTargets(orderNumber): void {
        this.esbModelService.esbmPerformanceTargetGet(orderNumber).subscribe(performanceTargets => {
            if (performanceTargets.length !== 0) {
                this.performanceTarget = performanceTargets[0];

                if (performanceTargets[0].type === 'ACTUAL') {
                    this.trueAmountDay = performanceTargets[0].processed_parts_per_day
                } else {
                    this.trueAmountDay = Math.round(
                        (performanceTargets[0].availability.hours_per_day * 3600) /
                        performanceTargets[0].cycle_time,
                    );
                }

                this.amount = Math.round(3600 / performanceTargets[0].cycle_time);
                this.amountDay = Math.round(
                    (performanceTargets[0].availability.hours_per_day * 3600) /
                        this.currentProductionOrder.planned_cycle_time,
                );

            }
            this.isLoaded = true;
        });
    }

    public openModal($event): void {
        $event.preventDefault();
        $event.stopPropagation();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-performance';

        this.modalRef = this.modalService.show(PerformanceModalComponent, modalOptions);
        this.modalRef.content.setModalRef(this.modalRef, this);
    }
}
