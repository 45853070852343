<div
  class="checklist-item"
  [class.checklist-item--done]="!todo"
  [class.checklist-item--loading]="loading"
>
  <button
    class="btn btn-outline-success mr-4 checklist-item-checkbox"
    [class.checklist-item-checkbox--done]="done"
    (click)="toggle()"
  >
    <i class="fas fa-check" *ngIf="!loading"></i>
    <i class="fas fa-spin fa-spinner-third" *ngIf="loading"></i>
  </button>
  <div class="checklist-item-title">
    {{ item.name }}
  </div>
  <div class="ml-auto"></div>
  <ng-container *ngIf="todo">
    <div class="mr-2">
      <button class="btn btn-outline-primary" (click)="skipItem()">Overslaan</button>
    </div>
    <div class="checklist-item-employee">
      <betech-select-dropdown
        [required]="true"
        [items]="employees"
        [model]="item.employee"
        label="full_name"
        search="full_name"
        (modelChange)="setEmployee($event)"
      ></betech-select-dropdown>
    </div>
  </ng-container>
  <ng-container *ngIf="!todo">
    <div *ngIf="skipped" class="mr-4 checklist-item-title text-muted">
      <i class="fas fa-fw fa-forward mr-2"></i>
      Overgeslagen
    </div>
    <div *ngIf="done" class="mr-4 checklist-item-title text-success">
      <i class="fas fa-fw fa-check mr-2"></i>
      Afgerond
    </div>
    <div class="checklist-item-title">
      {{ item.employee_number | employee }}
    </div>
  </ng-container>
</div>
