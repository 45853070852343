/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Employee } from './employee';
import { Instruction } from './instruction';
import { ProcessingStation } from './processingStation';


export interface ProductionOrder { 
    readonly id?: string;
    _created_at?: Date;
    _updated_at?: Date;
    order_number?: string;
    order_line_number?: number;
    quantity?: number;
    start_machine_counter?: number;
    status?: ProductionOrder.StatusEnum;
    reported_status?: ProductionOrder.ReportedStatusEnum;
    /**
     * planned setup time in seconds
     */
    planned_setup_time?: number;
    /**
     * planned cycle time in seconds
     */
    planned_cycle_time?: number;
    /**
     * additional instruction for this production order
     */
    instruction?: Array<Instruction>;
    processing_station_number?: string;
    readonly processing_station?: ProcessingStation;
    reported_processing_station?: ProcessingStation;
    fabrication_part_id?: string;
    fabrication_part_name?: string;
    fabrication_part_articlenumber?: string;
    calculation_number?: string;
    calculation_id?: string;
    start_date?: string;
    cnc_program?: string;
    company?: string;
    due_date?: Date;
    drawing_image_url?: string;
    drawing_image_urls?: Array<string>;
    drawing_revision?: string;
    reference?: string;
    measurement_count?: number;
    planned_material_per_cycle?: number;
    material_available?: boolean;
    production_number?: string;
    combi_id?: string;
    started_by?: Employee;
    active_workers?: Array<Employee>;
}
export namespace ProductionOrder {
    export type StatusEnum = 'NOT_STARTED' | 'STARTED' | 'PREPARED' | 'PAUSED' | 'RESUMED' | 'ENDED';
    export const StatusEnum = {
        NOTSTARTED: 'NOT_STARTED' as StatusEnum,
        STARTED: 'STARTED' as StatusEnum,
        PREPARED: 'PREPARED' as StatusEnum,
        PAUSED: 'PAUSED' as StatusEnum,
        RESUMED: 'RESUMED' as StatusEnum,
        ENDED: 'ENDED' as StatusEnum
    };
    export type ReportedStatusEnum = 'NOT_STARTED' | 'STARTED' | 'PREPARED' | 'PAUSED' | 'RESUMED' | 'ENDED';
    export const ReportedStatusEnum = {
        NOTSTARTED: 'NOT_STARTED' as ReportedStatusEnum,
        STARTED: 'STARTED' as ReportedStatusEnum,
        PREPARED: 'PREPARED' as ReportedStatusEnum,
        PAUSED: 'PAUSED' as ReportedStatusEnum,
        RESUMED: 'RESUMED' as ReportedStatusEnum,
        ENDED: 'ENDED' as ReportedStatusEnum
    };
}
