import { Component, OnDestroy, OnInit } from '@angular/core';
import { InMemoryFilter } from '../../filter/filter';
import {
    Performance,
    ProductionOrderPerformanceTotals,
    Employee, ProcessingStation,
} from '../../../swagger-client';
import { Subscription } from 'rxjs';
import { EmployeePerformanceService } from '../../../services/employee-performance/employee-performance.service';

@Component({
    templateUrl: './employees-performance.component.html',
})
export class EmployeesPerformanceComponent implements OnInit, OnDestroy {
    public filter: InMemoryFilter = new InMemoryFilter();

    public filters: {
        employee: Employee;
        processingStation: ProcessingStation;
    } = {
        employee: null,
        processingStation: null,
    };

    public total: number;
    public receivedAll: boolean;
    public loading: boolean;
    private page: number;
    public performance: Array<Performance>;
    private subs: Subscription[] = [];

    constructor(private employeePerformanceService: EmployeePerformanceService) {}

    public ngOnInit(): void {
        this.resetContent();
        this.loadInitialData();
        this.subs.push(
            this.employeePerformanceService.employeePerformance$.subscribe(data =>
                this.appendEmployeePerformance(data),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public requestNewPerformance(): void {
        this.loading = true;
        this.employeePerformanceService
            .fetch(
                ++this.page,
                this.filters.employee?.employee_number.replace(/X/g, '\\d'),
                this.filters.processingStation?.trumpf_id.replace(/X/g, '\\d'))
            .subscribe();
    }

    private loadInitialData(): void {
        this.loading = true;
        this.employeePerformanceService.fetch().subscribe();
    }

    private appendEmployeePerformance(response: ProductionOrderPerformanceTotals): void {
        this.loading = false;

        this.total = response.total;
        this.performance = [...this.performance, ...response.data];

        this.receivedAll = this.performance.length == this.total || response.data.length != 10;
    }

    private resetContent(): void {
        this.page = 1;
        this.performance = [];
        this.filters.employee = undefined;
        this.receivedAll = false;
    }

    public changeProcessingStation(station) {
        if (this.loading) {
            return;
        }

        this.resetContent();
        this.page = 0;
        this.filters.processingStation = station;
        this.requestNewPerformance();
    }

    trackByFunc(index, item: Performance) {
        return false;
    }
}
