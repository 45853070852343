import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { AuthComponent } from './auth.component';

const authRoutes: Routes = [
    {
        path: 'auth',
        component: AuthComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login',
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'not-supported',
                component: NotSupportedComponent,
            },
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'login',
            },
            //,
            // {
            //     path: '**',
            //     pathMatch: 'full',
            //     redirectTo: '/'
            // }
        ],
    },
];

export const AuthRoutingModule = RouterModule.forChild(authRoutes);
