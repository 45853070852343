import { LOCALE_ID, NgModule, ErrorHandler as BaseErrorHandler } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BetechRoutingModule } from './betech.routing.module';
import { BetechComponent } from './betech.component';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { ApiModule, Configuration, EsbmodelService } from './swagger-client';
import { AuthModule } from './auth/auth.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MediaModalComponent } from './shared/media-modal/media-modal.component';
import { ActiveMachineService } from './services/active-machine/active-machine.service';
import { TokenService } from './services/token/token.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { UserService } from './services/user/user.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { AdminGuard } from './guards/admin/admin.guard';
import { MainGuard } from './guards/main/main.guard';
import { SharedModule } from './shared/shared.module';
import { ActiveProductionOrdersService } from './services/active-production-orders/active-production-orders.service';
import localeNl from '@angular/common/locales/nl';
import { BrowserSupportService } from './services/browser-support/browser-support.service';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { SensorDataService } from './services/sensor-data/sensor-data.service';
import { ToolReplaceComponent } from './main/tool-list/tool-replace/tool-replace.component';
import { ToolSheetComponent } from './main/tool-list/tool-sheet/tool-sheet.component';
import { FormsModule } from '@angular/forms';
import { NotSupportedComponent } from './auth/not-supported/not-supported.component';
import { ErrorsInterceptor } from './http-interceptors/errors/errors.interceptor';
import { AlertService } from './services/alert/alert.service';
import { MainModule } from './main/main.module';
import { AdminModule } from './admin/admin.module';
import { ActiveEmployeeService } from './services/active-employee/active-employee.service';
import { VersionCheckService } from './check.service';
import { LineChartModalComponent } from 'app/shared/line-chart/modal/line-chart-modal.component';
import { MeasurementService } from 'app/services/measurement/measurement.service';
import { ErrorHandler } from './services/error-handler/error-handler.service';
import { SupportService } from './services/support/support.service';
import { ProducedAmountDataService } from './services/produced-amount-data-service/produced-amount-data-service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DrawingDuringMeasurementService } from './services/drawing-during-measurement/drawing-during-measurement.service';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BetechLoadingBarModule } from './loading-bar/loading-bar.module';
import { LoadingModule } from './services/loading/loading.module';
import { ActiveEntityMediatorService } from './services/active-entity-mediator/active-entity-mediator.service';
import { ConfirmationService } from './services/confirmation/confirmation.service';

// Create the API configuration
export function apiConfiguration() {
    return new Configuration({ basePath: environment.apiUrl + '/api' });
}

// Register locales
registerLocaleData(localeNl);

@NgModule({
    imports: [
        CommonModule,
        BetechRoutingModule,
        BrowserModule,
        RouterModule,
        TooltipModule.forRoot(),
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ApiModule.forRoot(apiConfiguration),
        AuthModule,
        AlertModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule,
        PinchZoomModule,
        FormsModule,
        ProgressbarModule.forRoot(),
        MainModule,
        BetechLoadingBarModule,
        LoadingModule,
        AdminModule,
        NgxDatatableModule,
    ],

    declarations: [
        BetechComponent,
        MediaModalComponent,
        ToolReplaceComponent,
        ToolSheetComponent,
        NotSupportedComponent,
        LineChartModalComponent,
    ],

    providers: [
        { provide: BaseErrorHandler, useClass: ErrorHandler },
        { provide: LOCALE_ID, useValue: 'nl' },

        EsbmodelService,
        ActiveMachineService,
        ActiveEmployeeService,
        ActiveProductionOrdersService,
        ActiveEntityMediatorService,
        DrawingDuringMeasurementService,
        SensorDataService,
        LocalStorageService,
        TokenService,
        UserService,
        AuthenticationService,
        AdminGuard,
        MainGuard,
        BrowserSupportService,
        AlertService,
        VersionCheckService,
        SupportService,
        ProducedAmountDataService,
        ConfirmationService,

        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true,
        },
        MeasurementService,
    ],

    entryComponents: [
        MediaModalComponent,
        ToolReplaceComponent,
        ToolSheetComponent,
        LineChartModalComponent,
    ],

    bootstrap: [BetechComponent],

    exports: [SharedModule],
})
export class BetechModule {}
