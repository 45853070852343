<div class="qr-code-scanner-modal">
    <div class="betech-modal__header">
        <h2>Scan Code</h2>
        <div class="betech-modal__close" (click)="close()">
            <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
        </div>
    </div>
    <div class="row mx-4 mt-3 overflow-hidden">
        <div class="col p-0">
            <video class="w-100" #qrScanner></video>
            <div #qrActiveRegion></div>
        </div>
    </div>
    <div class="row m-4">
        <div class="col text-right">
            <button class="btn btn-secondary" (click)="close()">Sluiten</button>
        </div>
    </div>
</div>

<div *ngIf="searchProductionOrder && showProductionOrder" class="container position-absolute fixed-top h-100" style="pointer-events: none">
    <div class="row h-100 justify-content-center align-items-center">
        <div class="col-12">
            <div class="row justify-content-center align-items-center">
                <div class="col-6 p-4 rounded bg-white shadow" style="pointer-events: initial">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 p-0 border-bottom">
                                <h2>Gevonden order:</h2>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>Productienummer:</div>
                                    <div>{{foundProductionOrder.production_number}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>Product:</div>
                                    <div> {{foundProductionOrder.fabrication_part_name}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>Aantal:</div>
                                    <div>{{foundProductionOrder.quantity}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="d-flex justify-content-between">
                                    <div>Leverdatum:</div>
                                    <div>{{foundProductionOrder.due_date | date:'dd-MM-yyyy' }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="row p-0 border-top mt-4">
                            <div class="col-12 p-0 mt-4">
                                <div class="d-flex justify-content-between">
                                    <button class="btn btn-secondary" (click)="removeFoundOrder()">Annuleren</button>
                                    <button class="btn btn-primary" (click)="close()">Starten</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
