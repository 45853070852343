<!-- main measurement card -->
<betech-card clickUrl="/stock-request" *ngIf="currentProductionOrder && lastStockRequest">
  <i card-icon class="fal fa-3x fa-ball-pile fa-fw"></i>
  <span card-title i18n>Materiaal</span>
  <span card-subtitle i18n>Order {{ currentProductionOrder.production_number || '-' }}</span>
  <button
    card-button
    *ngIf="lastStockRequest.status === 'ORDERED'"
    class="btn btn-secondary btn-block rounded text-uppercase"
    href="#"
    (click)="received($event, receiveModalTemplate)"
  >
    <span i18n>Ontvangen</span>
  </button>
  <button
    card-button
    class="btn btn-secondary btn-block rounded text-uppercase"
    href="#"
    (click)="order($event, orderModalTemplate)"
  >
    <span i18n>Bestel materiaal</span>
  </button>
  <ng-container card-info>
    <ng-container *ngIf="lastStockRequest.material_article">
      <div class="d-flex justify-content-between">
        <div><span i18n>Artikelnr</span>:</div>
        <div class="text-primary text-right">
          {{ lastStockRequest.material_article.articlenumber_pdc }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Naam</span>:</div>
        <div class="text-primary text-right">{{ lastStockRequest.material_article.name }}</div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Chargenummer</span>:</div>
        <div class="text-primary text-right">
          {{ getCertificateLabel(lastStockRequest) }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Materiaal over</span>:</div>
        <div
          class="text-right"
          [ngClass]="[lastStockRequest.surplus > 0 ? 'text-success' : 'text-danger']"
        >
          <span *ngIf="lastStockRequest.surplus > 0">+</span
          >{{ lastStockRequest.surplus || 0 | number: '1.0-1' }} m.
        </div>
      </div>
    </ng-container>
    <div class="alert alert-danger" *ngIf="!lastStockRequest.material_article">
      <small class="text-danger" i18n
        >This production order does not seem to be properly connected to any material. Please
        contact support.</small
      >
    </div>
  </ng-container>
  <ng-container card-notifications *ngIf="lastStockRequest.material_article">
    <betech-card-notification
      [type]="
        lastStockRequest.calculateMachine(producedAmountDetails) <= 20 ? 'warning' : 'success'
      "
    >
      <span
        [class]="
          lastStockRequest.calculateMachine(producedAmountDetails) <= 20
            ? 'text-warning'
            : 'text-success'
        "
        i18n
      >
        Nog
        <b
          >{{ lastStockRequest.calculateMachine(producedAmountDetails) || 0 | number: '1.0-1' }}
          {{ lastStockRequest.material_article.length.unit }}</b
        >
        in machine
      </span>
      <button
        *ngIf="!lastStockRequest.isWaiting()"
        class="btn btn-sm btn-warning mt-1"
        (click)="measure($event, measureModalTemplate)"
      >
        BIJWERKEN
      </button>
    </betech-card-notification>
  </ng-container>
  <ng-container card-footer *ngIf="lastStockRequest.material_article">
    <span class="text-success" *ngIf="currentProductionOrder.material_available">
      <i class="fa fa-check-square text-success"></i>
      Er is voldoende voorraad aanwezig voor deze order.
    </span>
    <span class="text-danger" *ngIf="!currentProductionOrder.material_available">
      <i class="fa fa-times-square text-danger"></i>
      Er is onvoldoende voorraad aanwezig voor deze order!
    </span>
  </ng-container>
</betech-card>

<!-- measure modal -->
<ng-template #measureModalTemplate>
  <betech-modal (closeEvent)="modalRef.hide()">
    <i modal-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
    <span i18n modal-title>Materiaal voorraad</span>
    <ng-container modal-body>
      <div class="form-group">
        <label>Bijgewerkt door</label>
        <betech-select-dropdown
          [required]="true"
          placeholder="Kies een medewerker"
          i18n-placeholder
          [items]="employeeList"
          label="full_name"
          search="full_name"
          [model]="newStockRequest.employee"
          (modelChange)="newStockRequest.employee = $event"
        >
        </betech-select-dropdown>
      </div>
      <div class="form-group">
        <label for="measure_stock_level">Aantal meters</label>
        <input
          id="measure_stock_level"
          class="form-control"
          type="number"
          [(ngModel)]="newStockRequest.stock_level"
          [required]="true"
        />
      </div>
      <div class="clearfix">
        <button i18n class="btn btn-secondary float-right" (click)="post()">BIJWERKEN</button>
      </div>
    </ng-container>
  </betech-modal>
</ng-template>

<!-- order modal -->
<ng-template #orderModalTemplate>
  <betech-modal (closeEvent)="modalRef.hide()">
    <i modal-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
    <span i18n modal-title>Bestel materiaal</span>
    <ng-container modal-body>
      <div class="form-group">
        <label i18n>Materiaal</label>
        <input
          class="form-control"
          disabled
          [value]="lastStockRequest?.material_article?.material_summary"
        />
      </div>
      <div class="form-group">
        <label>Bestelling door</label>
        <betech-select-dropdown
          [required]="true"
          placeholder="Kies een medewerker"
          i18n-placeholder
          [items]="employeeList"
          label="full_name"
          search="full_name"
          [model]="newStockRequest.employee"
          (modelChange)="newStockRequest.employee = $event"
        >
        </betech-select-dropdown>
      </div>
      <div class="form-group">
        <label>Voor order</label>
        <betech-select-dropdown
          [required]="true"
          placeholder="Kies een order"
          i18n-placeholder
          [items]="orderList"
          label="production_number"
          [customSearch]="true"
          (searchInputChange)="filterOrders($event)"
          [model]="newStockRequest.production_order"
          (modelChange)="orderSelected($event)"
        >
        </betech-select-dropdown>
      </div>
      <div class="form-group">
        <label i18n>Benodigd op</label>
        <input
          class="form-control"
          type="datetime-local"
          [ngModel]="newStockRequest.delivery_at | date: 'yyyy-MM-ddTHH:mm'"
          (ngModelChange)="newStockRequest.delivery_at = $event"
        />
      </div>
      <div class="clearfix">
        <button i18n class="btn btn-secondary float-right" (click)="post()">BESTELLEN</button>
      </div>
    </ng-container>
  </betech-modal>
</ng-template>

<!-- receive modal -->
<ng-template #receiveModalTemplate>
  <betech-modal (closeEvent)="modalRef.hide()" *ngIf="lastStockRequest">
    <i modal-icon class="fal fa-3x fa-ruler-combined fa-fw"></i>
    <span i18n modal-title>Materiaal ontvangen</span>
    <ng-container modal-body *ngIf="!loadingAllocation && !loadingRequests">
      <div class="alert alert-warning" *ngIf="stockAllocations.length === 0">
        Er lijkt geen materiaal gealloceerd.
      </div>
      <div class="form-group" *ngIf="selectedStockRequest && stockAllocations.length > 1">
        <label i18n>Materiaal reservering</label>
        <betech-select-dropdown
          [required]="true"
          [items]="stockAllocations"
          [label]="stockRequestLabel"
          [(model)]="selectedStockRequest.stock_allocation"
        ></betech-select-dropdown>
      </div>
      <div class="form-group" *ngIf="allStockRequests.length > 1">
        <label i18n>Materiaal selecteren</label>
        <betech-select-dropdown
          [required]="true"
          [items]="allStockRequests"
          [model]="selectedStockRequest"
          (modelChange)="setSelectedStockRequest($event)"
          [label]="getStockRequestLabel"
        ></betech-select-dropdown>
      </div>
        <label i18n>Gealloceerd op:</label>
        <div>
            {{ (selectedStockRequest?.stock_allocation?._created_at | date: 'dd-MM-yyyy HH:mm') || '-' }}
        </div>
      <div class="form-group">
        <label for="material" i18n>Materiaal</label>
        <input
          id="material"
          class="form-control"
          type="text"
          [value]="selectedStockRequest?.stock_allocation?.material_article?.material_summary"
          [required]="true"
        />
      </div>
      <div class="form-group">
        <label for="certificate" i18n>Certificaat</label>
        <input
          id="certificate"
          class="form-control"
          type="text"
          [value]="getCertificateLabel(selectedStockRequest)"
          [required]="true"
        />
      </div>
      <div class="form-group">
        <label for="receive_quantity" i18n>Aantal meters ontvangen</label>
        <input
          id="receive_quantity"
          class="form-control"
          type="number"
          [value]="selectedStockRequest?.stock_allocation?.quantity"
          [required]="true"
        />
      </div>
      <div class="clearfix">
        <button i18n class="btn btn-secondary float-right" (click)="put('RECEIVED')">
          OPSLAAN
        </button>
      </div>
    </ng-container>
  </betech-modal>
</ng-template>
