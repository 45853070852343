/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Employee } from './employee';
import { MaterialArticle } from './materialArticle';
import { ProcessingStation } from './processingStation';
import { ProductionOrder } from './productionOrder';
import { StockAllocation } from './stockAllocation';


export interface StockRequest { 
    readonly id?: string;
    readonly _created_at?: Date;
    readonly _updated_at?: Date;
    production_order?: ProductionOrder;
    processing_station?: ProcessingStation;
    employee?: Employee;
    material_article?: MaterialArticle;
    stock_allocation?: StockAllocation;
    sequence_number?: number;
    quantity?: number;
    delivery_at?: Date;
    stock_level?: number;
    location?: string;
    charge_number?: string;
    processed_parts?: number;
    surplus?: number;
    status?: StockRequest.StatusEnum;
}
export namespace StockRequest {
    export type StatusEnum = 'ORDERED' | 'RECEIVED' | 'MEASURED';
    export const StatusEnum = {
        ORDERED: 'ORDERED' as StatusEnum,
        RECEIVED: 'RECEIVED' as StatusEnum,
        MEASURED: 'MEASURED' as StatusEnum
    };
}
