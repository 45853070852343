import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFilterComponent } from '../../filter-container/abstract.filter.component';
import { ProductionOrder } from '../../../swagger-client';
import { statusLabel } from '../../../pipes/order-status/order-status.pipe';
import ReportedStatusEnum = ProductionOrder.ReportedStatusEnum;

@Component({
    selector: 'betech-filter-order-status',
    templateUrl: './order-status.filter.component.html',
    providers: [{ provide: AbstractFilterComponent, useExisting: OrderStatusFilterComponent }],
})
export class OrderStatusFilterComponent extends AbstractFilterComponent {
    public choices: Array<{ value: ReportedStatusEnum; label: string }> = [];

    constructor() {
        super();
        Object.values(ReportedStatusEnum).forEach((value: ReportedStatusEnum) =>
            this.choices.push({ value: value, label: statusLabel(value) }),
        );
    }

    @Input() public set data(item: ReportedStatusEnum) {
        if (!item) {
            this.clear();
            return;
        }

        this._selectedItem = { value: item, label: statusLabel(item) };
    }

    private _selectedItem: any;
    public get selectedItem() {
        return this._selectedItem;
    }
    public set selectedItem(item: { value: ReportedStatusEnum; label: string }) {
        this._selectedItem = item;
        this.filterChange.emit(item ? item.value : null);
    }

    @Output() public filterChange = new EventEmitter();

    public clear() {
        this._selectedItem = null;
        this.filterChange.emit(null);
    }
}
