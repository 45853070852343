import {Component, OnDestroy, OnInit} from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { environment } from '../../../environments/environment';
import {ProcessingStation, ProductionOrder} from "../../swagger-client";
import {Subscription} from "rxjs";
import {ActiveMachineService} from "../../services/active-machine/active-machine.service";
import {NoEmployeeModalComponent} from "../dashboard/no-employee-card/no-employee-modal/no-employee-modal.component";
import {BsModalRef, BsModalService, ModalOptions} from "ngx-bootstrap/modal";
import {SupportModalComponent} from "../support/support-modal.component";

@Component({
    selector: 'betech-sidebar',
    templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
    public isOpen: boolean = false;
    public showWorklogCard: boolean = false;
    public logoLocation: string;
    private subs: Subscription[] = [];
    public activeMachine: ProcessingStation;
    public modalRef: BsModalRef;

    constructor(
        private authenticationService: AuthenticationService,
        private activeMachineService: ActiveMachineService,
        private modalService: BsModalService
    ) {}

    public ngOnInit() {
        this.logoLocation = environment.logoSingleColor;
        this.showWorklogCard = environment.config.showWorklogCard;

        this.subs.push(
            this.activeMachineService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * Menu event called from parent
     */
    public onClickedOutside(): void {
        if (this.isOpen) {
            this.expandToggle();
        }
    }

    /**
     * Menu event called from child
     */
    public menuChildEventCalled(): void {
        this.expandToggle();
    }

    /**
     * Toggle expanded class on element
     */
    public expandToggle(): void {
        this.isOpen = !this.isOpen;
    }

    /**
     * open popup modal for support.
     */
    public modalSupport(): void {
        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-support';

        this.modalRef = this.modalService.show(SupportModalComponent, modalOptions);
        this.modalRef.content.setModalRef(this.modalRef);
    }

    /**
     * Log out
     */
    public logout(): void {
        this.authenticationService.logout();
    }
}
