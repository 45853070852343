import { Component, OnInit } from '@angular/core';
import { EsbmodelService, PerformanceTarget, ProductionOrder} from '../../swagger-client';
import { ActiveEntityMediatorService } from '../../services/active-entity-mediator/active-entity-mediator.service';

@Component({
    selector: 'betech-order-performance',
    templateUrl: './order-performance.component.html',
})
export class OrderPerformanceComponent implements OnInit {
    public performanceTargets: PerformanceTarget[];
    public currentProductionOrder: ProductionOrder;
    public isEmpty: boolean;

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit(): void {
        this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                if (currentPO !== null) {
                    this.currentProductionOrder = currentPO;
                    this.getPerformanceTargets(currentPO.order_number);
                }
            });
    }

    public getPerformanceTargets(orderNumber) {
        this.esbModelService.esbmPerformanceTargetGet(orderNumber).subscribe(data => {
            if (data.length !== 0) {
                this.performanceTargets = data;
            } else {
                this.isEmpty = true;
            }
        });
    }
}
