import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { ActiveEntityMediatorService } from '../../services/active-entity-mediator/active-entity-mediator.service';

@Injectable()
export class MainGuard implements CanActivate, CanActivateChild {
    constructor(
        private authService: AuthenticationService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private router: Router,
    ) {}

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        let activeEntityInit = this.activeEntityMediatorService.isInitialized();

        if (!activeEntityInit) {
            this.router.navigateByUrl('/auth/login');
            this.authService.logout();
        }

        return this.authService.isAuthenticated && activeEntityInit;
    }

    public canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        let activeEntityInit = this.activeEntityMediatorService.isInitialized();

        if (!activeEntityInit) {
            this.router.navigateByUrl('/auth/login');
            this.authService.logout();
        }

        return this.authService.isAuthenticated && activeEntityInit;
    }
}
