<div class="login bg-white shadow rounded">
    <h1 class="login__heading">Log in</h1>

    <ul class="login__tabs nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{ active: !admin }" (click)="admin = false"
            >Als operator</a
            >
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{ active: admin }" (click)="admin = true"
            >Als admin</a
            >
        </li>
    </ul>

    <form [formGroup]="_form" (ngSubmit)="_submit()" class="login__form">
        <!-- Username -->
        <div class="form-group">
            <label class="text-primary font-weight-bold" for="inputEmail" i18n>Email adres</label>
            <input
                type="email"
                class="form-control"
                id="inputEmail"
                formControlName="username"
                placeholder="Voer email in"
                i18n-placeholder
            />
        </div>

        <!-- Password -->
        <div class="form-group">
            <label class="text-primary font-weight-bold" for="inputPassword" i18n>Wachtwoord</label>
            <input
                type="password"
                class="form-control"
                id="inputPassword"
                formControlName="password"
                placeholder="Voer wachtwoord in"
                i18n-placeholder
            />
        </div>
        <!-- Machine -->
        <div class="form-group mb-5" *ngIf="constantMachine && !admin">
            <label class="text-primary font-weight-bold" for="inputMachine" i18n>Machine</label>
            <input
                type="text"
                class="form-control"
                id="inputMachine"
                formControlName="machine"
                placeholder="Voer machine code in"
                i18n-placeholder
            />
        </div>

        <!-- Submit button -->
        <div class="clearfix">
            <a class="text-secondary" routerLink="/auth/forgot-password" i18n>Wachtwoord vergeten</a>
            <button
                type="submit"
                class="btn bg-secondary text-white rounded float-right text-uppercase"
                i18n
            >
                Log in
            </button>
        </div>
    </form>

    <!-- Error -->
    <div
        class="flex-row bg-danger text-white px-4 py-2 rounded-bottom"
        *ngIf="_loginFailure === true"
    >
        <span i18n>Verkeerde gebruikersnaam/wachtwoord</span>
    </div>

    <!-- Machine error -->
    <div
        class="flex-row bg-danger text-white px-4 py-2 rounded-bottom"
        *ngIf="_machineFailure === true"
    >
        <span i18n>Machine kon niet worden gevonden</span>
    </div>

    <!-- Multiple machines error -->
    <div
        class="flex-row px-4 py-2 rounded-bottom bg-danger text-white"
        *ngIf="_multipleMachinesError === true"
    >
        <span i18n>Meerdere machines gevonden</span>
        <button
            (click)="_continue()"
            class="btn btn-danger float-right border-0 integrated-btn rounded"
            i18n
        >
            Ok
        </button>
    </div>
</div>
