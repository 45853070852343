<div class="ticket">
  <div class="ticketid">
    {{ ticket.code || '-' }}
  </div>
</div>
<div class="ticket">
  <div class="summary text-wrap">
    {{ ticket.summary || '-' }}
  </div>
</div>
<div class="ticket">
  <div class="row">
    <small class="text-muted">{{ ticket.reporter || '-' }}|</small>
    <small class="text-muted">{{ ticket.created || '-' }}</small>
  </div>
  <div class="row">
    <p class="status">
      <span>{{ ticket.status || '-' }}</span>
    </p>
  </div>
</div>
