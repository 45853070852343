import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Employee, ProcessingStation, ProductionOrder } from '../../swagger-client';
import { Subscription } from 'rxjs';
import {ActiveEntityMediatorService} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit, OnDestroy {
    public showProgressCard: boolean = false;
    public showWorklogCard: boolean = false;
    public activeOrder: boolean = false;

    public activeMachine: ProcessingStation;
    public activeEmployee: Employee;

    private subs: Subscription[] = [];

    constructor(
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit() {
        this.showProgressCard = environment.config.showProgressCard;
        this.showWorklogCard = environment.config.showWorklogCard;

        this.subs.push(
            this.activeEntityMediatorService
                .activeProductionOrder$
                .subscribe((currentPO: ProductionOrder) => {
                    this.activeOrder = currentPO !== null;
                }),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
            this.activeEntityMediatorService.activeEmployee$.subscribe(
                (employee: Employee) => {
                    this.activeEmployee = employee;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    get isInitialized(): boolean {
        return this.activeMachine != null || this.activeEmployee != null;
    }
}
