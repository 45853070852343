<betech-filter-container>
    <betech-filter-processing-station [data]="filters.processingStation"
                                      (filterChange)="changeProcessingStation($event)"></betech-filter-processing-station>
</betech-filter-container>

<div class="container-fluid mx-3 pt-2 -pb-2">
    <div class="row">
        <div class="col-3 px-2 m-1">
            Details
        </div>
        <div class="col px-2 m-1">
            Gisteren
        </div>
        <div class="col px-2 m-1">
            Vandaag
        </div>
        <div class="col-3 px-2 m-1">
            Totaal
        </div>
        <div class="col-3 px-2 m-1 text-right">
            Status
        </div>
        <div class="col m-1">
        </div>
    </div>
</div>

<ng-container *ngIf="performance.length; else noProcessingStations">
    <ng-container *ngFor="let singlePerformanceItem of performance;">
        <betech-performance-machines-entry
            [performance] = singlePerformanceItem
        ></betech-performance-machines-entry>
    </ng-container>
</ng-container>

<ng-container *ngIf="performance.length">
    <div class="m-2 p-2" *ngIf="!receivedAll">
        <button
            class="btn btn-secondary w-100"
            (click)="requestNewStats()"
            [disabled]="loading"
            >
            Laad meer
        </button>
    </div>
</ng-container>

<!-- No contacts -->
<ng-template #noProcessingStations>
    <div class="h-50 text-gray-400 text-center">{{ loading ? "Gegevens ophalen. Dit kan even duren." : "Geen data" }}</div>
</ng-template>
