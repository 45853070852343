import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token/token.service';
import { AuthenticationService, OauthAccessToken } from '../../swagger-client';
import { catchError, filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import { AlertService } from '../../services/alert/alert.service';

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private tokenService: TokenService,
        private alertService: AlertService,
    ) {}

    private addAuthToken(request: HttpRequest<any>): HttpRequest<any> {
        if (!this.tokenService.getToken()) {
            return request;
        }

        return request.clone({
            headers: request.headers.set(
                'Authorization',
                `Bearer ${this.tokenService.getToken().access_token}`,
            ),
        });
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthToken(request);

        return next.handle(request).pipe(
            // catch all errors and alert them for the user to see
            catchError((error: HttpErrorResponse) => {
                if (error.status === 502 || error.status === 503) {
                    console.error(error);
                    return throwError(error);
                }

                // refresh token logic
                if (
                    error.status === 401 &&
                    'error' in error.error &&
                    error.error.error === 'invalid_grant'
                ) {
                    // refresh token logic
                    if (this.isRefreshingToken) {
                        return this.refreshTokenSubject.pipe(
                            filter(result => result !== null),
                            take(1),
                            switchMap(result => next.handle(this.addAuthToken(request))),
                        );
                    } else {
                        this.isRefreshingToken = true;
                        this.refreshTokenSubject.next(null);
                        return this.authenticationService
                            .authTokenGet(
                                'refresh_token',
                                environment.clientId,
                                environment.clientSecret,
                                this.tokenService.getToken().refresh_token,
                            )
                            .pipe(
                                switchMap((token: OauthAccessToken) => {
                                    this.tokenService.setToken(token);
                                    this.isRefreshingToken = false;
                                    this.refreshTokenSubject.next(true);
                                    return next.handle(this.addAuthToken(request));
                                }),
                                catchError((refreshError: HttpErrorResponse) => {
                                    this.router.navigateByUrl('/auth/login');
                                    return of(error);
                                }),
                                finalize(() => (this.isRefreshingToken = false)),
                            );
                    }
                }

                // other errors
                let errorMessage = '';

                // client-side error
                if (error.error instanceof ErrorEvent) {
                    errorMessage = error.error.message;
                } else if (error.status === 0) {
                    // seems redundant, but without the if it's annoyingly refreshing the HTML each time, making inspect life very hard
                    if (this.alertService.isOnline) {
                        this.alertService.isOnline = false;
                    }

                    return throwError(error);
                } else if (error.error.message === 'Validation Failed') {
                    // ignore validation errors, should be handled individually
                    return throwError(error);

                    // other errors
                } else {
                    errorMessage = error.message;
                }

                this.alertService.error(errorMessage);

                return throwError(error);
            }),

            tap((response: HttpResponse<any>) => {
                if (response.status !== 0) {
                    this.alertService.isOnline = true;
                }
            }),
        );
    }
}
