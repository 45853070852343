import { Injectable } from '@angular/core';

@Injectable()
export class BrowserSupportService {
    private innerWidth: number | null;

    /**
     * Verify if the browser is supported
     */
    public verifyBrowser(): boolean {
        this.innerWidth = window.innerWidth;
        return this.isBrowserSupported();
    }

    /**
     * Check if browser and screen is supported
     */
    private isBrowserSupported(): boolean {
        return (
            window.navigator.userAgent.toLowerCase().indexOf('chrome') && this.innerWidth >= 1024
        );
    }
}
