<div class="betech-modal">
  <div class="betech-modal__header">
    <div class="betech-modal__icon">
      <ng-content select="[modal-icon]"></ng-content>
    </div>
    <div class="betech-modal__title">
      <ng-content select="[modal-title]"></ng-content>
    </div>
    <div *ngIf="showCloseButton" class="betech-modal__close" (click)="doClose()">
      <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
    </div>
  </div>
  <div class="betech-modal__body">
    <ng-content select="[modal-body]"></ng-content>
  </div>
  <div class="betech-modal__footer">
    <ng-content select="[modal-footer]"></ng-content>
  </div>
</div>
