<div class="input-group" style="width: 20rem">
    <input
      class="form-control"
      size="10"
      placeholder="Productie- of artikelnummer"
      i18n-placeholder
      [(ngModel)]="orderNumber"
    />
    <div *ngIf="hasCamera" class="input-group-append">
        <a
            class="input-group-text"
            href="#"
            (click)="openQrScanScreen($event)"
        >
            <i class="fal fa-qrcode fa-fw"></i>
        </a>
    </div>
</div>
