<betech-card class="update-notification col-sm-6 col-md-6 col-lg-6 col-xl-4"
             [fullWidth]="true"
             *ngIf="showNotification">
    <i card-icon class="fal fa-3x fa-info fa-fw"></i>
    <span card-title i18n>Update</span>
    <span card-subtitle>{{ today | date: 'dd-MM-yyyy' || '-' }}</span>
    <a card-button class="btn btn-secondary btn-block rounded text-uppercase" (click)="acknowledgeScreen()">
        <span i18n>Sluiten</span>
    </a>

    <ng-container card-info>
        <span i18n>De software is automatisch bijgewerkt op</span>:
        <span class="text-primary">{{ today | date: 'dd/MM/yyyy HH:mm' || '-' }}</span>
        <br>
        <br>
        <span>Eventuele details over de update zijn te vinden in de support mailbox.</span>
    </ng-container>
</betech-card>
