<betech-card clickUrl="/worklog" [fullWidth]="true">
  <i card-icon class="fal fa-3x fa-clock fa-fw"></i>
  <span card-title i18n>Werklog vandaag</span>
  <span card-subtitle>{{ today | date: 'dd-MM-yyyy' || '-' }}</span>
  <ng-container card-info>
    <div class="row">
      <div class="col-sm-4"><span i18n>Aantal gelogde uren</span>:</div>
      <div class="col-sm-8 text-primary">{{ totals?.total_hours | number: '1.0-0' || '-' }}</div>
    </div>
    <div class="row">
      <div class="col-sm-4"><span i18n>Aantal operators</span>:</div>
      <div class="col-sm-8 text-primary">{{ totals?.employees || '-' }}</div>
    </div>
    <div class="row">
      <div class="col-sm-4"><span i18n>Nu aangemeld</span>:</div>
      <div class="col-sm-8 text-primary">
        <span class="mr-2 text-primary" *ngFor="let employee of totals?.active_employees">
          <i class="fa fa-user text-primary"></i> {{ employee.full_name }}
        </span>
      </div>
    </div>
  </ng-container>
  <ng-container card-footer>
    <span i18n>Laatste log op</span>:
    <span class="text-primary">{{
      totals?.last_log?.start_datetime | date: 'dd-MM-yyyy' || '-'
    }}</span
    >&nbsp; <span i18n>om</span>&nbsp;<span class="text-primary">{{
      totals?.last_log?.start_datetime | date: 'HH:mm' || '-'
    }}</span>
  </ng-container>
</betech-card>
