import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    EsbmodelService,
    ProcessingStation,
    ProducedAmountDetails,
    ProductionOrder,
} from '../../../swagger-client';
import { Subscription } from 'rxjs';
import { statusLabel } from '../../../pipes/order-status/order-status.pipe';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UpdateProgressModalComponent } from './update-progress-modal/update-progress-modal.component';
import { ProducedAmountDataService } from '../../../services/produced-amount-data-service/produced-amount-data-service';
import { ActiveEntityMediatorService } from "../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-progress-card',
    templateUrl: './progress-card.component.html',
})
export class ProgressCardComponent implements OnInit, OnDestroy {
    public isLoaded: boolean = false;
    public dueDate: Date;

    public percentage: number = 0;
    public status: string;

    public quantity: number = 0;
    public produced: number = 0;
    public correct: number = 0;
    public incorrect: number = 0;

    public activeMachine: ProcessingStation;
    public currentProductionOrder: ProductionOrder;
    public currentOrder: ProductionOrder;

    private subs: Subscription[] = [];

    public modalRef: BsModalRef;

    constructor(
        private esbModelService: EsbmodelService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private modalService: BsModalService,
        private producedAmountDataService: ProducedAmountDataService,
    ) {}

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public ngOnInit() {
        this.isLoaded = false;

        this.subs.push(
            this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                this.currentProductionOrder = currentPO;
                this.loadDetails();
            }),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );

        this.subs.push(
            this.producedAmountDataService
            .observe()
            .subscribe((producedAmountDetails: ProducedAmountDetails) => {
                if (producedAmountDetails) {
                    this.incorrect = producedAmountDetails.scrap;
                    this.correct = producedAmountDetails.approved;
                    this.produced = producedAmountDetails.processed;

                    this.calculatePercentage();
                }
            })
        );
    }

    private loadDetails() {
        this.currentOrder = this.currentProductionOrder;

        if (null === this.currentOrder) {
            return;
        }

        this.status = statusLabel(this.currentOrder.reported_status);
        this.dueDate = new Date(this.currentOrder.due_date);
        this.quantity = this.currentOrder.quantity;

        this.isLoaded = true;
    }

    private calculatePercentage() {
        this.percentage = Math.round((this.correct / this.quantity) * 100);
    }

    public openUpdateScreen($event) {
        $event.preventDefault();
        $event.stopPropagation();

        if (null === this.currentOrder) {
            return;
        }

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-update-progress';

        this.modalRef = this.modalService.show(UpdateProgressModalComponent, modalOptions);
        this.modalRef.content.setModalRef(this.modalRef, this);
    }

    public update(): void {
        this.producedAmountDataService.forceUpdate();
    }
}
