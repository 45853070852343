import { ProducedAmountDetails, StockRequest } from '../../../swagger-client';
import StatusEnum = StockRequest.StatusEnum;

/**
 * The extra interface is a trick to get all properties without code duplication in the class.
 * Also useful to override setter and getter instead of simple property.
 */
export interface StockRequestModel extends StockRequest {}

export class StockRequestModel implements StockRequestModel {
    /**
     * Make data accessible in the same way as the interface for easy usage
     */
    constructor(private stockRequest: StockRequest) {
        for (const key of Object.keys(stockRequest)) {
            this[key] = stockRequest[key];
        }
    }

    /**
     * ORDERED and BACKORDERED are the only two statusses that should wait for the order to
     * complete and be received/returned. This is a helper for the frontend indicators.
     */
    public isWaiting(): boolean {
        return this.status === StatusEnum.ORDERED;
    }

    /**
     * This is a helper to get the last update date, even if never updated
     */
    public get date(): Date {
        return this._updated_at || this._created_at;
    }

    public calculateMachine(producedAmountDetails: ProducedAmountDetails) {
        if (!this.production_order.planned_material_per_cycle) {
            return this.stock_level;
        }

        const processedParts: number = producedAmountDetails
            ? producedAmountDetails.processed - this.processed_parts
            : 0;
        return Math.max(
            0,
            (this.stock_level || 0) -
                processedParts * this.production_order.planned_material_per_cycle,
        );
    }
}
