<div class="bg-white shadow rounded">
  <h1 class="heading bg-primary text-white p-3">Wachtwoord vergeten</h1>

  <div class="alert alert-danger">Not implemented!</div>

  <form [formGroup]="_form" (ngSubmit)="_submit()" class="p-4">
    <!-- Username -->
    <div class="form-group mb-5">
      <label class="text-primary" for="inputEmail" i18n>Email adres</label>
      <input
        type="email"
        class="form-control"
        id="inputEmail"
        formControlName="username"
        placeholder="Voer email in"
        i18n-placeholder
      />
    </div>

    <!-- Submit button -->
    <div class="clearfix">
      <a class="text-secondary" routerLink="/auth/login" i18n>Terug</a>
      <button type="submit" class="btn bg-secondary text-white rounded float-right" i18n>
        Verstuur wachtwoord
      </button>
    </div>
  </form>
</div>
