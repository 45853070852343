import { Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    SimilarToolXrefs,
    Tool,
    ToolXref,
} from '../../swagger-client';
import { Subscription } from 'rxjs';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ToolTakeoverModalComponent } from './tool-takeover-modal/tool-takeover-modal.component';
import {ActiveMachineService} from "../../services/active-machine/active-machine.service";
import {take} from "rxjs/operators";
import {ActiveEntityMediatorService} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-tool-list',
    templateUrl: './tool-list.component.html',
})
export class ToolListComponent implements OnInit, OnDestroy {
    public toolXrefList: ToolXref[];
    public statusEnum = ProductionOrder.StatusEnum;
    public currentOrder: ProductionOrder;
    public fabricationPartId: string;

    public checkingTools: boolean;

    public toolsNotification: string;

    private activeMachine: ProcessingStation;

    private otherToolsData: Array<SimilarToolXrefs>;

    public toolPositionGroups: ToolXref[][] = [];
    public toolList: Tool[] = [];

    private subs: Subscription[] = [];


    private modalRef;

    public showTools: boolean;

    constructor(
        private _esbModelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private modalService: BsModalService,
        private activeMachineService: ActiveMachineService,
        private zone: NgZone,
    ) {}

    public ngOnInit() {
        this.showTools = false;
        this.toolsNotification = 'Controleren';
        // load current order to be able to get related XRefs
        this.subs.push(
            this.activeMachineService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
            this._activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                // nothing to do..
                if (!currentPO) {
                    this.currentOrder = null;
                    return;
                }

                this.currentOrder = currentPO;
                this.fabricationPartId = currentPO.fabrication_part_id;
                this.refreshTools();
            })
        );

        this.getToolList();
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public onXrefChange(toolXref: ToolXref) {
        // always resort the list for correct UI response
        this.sort(this.toolXrefList);

        // for tool position groups, also update lastReplace, purely to trigger a change, which in turn recalculates forecast
        if (
            toolXref.tool_position.tool_position_group &&
            this.toolPositionGroups[toolXref.tool_position.tool_position_group.id]
        ) {
            this.toolPositionGroups[toolXref.tool_position.tool_position_group.id].forEach(
                (item: ToolXref) => {
                    if (item.id === toolXref.id) {
                        return; // not needed to update same element twice
                    }
                    const index: number = this.toolXrefList.findIndex(
                        (item2: ToolXref) => item2.id === item.id,
                    );

                    this.toolXrefList[index].last_replace = toolXref.last_replace;
                },
            );
        }
    }

    /**
     * Sort the tool xref list
     */
    public sort(unsorted: ToolXref[]): void {
        this.toolXrefList = unsorted.sort((a: ToolXref, b: ToolXref) => {
            // group tool position together
            if (
                a.tool_position.tool_position_group &&
                b.tool_position.tool_position_group &&
                a.tool_position.tool_position_group.id === b.tool_position.tool_position_group.id
            ) {
                return a.tool_position.name < b.tool_position.name ? -1 : 0;
            }

            // and order everything by name
            return a.tool_position.name < b.tool_position.name
                ? -1
                : a.tool_position.name > b.tool_position.name
                ? 1
                : 0;
        });
    }

    public getToolList(): void {
        // also load tools once for dropdowns
        this._esbModelService.esbmToolGet(100).subscribe(data => {
            this.toolList = data.data;
        });
    }

    private setSimilarToolXrefData(data: Array<SimilarToolXrefs>): void {
        this.checkingTools = false;
        this.otherToolsData = data;
        this.toolsNotification = 'Tools Overnemen';
    }

    private refreshTools(): void {
        if (null == this.activeMachine) {
            return;
        }

        this._esbModelService
            .esbmToolXrefGet(this.activeMachine.id, this.currentOrder.id)
            .pipe(take(1))
            .subscribe(data => {
                this.sort(data.data);

                this.toolPositionGroups = [];
                this.toolXrefList.forEach((item: ToolXref) => {
                    if (item.tool_position.tool_position_group) {
                        if (
                            this.toolPositionGroups[
                                item.tool_position.tool_position_group.id
                                ] === undefined
                        ) {
                            this.toolPositionGroups[
                                item.tool_position.tool_position_group.id
                                ] = [];
                        }
                        this.toolPositionGroups[
                            item.tool_position.tool_position_group.id
                            ].push(item);
                    }
                });

                // show same last-replace as 'group leader' (last in group)
                // TODO this should not be needed if the backend also updated the other children in a ToolPositionGroup with the same replace
                for (const groupId of Object.keys(this.toolPositionGroups)) {
                    const group = this.toolPositionGroups[groupId];
                    group.forEach((xref: ToolXref) => {
                        const index: number = this.toolXrefList.findIndex(
                            (item2: ToolXref) => item2.id === xref.id,
                        );
                        this.toolXrefList[index].last_replace =
                            group[group.length - 1].last_replace;
                    });
                }

                this.showTools = true;
            });

        if (this.fabricationPartId !== null && this.fabricationPartId !== undefined) {
            this._esbModelService
                .esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(
                    this.currentOrder.id,
                    this.fabricationPartId,
                )
                .pipe(take(1))
                .subscribe(data => {
                    this.setSimilarToolXrefData(data.data);
                });
        }
    }

    public showToolsModal($event): void {
        $event.preventDefault();
        $event.stopPropagation();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-update-progress';

        this.modalRef = this.modalService.show(ToolTakeoverModalComponent, modalOptions);
        this.modalRef.content.setModalRef(this.modalRef, this.otherToolsData, this);
    }

    public refresh(): void {
        this.zone.run(() => {
            this.showTools = false;
            this.ngOnInit();
        });
    }
}
