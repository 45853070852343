import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProductionOrder } from '../../swagger-client';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { TokenService } from '../../services/token/token.service';
import { DrawingDuringMeasurementService } from '../../services/drawing-during-measurement/drawing-during-measurement.service';
import { Subscription } from 'rxjs';
import {ActiveEntityMediatorService} from "../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-media-slideshow-modal',
    templateUrl: './media-slideshow-modal.component.html',
})
export class MediaSlideshowModalComponent implements OnInit, OnDestroy {
    @ViewChild('image', { static: true }) private image: ElementRef;

    public selectedOrder: ProductionOrder;
    public selectedImageUrl: string;
    private bsModalRef: BsModalRef;

    private imageUrls: Array<string>;
    private rotationHistory: Array<number>;
    public index: number;
    public numberOfImages: number;

    public drawingShown: boolean;

    private subs: Subscription[] = [];

    private currentProductionOrder: ProductionOrder;

    constructor(
        private tokenService: TokenService,
        private drawingDuringMeasurementService: DrawingDuringMeasurementService,
        private activeEntityMediatorService: ActiveEntityMediatorService,
    ) {}

    public ngOnInit() {
        this.drawingShown = this.drawingDuringMeasurementService.load();

        this.subs.push(
            this.drawingDuringMeasurementService
            .observe()
            .subscribe((show: boolean) => {
                this.drawingShown = show;
                this.getOrderDetails();
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    private getOrderDetails(): void {
        if (!this.drawingShown) {
            return;
        }

        if (this.currentProductionOrder === null) {
            return;
        }

        this.subs.push(
            this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentProductionOrder: ProductionOrder) => {
                this.showMediaSlideshow(
                    currentProductionOrder,
                    currentProductionOrder.drawing_image_urls,
                );
            })
        );
    }

    /**
     * show selected image url
     */
    public showMediaSlideshow(item: ProductionOrder, imageUrls: Array<string>): void {
        const newOrder = this.newOrder(item);

        if (newOrder) {
            this.index = 0;
        }

        this.selectedOrder = item;
        this.imageUrls = imageUrls;
        this.numberOfImages = this.imageUrls.length;
        this.rotationHistory = this.makeRotationArray(newOrder);
        this.index = this.index <= this.numberOfImages - 1 ? this.index : this.numberOfImages - 1;

        this.setImage();
    }

    public newOrder(item: ProductionOrder): boolean {
        if (undefined === this.selectedOrder) {
            return true;
        }

        return this.selectedOrder.id !== item.id;
    }

    private makeRotationArray(newOrder: boolean): Array<number> {
        if (newOrder) {
            return Array(this.numberOfImages).fill(0);
        }

        const imageChange = this.numberOfImages - this.rotationHistory.length;

        if (imageChange > 0) {
            const missingValues: Array<number> = Array(imageChange).fill(0);
            this.rotationHistory.concat(missingValues);
        } else if (imageChange < 0) {
            this.rotationHistory.splice(imageChange);
        }

        return this.rotationHistory;
    }

    /**
     * set bsModalRef
     */
    public setModalRef(modalRef: BsModalRef): void {
        this.bsModalRef = modalRef;
    }

    public nextImage(): void {
        this.index++;

        if (this.index === this.numberOfImages) {
            this.index = 0;
        }

        this.setImage();
    }

    public previousImage(): void {
        this.index--;

        if (this.index < 0) {
            this.index = this.numberOfImages - 1;
        }

        this.setImage();
    }

    public setImage(): void {
        const accessToken: string = this.tokenService.getToken().access_token;
        this.selectedImageUrl = `${environment.apiUrl}/api${
            this.imageUrls[this.index]
        }?access_token=${accessToken}`;
        this.image.nativeElement.style.transform = `rotate(${this.rotationHistory[this.index]}deg)`;
    }

    /**
     * Close the popup
     */
    public close(): void {
        if (this.bsModalRef) {
            this.bsModalRef.hide();
            this.bsModalRef = null;

            return;
        }

        if (this.drawingShown) {
            this.drawingDuringMeasurementService.reset();

            return;
        }
    }

    public rotate(): void {
        this.rotationHistory[this.index] += 90;
        this.image.nativeElement.style.transform = `rotate(${this.rotationHistory[this.index]}deg)`;
    }
}
