import { Component, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogConfig } from './dialog.config';
import { DialogReturnType } from './dialog-return-type.enum';
import { take } from 'rxjs/operators';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './dialog.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent {
    private _closeAction: Subject<DialogReturnType> = new Subject<DialogReturnType>();
    public config: DialogConfig;

    constructor(public _bsModalRef: BsModalRef) {}

    get closeAction$(): Observable<DialogReturnType> {
        return this._closeAction.asObservable().pipe(take(1));
    }

    public confirm(): void {
        this._closeAction.next(DialogReturnType.Confirm);
        this._bsModalRef.hide();
    }

    public close(): void {
        this._closeAction.next(DialogReturnType.Cancel);
        this._bsModalRef.hide();
    }
}
