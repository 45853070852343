<div class="navigation">
    <ul class="navigation__tabs nav nav-tabs">
        <li class="nav-item" *ngFor="let item of navigationItems; index as i">
            <a class="nav-link"
               [routerLinkActive]="['active']"
               routerLink="{{item.location}}">
                <div class="font-medium leading-6"> {{item.name}} </div>
            </a>
        </li>
    </ul>
</div>
