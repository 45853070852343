import { Component } from '@angular/core';
import { ChecklistItem, ChecklistService } from 'app/services/checklist/checklist.service';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ChecklistComponent } from 'app/main/checklist/checklist/checklist.component';
import { ReportedChecklist, ReportedChecklistItem } from '../../../swagger-client';
import { IntervalObservable } from 'rxjs-compat/observable/IntervalObservable';

@Component({
    selector: 'betech-checklist-card',
    templateUrl: './checklist-card.component.html',
    styleUrls: ['./checklist-card.component.css'],
})
export class ChecklistCardComponent {
    public modalRef: BsModalRef;
    public checklistItemStatus = ReportedChecklistItem.StatusEnum;

    constructor(
        public readonly checklistService: ChecklistService,
        private modalService: BsModalService,
    ) {}

    public status(status: number): string {
        return this.checklistItemStatus[status];
    }

    public get remaining(): string {
        return this.checklistService.remaining;
    }

    public openModal($event: MouseEvent | TouchEvent) {
        $event.stopPropagation();
        $event.preventDefault();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'checklist-modal';

        this.modalRef = this.modalService.show(ChecklistComponent, modalOptions);
        this.modalRef.content.setAsModal(this.modalRef);
    }
}
