<div class="checklist" *ngIf="checklistService.checklist">
  <div class="checklist-toolbar" [class.checklist-toolbar--done]="checklistService.done">
    <div class="checklist-toolbar-title mr-5">
      {{ checklistService.checklist.name }}
    </div>
    <div class="checklist-toolbar-counter mr-auto">
      {{ checklistService.itemsDone.length }} van {{ checklistService.items.length }} afgerond
    </div>
    <div *ngIf="!checklistService.done" style="width: 25%">
      <betech-progress-bar [value]="checklistService.progress"></betech-progress-bar>
    </div>
    <div *ngIf="checklistService.done">
      <a (click)="return()" class="btn btn-success btn-lg checklist-toolbar-button">
        Door naar dashboard
      </a>
    </div>
    <div *ngIf="!checklistService.done && !!bsModalRef">
      <div class="betech-modal__close" (click)="return()">
        <i class="fa-2x fas fa-times ml-4 px-2 py-1 rounded border border-dark"></i>
      </div>
    </div>
  </div>
  <div class="checklist-section">
    <ng-container *ngIf="checklistService.itemsTodo.length">
      <p class="checklist-placeholder">Taken</p>
      <betech-checklist-item
        *ngFor="let item of checklistService.itemsTodo"
        [item]="item"
        [checklistOverrideActiveEmployee]="checklistOverrideActiveEmployee"
      ></betech-checklist-item>
    </ng-container>
    <ng-container *ngIf="!checklistService.itemsTodo.length">
      <p class="checklist-placeholder">
        <i class="fal fa-check mr-3"></i>
        Je hebt alle items afgerond. De checklist wordt over
        {{ checklistService.remaining }} automatisch afgerond.
      </p>
    </ng-container>
  </div>
  <div class="checklist-section">
    <ng-container *ngIf="checklistService.itemsDone.length">
      <p class="checklist-placeholder">Afgeronde items</p>
      <betech-checklist-item
        *ngFor="let item of checklistService.itemsDone"
        [item]="item"
      ></betech-checklist-item>
    </ng-container>
    <ng-container *ngIf="!checklistService.itemsDone.length">
      <p class="checklist-placeholder">Je hebt nog geen items afgerond.</p>
    </ng-container>
  </div>
</div>
<div class="checklist" *ngIf="!checklistService.checklist">
  <div class="checklist-toolbar checklist-toolbar--done">
    <div class="checklist-toolbar-title mr-5">Geen checklist actief</div>
    <div class="checklist-toolbar-counter mr-auto">
      Hier komt automatisch een nieuwe checklist te staan
    </div>
    <a (click)="return()" class="btn btn-success btn-lg checklist-toolbar-button">
      Door naar dashboard
    </a>
  </div>
</div>
