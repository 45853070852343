/**
 * The extra interface is a trick to get all properties without code duplication in the class.
 * Also useful to override setter and getter instead of simple property.
 */
import {
    MeasurementSample,
    SensorData,
    ProductionOrderStats,
    StockRequest,
} from '../../swagger-client';
import StatusEnum = StockRequest.StatusEnum;

export interface ProductionOrderStatsModel extends ProductionOrderStats {}

export class ProductionOrderStatsModel implements ProductionOrderStatsModel {
    /**
     * Make data accessible in the same way as the interface for easy usage
     */
    constructor(productionOrderStats: ProductionOrderStats) {
        for (const key of Object.keys(productionOrderStats)) {
            this[key] = productionOrderStats[key];
        }
    }

    // TODO warning: will return NaN when planned_cycle_time = 0..
    public getMeasurementTimeUntilNextMeasurement(): number {
        if (!this.measurement_last_count) {
            return (
                (this.planned_measurement_count - (this.processed_parts || 0)) *
                this.planned_cycle_time
            ); // no last measurement means: go do it now!
        }

        return (
            (this.planned_measurement_count -
                ((this.processed_parts || 0) - this.measurement_last_count)) *
            this.planned_cycle_time
        );
    }

    public getMeasurementTimeUntilNextAction(): number {
        return !this.planned_measurement_count
            ? 0 // danger zone without timer when no measurement count
            : this.measurement_last_status === MeasurementSample.StatusEnum.PROCESSEDFAILURE &&
              this.measurement_last_at instanceof Date
            ? Math.round((this.measurement_last_at.getTime() - new Date().getTime()) / 1000) // in seconds
            : this.getMeasurementTimeUntilNextMeasurement();
    }

    public getToolTimeUntilNextReplace(): number {
        return (
            ((this.tool_lowest_quantity_needed || 0) - (this.processed_parts || 0)) *
            this.planned_cycle_time
        );
    }

    public getToolTimeUntilNextAction(): number {
        return !this.tool_tools ? 0 : this.getToolTimeUntilNextReplace();
    }

    /**
     * Determine time left until machine is empty based on current stock, planned material per cycle and planned cycle time
     */
    public getStockTimeUntilNextAction(): number {
        if (!this.planned_material_per_cycle) {
            return 0;
        }

        if (!this.planned_cycle_time) {
            return 0;
        }

        return (
            (this.calculateMachine() / this.planned_material_per_cycle) * this.planned_cycle_time
        );
    }

    public calculateMachine(): number {
        if (!this.planned_material_per_cycle) {
            return this.stock_request_machine || 0;
        }

        return (
            (this.stock_request_machine || 0) -
            (this.processed_parts || 0) * this.planned_material_per_cycle
        );
    }

    public getEarliestTimeUntilNextAction(): number {
        return [
            this.getMeasurementTimeUntilNextAction(),
            this.getStockTimeUntilNextAction(),
            this.getToolTimeUntilNextAction(),
        ].sort((a, b) => a - b)[0];
    }

    public getUrgency(
        type: ProductionOrderStatsModel.UrgencyEnum,
    ): ProductionOrderStatsModel.PriorityEnum {
        let t: number;
        switch (type) {
            case 'measurement_action':
                t = this.getMeasurementTimeUntilNextAction();
                break;
            case 'measurement_next':
                t = this.getMeasurementTimeUntilNextMeasurement();
                break;
            case 'tool_action':
                t = this.getToolTimeUntilNextAction();
                break;
            case 'tool_replace':
                t = this.getToolTimeUntilNextReplace();
                break;
            case 'material_action':
                t = this.getStockTimeUntilNextAction();
                break;
            case 'material_surplus':
                return this.stock_request_surplus < 0
                    ? ProductionOrderStatsModel.PriorityEnum.PRIORITY_HIGH
                    : ProductionOrderStatsModel.PriorityEnum.PRIORITY_LOW;
            case 'dashboard':
                t = this.getEarliestTimeUntilNextAction();
                break;
        }

        switch (true) {
            case t <= 10 * 60:
                return ProductionOrderStatsModel.PriorityEnum.PRIORITY_URGENT;
            case t < 60 * 60:
                return ProductionOrderStatsModel.PriorityEnum.PRIORITY_HIGH;
            case t < 120 * 60:
                return ProductionOrderStatsModel.PriorityEnum.PRIORITY_NORMAL;
            default:
                return ProductionOrderStatsModel.PriorityEnum.PRIORITY_LOW;
        }
    }

    public getUrgencyClass(type: ProductionOrderStatsModel.UrgencyEnum) {
        switch (this.getUrgency(type)) {
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_URGENT:
                return 'danger';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_HIGH:
                return 'warning';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_NORMAL:
                return 'attention';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_LOW:
                return 'success';
        }
    }

    public getUrgencyIcon(type: ProductionOrderStatsModel.UrgencyEnum) {
        switch (this.getUrgency(type)) {
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_URGENT:
                return 'fa-exclamation-circle';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_HIGH:
                return 'fa-exclamation-triangle';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_NORMAL:
                return 'fa-hourglass';
            case ProductionOrderStatsModel.PriorityEnum.PRIORITY_LOW:
                return 'fa-check';
        }
    }
}

export namespace ProductionOrderStatsModel {
    export type UrgencyEnum =
        | 'measurement_action'
        | 'measurement_next'
        | 'tool_replace'
        | 'tool_action'
        | 'material_action'
        | 'material_surplus'
        | 'dashboard';

    export type PriorityEnum = 10 | 8 | 5 | 2;
    export const PriorityEnum = {
        PRIORITY_URGENT: 10 as PriorityEnum,
        PRIORITY_HIGH: 8 as PriorityEnum,
        PRIORITY_NORMAL: 5 as PriorityEnum,
        PRIORITY_LOW: 2 as PriorityEnum,
    };
}
