/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ProductionOrderResponseDomain } from '../model/productionOrderResponseDomain';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProductionOrdersService {

    protected basePath = 'http://localhost/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get ProductionOrders
     * 
     * @param order_number 
     * @param article_number 
     * @param status 
     * @param reported_status 
     * @param cnc 
     * @param production_number 
     * @param company_name 
     * @param quantity 
     * @param delivery_date 
     * @param material 
     * @param sort_by 
     * @param calculation_statuses 
     * @param q 
     * @param offset 
     * @param limit 
     * @param order 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public esbmProductionOrderGet(order_number?: string, article_number?: string, status?: string, reported_status?: string, cnc?: boolean, production_number?: string, company_name?: string, quantity?: string, delivery_date?: string, material?: boolean, sort_by?: string, calculation_statuses?: string, q?: string, offset?: number, limit?: number, order?: string, observe?: 'body', reportProgress?: boolean): Observable<ProductionOrderResponseDomain>;
    public esbmProductionOrderGet(order_number?: string, article_number?: string, status?: string, reported_status?: string, cnc?: boolean, production_number?: string, company_name?: string, quantity?: string, delivery_date?: string, material?: boolean, sort_by?: string, calculation_statuses?: string, q?: string, offset?: number, limit?: number, order?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProductionOrderResponseDomain>>;
    public esbmProductionOrderGet(order_number?: string, article_number?: string, status?: string, reported_status?: string, cnc?: boolean, production_number?: string, company_name?: string, quantity?: string, delivery_date?: string, material?: boolean, sort_by?: string, calculation_statuses?: string, q?: string, offset?: number, limit?: number, order?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProductionOrderResponseDomain>>;
    public esbmProductionOrderGet(order_number?: string, article_number?: string, status?: string, reported_status?: string, cnc?: boolean, production_number?: string, company_name?: string, quantity?: string, delivery_date?: string, material?: boolean, sort_by?: string, calculation_statuses?: string, q?: string, offset?: number, limit?: number, order?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (order_number !== undefined && order_number !== null) {
            queryParameters = queryParameters.set('order_number', <any>order_number);
        }
        if (article_number !== undefined && article_number !== null) {
            queryParameters = queryParameters.set('article_number', <any>article_number);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (reported_status !== undefined && reported_status !== null) {
            queryParameters = queryParameters.set('reported_status', <any>reported_status);
        }
        if (cnc !== undefined && cnc !== null) {
            queryParameters = queryParameters.set('cnc', <any>cnc);
        }
        if (production_number !== undefined && production_number !== null) {
            queryParameters = queryParameters.set('production_number', <any>production_number);
        }
        if (company_name !== undefined && company_name !== null) {
            queryParameters = queryParameters.set('company_name', <any>company_name);
        }
        if (quantity !== undefined && quantity !== null) {
            queryParameters = queryParameters.set('quantity', <any>quantity);
        }
        if (delivery_date !== undefined && delivery_date !== null) {
            queryParameters = queryParameters.set('delivery_date', <any>delivery_date);
        }
        if (material !== undefined && material !== null) {
            queryParameters = queryParameters.set('material', <any>material);
        }
        if (sort_by !== undefined && sort_by !== null) {
            queryParameters = queryParameters.set('sort_by', <any>sort_by);
        }
        if (calculation_statuses !== undefined && calculation_statuses !== null) {
            queryParameters = queryParameters.set('calculation_statuses', <any>calculation_statuses);
        }
        if (q !== undefined && q !== null) {
            queryParameters = queryParameters.set('q', <any>q);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (order !== undefined && order !== null) {
            queryParameters = queryParameters.set('order', <any>order);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ProductionOrderResponseDomain>(`${this.basePath}/esbm/production-order`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
