import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {
    EsbmodelService,
    ProcessingStation,
    ProcessingStationTotals,
} from '../../../swagger-client';
import { ActiveMachineService } from '../../../services/active-machine/active-machine.service';
import {Subscription} from "rxjs";

@Component({
    selector: 'betech-worklog-card',
    templateUrl: './worklog-card.component.html',
})
export class WorklogCardComponent implements OnInit, OnDestroy {
    private readonly interval = 1000 * 65; // every minute + 5 sec (to avoid too many overlaps)
    private interval$;
    private activeMachine: ProcessingStation;

    public totals: ProcessingStationTotals;
    public today: number;

    private subs: Subscription[] = [];

    constructor(
        private _activeMachineService: ActiveMachineService,
        private _esbmodelService: EsbmodelService,
    ) {
        this.today = Date.now();
    }

    /**
     * start interval
     */
    public ngOnInit(): void {
        this.subs.push(
            this._activeMachineService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );
        this.refreshTotals();
        this.interval$ = setInterval(() => this.refreshTotals(), this.interval);
    }

    public ngOnDestroy() {
        clearInterval(this.interval$);
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * Refresh totals from backend
     */
    private refreshTotals(): void {
        if (!this.activeMachine) {
            return;
        }

        this._esbmodelService
            .esbmWorklogTotalsGet(this.activeMachine.id)
            .subscribe((procTotals: ProcessingStationTotals) => {
                this.totals = procTotals;
            });
    }
}
