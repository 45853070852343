<betech-filter-container>
  <betech-filter-department (filterChange)="filter.filter('1', $event)"></betech-filter-department>
</betech-filter-container>

<table class="dashboard-list betech-table">
  <thead>
    <tr class="betech-table__row">
      <th class="betech-table__heading"><span i18n>Actie over</span></th>
      <th class="betech-table__heading"><span i18n>Machine</span></th>
      <th class="betech-table__heading"><span i18n>Productie Nummer</span></th>
      <th class="betech-table__heading"><span i18n>Voortgang</span></th>
      <th class="betech-table__heading"><span i18n>Materiaal</span></th>
      <th class="betech-table__heading"><span i18n>Meting</span></th>
      <th class="betech-table__heading"><span i18n>Tools</span></th>
      <th class="betech-table__heading"><span i18n>Gelogd</span></th>
    </tr>
  </thead>
  <ng-container *ngFor="let stat of filter.current">
    <tr class="betech-table__row" [ngClass]="{ 'betech-table__row--highlight': isHighlight(stat) }">
      <td class="betech-table__cell">
        <div class="d-flex d-flex justify-content-around align-items-center">
          <div *ngIf="isHighlight(stat)">
            <i
              class="fas fa-2x fa-fw"
              [ngClass]="
                stat.getUrgencyClass('dashboard') === 'danger'
                  ? 'fa-exclamation-circle text-danger'
                  : 'fa-hourglass text-warning'
              "
            ></i>
          </div>

          <div class="text-left">
            <span i18n>Actie vereist over</span><br />
            <strong class="text-danger" *ngIf="stat.getUrgencyClass('dashboard') === 'danger'"
              >NU!&nbsp;</strong
            >
            <span [ngClass]="['text-' + stat.getUrgencyClass('dashboard')]">{{
              stat.getEarliestTimeUntilNextAction() | forecast
            }}</span>
          </div>
        </div>
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.trumpf_id || '(no trumpf_id)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.production_number || '(Geen productie nummer)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.processed_parts || '-' }} / {{ stat.total_parts || '?' }}
      </td>
      <td
        class="betech-table__cell"
        [ngClass]="['text-' + stat.getUrgencyClass('material_action')]"
      >
        <strong>{{ stat.calculateMachine() || 0 | number: '1.0-1' }} m.</strong>
      </td>
      <td class="betech-table__cell" *ngIf="!stat.planned_measurement_count">
        <span title="No planned measurement_count configured for production order!" i18n-title
          >??</span
        >
      </td>
      <td class="betech-table__cell" *ngIf="stat.planned_measurement_count">
        <ng-container
          *ngIf="stat.getUrgencyClass('measurement_next') === 'danger'; else nextMeasurement"
        >
          <strong class="text-danger" i18n>Nu meten</strong>
        </ng-container>

        <ng-template #nextMeasurement>
          <span [ngClass]="['text-' + stat.getUrgencyClass('measurement_next')]">{{
            stat.getMeasurementTimeUntilNextMeasurement() | forecast
          }}</span>
        </ng-template>
      </td>
      <td class="betech-table__cell">
        <ng-container *ngIf="stat.getUrgencyClass('tool_replace') === 'danger'; else next">
          <strong class="text-danger" i18n>Wissel nu</strong>
        </ng-container>

        <ng-template #next>
          <span [ngClass]="['text-' + stat.getUrgencyClass('tool_replace')]">{{
            stat.getToolTimeUntilNextReplace() | forecast
          }}</span>
        </ng-template>
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.active_employees }}
      </td>
    </tr>
  </ng-container>
</table>
