import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

// TODO: set locale somewhere global maybe
moment.locale('nl');

@Pipe({
    name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
    public transform(timestamp: any, args?: any): any {
        return moment(timestamp).fromNow();
    }
}
