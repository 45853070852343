<div class="container-fluid">
    <div class="row mx-1 mt-3 py-2 bg-white shadow-sm rounded overflow-hidden" style="z-index: 200">
        <!--Details-->
        <div class="col-2 m-1 px-2">
            <div class="container">
                <div class="row">
                    <div class="col-2 bg-primary rounded items-center text-white p-0" style="aspect-ratio: 1 / 1;">
                        <div class="d-flex align-content-around flex-wrap h-100">
                            <div class="w-100 text-center font-medium leading-6">
                                <i class="fa fa-user"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col px-2">
                        <div class="d-flex align-content-around flex-wrap h-100 ">
                            <div class="w-100">
                                <span class="h-50 align-middle">
                                    <strong> {{ activeProductionOrderPerformance.employee_name }} </strong>
                                </span>
                            </div>
                            <div class="w-100">
                                <span class="h-50 align-middle">
                                    <strong> {{ activeProductionOrderPerformance.processing_station?.trumpf_id || activeProductionOrderPerformance.processing_station?.name }} </strong>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Production number-->
        <div class="col-3 m-1 px-2 border-left border-light text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100 ">
                <div class="w-100">
                    <span class="h-50 align-middle">
                        {{ activeProductionOrderPerformance.active_production_order?.production_number }} /
                        {{ activeProductionOrderPerformance.active_production_order?.order_line_number }}
                    </span>
                </div>
                <div class="w-100">
                    <span class="h-50 align-middle">
                        {{ activeProductionOrderPerformance.active_production_order?.fabrication_part_name }}
                    </span>
                </div>
            </div>
        </div>
        <!--Fabrication Part Name-->
        <div class="col-1 m-1 px-2 border-left border-light text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100 ">
                <div class="w-100">
                    <span class="h-50 align-middle">
                        {{ startDate | date: 'd LLL HH:mm'  }}
                    </span>
                </div>
                <div class="w-100">
                    <span class="h-50 align-middle">
                        <betech-progress-bar [value]="durationPercentage" color="success" class="m-auto w-100"></betech-progress-bar>
                    </span>
                </div>
            </div>
        </div>
        <!--Totaal-->
        <div class="col-2 m-1 px-2 border-left border-l-gray-200 text-gray-400">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100">
                    <div class="d-flex flex-row align-content-around">
                        <div class="h-full w-100 items-center text-right">
                            <span class="text-warning">{{ activeProductionOrderPerformance.reported_scrap_parts }}</span>
                            /&nbsp;{{ activeProductionOrderPerformance.reported_processed_parts }}&nbsp;&nbsp;afkeur
                            &nbsp;&nbsp;{{ declinedPercentage }}%
                        </div>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-flex flex-row align-content-around">
                        <div class="h-full w-100 items-center text-right">
                            <span class="text-success">{{ activeProductionOrderPerformance.reported_approved_parts }}</span>
                            /&nbsp;{{ activeProductionOrderPerformance.required_parts }}&nbsp; gereed
                            &nbsp;&nbsp;{{ readyPercentage }}%
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Status-->
        <div class="col m-1 border-left border-light">
            <div class="d-flex align-content-around flex-wrap h-100">
                <div class="w-100 text-right">
                    <div class="d-flex align-content-around justify-content-end flex-wrap text-right h-100">
                        <div><i class="fa fa-sync mx-2"></i><span> {{remaining || 0}} resterend</span></div>&nbsp;
                        <span *ngIf="activeProductionOrderPerformance.active_production_order.reported_status === 'STARTED'"  class="text-success">Actief <i class="fa fa-sync"></i></span>
                        <span *ngIf="activeProductionOrderPerformance.active_production_order.reported_status === 'PAUSED'" class="text-warning">Pauze <i class="fa fa-hourglass-start"></i></span>
                        <span *ngIf="activeProductionOrderPerformance.active_production_order.reported_status === 'PREPARED'" class="text-success">Voorbereiden <i class="fa fa-ballot-check"></i></span>
                    </div>
                </div>
                <div class="w-100">
                    <div class="d-flex align-content-around justify-content-end flex-wrap text-right h-100">
                    <div *ngIf="performanceTarget?.employee !== null && amountDay"><i class="fa fa-check mx-2"></i><span>Theoretisch /dag: {{ amountDay || '-' }}</span></div>
                    <div *ngIf="performanceTarget?.employee === null && trueAmountDay"><i class="fa fa-check mx-2"></i><span>Gemeten /dag: {{ trueAmountDay || '-' }}</span></div>
                    <div><i class="fa fa-calendar mx-2"></i><span>Geupdate: {{
                        (performanceTarget?.timestamp | date: 'shortDate') || '-'
                    }}</span></div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingOrderInfo>
    <div class="flex flex-col px-2">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>


<ng-template #loadingProductionOrder>
    <div class="basis-9/12 m-1 px-2 border-left border-light text-gray-400">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>

<ng-template #noProductionOrderDetails>
    <div  class="w-full m-4 px-2 text-gray-400">
        <div class="flex flex-row h-full items-center w-full text-center">
            <div class="w-full text-center">Geen data</div>
        </div>
    </div>
</ng-template>
