import { Component, OnDestroy, OnInit } from '@angular/core';
import { InMemoryFilter } from '../../filter/filter';
import {
    Performance,
    ProductionOrderPerformanceTotals,
    ProcessingStation,
} from '../../../swagger-client';
import { Subscription } from 'rxjs';
import { MachinePerformanceService } from '../../../services/machine-performance/machine-performance.service';

@Component({
    templateUrl: './machines-performance.component.html',
})
export class MachinesPerformanceComponent implements OnInit, OnDestroy {
    public filter: InMemoryFilter = new InMemoryFilter();

    public filters: {
        processingStation: ProcessingStation;
    } = {
        processingStation: null,
    };

    public total: number;
    public receivedAll: boolean;
    public loading: boolean;
    private page: number;
    public performance: Array<Performance>;
    private subs: Subscription[] = [];

    constructor(private machinePerformance: MachinePerformanceService) {}

    public ngOnInit(): void {
        this.resetContent();
        this.loadInitialData();
        this.subs.push(
            this.machinePerformance.machinePerformance$.subscribe(data =>
                this.appendMachinePerformance(data),
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public requestNewStats(): void {
        this.loading = true;
        this.machinePerformance
            .fetch(++this.page, this.filters.processingStation?.trumpf_id.replace(/X/g, '\\d'))
            .subscribe();
    }

    private loadInitialData(): void {
        this.loading = true;
        this.machinePerformance.fetch().subscribe();
    }

    private appendMachinePerformance(response: ProductionOrderPerformanceTotals): void {
        this.loading = false;

        this.total = response.total;
        this.performance = [...this.performance, ...response.data];

        this.receivedAll = this.performance.length == this.total || response.data.length != 10;
    }

    private resetContent(): void {
        this.page = 1;
        this.performance = [];
        this.filters.processingStation = undefined;
        this.receivedAll = false;
    }

    public changeProcessingStation(station) {
        this.resetContent();
        this.page = 0;
        this.filters.processingStation = station;
        this.requestNewStats();
    }
}
