import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/dialog.component';
import { DialogConfig } from '../../shared/confirmation-dialog/dialog.config';
import { Observable } from 'rxjs';
import { DialogReturnType } from '../../shared/confirmation-dialog/dialog-return-type.enum';
import { merge } from 'lodash-es';

@Injectable()
export class ConfirmationService {
    private _defaultConfig: DialogConfig = {
        title: 'Bevestig actie',
        message: {
            text: 'Weet je zeker dat je deze actie wilt uitvoeren?',
            alert: false,
        },
        icon: {
            show: true,
            name: 'fa-question',
        },
        actions: {
            confirm: {
                show: true,
                label: 'Bevestigen',
                class: 'btn-secondary',
            },
            cancel: {
                show: true,
                label: 'Annuleren',
                class: 'btn-outline-secondary',
            },
        },
        dismissible: false,
    };

    constructor(private _modalService: BsModalService) {}

    public open(config: DialogConfig = {}): Observable<DialogReturnType> {
        const userConfig = merge({}, this._defaultConfig, config);
        const modalRef = this._modalService.show(ConfirmationDialogComponent, {
            initialState: { config: userConfig },
        });
        const dialog: ConfirmationDialogComponent = modalRef.content;

        return dialog.closeAction$;
    }
}
