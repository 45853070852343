import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from 'environments/environment';
import { SupportInterface } from './support.interface';

@Injectable()
export class SupportService {
    private formUpdates = new Subject();

    private formSubscriber = this.formUpdates.asObservable();

    private apiUrl: string = environment.apiUrl;

    private postTicketEndpoint: string = '/api/esbm/support';
    private getTicketsEndpoint: string = '/api/esbm/support/tickets';

    private defaultLog;
    private defaultError;
    private defaultWarning;

    private savedLogs;
    private savedErrors;
    private savedWarnings;

    constructor(private httpClient: HttpClient) {
        this.saveLogs();
        this.saveWarnings();
        this.saveErrors();
    }

    public submit(Data: SupportInterface): Observable<any> {
        const formData = new FormData();

        formData.append('email', Data.email);
        formData.append('description', Data.description);
        formData.append('logs', JSON.stringify(this.savedLogs));
        formData.append('warnings', JSON.stringify(this.savedWarnings));
        formData.append('errors', JSON.stringify(this.savedErrors));
        formData.append('url', Data.url);
        formData.append('screenshot', Data.screenshot);

        return this.httpClient.post<any>(this.apiUrl + this.postTicketEndpoint, formData);
    }

    public getFormSubscriber(): Observable<any> {
        return this.formSubscriber;
    }

    public sendUpdateToSubsriber(update: any): void {
        this.formUpdates.next(update);
    }

    private saveLogs(): void {
        this.savedLogs = ['logs'];
        this.defaultLog = console.log.bind(console);

        console.log = function () {
            this.defaultLog.apply(console, arguments);
            this.savedLogs.push(Array.from(arguments));
        }.bind(this);
    }

    private saveWarnings(): void {
        this.savedWarnings = ['warning'];
        this.defaultWarning = console.warn.bind(console);

        console.warn = function () {
            this.defaultWarning.apply(console, arguments);
            this.savedWarnings.push(Array.from(arguments));
        }.bind(this);
    }

    private saveErrors(): void {
        this.savedErrors = ['error'];
        this.defaultError = console.error.bind(console);

        console.error = function () {
            this.defaultError.apply(console, arguments);
            this.savedErrors.push(Array.from(arguments));
        }.bind(this);
    }

    public getTickets(): Observable<any> {
        return this.httpClient.get(this.apiUrl + this.getTicketsEndpoint);
    }
}
