<div class="betech-table">
  <div class="betech-table__row">
    <div class="betech-table__heading"><span i18n>Operator</span></div>
    <div class="betech-table__heading"><span i18n>Datum</span></div>
    <div class="betech-table__heading"><span i18n>Start</span></div>
    <div class="betech-table__heading"><span i18n>Eind</span></div>
    <div class="betech-table__heading"><span i18n>Tijd</span></div>
    <div class="betech-table__heading"></div>
    <div class="betech-table__heading"></div>
  </div>
  <betech-worklog-list-new
    class="betech-table__row betech-table__row--highlight betech-table__row--editing"
    [employeeList]="employeeList"
    [orderList]="orderList"
    [worklogList]="worklogList"
    [activeMachine]="activeMachine"
    [activeEmployee]="activeEmployee"
  ></betech-worklog-list-new>
  <ng-container *ngFor="let worklog of worklogList; let i = index">
    <betech-worklog-list-item
      class="betech-table__row"
      [worklog]="worklog"
      [employeeList]="employeeList"
      [orderList]="orderList"
      (worklogChange)="sortWorklogList()"
    ></betech-worklog-list-item>
  </ng-container>
</div>
