<betech-filter-container>
    <betech-filter-processing-station [data]="filters.processingStation"
                                      (filterChange)="changeProcessingStation($event)"></betech-filter-processing-station>
</betech-filter-container>

<div class="container-fluid px-4 pt-2 -pb-2">
    <div class="row">
        <div class="col-2 px-2 m-1">
            Details
        </div>
        <div class="col-3 px-2 m-1">
            Productie
        </div>
        <div class="col-1 px-2 m-1">
            Productietijd
        </div>
        <div class="col-2 px-2 m-1">
            Totaal
        </div>
        <div class="col px-2 m-1 text-right">
            Status
        </div>
    </div>
</div>

<ng-container *ngIf="performance.length; else noEmployees">
    <ng-container *ngFor="let activeProductionOrderPerformance of performance; let i = index; trackBy:trackByFunc">
        <betech-performance-employees-entry *ngIf="activeProductionOrderPerformance.active_production_order.order_number"
            [activeProductionOrderPerformance] = activeProductionOrderPerformance
        ></betech-performance-employees-entry>
        <betech-performance-employees-not-active *ngIf="!activeProductionOrderPerformance.active_production_order.order_number"
                                            [activeProductionOrderPerformance] = activeProductionOrderPerformance
        ></betech-performance-employees-not-active>
    </ng-container>
</ng-container>

<ng-container *ngIf="performance.length && !receivedAll">
    <div class="m-2 p-2">
        <button
            class="btn btn-secondary w-100"
            (click)="requestNewPerformance()"
            [disabled]="loading"
        >
            Laad meer
        </button>
    </div>
</ng-container>

<!-- No contacts -->
<ng-template #noEmployees>
    <div class="h-50 text-gray-400 text-center">{{ loading ? "Gegevens ophalen. Dit kan even duren." : "Geen data" }}</div>
</ng-template>
