import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SimilarToolXrefs } from '../../../../swagger-client';

@Component({
    selector: 'betech-tool-takeover-item',
    templateUrl: './tool-takeover-item.component.html',
})
export class ToolTakeoverItemComponent implements OnInit, OnChanges {
    @Input()
    public ownTooldata: SimilarToolXrefs;

    @Input()
    public choosenTools: SimilarToolXrefs;

    @Output()
    public toolTakeoverChange = new EventEmitter<SimilarToolXrefs>();

    public productionNumber: string;
    public machineNumber: string;

    public isChoosen: boolean;
    public show: boolean;

    public ngOnInit(): void {
        if (
            this.ownTooldata._id.productionNumber &&
            this.ownTooldata._id.reportedProcessingStation &&
            this.ownTooldata._id.reportedProcessingStation.name
        ) {
            this.productionNumber = this.ownTooldata._id.productionNumber;
            this.machineNumber = this.ownTooldata._id.reportedProcessingStation.trumpfId;
            this.show = true;
        } else {
            this.show = false;
        }
    }

    public ngOnChanges() {
        this.checkIfActive();
    }

    public select(): void {
        this.toolTakeoverChange.emit(this.ownTooldata);
        this.isChoosen = true;
    }

    private checkIfActive(): void {
        if (this.choosenTools == null || this.ownTooldata == null) {
            this.isChoosen = false;
            return;
        }
        this.isChoosen =
            this.ownTooldata._id.productionNumber === this.choosenTools._id.productionNumber &&
            this.ownTooldata._id.reportedProcessingStation.trumpfId ===
                this.choosenTools._id.reportedProcessingStation.trumpfId;
    }
}
