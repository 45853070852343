import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'betech-header',
    templateUrl: 'header.component.html',
})
export class HeaderComponent implements OnInit {
    public logoLocation: string;

    public ngOnInit() {
        this.logoLocation = environment.logoMultipleColors;
    }
}
