import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'betech-remark-modal',
    templateUrl: './remark-modal.component.html',
})
export class RemarkModalComponent {
    @Input() public remark: string;
    public modalRef: BsModalRef;

    constructor() {}

    public setModalRef(modalRef: BsModalRef): void {
        this.modalRef = modalRef;
    }
}
