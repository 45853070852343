<div class="update-progress-modal">
  <div class="betech-modal__header">
    <h2>Voortgang historie informatie</h2>
  </div>
  <div class="container-fluid">
    <div class="row" *ngIf="data">
      <div class="col-6" style="padding: 1rem 2rem 2rem">
        <div class="row">
          <div class="col-6">
            <label>Reeds goedgekeurd</label>
            <input type="text" value="{{ data.stats.approved }}" disabled />
          </div>
          <div class="col-6">
            <label>Reeds afgekeurd</label>
            <input type="text" value="{{ data.stats.scrap }}" disabled />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Totaal geproduceerd</label>
            <input type="text" value="{{ data.stats.processed }}" disabled />
          </div>
          <div class="col-6">
            <label>Te produceren</label>
            <input type="text" value="{{ data.stats.quantity }}" disabled />
          </div>
          <div class="col-12">
            <label>{{ data.stats.scrapPercentage }}% afkeur</label>
            <betech-progress-bar [value]="data.stats.scrapPercentage"></betech-progress-bar>
          </div>
          <div class="col-12">
            <label>{{ data.stats.progressPercentage }}% order gereed</label>
            <betech-progress-bar [value]="data.stats.progressPercentage"></betech-progress-bar>
          </div>
        </div>
      </div>
      <div class="col-6" style="padding: 1rem 2rem 2rem">
        <div class="row">
          <div class="col-6">
            <label>Goedgekeurd</label>
            <input type="text" value="{{ data.report.approved_parts }}" disabled />
          </div>
          <div class="col-6">
            <label>Afgekeurd</label>
            <input type="text" value="{{ data.report.scrap_parts }}" disabled />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label>Type update</label>
            <input type="text" value="{{ data.report.employee_number }}" disabled />
          </div>
          <div class="col-6">
            <label>Categorie afkeur</label>
            <input type="text" value="{{ data.report.reject_code | rejectionCode }}" disabled />
          </div>
        </div>
        <div class="row">
              <div class="col-6">
                  <label>Categorie storing</label>
                  <input type="text" value="{{ data.report.failure_code | failureCode }}" disabled />
              </div>
              <div class="col-6">
                  <label>Categorie oorzaak</label>
                  <input type="text" value="{{ data.report.cause_code | causeCode }}" disabled />
              </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Beschrijving</label>
            <textarea cols="10" rows="10" disabled>{{ data.report.description }}</textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <label>Wijziging door</label>
            <input type="text" value="{{ data.report.employee_number | employee }}" disabled />
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12 text-right">
            <button class="btn btn-secondary" (click)="closeScreen()">Sluiten</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
