<betech-filter-container>
  <betech-filter-department (filterChange)="filter.filter('1', $event)"></betech-filter-department>
</betech-filter-container>

<table class="-sample-list betech-table">
  <thead>
    <tr class="betech-table__row">
      <th class="betech-table__heading"><span i18n>Actie over</span></th>
      <th class="betech-table__heading"><span i18n>Machine</span></th>
      <th class="betech-table__heading"><span i18n>Productie nummer</span></th>
      <th class="betech-table__heading"><span i18n>Product</span></th>
      <th class="betech-table__heading"><span i18n># tools/posities</span></th>
      <th class="betech-table__heading"><span i18n>Laatste wissel</span></th>
      <th class="betech-table__heading"><span i18n>Volgende wissel</span></th>
      <th class="betech-table__heading"><span i18n>Gelogd</span></th>
    </tr>
  </thead>
  <ng-container *ngFor="let stat of filter.current">
    <tr class="betech-table__row" [ngClass]="{ 'betech-table__row--highlight': isHighlight(stat) }">
      <td class="betech-table__cell">
        <div class="d-flex d-flex justify-content-around align-items-center">
          <div>
            <i
              class="fas fa-fw"
              [ngClass]="[
                'text-' + stat.getUrgencyClass('tool_action'),
                stat.getUrgencyIcon('tool_action')
              ]"
            ></i>
          </div>

          <div class="text-left">
            <span i18n>Actie vereist over</span><br />
            <strong class="text-danger" *ngIf="stat.getUrgencyClass('tool_action') === 'danger'"
              >NU!&nbsp;</strong
            >
            <span [ngClass]="['text-' + stat.getUrgencyClass('tool_action')]">{{
              stat.getToolTimeUntilNextAction() | forecast
            }}</span>
          </div>
        </div>
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.trumpf_id || '(no trumpf_id)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.production_number || '(Geen productie nummer)' }}
      </td>
      <td class="betech-table__cell">
        {{ stat.fabrication_part_name }}
      </td>
      <td class="betech-table__cell" [ngClass]="{ 'text-danger text-bold': !stat.tool_tools }">
        {{ stat.tool_tools || 0 }} / {{ stat.tool_positions }}
      </td>
      <td class="betech-table__cell">
        {{ (stat.tool_last_replace_at | date: 'dd-MM-yyyy HH:mm') || '-' }}
      </td>
      <td class="betech-table__cell">
        <ng-container *ngIf="stat.getUrgencyClass('tool_replace') === 'danger'; else next">
          <strong class="text-danger" i18n>Nu wisselen</strong>
        </ng-container>

        <ng-template #next>
          <span [ngClass]="['text-' + stat.getUrgencyClass('tool_replace')]"
            >over {{ stat.getToolTimeUntilNextReplace() | forecast }}</span
          >
        </ng-template>
      </td>
      <td class="betech-table__cell">
        {{ stat.processing_station_stats.active_employees }}
      </td>
    </tr>
  </ng-container>
</table>
