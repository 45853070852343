import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    EsbmodelService,
    FabricationPart,
    InlineResponse20015,
    ProductionOrder,
    SimilarToolXrefs,
} from '../../../swagger-client';
import { Observer, of, Subscription } from 'rxjs';
import { ToolListComponent } from '../tool-list.component';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import {ActiveEntityMediatorService} from "../../../services/active-entity-mediator/active-entity-mediator.service";

@Component({
    selector: 'betech-tool-takeover-modal',
    templateUrl: './tool-takeover-modal.component.html',
})
export class ToolTakeoverModalComponent implements OnInit, OnDestroy {
    public modalRef;

    public previousTools;

    public fabricationPartName: string;

    private currentProductionOrder: ProductionOrder;

    public choosenTools: SimilarToolXrefs;
    public productionNumber: string;
    public machineNumber: string;

    private parentComponent: ToolListComponent;
    public fabParts: FabricationPart[];
    public currentfabPart: FabricationPart;

    public search?: string;
    public dataSource: Observable<FabricationPart[]>;

    private subs: Subscription[] = [];

    constructor(
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private esbModelService: EsbmodelService,
    ) {}

    public ngOnInit(): void {
        this.dataSource = new Observable((observer: Observer<string>) => {
            observer.next(this.search);
        }).pipe(
            switchMap((query: string) => {
                if (query) {
                    return this.esbModelService
                        .esbmFabricationPartGet(10, null, null, query)
                        .pipe(map((data: InlineResponse20015) => data.data || []));
                }
                return of([]);
            }),
        );

        this.subs.push(
            this.activeEntityMediatorService
            .activeProductionOrder$
            .subscribe((currentPO: ProductionOrder) => {
                if (!currentPO) {
                    return;
                }

                this.search = currentPO.fabrication_part_name;
                this.currentProductionOrder = currentPO;
            })
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public closeScreen(): void {
        this.modalRef.hide();
        this.choosenTools = null;
    }

    public setModalRef(
        modalRef: any,
        data: Array<SimilarToolXrefs>,
        parentComponent: ToolListComponent,
    ): void {
        this.modalRef = modalRef;
        this.previousTools = data;
        this.parentComponent = parentComponent;
    }

    public setTools(event: SimilarToolXrefs): void {
        this.choosenTools = event;
        this.productionNumber = this.choosenTools._id.productionNumber;
        this.machineNumber = this.choosenTools._id.reportedProcessingStation.trumpfId;
    }

    public submitTools(): void {
        this.esbModelService
            .esbmToolXrefReplaceSimilarPost(
                this.currentProductionOrder.id,
                this.choosenTools._id.productionOrderId,
            )
            .subscribe(() => {
                this.parentComponent.refresh();
                this.closeScreen();
            });
    }

    public getSimilar(event) {
        this.esbModelService
            .esbmToolXrefSimilarProductionOrderIdFabricationPartIdGet(
                this.currentProductionOrder.id,
                event.item.id,
            )
            .subscribe(data => {
                this.previousTools = data.data;
            });
    }
}
