import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractFilterComponent } from '../../../shared/filter-container/abstract.filter.component';
import { locationLabel } from '../../../pipes/measurement-sample-location/measurement-sample-location.pipe';
import { MeasurementSample } from '../../../swagger-client';
import LocationEnum = MeasurementSample.LocationEnum;
import { ProductionOrderStatsModel } from '../../model/production-order-stats.model';

@Component({
    selector: 'betech-filter-measurement-location',
    templateUrl: './measurement-location.filter.component.html',
    providers: [
        { provide: AbstractFilterComponent, useExisting: MeasurementLocationFilterComponent },
    ],
})
export class MeasurementLocationFilterComponent extends AbstractFilterComponent {
    public choices: Array<{ value: LocationEnum; label: string }> = [];

    constructor() {
        super();
        Object.values(LocationEnum).forEach((location: LocationEnum) =>
            this.choices.push({ value: location, label: locationLabel(location) }),
        );
    }

    private _selectedItem: any;
    public get selectedItem() {
        return this._selectedItem;
    }
    public set selectedItem(item: { value: LocationEnum; label: string } | null) {
        this._selectedItem = item;
        this.filterChange.emit(
            item
                ? (value: ProductionOrderStatsModel) =>
                      item && value.measurement_location === item.value
                : null,
        );
    }

    @Output()
    public filterChange = new EventEmitter<(item: ProductionOrderStatsModel) => boolean | null>();

    public clear() {
        this.selectedItem = null;
        this.filterChange.emit(null);
    }
}
