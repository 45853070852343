import { Component, Input, OnInit } from '@angular/core';
import { Performance, PerformanceTarget } from '../../../../swagger-client';

@Component({
    selector: 'betech-performance-employees-entry',
    templateUrl: './employees-entry-performance.component.html',
})
export class EmployeesEntryPerformanceComponent implements OnInit {
    @Input() activeProductionOrderPerformance: Performance;

    public declinedPercentage: number;
    public readyPercentage: number;
    public remaining: number;
    public remainingDay: number;

    public performanceTarget: PerformanceTarget;
    public amount: number;
    public amountDay: number;
    public trueAmountDay: number;

    public startDate: Date;
    public now: Date;
    public durationPercentage: number;

    public ngOnInit() {
        this.remaining = Math.max(
            this.activeProductionOrderPerformance.required_parts -
                this.activeProductionOrderPerformance.reported_approved_parts,
            0,
        );

        this.readyPercentage = Math.round(
            (this.activeProductionOrderPerformance.reported_approved_parts /
                this.activeProductionOrderPerformance.required_parts) *
                100,
        );

        if (this.activeProductionOrderPerformance.reported_processed_parts == 0) {
            this.declinedPercentage = 0;
        } else {
            this.declinedPercentage = Math.round(
                (this.activeProductionOrderPerformance.reported_scrap_parts /
                    this.activeProductionOrderPerformance.reported_processed_parts) *
                    100,
            );
        }

        this.startDate = new Date(
            this.activeProductionOrderPerformance.active_production_order.start_date,
        );

        this.now = new Date();
        let durationInSeconds = Math.round((this.now.getTime() - this.startDate.getTime()) / 1000);
        this.durationPercentage = 0;

        if (
            this.activeProductionOrderPerformance?.active_production_order?.remaining_time !==
                null &&
            this.activeProductionOrderPerformance.active_production_order.remaining_time !== 0
        ) {
            this.durationPercentage = Math.round(
                (durationInSeconds /
                    this.activeProductionOrderPerformance.active_production_order.remaining_time) *
                    100,
            );
            this.durationPercentage = Math.min(this.durationPercentage, 100);
            this.durationPercentage = Math.max(this.durationPercentage, 0);
        }

        this.calculateMachinePerformance();
    }

    private calculateMachinePerformance(): void {
        if (
            this.activeProductionOrderPerformance?.performance_targets &&
            this.activeProductionOrderPerformance?.performance_targets?.length !== 0
        ) {
            this.performanceTarget = this.activeProductionOrderPerformance.performance_targets[0];
            let availability = this.performanceTarget.availability?.hours_per_day ?? 8;

            if (this.performanceTarget.type === 'ACTUAL') {
                this.trueAmountDay = this.performanceTarget.processed_parts_per_day;
            } else {
                this.trueAmountDay = Math.round(
                    (availability * 3600) / this.performanceTarget.cycle_time,
                );
            }

            this.amount = Math.round(3600 / this.performanceTarget.cycle_time);
            if (
                this.performanceTarget.availability !== null &&
                this.activeProductionOrderPerformance?.active_production_order
                    ?.planned_cycle_time !== null &&
                this.activeProductionOrderPerformance?.active_production_order
                    ?.planned_cycle_time !== 0
            ) {
                this.amountDay = Math.round(
                    (availability * 3600) /
                        this.activeProductionOrderPerformance.active_production_order
                            .planned_cycle_time,
                );
            }
            this.remainingDay = Math.round((this.remaining / this.trueAmountDay) * 100) / 100;
        }
    }
}
