import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment-duration-format';

@Pipe({
    name: 'forecast',
})
export class ForecastPipe implements PipeTransform {
    public transform(totalSeconds: any, args?: any): any {
        return (<any>moment.duration(totalSeconds, 'seconds')).format('D:HH:mm');
    }
}
