import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SupportModalComponent } from './support-modal.component';
import { SupportTextfieldComponent } from './textfield/support-textfield.component';
import { SupportTextareaComponent } from './textarea/support-textarea.component';
import { SupportAbstractFormComponent } from './support-abstract-form.component';
import { SupportEmailfieldComponent } from './emailfield/support-emailfield.component';
import { SupportTicketComponent } from './support-ticket/support-ticket.component';

@NgModule({
    declarations: [
        SupportModalComponent,
        SupportTextfieldComponent,
        SupportTextareaComponent,
        SupportAbstractFormComponent,
        SupportEmailfieldComponent,
        SupportTicketComponent,
    ],
    imports: [ReactiveFormsModule, FormsModule, CommonModule],
    exports: [SupportModalComponent],
})
export class SupportModule {}
