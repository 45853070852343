<div class="card" (click)="click()" [ngClass]="bgClass">
  <div class="card__body">
    <div class="row">
      <div class="col-sm-7 position-relative">
        <div class="card__badge badge border position-absolute">
          <ng-content select="[card-icon]"></ng-content>
        </div>
        <div class="card__header">
          <h3 class="card__title">
            <ng-content select="[card-title]"></ng-content>
          </h3>
          <h5 class="card__subtitle">
            <ng-content select="[card-subtitle]"></ng-content>
          </h5>
        </div>
      </div>
      <div class="card__button">
        <ng-content select="[card-button]"></ng-content>
      </div>
    </div>
    <div class="row">
      <div class="card__info" [ngClass]="{ 'col-sm-7': !fullWidth, 'col-sm-12': fullWidth }">
        <ng-content select="[card-info]"></ng-content>
      </div>
      <div class="position-relative" [ngClass]="{ 'col-sm-5': !fullWidth, 'hide': fullWidth }">
        <ng-content select="[card-notifications]"></ng-content>
      </div>
    </div>
  </div>
  <div class="card__footer card-footer border-0 pt-0" [ngClass]="bgClass || ''">
    <small>
      <ng-content select="[card-footer]"></ng-content>
    </small>
  </div>
</div>
