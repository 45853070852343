<div
  *ngIf="
    currentOrder?.reported_status === statusEnum.STARTED ||
    currentOrder?.reported_status === statusEnum.PREPARED
  "
  class="betech-table"
>
  <div class="betech-table__row">
    <div class="betech-table__heading"><span i18n>Toolpositie</span></div>
    <div class="betech-table__heading"><span i18n>Standaard</span></div>
    <div class="betech-table__heading"><span i18n>Tool</span></div>
    <div class="betech-table__heading"><span i18n>Toolsheet</span></div>
    <div class="betech-table__heading"><span i18n>Aantal</span></div>
    <div class="betech-table__heading"><span i18n>Huidige stand</span></div>
    <div class="betech-table__heading"><span i18n>Prognose</span></div>
    <div class="betech-table__heading"><span i18n>Laatste wissel</span></div>
    <div class="betech-table__heading"></div>
    <div class="betech-table__heading"></div>
  </div>
  <ng-container *ngIf="showTools">
    <ng-container *ngFor="let toolXref of toolXrefList; let i = index">
      <betech-tool-list-item
        class="betech-table__row"
        [ngClass]="{
          'grouped-top':
            toolXref.tool_position.tool_position_group &&
            (i === 0 ||
              toolXrefList[i - 1].tool_position.tool_position_group?.id !==
                toolXref.tool_position.tool_position_group?.id),
          'grouped-bottom':
            toolXref.tool_position.tool_position_group &&
            (i === toolXrefList.length ||
              toolXrefList[i + 1].tool_position.tool_position_group?.id !==
                toolXref.tool_position.tool_position_group?.id),
          'grouped': toolXref.tool_position.tool_position_group
        }"
        [groupXrefs]="
          toolXref.tool_position.tool_position_group
            ? toolPositionGroups[toolXref.tool_position.tool_position_group.id]
            : []
        "
        [toolXref]="toolXref"
        [lastReplace]="toolXref.last_replace"
        [isLastItem]="i < toolXrefList.length - 1"
        [toolList]="toolList"
        (toolXrefChange)="onXrefChange($event)"
      >
      </betech-tool-list-item>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!showTools">
          <div class="text-center p-4">Tools laden...</div>
  </ng-container>
  <tfoot>
    <tr>
      <tf colspan="10">
        <div style="height: 5rem; width: 100%"></div>
      </tf>
    </tr>
  </tfoot>
  <div class="betech-page-footer">
    <button
      class="btn btn-outline-primary float-right"
      i18n
      (click)="showToolsModal($event)"
    >
      <i *ngIf="checkingTools" class="fas fa-sync fa-spin mx-2"></i>
      {{ toolsNotification }}
    </button>
  </div>
</div>

<div class="alert alert-warning" *ngIf="toolXrefList && !toolXrefList.length">
  No Tool Positions are configured for this machine..
</div>

<ng-container
  *ngIf="
    currentOrder?.reported_status !== statusEnum.STARTED &&
    currentOrder?.reported_status !== statusEnum.PREPARED
  "
>
  <betech-error-page>
    <span i18n>Er is op dit moment geen order actief.</span>
  </betech-error-page>
</ng-container>
