import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './auth/login/login.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'dash' },
    {
        path: 'admin',
        component: AdminComponent,
    },
    {
        path: 'auth',
        component: LoginComponent,
    },
    {
        path: '',
        component: MainComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' }),
    ], // !environment.production})],
    exports: [RouterModule],
})
export class BetechRoutingModule {}
