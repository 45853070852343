import { Injectable } from '@angular/core';
import TypeEnum = Alert.TypeEnum;

export interface Alert {
    type: string;
    message: string;
}

export namespace Alert {
    export type TypeEnum = 'danger' | 'success' | 'warning';
}

@Injectable()
export class AlertService {
    public alerts: Array<Alert> = [];
    public isOnline: boolean = true;

    constructor() {}

    public add(type: TypeEnum, message: string) {
        this.alerts.push({ type: type, message: message });
    }

    public error(message: string): void {
        this.add('danger', message);
    }

    public warning(message: string): void {
        this.add('warning', message);
    }

    public get(): Array<Alert> {
        return this.alerts;
    }
}
