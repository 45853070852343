import { Injectable } from '@angular/core';
import { EsbmodelService, RejectionCode } from 'app/swagger-client';

@Injectable({
    providedIn: 'root',
})
export class RejectionCodeService {
    public rejectCodes: RejectionCode[] = [];
    public failureCodes: RejectionCode[] = [];
    public causeCodes: RejectionCode[] = [];

    constructor(private esbModelService: EsbmodelService) {}

    public initialize(): void {
        this.esbModelService
            .esbmRejectioncodesGet()
            .subscribe((result) => {
                this.rejectCodes = result.rejectCodes;
                this.failureCodes = result.failureCodes;
                this.causeCodes = result.causeCodes;
            });
    }
}
