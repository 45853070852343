import { Pipe, PipeTransform } from '@angular/core';
import { RejectionCode } from 'app/swagger-client';
import { RejectionCodeService } from 'app/services/rejection-code/rejection-code.service';

@Pipe({
    name: 'failureCode',
})
export class FailureCodePipe implements PipeTransform {
    constructor(private rejectionCodeService: RejectionCodeService) {}

    public transform(id: string, fieldName?: string): any {
        const rejectionCode = this.rejectionCodeService.failureCodes.find(
            (c: RejectionCode) => c.id === id,
        );

        if (rejectionCode === null || rejectionCode === undefined) {
            return '';
        }

        return rejectionCode[fieldName ? fieldName : 'code'];
    }
}
