import { Component, Input } from '@angular/core';

@Component({
    selector: 'betech-checkbox',
    templateUrl: 'checkbox.component.html',
})
export class CheckboxComponent {
    @Input() public disabled = false;
    @Input() public checked: boolean = false;

    public toggle(): void {
        this.checked = !this.checked;
    }
}
