<div class="main">
  <betech-sidebar></betech-sidebar>
  <div class="main__right">
    <betech-header></betech-header>
    <div class="main__content">
      <router-outlet></router-outlet>
      <betech-update-notification></betech-update-notification>
      <betech-error-page></betech-error-page>
    </div>
  </div>
</div>
