<div *ngIf="activeOrder && isInitialized" class="row mx-4 mt-5">
  <betech-order-card class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-order-card>
  <betech-worklog-card *ngIf="showWorklogCard" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-worklog-card>
  <betech-tool-lifetime-card *ngIf="activeMachine?.is_cnc" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-tool-lifetime-card>
  <betech-measurement-sample-card *ngIf="activeMachine?.is_cnc" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-measurement-sample-card>
  <betech-stock-request-card *ngIf="activeMachine?.is_cnc" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-stock-request-card>
  <betech-progress-card *ngIf="showProgressCard" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-progress-card>
  <betech-checklist-card *ngIf="activeMachine?.is_cnc" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-checklist-card>
  <betech-performance-card *ngIf="activeMachine?.is_cnc" class="col-sm-6 col-md-6 col-lg-6 col-xl-4"></betech-performance-card>
</div>
<div>
  <betech-instructions-bar></betech-instructions-bar>
</div>
<div *ngIf="!activeOrder && isInitialized" class="row justify-content-md-center mx-4 mt-5">
  <betech-no-order-active-card class="col-6"></betech-no-order-active-card>
  <betech-worklog-card *ngIf="showWorklogCard" class="col-6"></betech-worklog-card>
</div>
<div *ngIf="!isInitialized" class="row justify-content-md-center mx-4 mt-5">
    <betech-no-employee-card class="col-6"></betech-no-employee-card>
</div>
