<div class="container-fluid main-header sticky-top shadow">
    <div class="row h-100">
        <div class="col-xl-2 my-auto d-lg-none d-xl-block">
            <img class="main-header__logo" [src]="logoLocation"/>
        </div>
        <div class="col-12 col-xl-10 h-100">
            <div class="d-flex flex-row justify-content-end h-100">

                <div class="align-self-center px-4">
                    <span i18n>Machine:</span><br>
                    <span class="font-weight-bold text-primary">{{ machineNumberForHeader || '-' }}</span>
                </div>

                <div *ngIf="companyName === 'DMI'" class="align-self-center px-4 border-left">
                    <span i18n>Werknemer:</span><br>
                    <span class="font-weight-bold text-primary">{{ employeeForHeader || '-' }}</span>
                </div>

                <div class="text-truncate align-self-center px-4 border-left">
                    <span i18n>Productienr:</span><br>
                    <span class="font-weight-bold text-primary"> {{ currentOrder?.production_number  || '-' }}</span>
                </div>

                <div class="text-truncate align-self-center px-4 border-left">
                    <span i18n>Part:</span><br>
                    <span class="font-weight-bold text-primary"> {{ currentOrder?.fabrication_part_name || '-' }}</span>
                </div>

                <div class="align-self-center px-4 border-left">
                    <span i18n>Status:</span><br>
                    <span class="font-weight-bold text-primary">{{ (currentOrder?.reported_status | orderStatus) || '-'}}</span>
                </div>

                <ng-container *ngIf="showMachineCounter">
                    <ng-container *ngIf="!currentOrder">
                        <div class="align-self-center px-3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <i class="fal fa-tally fa-fw"></i>
                                    </span>
                                </div>
                                <input
                                    type="number"
                                    disabled
                                    class="form-control main-header__processed-parts"
                                />
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="currentOrder">
                        <div class="align-self-center px-3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text {{htmlStatusClass}}">
                                        <i class="fal fa-tally fa-fw"></i>
                                    </span>
                                </div>
                                <input
                                    id="processed_parts"
                                    [(ngModel)]="processed_parts"
                                    (focusout)="pauseSensorData(false)"
                                    (focusin)="pauseSensorData(true)"
                                    type="number" [disabled]="this.activeMachine?.has_sensor"
                                    class="form-control main-header__processed-parts {{htmlStatusClass}}"/>
                                <div *ngIf="!this.activeMachine?.has_sensor" class="input-group-append">
                                    <a
                                        class="input-group-text bg-white"
                                        (click)="updateSensorData()"
                                    >
                                        <i class="fal fa-save fa-fw"></i>
                                    </a>
                                </div>
                                <div *ngIf="this.activeMachine?.has_sensor && lastSensorData?.machine_status !== null"
                                     class="input-group-append sync-indicator {{showIconStack('inputGroup')}}">
                                    <div class="input-group-text icon-position {{htmlStatusClass}} {{htmlStatusClassSyncIcon}}">
                                        <i
                                        class="fal fa-sync {{showIconStack('syncIcon')}}"
                                        [ngClass]="{'fa-spin': lastSensorData?.machine_status === 'active'}"
                                        ></i>
                                        <i
                                            (click)="showStatusPopup()"
                                            *ngIf="statusErrorOrFailure()" class="fa fa-exclamation fa-stack-1x"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div class="align-self-center px-3">
                    <button class="btn btn-outline-dark" type="button" (click)="toggleFullscreen()">
                        <i class="fas"
                           [ngClass]="{'fa-expand' : isFullscreen() === false, 'fa-compress' : isFullscreen() === true}"
                        ></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
