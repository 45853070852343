import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProducedAmountDataService } from 'app/services/produced-amount-data-service/produced-amount-data-service';
import { Subscription } from 'rxjs';
import {
    EsbmodelService,
    ProductionOrderReport,
    ProducedAmountDetails,
    ProductionOrder,
} from 'app/swagger-client';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ProgressReportModalComponent } from '../progress-report-modal/progress-report-modal.component';
import { ActiveEntityMediatorService } from '../../../services/active-entity-mediator/active-entity-mediator.service';

export interface ProgressStats {
    approved: number;
    missing: number;
    processed: number;
    scrap: number;
    quantity: number;
    progressPercentage: number;
    scrapPercentage: number;
}

@Component({
    selector: 'betech-progress',
    templateUrl: './progress.component.html',
})
export class ProgressComponent implements OnInit, OnDestroy {
    public approved = 0;
    public missing = 0;
    public processed = 0;
    public scrap = 0;
    public quantity = 0;
    public reports: ProductionOrderReport[];

    private subs: Subscription[] = [];
    private orderNumber: string;
    private modalRef: BsModalRef;

    constructor(
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private producedAmountDataService: ProducedAmountDataService,
        private esbModelService: EsbmodelService,
        private modalService: BsModalService,
    ) {}

    public get scrapPercentage(): number {
        if (this.scrap === 0 && this.processed === 0) {
            return 0;
        }
        const percentage = (this.scrap / this.processed) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    public get progressPercentage(): number {
        if (this.approved === 0 && this.quantity === 0) {
            return 0;
        }
        const percentage = (this.approved / this.quantity) * 100;
        return parseFloat(percentage.toFixed(2));
    }

    private get stats(): ProgressStats {
        return {
            approved: this.approved,
            missing: this.missing,
            processed: this.processed,
            scrap: this.scrap,
            quantity: this.quantity,
            scrapPercentage: this.scrapPercentage,
            progressPercentage: this.progressPercentage,
        };
    }

    public openReport(report: any): void {
        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-update-progress';

        this.modalRef = this.modalService.show(
            ProgressReportModalComponent,
            modalOptions,
        ) as BsModalRef;
        this.modalRef.content.setModalRef(this.modalRef, { report, stats: this.stats });
    }

    public ngOnInit(): void {
        // Push subscriptions to the subs sink to easily unsubscribe onDestroy
        this.subs.push(
            this._activeEntityMediatorService.activeProductionOrder$.subscribe(
                (result: ProductionOrder) => this._handleProductionOrder(result),
            ),
            this.producedAmountDataService
                .observe()
                .subscribe((result: ProducedAmountDetails) =>
                    this._handleProducedAmountData(result),
                ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    private _handleProductionOrder(result: ProductionOrder): void {
        if (result) {
            this.orderNumber = result.order_number;
            this.quantity = result.quantity;

            this.subs.push(
                this.esbModelService
                    .esbmProductionOrderReportGet(null, null, null, null, null, result.order_number)
                    .subscribe((r: { data: ProductionOrderReport[] }) =>
                        this._handleReportsData(r),
                    ),
            );
        }
    }

    private _handleProducedAmountData(result: ProducedAmountDetails): void {
        if (!result) {
            return;
        }

        this.scrap = result.scrap;
        this.processed = result.processed;
        this.missing = result.missing;
        this.approved = result.approved;
    }

    private _handleReportsData(result: { data: ProductionOrderReport[] }): void {
        if (!result) {
            return;
        }
        this.reports = result.data;
    }
}
