/**
 * Attributes ESB API
 * This is the Attributes API definition.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: youri.lefers@x-ip.nl
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ReportedChecklistItem { 
    name?: string;
    id?: string;
    description?: string;
    code?: string;
    status?: ReportedChecklistItem.StatusEnum;
    employee_number?: string;
}
export namespace ReportedChecklistItem {
    export type StatusEnum = 'TODO' | 'DONE' | 'SKIPPED' | 'NOT_DONE';
    export const StatusEnum = {
        TODO: 'TODO' as StatusEnum,
        DONE: 'DONE' as StatusEnum,
        SKIPPED: 'SKIPPED' as StatusEnum,
        NOTDONE: 'NOT_DONE' as StatusEnum
    };
}
