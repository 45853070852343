import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'betech-forgot-password',
    templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
    // The sign in form
    public _form: FormGroup;

    constructor(private _formBuilder: FormBuilder) {}

    public ngOnInit(): void {
        this._form = this._formBuilder.group({
            username: new FormControl('', [Validators.required]),
        });
    }

    public _submit(): void {
        if (this._form.valid) {
            const values = this._form.getRawValue();

            // TODO implement forgot password
        }
    }
}
