import { Component } from '@angular/core';
import { ProgressStats } from '../progress/progress.component';
import { ProductionOrderReport } from 'app/swagger-client';

@Component({
    selector: 'betech-progress-report-modal',
    templateUrl: './progress-report-modal.component.html',
})
export class ProgressReportModalComponent {
    public modalRef;
    public data: {
        report: ProductionOrderReport;
        stats: ProgressStats;
    };

    public setModalRef(modalRef: any, data: any): void {
        this.modalRef = modalRef;
        this.data = data;
    }

    public closeScreen(): void {
        this.modalRef.hide();
    }
}
