import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'betech-select-dropdown',
    templateUrl: './select-dropdown.component.html',
})
export class SelectDropdownComponent {
    @HostBinding('class.select-dropdown') private hasMainClass = true;
    @HostBinding('class.form-control') private isFormControl = true;

    /**
     * The list of options to choose from. When using an array of objects, it's
     * required to specify a label.
     *
     * Example:
     * <betech-select-dropdown [items]="['test1', 'test2']">
     * <betech-select-dropdown [items]="[{id: 1, name: 'test'}]" label="name" >
     */
    @Input() public items: Array<any> = [];

    /**
     * When items is an array of objects, you can specify the label that should be returned
     * either as a string or as a callback function that determines or combines labels.
     *
     * Example:
     * <betech-select-dropdown label="name" [items]="[{id: 1, name: 'test'}]">
     */
    @Input() public label: ((item: any) => string) | string;

    /**
     * The placeholder text for when no option is selected.
     * Defaults to "..selecteer"
     *
     * Example:
     * <betech-select-dropdown placeholder="Selecteer een tool">
     */
    @Input() public placeholder: string = '..selecteer';

    /**
     * Whether or not selecting an option is required.
     * When not required it's impossible to reselect the placeholder after choosing something.
     *
     * Example:
     * <betech-select-dropdown [required]="true">
     */
    @Input() public required: boolean = false;

    /**
     * Allows searching.
     * When items is an array of objects, you should use a string here with the specific object key to search in.
     *
     * Example:
     * <betech-select-dropdown search="name" [items]="[{id: 1, name: 'test'}]">
     */
    @Input() public search: any;

    /**
     * An external search function defined in a different component that returns the filtered search items
     */
    @Input() public customSearch: boolean = false;

    /**
     * Example:
     *
     * <betech-select-dropdown [template]="selectItem">
     * <ng-template #selectItem let-item="item">
     *      <div class="d-flex justify-content-center align-items-center">
     *          <div style="width: 30rem;">{{ item.name }}</div>
     *          <div style="width: 7.5rem" class="border-left border-primary">
     *              <button class="btn btn-sm" [ngClass]="{'btn-outline-dark': !item?.tool_sheet, 'btn-outline-secondary': item?.tool_sheet}" (click)="showToolSheet($event, item);">
     *                  <i class="fas fa-file-pdf fa-fw"></i>
     *              </button>
     *          </div>
     *      </div>
     * </ng-template>
     */
    @Input() public template: TemplateRef<any>;

    /**
     * As [ngStyle] but specifically for the dropdown-menu element. Makes it possible
     * to create a fixed width or different margin.
     *
     * Example:
     * [menuStyle]="{'margin': '-1rem', 'width': '37.5rem'}"
     */
    @Input() public menuStyle: any = {};

    /**
     * In theory the combined [model] and (modelChange) should allow for two-way binding,
     * [(model)]="something", but I haven't got that to work.
     *
     * So for now use [model]="yourVar" to set the data and use the emitted (modelChange)="yourVar = $event"
     * to capture the changes in the model.
     *
     * Example:
     * <betech-select-dropdown [model]="myVar" (modelChange)="myVar = $event">
     */
    public modelValue;

    @Input()
    public get model() {
        return this.modelValue;
    }

    public set model(item) {
        if (this.modelValue === item) {
            return;
        }

        this.modelValue = item;
        this.modelChange.emit(item);
    }

    @Output() public modelChange = new EventEmitter();

    /**
     * The search input Event for Parent
     */
    @Output() public searchInputChange: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Internal var to show searched items as opposed to the full list
     */
    public searchedItems: Array<any>;

    /**
     * The current search input string
     */
    public searchInput: string = '';

    @ViewChild('dropdownSearch', { static: true }) public dropdownSearch: ElementRef;

    public focusSearch($event): void {
        if (false === $event || !this.search) {
            return;
        }

        // reset search
        this.searchInput = '';
        if (!this.customSearch) {
            this.searchedItems = this.items;
        }

        // focus on html element when it appears (takes a little bit of time)
        setTimeout(() => {
            if (this.dropdownSearch) {
                this.dropdownSearch.nativeElement.focus();
            }
        }, 100);
    }

    /**
     * Show the correct option label, as specified by the @Input('label')
     */
    public getLabel(item: any = this.model) {
        if (item) {
            return this.label
                ? this.label instanceof Function
                    ? this.label(item)
                    : item[this.label]
                : item;
        }

        if (this.placeholder) {
            return this.placeholder;
        }

        return '..selecteer';
    }

    /**
     * Handle click by updating the model, which will emit a change
     */
    public click(item) {
        if (item !== 'undefined') {
            this.model = item;
        }
    }

    /**
     * Handle search
     */
    public searchItems() {
        if (this.customSearch) {
            this.searchInputChange.emit(this.searchInput);

            return;
        }

        if (!this.searchInput) {
            this.searchedItems = this.items;
            return;
        }

        this.searchedItems = this.items.filter(e => {
            const pattern = new RegExp(this.searchInput, 'i');

            if (!(e instanceof Object)) {
                return (e || '').match(pattern);
            }

            switch (typeof this.search) {
                case 'string':
                    return e[this.search].match(pattern);
                case 'function':
                    return this.search(e, this.searchInput);
                default:
                    return false;
            }
        });
    }
}
