<div class="row" *ngIf="show">
    <div
        class="col tool-takeover-item py-3 px-3 my-2"
        [ngClass]="{ active: isChoosen }"
        (click)="select()"
    >
        <div class="row">
            <div class="col-2">
                <span i18n>Machine:</span>
                <span class="font-weight-bold text-primary">
          {{ machineNumber }}
        </span>
            </div>
            <div class="col-10">
                <span i18n>Productienr:</span>
                <span class="font-weight-bold text-primary">
          {{ productionNumber }}
        </span>
            </div>
        </div>
    </div>
</div>
