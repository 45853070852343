<ng-container *ngIf="currentProductionOrder">
  <betech-card *ngIf="currentProductionOrder">
    <i card-icon class="fal fa-3x fa-file-contract fa-fw"></i>
    <span card-title i18n>Huidige order</span>

    <span card-subtitle>
      <span i18n>Order</span> {{ currentProductionOrder?.production_number || '-' }}
    </span>

    <a
      card-button
      class="btn btn-secondary btn-block rounded text-uppercase"
      routerLink="/calculation-progress"
      *ngIf="currentProductionOrder"
    >
      <span i18n>Open order</span>
    </a>

    <ng-container card-info>
      <div class="d-flex justify-content-between">
        <div><span i18n>Productienummer</span>:</div>
        <div class="text-primary text-truncate text-right">
          {{ currentProductionOrder?.production_number || '-' }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Gestart op</span>:</div>
        <div class="text-primary text-truncate text-right">
          {{ correctProduced || '-' }} /
          {{ currentProductionOrder?.quantity || '-' }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Product</span>:</div>
        <div class="text-primary text-truncate text-right">
          {{ currentProductionOrder.fabrication_part_name || '-' }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Artikelnr</span>:</div>
        <div class="text-primary text-truncate text-right">
          {{ currentProductionOrder.fabrication_part_articlenumber || '-' }}
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <div><span i18n>Tekeningnr</span>:</div>
        <div class="text-primary text-truncate text-right">
          {{ currentProductionOrder.reference || '-' }} /
          {{ currentProductionOrder.drawing_revision || '-' }}
        </div>
      </div>
    </ng-container>

    <ng-container card-notifications>
      <ng-container *ngIf="currentProductionOrder">
        <betech-card-notification
          type="warning"
          *ngIf="!currentProductionOrder.drawing_image_url"
        >
          <span i18n>Geen tekening beschikbaar</span>
        </betech-card-notification>
        <betech-card-notification
          type="success"
          *ngIf="currentProductionOrder.drawing_image_url"
          (click)="showMediaModal($event)"
          style="cursor: pointer"
        >
          <img
            class="card-pointer card-img img-fluid"
            style="height: 8rem"
            [src]="imageUrl | accessTokenPipe"
            [alt]="currentProductionOrder?.fabrication_part_name"
          />
        </betech-card-notification>
      </ng-container>
      <betech-card-notification type="warning " *ngIf="!currentProductionOrder">
        <span i18n>Geen actieve order.</span>
      </betech-card-notification>
    </ng-container>

    <ng-container card-footer *ngIf="currentProductionOrder?.reported_processing_station?.next_production_orders?.length > 0">
      <span i18n>Volgende Order</span>:
      <span class="text-primary">{{
        currentProductionOrder.reported_processing_station.next_production_orders[0]?.production_number || '-'
      }}</span>
    </ng-container>
  </betech-card>
</ng-container>
