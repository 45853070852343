import { Injectable } from '@angular/core';
import { ProductionOrderPerformanceTotals, EsbmodelService } from '../../swagger-client';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class EmployeePerformanceService {
    constructor(private _esbmodelService: EsbmodelService) {}

    private _employeePerformance: ReplaySubject<ProductionOrderPerformanceTotals> =
        new ReplaySubject<ProductionOrderPerformanceTotals>(1);

    get employeePerformance$(): Observable<ProductionOrderPerformanceTotals> {
        return this._employeePerformance.asObservable();
    }

    public fetch(offset: number = 1, employeeNumber: string = undefined, trumpfId: string = undefined): Observable<any> {
        return this._esbmodelService
            .esbmEmployeePerformanceGet(employeeNumber, trumpfId, 10, offset)
            .pipe(
                tap(stats => {
                    this._employeePerformance.next(stats);
                }),
            );
    }
}
