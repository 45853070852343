import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class DrawingDuringMeasurementService {
    private drawingObservable: ReplaySubject<boolean> = new ReplaySubject(1);
    private lastValue: boolean;

    public load(): boolean {
        if (this.lastValue == null) {
            this.lastValue = false;
        }

        return this.lastValue;
    }

    public reset(): void {
        this.showDrawing(false);
    }

    public observe(): Observable<boolean> {
        return this.drawingObservable.asObservable();
    }

    public showDrawing(show: boolean): void {
        this.lastValue = show;
        this.drawingObservable.next(this.lastValue);
    }

    public toggleDrawing(): void {
        this.lastValue = !this.lastValue;
        this.drawingObservable.next(this.lastValue);
    }
}
