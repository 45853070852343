<div class="tool-sheet modal-full">
  <div class="modal-header p-3 text-white bg-primary set-shadow">
    <div class="modal-title pt-2 pl-2 w-100">
      <h4 class="text-center">
        <i class="fas fa-file-pdf"></i>
        &nbsp;
        <span i18n>Tool sheet:</span>
        <span class="pr-3">{{ selectedTool.articlenumber_pdc }} - {{ selectedTool.name }}</span>
      </h4>
    </div>
    <span class="float-right mx-2 mt--2" (click)="close()"
      ><i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i
    ></span>
  </div>
  <div class="modal-body">
    <iframe class="tool-sheet__iframe" [src]="safeUrl"></iframe>
  </div>
</div>
