import { Component, Input } from '@angular/core';
import { Performance } from '../../../../swagger-client';

@Component({
    selector: 'betech-performance-employees-not-active',
    templateUrl: './employees-not-active.component.html',
})
export class EmployeesNotActiveComponent {
    @Input() activeProductionOrderPerformance: Performance;
}
