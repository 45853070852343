import { Injectable, OnDestroy } from '@angular/core';
import {
    Employee,
    EsbmodelService,
    ProcessingStation,
    ProductionOrder,
    ProductionOrderResponse,
} from '../../swagger-client';
import { ActiveEntityMediatorService } from '../active-entity-mediator/active-entity-mediator.service';
import { Observable, of, ReplaySubject, Subscription } from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import { ActiveMachineService } from '../active-machine/active-machine.service';
import { environment } from '../../../environments/environment';
import { ProductionOrderFilter } from './production-order.filter';
import { concat, isEqual, merge } from 'lodash-es';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { DialogConfig } from '../../shared/confirmation-dialog/dialog.config';
import { DialogReturnType } from '../../shared/confirmation-dialog/dialog-return-type.enum';
import { cloneDeep } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class ProductionOrderService implements OnDestroy {
    private _faultyMachineCounterError: DialogConfig = {
        title: 'Machine teller',
        message: {
            text: '',
        },
        icon: {
            show: false,
        },
        actions: {
            cancel: {
                show: false,
            },
            confirm: {
                label: 'probeer opnieuw',
            },
        },
        dismissible: true
    };

    private activeMachine: ProcessingStation;

    private _defaultFilter: ProductionOrderFilter = {
        limit: 20,
    };

    private _latestResponse: ProductionOrderResponse;
    private _latestFilter: ProductionOrderFilter = {};
    private _productionOrders: ReplaySubject<ProductionOrderResponse> =
        new ReplaySubject<ProductionOrderResponse>(1);
    private subs: Subscription[] = [];

    constructor(
        private _esbModelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private _activeMachineService: ActiveMachineService,
        private _confirmationService: ConfirmationService,
    ) {}

    public initialize(): void {
        this.subs.push(
            this._activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
        this._productionOrders.next(null);
        this._productionOrders.complete();
    }

    public get productionOrders$(): Observable<ProductionOrderResponse> {
        return this._productionOrders.asObservable();
    }

    public fetchProductionOrders(
        userFilter: ProductionOrderFilter = null,
        offset: number = null,
    ): Observable<ProductionOrderResponse> {
        // PLEASE NOTE, ORDER IS IMPORTANT, INVERSE OF PRIORITY. In this case, userFilter has the highest priority
        const filter = merge({}, this._defaultFilter, userFilter);

        return this._esbModelService
            .esbmProductionOrderGet(
                filter.limit,
                offset,
                filter.sortDir,
                filter.search,
                null,
                null,
                null,
                null,
                filter.processingStation,
                null,
                filter.reportedStatus,
                environment.config.filterCnc ? true : filter.cnc,
                filter.productionNumber,
                filter.company,
                filter.fabricationPartName,
                filter.quantity,
                filter.deliveryDate,
                filter.materialAvailable,
                environment.config.showProductionOrderPdcStatus,
                filter.sortBy,
            )
            .pipe(
                map(response => {
                    if (this.filterSameAsPrevious(filter) && offset != null) {
                        response.data = concat(this._latestResponse.data, response.data);
                    }

                    this._latestResponse = response;
                    this._productionOrders.next(response);
                    return response;
                }),
            );
    }

    public sendQueueOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdQueuePost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                { employee_number: employee?.employee_number },
            )
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public sendUnqueueOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdUnqueuePost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                { employee_number: employee?.employee_number },
            )
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public sendStartOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdStartPost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                employee.employee_number,
            )
            .pipe(
                tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)),
                catchError(err => {
                    let errorMessage: DialogConfig = cloneDeep(this._faultyMachineCounterError);
                    errorMessage.message.text = `We kunnen helaas geen actuele tellerstand van de machine ophalen. Het starten van een order hierdoor is niet gelukt. Probeer het a.u.b. nog een keer`;
                    return this._confirmationService.open(errorMessage).pipe(
                        switchMap(value => {
                            if (value === DialogReturnType.Confirm) {
                                return this.sendStartOrder(order, employee);
                            }

                            return of({});
                        }),
                    );
                }),
            );
    }

    public sendStopOrder(order: ProductionOrder): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdStopPost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
            )
            .pipe(
                tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)),
                catchError(err => {
                    let errorMessage: DialogConfig = cloneDeep(this._faultyMachineCounterError);
                    errorMessage.message.text = `We kunnen helaas geen actuele tellerstand van de machine ophalen. Het stoppen van een order hierdoor is niet gelukt. Probeer het a.u.b. nog een keer`;
                    return this._confirmationService.open(errorMessage).pipe(
                        switchMap(value => {
                            if (value === DialogReturnType.Confirm) {
                                return this.sendStopOrder(order);
                            }

                            return of({});
                        }),
                    );
                }),
            );
    }

    public sendPrepareOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdPreparePost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                { employee_number: employee?.employee_number },
            )
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public addWorkerToOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdAddEmployeeEmployeeNumberPost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                employee.id,
            )
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public removeWorkerFromOrder(order: ProductionOrder, employee: Employee): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdProcessingStationProcessingStationIdRemoveEmployeeEmployeeNumberPost(
                order.id,
                this.activeMachine?.id ?? order.processing_station.id,
                employee.id,
            )
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public resetOrder(order: ProductionOrder): Observable<any> {
        return this._esbModelService
            .esbmProductionOrderIdResetPost(order.id)
            .pipe(tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)));
    }

    public pauseOrder(order: ProductionOrder): Observable<any> {
        return this._esbModelService.esbmProductionOrderIdPausePost(order.id).pipe(
            tap((productionOrder: ProductionOrder) => this.atOrderChange(productionOrder)),
            catchError(err => {
                let errorMessage: DialogConfig = cloneDeep(this._faultyMachineCounterError);
                errorMessage.message.text = `We kunnen helaas geen actuele tellerstand van de machine ophalen. Het pauzeren van een order hierdoor is niet gelukt. Probeer het a.u.b. nog een keer`;
                return this._confirmationService.open(errorMessage).pipe(
                    switchMap(value => {
                        if (value === DialogReturnType.Confirm) {
                            return this.pauseOrder(order);
                        }

                        return of({});
                    }),
                );
            }),
        );
    }

    private atOrderChange(order: ProductionOrder = null): void {
        if (order) {
            const i = this._latestResponse.data.findIndex(o => o.id === order.id);
            if (this._latestResponse.data[i]) {
                this._latestResponse.data[i] = order;
                this._productionOrders.next(this._latestResponse);
            }
        }
        this._activeEntityMediatorService.reloadProductionOrder();
    }

    private filterSameAsPrevious(filter: ProductionOrderFilter): boolean {
        const areFiltersEqual = isEqual(filter, this._latestFilter);
        this._latestFilter = filter;
        return areFiltersEqual;
    }
}
