<ng-container>
  <div class="calculation-progress">
    <div class="px-3" style="width: 100%; overflow-x: auto">
      <table class="table calculation-progress-table">
        <thead>
          <tr>
            <td>
              <a routerLink="/dash"><i class="fas fa-arrow-left"></i> Terug </a>
            </td>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let order of productionOrders">
            <ng-container *ngIf="order.processing_station.true_station">
              <betech-production-order [productionOrder]="order"></betech-production-order>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
    <ng-container *ngIf="totals !== undefined">
      <div class="betech-page-footer" *ngIf="progressPercentage() < 100">
        <div class="container">
          <p class="p-2">
            {{ totals.approved }} van {{ productionOrders[0].quantity }} gereed gemeld
          </p>
          <div class="progress">
            <div
              class="progress-bar bg-success"
              role="progressbar"
              [ngStyle]="{ width: progressPercentage() + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <div class="betech-page-footer complete" *ngIf="progressPercentage() >= 100">
        <div class="container">
          <p class="p-2">
            <i class="fas fa-check fa-lg"></i> {{ progressPercentage() }} % afgerond
          </p>
          <div class="dashboard-button">
            <button type="button" class="btn btn-lg" routerLink="/dash">Door naar dashboard</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
