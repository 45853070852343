import {Component, OnInit, Output} from '@angular/core';
import { ChecklistService } from 'app/services/checklist/checklist.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {Subject} from "rxjs";
import {Employee} from "../../../swagger-client";

@Component({
    selector: 'betech-checklist',
    templateUrl: './checklist.component.html',
})
export class ChecklistComponent implements OnInit {
    private bsModalRef: BsModalRef;

    @Output() public checklistOverrideActiveEmployee: Subject<Employee> = new Subject<Employee>();

    constructor(public readonly checklistService: ChecklistService, private router: Router) {}

    public ngOnInit(): void {
        this.bsModalRef = null;
    }

    public setAsModal(modal: BsModalRef): void {
        this.bsModalRef = modal;
    }

    public loaded(): boolean {
        return !!this.checklistService.checklist;
    }

    public return(): void {
        if (null == this.bsModalRef) {
            return this.routeToDashboard();
        }

        return this.closeModal();
    }

    private routeToDashboard(): void {
        this.router.navigateByUrl('/dash');
    }

    private closeModal(): void {
        this.bsModalRef.hide();
        this.bsModalRef = null;
    }
}
