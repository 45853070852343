import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    EsbmodelService,
    ProcessingStation, ProducedAmountDetails,
    ProductionOrder, SensorData,
    Tool,
    ToolReplace,
    ToolXref,
} from '../../../swagger-client';
import {ToolReplaceComponent} from '../tool-replace/tool-replace.component';
import {ToolSheetComponent} from '../tool-sheet/tool-sheet.component';
import {Subscription} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SensorDataService} from '../../../services/sensor-data/sensor-data.service';
import {ActiveEntityMediatorService} from "../../../services/active-entity-mediator/active-entity-mediator.service";
import {ProducedAmountDataService} from "../../../services/produced-amount-data-service/produced-amount-data-service";

@Component({
    selector: 'betech-tool-list-item',
    templateUrl: './tool-list-item.component.html',
})
export class ToolListItemComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
    @ViewChild('toolSearch') public toolSearch: ElementRef;

    @Input() public groupXrefs: ToolXref[];
    @Input() public toolXref: ToolXref;
    @Input() public toolList: Tool[];
    @Input() public isLastItem: boolean;
    @Input() public lastReplace: ToolReplace;

    @Output() public toolXrefChange = new EventEmitter<ToolXref>();

    // Global variables
    public activeMachine: ProcessingStation;
    public currentProductionOrder: ProductionOrder;

    private sensorData: SensorData;

    @HostBinding('class.betech-table__row--editing') public editMode: boolean = false;
    public isSaving: boolean = false;

    public percentage: number;
    public producedSinceLastReplace: number;
    public forecastInSeconds: number;
    public forecast: string;

    public toolListRecover: Array<Tool>;

    public replaceModal: BsModalRef;
    public toolSheetModal: BsModalRef;

    public search: string;

    private subs: Subscription[] = [];

    constructor(
        private _esbModelService: EsbmodelService,
        private _activeEntityMediatorService: ActiveEntityMediatorService,
        private _modalService: BsModalService,
        private _changeDetectionRef: ChangeDetectorRef,
        private sensorDataService: SensorDataService,
    ) {
    }

    public ngOnInit() {
        if (this.editMode === false && (!this.toolXref.tool || !this.toolXref.expected_quantity)) {
            this.editMode = true;
        }

        this.subs.push(
            this.sensorDataService
                .observe()
                .subscribe((sensorData: SensorData) => {
                    if (sensorData) {
                        this.sensorData = sensorData;
                        this.refreshPercentageAndForecast();
                    }
                }),
            this._activeEntityMediatorService.activeProductionOrder$
                .subscribe((currentPO: ProductionOrder) => {
                this.currentProductionOrder = currentPO;
                this.refreshPercentageAndForecast();
            })
        );
        this.toolListRecover = this.toolList;
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public ngAfterViewChecked(): void {
        this._changeDetectionRef.detectChanges();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (typeof changes.lastReplace !== 'undefined') {
            this.refreshPercentageAndForecast();
        }
    }

    /**
     * Has tool sheet?
     */
    public hasToolSheet(): boolean {
        return (
            this.toolXref !== null &&
            this.toolXref.tool !== null &&
            this.toolXref.tool.tool_sheet !== null
        );
    }

    /**
     * Show tool sheet
     */
    public showToolSheet($event: MouseEvent, tool: Tool): void {
        $event.preventDefault();
        $event.stopPropagation();

        if (!tool || !tool.tool_sheet) {
            return;
        }

        const initialState = {
            selectedTool: tool,
        };

        // Show modal
        this.toolSheetModal = this._modalService.show(ToolSheetComponent, {
            initialState,
            class: 'm-0 mw-100 h-auto',
        });
    }

    /**
     * Quickly get group parent
     * TODO the 'group leader' is now determined by simply getting the last element in the array. This can cause small problems when changing the order. If the backend would implement a 'leader' flag, this could be solved
     */
    public get groupLeaderXref(): ToolXref {
        return this.groupXrefs[this.groupXrefs.length - 1];
    }

    /**
     * Fetch total produced by this machine for current order
     */
    public refreshPercentageAndForecast(): void {
        if (!this.currentProductionOrder) {
            return;
        }

        if (!this.hasQuantity()) {
            this.percentage = 0;
            this.forecast = '--:--';
            return;
        }

        let startCounterOfCurrentTool: number = this.toolXref.last_replace
            ? this.toolXref.last_replace.machine_counter
            : this.currentProductionOrder.start_machine_counter;
        this.producedSinceLastReplace = this.sensorData.processed_parts - startCounterOfCurrentTool;

        // when tools are used across orders we start at start_processed_parts
        if (!this.toolXref.last_replace && null !== this.toolXref.start_processed_parts)
        {
            this.producedSinceLastReplace += this.toolXref.start_processed_parts;
        }

        // when we have a negative value the counters have been reset
        if (this.producedSinceLastReplace < 0) {
            this.producedSinceLastReplace = null;
        }

        let expectedQuantity;
        if (this.toolXref.tool_position.tool_position_group) {
            expectedQuantity = this.getExpectedQuantity();
        } else {
            expectedQuantity = this.toolXref.expected_quantity;
        }

        // use min, max to ensure values stay within 0 and 100.
        this.percentage = Math.max(
            Math.round((this.producedSinceLastReplace / expectedQuantity) * 100),
            0,
        );
        this.percentage = Math.min(this.percentage, 100);

        if (
            this.toolXref.tool_position.tool_position_group &&
            this.toolXref.id !== this.groupLeaderXref.id
        ) {
            return;
        }

        const totalToGo = expectedQuantity - this.producedSinceLastReplace;
        this.forecastInSeconds = Math.max(
            totalToGo * this.currentProductionOrder.planned_cycle_time,
            0,
        );
    }

    private getExpectedQuantity(): number {
        let expectedQuantity = 0;

        // get the total processed for myself and previous group members
        for (let i = 0; i < this.groupXrefs.length; i++) {
            if (this.groupXrefs[i].id === this.toolXref.id) {
                break;
            }
            expectedQuantity += this.groupXrefs[i].expected_quantity;
        }

        return expectedQuantity;
    }

    /**
     * Replace tool
     */
    public replace(): void {
        const initialState = {
            selectedToolXref: this.toolXref,
            class: 'modal-lg',
        };

        // Show modal
        this.replaceModal = this._modalService.show(ToolReplaceComponent, {initialState});
        this.subs.push(
            this.replaceModal.content.notifyParent().subscribe(result => {
                if (result) {
                    this.refreshPercentageAndForecast();
                    this.toolXrefChange.emit(this.toolXref);
                }
            }),
        );
    }

    /**
     * Set selected tool
     */
    public setSelectedTool(tool: Tool): void {
        this.toolXref.tool = tool;
    }

    /**
     * Toggle default field
     */
    public toggleDefault(): void {
        if (this.editMode) {
            this.toolXref.is_default = !this.toolXref.is_default;
        }
    }

    /**
     * Has quantity?
     */
    public hasQuantity(): boolean {
        return (
            this.toolXref !== null &&
            this.toolXref.expected_quantity !== null &&
            this.toolXref.expected_quantity !== 0
        );
    }

    /**
     * Save ToolXref
     */
    public saveToolXref(): void {
        // TODO cannot save when already saving..?
        if (this.isSaving) {
            return;
        }

        this.isSaving = true;
        this._esbModelService
            .esbmToolXrefIdPut(this.toolXref.id, this.toolXref)
            .subscribe(this.onSaved.bind(this));
    }

    private onSaved(toolXref: ToolXref) {
        this.isSaving = false;
        this.editMode = false;
        this.toolXref = toolXref;
        this.refreshPercentageAndForecast();
        this.toolXrefChange.emit(this.toolXref);
    }

    /**
     * Edit ToolXref
     */
    public editToolXref(): void {
        this.editMode = true;
    }

    public filterToolList(search: string): void {
        if (search === null || search.length < 3) {
            this.toolList = this.toolListRecover;

            return;
        }

        this._esbModelService.esbmToolGet(100, null, null, search).subscribe(data => {
            this.toolList = data.data;
        });
    }

    public labelByArticleNumberPdcName(item: Tool): string {
        return item.articlenumber_pdc + ' - ' + item.name;
    }

    public toolChange(event): void {
        this.toolXref.tool = event;
        this.toolXref.is_default = true;
    }
}
