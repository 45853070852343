import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from './services/authentication/authentication.service';
import { BrowserSupportService } from './services/browser-support/browser-support.service';
import { filter, pairwise } from 'rxjs/operators';
import { VersionCheckService } from './check.service';
import { environment } from '../environments/environment';
import * as moment from 'moment';
import { Title } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'betech-root',
    templateUrl: './betech.component.html',
})
export class BetechComponent implements OnInit {
    public static PREVIOUS_URL: string;
    private favIcon: HTMLLinkElement = document.querySelector('#appIcon');

    constructor(
        private _authService: AuthenticationService,
        private _router: Router,
        private _browserSupportService: BrowserSupportService,
        private versionCheckService: VersionCheckService,
        private titleService: Title,
        private modalService: BsModalService,
    ) {
        this.modalService.config.ignoreBackdropClick = true;
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        if (!this._browserSupportService.verifyBrowser()) {
            this._router.navigateByUrl('/auth/not-supported');
        }
    }

    /**
     * Checks authentication and redirects if necessary to login
     */
    public checkAuthentication(): void {
        if (false === this._authService.isAuthenticated) {
            this._router.navigateByUrl('/auth/login');
        }
    }

    /**
     * Init the main app
     */
    public ngOnInit(): void {
        this.versionCheckService.initVersionCheck(environment.versionCheckURL);
        this.titleService.setTitle(environment.applicationName);

        this.favIcon.href = 'assets/icons/' + environment.config.favIconName;

        setTimeout(() => {
            location.reload();
        }, moment('24:00:00', 'hh:mm:ss').diff(moment(), 'milliseconds'));

        this._router.events
            .pipe(
                filter((event: any) => event instanceof RoutesRecognized),
                pairwise(),
            )
            .subscribe((event: any) => {
                // Set previous url for check in NotSupportedComponent
                BetechComponent.PREVIOUS_URL = event[0].urlAfterRedirects;
            });
        this.onResize();
        this.checkAuthentication();
    }
}
