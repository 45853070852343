import { Component, Input } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';

@Component({
    selector: 'betech-error-page',
    templateUrl: './error-page.component.html',
})
export class ErrorPageComponent {
    @Input() public iconClass: string = 'fa-comment-alt-lines';

    constructor(public alertService: AlertService) {}
}
