<div class="no-employee-modal">
    <div class="betech-modal__header">
        <h2><i class="fal fa-3x fa-user fa-fw"></i>Selecteer werknemer</h2>
        <div class="betech-modal__close" (click)="closeScreen()">
            <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
        </div>
    </div>
    <div class="container">
        <div class="row mx-4 mt-3 pb-4">
            <div class="col form-group">
                <div class="col-12">
                    <label>Werknemer:</label>
                    <betech-select-dropdown
                        [required]="true"
                        [items]="employeeList"
                        label="full_name"
                        search="full_name"
                        [model]="activeEmployee"
                        (modelChange)="setEmployee($event)"
                    ></betech-select-dropdown>
                </div>
            </div>
        </div>
        <div class="row submit-buttons">
            <div class="col">
                <button i18n class="btn btn-outline-secondary float-left" (click)="closeScreen()">
                    Cancel
                </button>
            </div>
            <div class="col-6">
                <button
                    i18n
                    class="btn btn-secondary float-right"
                    [disabled]="!activeEmployee"
                    (click)="submit()"
                >
                    Updaten
                </button>
            </div>
        </div>
    </div>
</div>
