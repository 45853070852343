<!-- Operator -->
<div class="betech-table__cell">
  <ng-container *ngIf="!editMode">
    <span class="text-truncate">{{ worklog.employee?.full_name || '-' }}</span>
  </ng-container>

  <betech-select-dropdown
    *ngIf="editMode"
    [required]="true"
    placeholder="Kies een medewerker"
    [items]="employeeList"
    label="full_name"
    search="full_name"
    [model]="worklog.employee"
    (modelChange)="worklog.employee = $event"
  ></betech-select-dropdown>
</div>

<!-- Start date -->
<div class="betech-table__cell" [ngClass]="{ 'edit-mode': editMode }">
  <span>{{ worklog.start_datetime | date: 'dd-MM-yyyy' }}</span>
</div>

<!-- Start time -->
<div class="betech-table__cell">
  <span *ngIf="!editMode">{{ worklog.start_datetime | date: 'HH:mm' }}</span>
  <timepicker
    class="d-inline-block"
    *ngIf="editMode"
    [(ngModel)]="worklog.start_datetime"
    [showSpinners]="false"
    [showMeridian]="false"
  ></timepicker>
</div>

<!-- End time -->
<div class="betech-table__cell">
  <span *ngIf="!editMode">{{
    worklog.end_datetime ? (worklog.end_datetime | date: 'HH:mm') : '--:--'
  }}</span>
  <timepicker
    *ngIf="editMode"
    class="d-inline-block"
    [(ngModel)]="worklog.end_datetime"
    [showSpinners]="false"
    [showMeridian]="false"
  ></timepicker>
</div>

<!-- Total time -->
<div class="betech-table__cell">
  {{ worklog.start_datetime | subtractDates: worklog.end_datetime || (now | async) }}
</div>

<!-- Cube -->
<div
  class="betech-table__cell"
  *ngIf="worklog.end_datetime"
  (click)="editMode ? saveWorklog() : startWorklogEdit()"
>
  <button class="btn btn-sm btn-outline-secondary">
    <i class="fas fa-fw" [ngClass]="editMode ? 'fa-save' : 'fa-pencil-alt'"></i>
  </button>
</div>

<div class="betech-table__cell" *ngIf="!worklog.end_datetime" (click)="stopStopwatch()">
  <button class="btn btn-sm btn-secondary">
    <i class="fas fa-fw fa-stop"></i>
  </button>
</div>

<!-- Dots -->
<div class="betech-table__cell"><i class="fas fa-ellipsis-v"></i></div>
