<!-- Operator -->
<div class="betech-table__cell">
  <betech-select-dropdown
    [required]="true"
    placeholder="Kies een medewerker"
    [items]="employeeList"
    label="full_name"
    search="full_name"
    [model]="activeEmployee"
    (modelChange)="activeEmployee = $event"
  ></betech-select-dropdown>
</div>

<!-- Start date -->
<div class="betech-table__cell">{{ now | async | date: 'dd-MM-yyyy' || '-' }}</div>

<!-- Start time-->
<div class="betech-table__cell">{{ now | async | date: 'HH:mm' || '-' }}</div>

<!-- End time -->
<div class="betech-table__cell">{{ '--:--' }}</div>

<!-- Total time -->
<div class="betech-table__cell">{{ '--:--' }}</div>

<!-- Cube -->
<div class="betech-table__cell" (click)="startStopWatch()">
  <button class="btn btn-sm btn-secondary">
    <i class="fas fa-play fa-fw"></i>
  </button>
</div>

<!-- Dots -->
<div class="betech-table__cell"><i class="fas fa-ellipsis-v"></i></div>
