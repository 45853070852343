import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ToolReplace,
    Employee,
    EsbmodelService,
    ToolXref,
    ProcessingStation,
} from '../../../swagger-client';
import { SensorDataService } from '../../../services/sensor-data/sensor-data.service';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActiveEntityMediatorService } from 'app/services/active-entity-mediator/active-entity-mediator.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import { DialogConfig } from '../../../shared/confirmation-dialog/dialog.config';
import { DialogReturnType } from '../../../shared/confirmation-dialog/dialog-return-type.enum';
import { ConfirmationService } from '../../../services/confirmation/confirmation.service';
import { cloneDeep } from 'lodash-es';

@Component({
    selector: 'betech-tool-replace',
    templateUrl: './tool-replace.component.html',
})
export class ToolReplaceComponent implements OnInit, OnDestroy {
    private _faultyMachineCounterError: DialogConfig = {
        title: 'Machine teller',
        message: {
            text: '',
        },
        icon: {
            show: false,
        },
        actions: {
            cancel: {
                show: false,
            },
            confirm: {
                label: 'probeer opnieuw',
            },
        },
        dismissible: true,
    };

    public selectedToolXref: ToolXref;

    private _result = new BehaviorSubject<any>(false);
    private subs: Subscription[] = [];

    public employeeList: Array<Employee>;
    public selectedEmployee: Employee;
    private activeMachine: ProcessingStation;

    constructor(
        private _esbmodelService: EsbmodelService,
        private _sensorDataService: SensorDataService,
        public bsModalRef: BsModalRef,
        private activeEntityMediatorService: ActiveEntityMediatorService,
        private _confirmationService: ConfirmationService,
    ) {}

    public ngOnInit() {
        // Fill employee list
        this._esbmodelService.esbmEmployeeGet().subscribe(
            data => {
                this.employeeList = data.data;
            },
            error => {
                // Error
            },
        );

        this.subs.push(
            this.activeEntityMediatorService.activeEmployee$.subscribe(
                (employee: Employee) => (this.selectedEmployee = employee),
            ),
            this.activeEntityMediatorService.activeMachine$.subscribe(
                (activeMachine: ProcessingStation) => {
                    this.activeMachine = activeMachine;
                },
            ),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    /**
     * show tool sheet in iframe
     */
    public showToolReplace(item: ToolXref): void {
        this.selectedToolXref = item;
    }

    /**
     * set bsModalRef
     */
    public setModalRef(modalRef: BsModalRef): void {
        this.bsModalRef = modalRef;
    }

    /**
     * Set onComplete callback function
     */
    public notifyParent(): any {
        return this._result;
    }

    /**
     * When button to change is pressed
     */
    public doChange() {
        if (!this.selectedEmployee || !this.activeMachine) {
            return;
        }

        const replace: ToolReplace = {
            timestamp: new Date(),
            employee: { id: this.selectedEmployee.id },
            tool_xref: { id: this.selectedToolXref.id },
            production_order: { id: this.selectedToolXref.production_order.id },
            processing_station: { id: this.activeMachine.id },
        };

        this.sendChange(replace);
    }

    public sendChange(replace: ToolReplace) {
        this.close();

        this._esbmodelService
            .esbmToolReplacePost(replace)
            .pipe(
                tap(() => {
                    this.selectedToolXref.last_replace = replace;
                    this._result.next(true);
                }),
                catchError(err => {
                    let errorMessage: DialogConfig = cloneDeep(this._faultyMachineCounterError);
                    errorMessage.message.text = `We kunnen helaas geen actuele tellerstand van de machine ophalen. De wissel is hierdoor niet gelukt. Probeer het a.u.b. nog een keer.`;
                    return this._confirmationService.open(errorMessage).pipe(
                        switchMap(value => {
                            if (value === DialogReturnType.Confirm) {
                                this.sendChange(replace);
                            }

                            return of({});
                        }),
                    );
                }),
            )
            .subscribe();
    }

    /**
     * Close the popup
     */
    public close(): void {
        this._result.next(false);
        if (this.bsModalRef)
        {
            this.bsModalRef.hide();
            this.bsModalRef = null;
        }
    }
}
