import {
    MeasurementData,
    MeasurementDataItem,
    MeasurementDefinitionItem,
    MeasurementSample,
    MeasurementSampleContainer,
} from '../../../swagger-client';
import { MeasurementDataModel } from './measurement-data.model';
import StatusEnum = MeasurementSample.StatusEnum;
import LocationEnum = MeasurementSample.LocationEnum;

/**
 * The extra interface is a trick to get all properties without code duplication in the class.
 * Also useful to override setter and getter instead of simple property.
 */
export interface MeasurementSampleModel extends MeasurementSample {}

export class MeasurementSampleModel implements MeasurementSampleModel {
    // private measurementData: MeasurementDataModel = null;

    // /**
    //  * When setting measurement datas, use the MeasurementDataModel.
    //  * And also use the opportunity to calculate some stats.
    //  */
    // public set measurement_data(data: any) {
    //     this.measurementData = data;
    // }
    //
    // /**
    //  * Simple getter
    //  */
    // public get measurement_data(): any {
    //     return this.measurementData;
    // }

    /**
     * Make data accessible in the same way as the interface for easy usage
     */
    constructor(private measurementSample: MeasurementSample) {
        this.measurement_data = null;

        for (const key of Object.keys(measurementSample)) {
            this[key] = measurementSample[key];
        }
    }

    /**
     * Wether or not the sample has measurement data that has actually been measured
     */
    public isMeasured(): boolean {
        return !!(this.measured_at || this.measurement_data != null);
    }

    /**
     * Helper to quickly determine if measurement is still in progress
     */
    public isInProgress(): boolean {
        return this.status === StatusEnum.INPROGRESS;
    }

    /**
     * Helper to find out if the (presumed measured) data has incorrect entries
     */
    public hasIncorrect(): boolean {
        return this.getIncorrect() > 0;
    }

    /**
     * Helper to quickly get number of incorrect data
     */
    public getIncorrect(): number {
        if (this.measurement_data === null) {
            return 0;
        }

        return this.measurement_data.measurement_items.filter(
            (data, index) => this.isValid(index) === false,
        ).length;
    }

    /**
     * Helper to quickly get number of total data
     */
    public getTotal(): number {
        if (this.measurement_data === null) {
            return 0;
        }

        return this.measurement_data.measurement_items.length;
    }

    /**
     * Helper to quickly get number of total data
     */
    public getTotalMeasured(): number {
        if (this.measurement_data === null) {
            return 0;
        }

        return this.measurement_data.measurement_items.filter(
            (data, index) => this.getMeasuredValue(index) !== null,
        ).length;
    }

    /**
     * Helper to quickly get number of correct data
     */
    public getCorrect(): number {
        if (this.measurement_data === null) {
            return 0;
        }
        return this.measurement_data.measurement_items.filter(
            (data, index) => this.isValid(index) === true,
        ).length;
    }

    /**
     * Determine if all of the data is measured
     */
    public isOneMeasured(): boolean {
        const measured: Array<MeasurementData> = this.measurement_data.measurement_items.filter(
            data => data.measured_value || 0 === parseFloat(data.measured_value),
        );

        return measured.length > 0;
    }

    /**
     * Helper to avoid loading LocationEnum everywhere
     */
    public isAtMachine(): boolean {
        return this.location === LocationEnum.MACHINE;
    }

    public getMeasuredValue(index: number): number {
        if (this.measurement_data === null) {
            return null;
        }

        const dataitem = this.measurement_data.measurement_items[index];

        if (dataitem === null || dataitem.measured_value === null) {
            return null;
        }

        let measuredValue: string = dataitem.measured_value;
        measuredValue = measuredValue.replace(/,/g, '.');
        const measuredValueFloat = parseFloat(measuredValue);

        return measuredValueFloat;
    }

    public getMeasuredValString(index: number): string {
        const dataitem = this.measurement_data.measurement_items[index];

        if (dataitem === null || dataitem.measured_value === null) {
            return null;
        }

        return dataitem.measured_value;
    }

    public hasValue(index: number): boolean {
        return this.getMeasuredValue(index) !== null;
    }

    public getBoolValue(index: number): boolean {
        return this.getMeasuredValString(index) === 'true' || this.getMeasuredValString(index) === '1';
    }

    public isWithin50PercentOfDefinition(index: number): boolean {
        return this.isValid(index, 0.5);
    }

    public isSkipped(index: number): boolean {
        if (this.measurement_data === null || this.measurement_definition === null) {
            return false;
        }

        const measurement: MeasurementDataItem = this.measurement_data.measurement_items[index];

        if (measurement.skipped === null) {
            // not measured
            return false;
        }

        return measurement.skipped;
    }

    public isValid(index: number, definitionFactor: number = 1.0): boolean {
        if (this.measurement_data === null || this.measurement_definition === null) {
            return null;
        }

        if (this.isSkipped(index)) {
            return true;
        }

        const measurement: MeasurementDataItem = this.measurement_data.measurement_items[index];

        if (measurement.measured_value === null) {
            return null;
        }

        const definition: MeasurementDefinitionItem =
            this.measurement_definition.definition_items[index];

        const min_value: number =
            definition.target_value - definition.min_tolerance * definitionFactor;
        const max_value: number =
            definition.target_value + definition.max_tolerance * definitionFactor;

        const sanitized_value: string = measurement.measured_value.replace(/,/g, '.');

        const measured_value: number = parseFloat(sanitized_value);

        if (definition.measurement_type === 'bool') {
            return measurement.measured_value === 'true' || measurement.measured_value === '1';
        }

        return !!(measured_value >= min_value && measured_value <= max_value);
    }
}
