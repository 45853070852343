<betech-card>
    <i card-icon class="fal fa-3x fa-file-contract fa-fw"></i>
    <span card-title i18n>Geen order actief</span>
    <span card-subtitle i18n></span>
    <button card-button class="btn btn-secondary btn-block rounded text-uppercase" [routerLink]="'/order'" >
        <span i18n>Order selecteren</span>
    </button>
    <a
        *ngIf="hasCamera"
        card-button
        class="btn btn-secondary btn-block rounded text-uppercase"
        href="#"
        (click)="openQrScanScreen($event)"
    >
        <span i18n>Scan Code</span>
    </a>
    <ng-container card-info>
        <div>
            <span>Op dit moment is er geen order actief.</span>
            <br />
            <span>Ga naar het overzicht en start een order om aan de slag te gaan.</span>
        </div>
    </ng-container>
    <ng-container card-footer>
    </ng-container>
</betech-card>

