import { Component, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractFilterComponent } from '../../filter-container/abstract.filter.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ScanQrCodeModalComponent } from '../../scan-qr-code-modal/scan-qr-code-modal.component';
import { debounceTime, take } from 'rxjs/operators';
import QrScanner from 'qr-scanner';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'betech-filter-order-number',
    templateUrl: './order-number.filter.component.html',
    providers: [{ provide: AbstractFilterComponent, useExisting: OrderNumberFilterComponent }],
})
export class OrderNumberFilterComponent
    extends AbstractFilterComponent
    implements OnInit, OnDestroy
{
    constructor(private _modalService: BsModalService, private _ngZone: NgZone) {
        super();
    }

    private subs: Subscription[] = [];
    inputControl: Subject<String> = new Subject<String>();

    public hasCamera: boolean = false;
    public modalRef: BsModalRef;

    private _orderNumber: string;
    public get orderNumber(): string {
        return this._orderNumber;
    }
    public set orderNumber(orderNumber: string) {
        this._orderNumber = orderNumber;

        if (orderNumber.length >= 4 || orderNumber.length === 0) {
            this.filterChange.next(orderNumber);
            this.inputControl.next(orderNumber);
        }
    }

    @Output() public filterChange = new EventEmitter();
    @Output() public filterChangeDelayed = new EventEmitter();

    public clear() {
        this._orderNumber = null;
        this.filterChange.emit(null);
        this.filterChangeDelayed.emit(null);
    }

    public ngOnInit(): void {
        QrScanner.hasCamera().then(result => {
            this._ngZone.run(() => {
                this.hasCamera = result;
            });
        });

        this.subs.push(
            this.inputControl
                .asObservable()
                .pipe(debounceTime(1000))
                .subscribe(value => this.filterChangeDelayed.emit(value)),
        );
    }

    public ngOnDestroy(): void {
        this.subs.forEach((s: Subscription) => s.unsubscribe());
    }

    public openQrScanScreen($event) {
        $event.preventDefault();
        $event.stopPropagation();

        const modalOptions = new ModalOptions();
        modalOptions.class = 'modal-update-progress';

        this.modalRef = this._modalService.show(ScanQrCodeModalComponent, modalOptions);
        this.modalRef.content.initModalRef(this.modalRef, {
            jsonField: 'production-number',
        });

        this.modalRef.content.scanResult$.pipe(take(1)).subscribe(result => {
            this.handleScanResult(result);
        });
    }

    private handleScanResult(result: string) {
        this._ngZone.run(() => {
            this._orderNumber = result;
            this.filterChange.emit(result);
            this.filterChangeDelayed.emit(result);
            this.modalRef.hide();
            this.modalRef = null;
        });
    }
}
