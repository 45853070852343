<div class="performance-modal">
  <div class="betech-modal__header">
    <h2><i class="fal fa-3x fa-chart-line fa-fw"></i> Performance updaten</h2>
    <div class="betech-modal__close" (click)="closeScreen()">
      <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
    </div>
  </div>
  <div class="row mx-4 mt-3 pb-4">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <label>Cyclustijd (sec)</label>
          <input
            type="number"
            name="cycle"
            id="cycle"
            [(ngModel)]="newPerformanceTarget.cycle_time"
            (ngModelChange)="update()"
            required
            #cyclus="ngModel"
          />
        </div>
        <div class="col-6">
          <label>Aantal (per uur)</label>
          <input
            type="number"
            step="0.01"
            name="amount_per_hour"
            id="amount_per_hour"
            [ngModel]="amountPerHour"
            disabled
          />
        </div>
      </div>
      <!--            <div class="row">-->
      <!--                <div class="col-6">-->
      <!--                    <label>Aantal x feedrate</label>-->
      <!--                    <input type="text" name="amount_feedrate" id="amount_feedrate" value="">-->
      <!--                </div>-->
      <!--                <div class="col-6">-->
      <!--                    <label>Feedrate</label>-->
      <!--                    <input type="text" name="feedrate" id="feedrate" [(ngModel)]="newPerformanceTarget.feedrate"-->
      <!--                           required #feedrate="ngModel">-->
      <!--                </div>-->
      <!--            </div>-->
      <div class="row">
        <div class="col-6">
          <label>Uren per dag</label>
          <input
            type="number"
            step="0.01"
            name="hours"
            id="hours"
            [(ngModel)]="availability.hours_per_day"
            required
            (ngModelChange)="update()"
            #hoursPerDay="ngModel"
          />
        </div>
        <div class="col-6">
          <label>Aantal per dag</label>
          <input type="number" step="0.01" name="amount_day" id="amount_day" [ngModel]="amountDay" disabled />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label>Dagen per week</label>
          <input
            type="number"
            step="0.01"
            name="hours"
            id="days"
            [(ngModel)]="availability.days_per_week"
            required
            (ngModelChange)="update()"
            #daysPerWeek="ngModel"
          />
        </div>
        <div class="col-6">
          <label>Aantal per week</label>
          <input type="number" step="0.01" name="amount_day" id="amount_week" [ngModel]="amountWeek" disabled />
        </div>
      </div>
      <!--            <div class="row">-->
      <!--                <div class="col-6">-->
      <!--                    <label>Overall Equipment Effectiveness</label>-->
      <!--                    <input type="text" name="effectiveness" id="effectiveness" [(ngModel)]="newPerformanceTarget.oee"-->
      <!--                           disabled>-->
      <!--                </div>-->
      <!--                <div class="col-6">-->
      <!--                    <label>Werkelijk aantal per dag</label>-->
      <!--                    <input type="text" name="true_amount_day" id="true_amount_day" value="{{trueAmountDay}}" disabled>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-12">
          <label>Toelichting</label>
          <textarea
            name="description"
            id="description"
            cols="50"
            rows="7"
            placeholder="Start met typen..."
            [(ngModel)]="newPerformanceTarget.remark"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Geupdatet door</label>
          <betech-select-dropdown
            [required]="true"
            [items]="employees"
            label="full_name"
            search="full_name"
            [model]="newPerformanceTarget.employee"
            (modelChange)="setEmployee($event)"
          ></betech-select-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div class="row submit-buttons">
    <div class="col-6">
      <button i18n class="btn btn-outline-secondary float-left" (click)="closeScreen()">
        Cancel
      </button>
    </div>
    <div class="col-6">
      <button
        i18n
        class="btn btn-secondary float-right"
        (click)="submit()"
        [attr.disabled]="cyclus.invalid || hoursPerDay.invalid || daysPerWeek.invalid ? true : null"
      >
        Updaten
      </button>
    </div>
  </div>
</div>
