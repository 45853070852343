import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    handleRequestsAutomatically: boolean;

    constructor(private _loadingService: LoadingService) {
        this._loadingService.auto$.subscribe(value => {
            this.handleRequestsAutomatically = value;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.handleRequestsAutomatically) {
            return next.handle(req);
        }

        this._loadingService._setLoadingStatus(true, req.url);

        return next.handle(req).pipe(
            finalize(() => {
                this._loadingService._setLoadingStatus(false, req.url);
            }),
        );
    }
}
