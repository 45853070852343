<div class="update-progress-modal">
  <div class="betech-modal__header">
    <h2>Voortgang bijwerken</h2>
    <div class="betech-modal__close" (click)="closeScreen()">
      <i class="fa-2x fas fa-times px-2 py-1 rounded border border-white"></i>
    </div>
  </div>
  <div class="row mx-4 mt-3 pb-4">
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <label for="approved">Al goedgekeurd</label>
          <input type="text" name="approved" id="already_approved" value="{{ correct }}" disabled />
        </div>
        <div class="col-6">
          <label for="rejected">Al afgekeurd</label>
          <input
            type="text"
            name="approved"
            id="already_rejected"
            value="{{ incorrect }}"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label for="approved">Totaal geproduceerd</label>
          <input type="text" name="approved" id="total_produced" value="{{ produced }}" disabled />
        </div>
        <div class="col-6">
          <label for="rejected">Totaal te produceren</label>
          <input
            type="text"
            name="approved"
            id="total_to_produced"
            value="{{ quantity }}"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <div class="progress-bar-modal col-12">
          <span>{{ percentageRejected }}% afgekeurd</span>
          <div class="progress">
            <div
              class="progress-bar"
              [ngClass]="
                percentageRejected === 0
                  ? 'bg-primary'
                  : percentageRejected === 100
                  ? 'bg-success text-white'
                  : 'bg-secondary'
              "
              [ngStyle]="{ width: percentageRejected + '%' }"
            ></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="progress-bar-modal col-12">
          <span>{{ percentageSucceed }}% afgerond van de hele order</span>
          <div class="progress">
            <div
              class="progress-bar"
              [ngClass]="
                percentageSucceed === 0
                  ? 'bg-primary'
                  : percentageSucceed === 100
                  ? 'bg-success text-white'
                  : 'bg-secondary'
              "
              [ngStyle]="{ width: percentageSucceed + '%' }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="row">
        <div class="col-6">
          <label for="approved">Goedgekeurd</label>
          <input
            type="number"
            step="0.01"
            name="approved"
            id="approved"
            [(ngModel)]="updateProgressModel.approved"
            (input)="updateCorrectValue()"
            [disabled]="updateProgressModel.autoApproved"
          />
        </div>
        <div class="col-6">
          <label for="rejected">Afgekeurd</label>
          <input
            type="number"
            step="0.01"
            name="rejected"
            id="rejected"
            [(ngModel)]="updateProgressModel.rejected"
            (input)="updateCorrectValue()"
          />
        </div>
      </div>
      <div *ngIf="requiresTypeUpdate" class="row">
        <div class="col-12">
          <label>Type update</label>
          <betech-select-dropdown
            [required]="true"
            [placeholder]=UPDATESTATUS
            [items]="updateTypes"
            [model]="updateProgressModel.status"
            (modelChange)="setTypeUpdate($event)"
          >
            ></betech-select-dropdown
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Categorie afkeur</label>
          <betech-select-dropdown
            [required]="true"
            [items]="rejectChoices"
            label="description"
            search="description"
            (modelChange)="setRejectCategory($event)"
          >
            ></betech-select-dropdown
          >
        </div>
      </div>
        <div *ngIf="updateProgressModel.reject_category !== null && causeChoices.length + failureChoices.length > 0" class="row">
            <div class="col-6">
                <label>Categorie storing</label>
                <betech-select-dropdown
                    [required]="false"
                    [items]="failureChoices"
                    label="description"
                    search="description"
                    (modelChange)="setFailureCategory($event)"
                >
                    ></betech-select-dropdown
                >
            </div>
            <div class="col-6">
                <label>Categorie oorzaak</label>
                <betech-select-dropdown
                    [required]="false"
                    [items]="causeChoices"
                    label="description"
                    search="description"
                    (modelChange)="setCauseCategory($event)"
                >
                    ></betech-select-dropdown
                >
            </div>
        </div>
      <div class="row">
        <div class="col-12">
          <label for="rejected_description">Beschrijving</label>
          <textarea
            name="rejected"
            id="rejected_description"
            cols="10"
            rows="10"
            [(ngModel)]="updateProgressModel.description"
          ></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <label>Opgegeven door:</label>
          <betech-select-dropdown
            [required]="true"
            [items]="employeeList"
            label="full_name"
            search="full_name"
            [model]="this.activeEmployee"
            (modelChange)="setEmployee($event)"
          >
            ></betech-select-dropdown
          >
        </div>
      </div>
      <div class="row submit-buttons">
        <div class="col-6">
          <button i18n class="btn btn-outline-secondary float-left" (click)="closeScreen()">
            Annuleren
          </button>
        </div>
        <div class="col-6">
          <button
            i18n
            class="btn btn-secondary float-right"
            [disabled]="pendingSent || !validForm"
            (click)="sendOrderUpdates()"
          >
            <i *ngIf="pendingSent" class="fas fa-sync fa-spin"></i>
            {{ submitButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
